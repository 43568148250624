import React from 'react';

import { Flex } from '@chakra-ui/react';

import { Seperator } from 'components/Seperator';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import DzbAddLegalRepresentativeModal from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/dzb/DzbAddLegalRepresentativeModal';
import DzbEditLegalRepresentativeModal from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/dzb/DzbEditLegalRepresentativeModal';
import { DzbLegalRepresentativeAccordion } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/dzb/DzbLegalRepresentativeAccordion';
import { DzbPortalLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import DzbDeleteLegalRepresentativeModal from './DzbDeleteLegalRepresentativeModal';
import { useEditAssociatedPeople } from '../../../hooks/useEditAssociatedPeople';

type Props = {
  legalRepresentatives: Array<DzbPortalLegalRepresentative>;
};

export const DzbLegalRepresentatives = ({ legalRepresentatives }: Props) => {
  const t = useTranslations();
  const { heading, noLegalRepresentatives } =
    translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives;
  const {
    isAddModalOpen,
    onAddModalClose,
    onAddModalOpen,
    isDeleteModalOpen,
    onDeleteModalClose,
    onDeleteModalOpen,
    isEditModalOpen,
    onEditModalClose,
    onEditModalOpen,
    selectedPerson: selectedLegalRep,
    setSelectedPerson: setSelectedLegalRep,
  } = useEditAssociatedPeople<DzbPortalLegalRepresentative>();

  return (
    <>
      <Seperator
        divider
        title={`${t(heading)} (${legalRepresentatives.length})`}
        action={<EditModeActions onAddModalOpen={onAddModalOpen} />}
      >
        {legalRepresentatives.length > 0 ? (
          legalRepresentatives.map((legalRepresentative) => {
            return (
              <DzbLegalRepresentativeAccordion
                key={legalRepresentative.id}
                legalRepresentative={legalRepresentative}
                setSelectedLegalRep={setSelectedLegalRep}
                onEditModalOpen={onEditModalOpen}
                onDeleteModalOpen={onDeleteModalOpen}
                index={legalRepresentatives.indexOf(legalRepresentative)}
              />
            );
          })
        ) : (
          <Flex justifyContent={'center'} w={'full'} p={12} color="text.tertiary">
            {t(noLegalRepresentatives)}
          </Flex>
        )}
      </Seperator>
      <DzbAddLegalRepresentativeModal isOpen={isAddModalOpen} onClose={onAddModalClose} />
      <DzbEditLegalRepresentativeModal
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        initialValues={selectedLegalRep}
      />
      <DzbDeleteLegalRepresentativeModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        legalRep={selectedLegalRep}
      />
    </>
  );
};

type ActionProps = {
  onAddModalOpen: () => void;
};

const Actions = ({ onAddModalOpen }: ActionProps) => {
  const t = useTranslations();
  const { actions } = translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives;
  return (
    <ButtonComponent
      variant={'link'}
      onClick={(event) => {
        onAddModalOpen();
        event.preventDefault();
      }}
      leftIcon={<AddIcon boxSize={6} display={'block'} />}
      testId="addLegalRepresentativeButton"
    >
      {t(actions.add.caption)}
    </ButtonComponent>
  );
};

const EditModeActions = withAssociatedPeopleEditMode(Actions);
