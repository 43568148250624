import {
  DzbPortalLegalRepresentative,
  PortalLegalRepresentative,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';

const transformBaseLegalRepresentative = (legalRepresentative: any) => {
  const attributes = legalRepresentative.attributes;

  return {
    id: legalRepresentative.id,
    email: attributes.email,
    birthDate: attributes['birth_date'],
    phoneNumber: attributes['phone_number'],
    firstName: attributes['first_name'],
    lastName: attributes['last_name'],
    soleSignatureAuthorized: attributes['additional_info']['sole_signature_authorized'],
    salutation: attributes['salutation'],
    source: attributes['source'],
    potentiallyManipulated: attributes['potentially_manipulated'],
  } satisfies PortalLegalRepresentative;
};

export const transformLegalRepresentatives = (
  legalRepresentatives: any,
): Array<PortalLegalRepresentative> => {
  return legalRepresentatives.map((legalRepresentative: any) => {
    return transformBaseLegalRepresentative(legalRepresentative);
  });
};

export const transformDzbLegalRepresentatives = (
  legalRepresentatives: any,
): Array<DzbPortalLegalRepresentative> => {
  return legalRepresentatives.map((legalRepresentative: any) => {
    const attributes = legalRepresentative.attributes;

    return {
      ...transformBaseLegalRepresentative(legalRepresentative),
      taxIdentificationNumber: attributes['additional_info']['tax_number'],
    } satisfies DzbPortalLegalRepresentative;
  });
};
