import React from 'react';

import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { Container } from 'components/PortalPage/styles';
import Spinner from 'components/Spinner';
import { translations } from 'new/form/common/types';
import { AssociatedPersonHeading } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/AssociatedPersonHeading';
import { BeneficialOwners } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/BeneficialOwners';
import { DzbLegalRepresentatives } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/dzb/DzbLegalRepresentatives';
import { useApprovalAssociatedPeople } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/useApprovalAssociatedPeople';
import { useGetDzbAssociatedPeople } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/useFetchAssociatedPeople';
import { ButtonComponent } from 'theme/components/Button';
import { useTranslations } from 'utils/hooks/useTranslations';

import { withAssociatedPeopleEditMode } from '../components/associatedPeopleMode/withAssociatedPeopleEditMode';

export const DzbAssociatedPeople = () => {
  const { companyId } = useParams<{ id: string; companyId: string }>();
  const { isLoading, legalRepresentatives, beneficiaryOwners } = useGetDzbAssociatedPeople({
    companyId,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container>
      <Box pt={12} pb={20}>
        <AssociatedPersonHeading />
        <DzbLegalRepresentatives legalRepresentatives={legalRepresentatives} />
        <BeneficialOwners beneficialOwners={beneficiaryOwners} />
        <EditModeConfirmAssociatedPeopleAction />
      </Box>
    </Container>
  );
};

const ConfirmAssociatedPeopleAction = () => {
  const { mutateAsync, isLoading } = useApprovalAssociatedPeople();
  const t = useTranslations();

  return (
    <Flex justifyContent={'flex-end'} mt={8}>
      <ButtonComponent
        variant={'primary'}
        leftIcon={<CheckIcon boxSize={4} display={'block'} mr={2} />}
        onClick={mutateAsync}
        isLoading={isLoading}
        testId="confirmAssociatedPeopleButton"
      >
        {t(translations.pages.companiesDetails.associatedPerson.new.confirmAssociactedPersons)}
      </ButtonComponent>
    </Flex>
  );
};

const EditModeConfirmAssociatedPeopleAction = withAssociatedPeopleEditMode(
  ConfirmAssociatedPeopleAction,
);
