import React from 'react';

import { Parser as HtmlToReactParser } from 'html-to-react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Wrapper from './Wrapper';

const htmlToReactParser = new HtmlToReactParser();

const getHtml = (content) => htmlToReactParser.parse(content);

const PartnersContent = ({ content }) => <Wrapper>{getHtml(content)}</Wrapper>;

PartnersContent.propTypes = {
  content: string.isRequired,
};

const mapStateToProps = (
  {
    partner: {
      copyImprint,
      copyDataProtection,
      copyTermsAndConditions,
      copyAdditionalTermsAndConditions,
    },
  },
  { name },
) => {
  const contents = {
    termsAndConditions: copyTermsAndConditions,
    privacyPolicy: copyDataProtection,
    impressum: copyImprint,
    additionalTermsAndConditions: copyAdditionalTermsAndConditions,
  };
  return {
    content: contents[name],
  };
};

export default withRouter(connect(mapStateToProps)(PartnersContent));
