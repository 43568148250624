import React from 'react';

import { Divider } from '@chakra-ui/react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Spinner from 'components/Spinner';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import { CbBankProcessSpecificFiles } from 'pages/customerPortal/InquiryDetails/DocumentExchange/ProcessSpecificFiles/CbBankProcessSpecificFiles';
import { InternalFiles } from 'pages/operationPortal/InquiryDetails/DocumentExchange/InternalFiles';
import RequestsForCustomer from 'pages/operationPortal/InquiryDetails/DocumentExchange/RequestsForCustomer/RequestsForCustomer';

import { useOperationDocumentExchange } from './useOperationDocumentExchange';
import AdditionalFilesFromCustomer from '../AdditionalFilesFromCustomer/AdditionalFilesFromCustomer';
import { MultipleFilesForCustomer } from '../MultipleFilesForCustomer/MultipleFilesForCustomer';

export const CbBankOperationDocumentExchange = () => {
  const { isLoading } = useOperationDocumentExchange();

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  return (
    <article>
      <InquiryDetailsHeader />
      <Divider opacity="1" my={12} borderColor="border.lightGrey" />
      <CbBankProcessSpecificFiles />
      <RequestsForCustomer />
      <AdditionalFilesFromCustomer />
      <InternalFiles />
      <MultipleFilesForCustomer />
    </article>
  );
};
