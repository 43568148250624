import React from 'react';

import { cbBankFields } from 'cbbank/inquiry/fields';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { translations } from 'new/form/common/types';
import { AdditionalTermsAndConditionsField } from 'pages/inquiryFlow/legal/termsAndConditions/AdditionalTermsAndConditionsField';
import { PrivacyPolicyField } from 'pages/inquiryFlow/legal/termsAndConditions/PrivacyPolicyField';
import { TermsOfServiceField } from 'pages/inquiryFlow/legal/termsAndConditions/TermsOfServiceField';
import { useTranslations } from 'utils/hooks/useTranslations';

export const ComplianceSection = () => {
  const t = useTranslations();
  const { title } = translations.inquiryType.cbBank.pages.companyData.sections.compliance;
  const {
    privacyPolicyAccepted: {
      caption: privacyPolicyLabel,
      links: { privacyPolicy: privacyPolicyText },
    },
    termsAndConditionsAccepted: {
      caption: tosLabel,
      links: { termsAndConditions: tosText },
    },
    factoringTerms: {
      caption: factoringTermsLabel,
      links: { factoringTerms: factoringTermsText },
    },
  } = translations.inquiryType.cbBank.pages.companyData.sections.compliance.fields;

  const { termsOfService, dataProtection, factoringTerms } =
    cbBankFields.companyDataPage.complianceSection;

  return (
    <FormSection title={t(title)} hideSectionNumber>
      <FormRow>
        <TermsOfServiceField
          fieldName={termsOfService}
          labelTranslationKey={tosLabel}
          tosTextTranslationKey={tosText}
        />
      </FormRow>
      <FormRow>
        <PrivacyPolicyField
          fieldName={dataProtection}
          labelTranslationKey={privacyPolicyLabel}
          privacyPolicyTextTranslationKey={privacyPolicyText}
        />
      </FormRow>
      <FormRow>
        <AdditionalTermsAndConditionsField
          fieldName={factoringTerms}
          labelTranslationKey={factoringTermsLabel}
          additionalTermsAndConditionsTextTranslationKey={factoringTermsText}
        />
      </FormRow>
    </FormSection>
  );
};
