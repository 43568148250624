import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import NavigationBarComponent from 'components/NavigationBar';
import { NavigationLink } from 'components/NavigationBar/styles';
import paths from 'constants/paths';
import {
  OperationRouteName,
  OperationsRouteDeclaration,
  useOperationDetailsRoutes,
} from 'pages/operationPortal/InquiryDetails/useOperationDetailsRoutes';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getCompanyId } from 'store/inquiryDetails/selectors';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import { useTranslations } from 'utils/hooks/useTranslations';
import { hasPermission as hasPermissionCreator } from 'utils/user/conditionals';

import { useFetchPlanningEvaluationProfitability } from '../../../../mittweida/portals/operations/hooks/useFetchPlanningEvaluationProfitability';
import { useGetMarketData } from '../../PlanningEvaluation/SharedComponent/PlanningEvaluation.service';
import CompanySubnavigation from '../CompanyRoutes/CompanySubnavigation';
import RealEstateSubNavigation from '../PlanningEvaluationRoutes/RealEstateSubNavigation';

const AdditionalNav = () => {
  const t = useTranslations();
  return (
    <NavigationLink exact to={paths.operation.inquiryList}>
      <ArrowLeftIcon boxSize={6} mr={2} />
      {t('buttons.back')}
    </NavigationLink>
  );
};

const OperationNavigationBar = ({ id }: { id: string }) => {
  useGetMarketData(id);
  useFetchPlanningEvaluationProfitability();
  const pathDeclarations = useOperationDetailsRoutes();
  const hasPermission = hasPermissionCreator();
  const companyId = useSelector(getCompanyId);
  const location = useLocation();
  const isMarketDataEnabled = useConfig(CONFIG.IS_MARKET_DATA_ENABLED);
  const isProfitabilityCalculationEnabled = useConfig(CONFIG.IS_PROFITABILITY_CALCULATION_ENABLED);
  const isRealEstateExpertEnabled = useConfig(CONFIG.IS_REAL_ESTATE_EXPERT_ENABLED);
  const isQuickCheckEnabled = useConfig(CONFIG.IS_QUICK_CHECK_ENABLED);
  const isMarketValueNavigationEnabled =
    isMarketDataEnabled ||
    isProfitabilityCalculationEnabled ||
    isRealEstateExpertEnabled ||
    isQuickCheckEnabled;

  const companyNavigation = location.pathname.startsWith(
    paths.operation.inquiryDetails.companyDetails.root(id, companyId),
  );
  const realEstateNavigation = location.pathname.startsWith(
    paths.operation.inquiryDetails.planningEvaluation.root(id),
  );

  const filterMarketValueNavigation = (route: OperationsRouteDeclaration) =>
    route.name === OperationRouteName.PlanningEvaluation ? isMarketValueNavigationEnabled : true;
  const t = useTranslations();
  const mainNav = pathDeclarations
    .filter((r) => (r.permission ? hasPermission(r.permission) : true))
    .filter(filterMarketValueNavigation)
    .map((route) => (
      <NavigationLink
        key={route.path}
        to={route.path.replace(':id', id).replace(':companyId', companyId)}
        data-testid={`${route.name}-link`}
      >
        {t(route.title)}
      </NavigationLink>
    ));

  return (
    <>
      <NavigationBarComponent mainNavigation={mainNav} additionalNavigation={<AdditionalNav />} />
      {companyNavigation && <CompanySubnavigation inquiryId={id} companyId={companyId} />}
      {realEstateNavigation && <RealEstateSubNavigation />}
    </>
  );
};

export default OperationNavigationBar;
