import { useSelector } from 'react-redux';

import { useInquiryProcessSpecificUploadedFiles } from 'api/CompeonReverseApi/operation/queryHooks/files';
import { IFile } from 'models/File.model';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

type QueryOptions = Parameters<typeof useInquiryProcessSpecificUploadedFiles>[0];
type QueryOptionsProps = Partial<{
  [K in Extract<keyof QueryOptions, 'enabled' | 'refetchInterval'>]: QueryOptions[K];
}>;

export const useProcessSpecificFiles = (kinds?: string[], queryOptions?: QueryOptionsProps) => {
  const inquiryId = useSelector(getInquiryIdSelector);

  const { data: files = [] } = useInquiryProcessSpecificUploadedFiles<IFile[]>({
    ...queryOptions,
    variables: { inquiryId },
  });

  return kinds?.length ? files.filter((file) => kinds.includes(file.kind)) : files;
};
