import { dzbFields } from 'dzb/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  customValidations,
  fieldValidators,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateContactPersonSection: ValidateSectionFunction<InquiryType.dzb> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap({
    validationMap: contactPersonSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { firstName, lastName, phoneNumber, email } = dzbFields.personalDataPage.contactPersonSection;

export const contactPersonSectionValidationMap = {
  [firstName]: fieldValidators.name().isValidName().required(),
  [lastName]: fieldValidators.name().isValidName().required(),
  [phoneNumber]: fieldValidators.phoneNumber().isMobilePhoneNumber().optional(),
  [email]: fieldValidators.string().required().custom(customValidations.isEmail),
};
