import _get from 'lodash/get';

import { cbBankFields, CbBankFieldTypes } from 'cbbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { getValidCompanyNameFieldName, hasExistingCompany } from 'utils/company';

const {
  companySearchSection: { selectedCompanyName: selectedCompanyNameFieldName },
  companyDetailsCompletionSection: {
    name,
    street,
    zipCode,
    city,
    legalForm,
    industry,
    olderThan2Years,
    subjectToAccounting,
    positiveEquity,
    positiveAnnualResult,
  },
} = cbBankFields.companyDataPage;

export const validateCompanyDetailsCompletionSection: ValidateSectionFunction<
  InquiryType.cbBank
> = (form, values, store, conditions) => {
  const companyNameFieldName = getValidCompanyNameFieldName<CbBankFieldTypes>(
    values,
    selectedCompanyNameFieldName,
  );
  const companyName = _get(values, companyNameFieldName);
  const existingCompany = hasExistingCompany(companyName);

  if (existingCompany) {
    return validateFieldValidationMap({
      validationMap: existingCompanyValidationMap,
      form,
      values,
      conditions,
    });
  }
  return validateFieldValidationMap({
    validationMap: companyDetailsCompletionSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const existingCompanyValidationMap = {
  [legalForm]: fieldValidators.string().required(),
  [industry]: fieldValidators.string().required(),
  [olderThan2Years]: fieldValidators.stringBoolean().required(),
  [subjectToAccounting]: fieldValidators.stringBoolean().required(),
  [positiveEquity]: fieldValidators.stringBoolean().required(),
  [positiveAnnualResult]: fieldValidators.stringBoolean().required(),
};

export const companyDetailsCompletionSectionValidationMap = {
  ...existingCompanyValidationMap,
  [name]: fieldValidators.string().required(),
  [street]: fieldValidators.string().required(),
  [zipCode]: fieldValidators.postalCode().isValid().required(),
  [city]: fieldValidators.string().required(),
};
