import React, { useState } from 'react';

import { Flex, VStack } from '@chakra-ui/react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoadingModal from 'components/LoadingModal';
import { useLoadingModal } from 'components/LoadingModal/useLoadingModal';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { translations } from './common/types';
import { useValidateAndScroll } from './scrollToError';
import { useMode, useInquiryProcessConfig } from './state/inquiryProcessConfig/hooks';
import { PageStateActionTypes } from './state/pageStateReducer';
import { useBeforeNextStep } from './useBeforeNextStepAction';
import { useBeforeSubmit } from './useBeforeSubmitAction';
import paths from '../../constants/paths';
import { InquiryType } from '../../modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from '../../shared/chooseSelectedInquiryTypeSpecificComponent';
import ButtonComponent from '../../theme/components/Button';
import ArrowRightIcon from '../../theme/components/Icon/ArrowRightIcon';

export function useChangeStep() {
  const {
    state: { currentStep, inquiryMode, pageValidations },
    dispatch,
    formStateData: { stepData, routeToPageNameMap, summaryPath: inquirySummaryPath, paths },
  } = useInquiryProcessConfig();
  const history = useHistory();
  const id = useSelector(getInquiryIdSelector);

  const isStepValid = pageValidations[currentStep as any].progress.isSectionValid;
  const hasPreviousStep = !!stepData[currentStep as any].previousStep;
  const nextStep = stepData[currentStep as any].nextStep;
  const hasNextStep = !!(nextStep || inquirySummaryPath);
  const previousPage = inquiryMode === 'create' ? paths.createPath : paths.listPaths[inquiryMode];

  const navigateToPath = (path: string) => {
    if (id && (inquiryMode === 'editOperation' || inquiryMode === 'editCustomer')) {
      history.push(path.replace(':id', id));
    } else {
      history.push({ pathname: path, state: { previousStep: currentStep } });
    }
  };

  const moveToNextStep = () => {
    const nextStep = stepData[currentStep as any].nextStep;

    if (nextStep) {
      dispatch({ type: PageStateActionTypes.NEXT_STEP, payload: nextStep });
      const nextPath = routeToPageNameMap[nextStep as any][inquiryMode];
      if (nextPath) {
        navigateToPath(nextPath);
      }
    } else {
      const summaryPath = inquirySummaryPath ? inquirySummaryPath[inquiryMode] : '/'; // not all inquiry flow have summary page
      navigateToPath(summaryPath);
    }
  };

  const moveToPreviousStep = () => {
    const previousStep = stepData[currentStep as any].previousStep;

    if (previousStep) {
      dispatch({ type: PageStateActionTypes.NEXT_STEP, payload: previousStep });
      const previousPath = routeToPageNameMap[previousStep as any][inquiryMode];
      if (previousPath) {
        navigateToPath(previousPath);
      }
    }
  };

  const moveToPreviousPage = () => {
    if (previousPage) {
      history.push(previousPage);
    }
  };

  return {
    isStepValid,
    moveToNextStep,
    hasPreviousStep,
    moveToPreviousStep,
    hasNextStep,
    moveToPreviousPage,
  };
}

type StepActionProps = {
  children: React.ReactNode;
};

export const StepActions = ({ children }: StepActionProps) => {
  const CancelEditButton = useSelectedInquiryTypeSpecificValue({
    [InquiryType.mmv]: () => null,
    [InquiryType.dzb]: () => null,
    default: CancelEditButtonImpl,
  });
  return (
    <VStack w={'full'} alignItems={'flex-end'} gap={4}>
      {children}
      <CancelEditButton />
    </VStack>
  );
};

const CancelEditButtonImpl = () => {
  const mode = useMode();
  const t = useTranslations();
  const inquiryId = useSelector(getInquiryIdSelector);
  const history = useHistory();

  if (mode === 'create') {
    return null;
  }

  const path =
    mode === 'editOperation'
      ? paths.operation.inquiryDetails.details
      : paths.customer.inquiryDetails.details;

  return (
    <ButtonComponent
      variant={'secondary'}
      onClick={() => {
        history.push(path.replace(':id', inquiryId!!));
      }}
    >
      {t(translations.buttons.cancelEdit)}
    </ButtonComponent>
  );
};

type ChangeStepProps = {
  isNextButtonLoading?: boolean;
};

export const ChangeStep = ({ isNextButtonLoading = false }: ChangeStepProps) => {
  const { submitting } = useFormState();
  const { submit } = useForm();
  const t = useTranslations();
  const validateAndScroll = useValidateAndScroll();
  const [isNextStepLoading, setIsNextStepLoading] = useState(false);
  const { isStepValid, moveToNextStep, hasPreviousStep, moveToPreviousStep, hasNextStep } =
    useChangeStep();
  const {
    formStateData: { formName },
  } = useInquiryProcessConfig();

  // Call the useBeforeNextStep to get the actual action function
  const beforeNextStepAction = useBeforeNextStep();
  // Call the useBeforeSubmit to get the actual action function
  const beforeSubmitAction = useBeforeSubmit();

  // Loading modal controls
  const {
    isLoadingModalOpen,
    onLoadingModalOpen,
    onLoadingModalClose,
    loadingModalProps,
    setLoadingModalProps,
  } = useLoadingModal();
  const loadingModalControls = { onLoadingModalOpen, onLoadingModalClose, setLoadingModalProps };

  const nextStepHandler = async () => {
    const actionProps = { loadingModalControls, moveToNextStep };
    if (beforeNextStepAction) {
      setIsNextStepLoading(true);
      await beforeNextStepAction(actionProps);
      setIsNextStepLoading(false);
    } else {
      moveToNextStep();
    }
  };

  const submitHandler = async () => {
    const actionProps = { loadingModalControls, submit };
    if (beforeSubmitAction) {
      setIsNextStepLoading(true);
      await beforeSubmitAction(actionProps);
      setIsNextStepLoading(false);
    } else {
      submit();
    }
  };

  return (
    <>
      <StepActions>
        <Flex w={'full'} justifyContent={'flex-end'} gap={4} mt={8}>
          {hasPreviousStep && (
            <ButtonComponent
              onClick={moveToPreviousStep}
              leftIcon={<ArrowLeftIcon boxSize={6} />}
              variant={'tertiary'}
              data-testid="previous-step-button"
            >
              {t(translations.buttons.back)}
            </ButtonComponent>
          )}
          {hasNextStep ? (
            <ButtonComponent
              onClick={isStepValid ? nextStepHandler : validateAndScroll}
              leftIcon={<ArrowRightIcon boxSize={6} />}
              data-testid="next-step-button"
              isLoading={isNextStepLoading || isNextButtonLoading}
            >
              {t(translations.buttons.continue)}
            </ButtonComponent>
          ) : (
            <ButtonComponent
              form={formName}
              onClick={isStepValid ? submitHandler : validateAndScroll}
              leftIcon={<ArrowRightIcon boxSize={6} />}
              isLoading={submitting || isNextStepLoading}
              data-testid="finalize-inquiry-button"
            >
              {t(translations.buttons.send)}
            </ButtonComponent>
          )}
        </Flex>
      </StepActions>
      <LoadingModal
        isOpen={isLoadingModalOpen}
        onClose={onLoadingModalClose}
        {...loadingModalProps}
      />
    </>
  );
};
