import React, { useCallback, useEffect } from 'react';

import _debounce from 'lodash/debounce';
import { useForm } from 'react-final-form';
import { useSelector, useStore } from 'react-redux';

import { checkEmailAvailabilityAction } from 'api/CompeonReverseApi/customer/actions';
import FormRow from 'components/FormRow';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import LogoutButton from 'components/LogoutButton';
import { PhoneNumberField } from 'components/PhoneNumberField';
import StaticField from 'components/StaticField';
import { translations } from 'new/form/common/types';
import { getSectionValidations } from 'new/form/progress/useCalculateProgress';
import {
  useFormValidations,
  useInquiryProcessConfig,
  useMode,
} from 'new/form/state/inquiryProcessConfig/hooks';
import { PageStateActionTypes } from 'new/form/state/pageStateReducer';
import { useToasts } from 'shared/hooks/useToasts';
import { store } from 'store';
import {
  isEmailAvailableSelector,
  isLoadingEmailAvailabilitySelector,
} from 'store/emailAvailable/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isEmailRegex } from 'utils/regexes';
import { getCurrentUser } from 'utils/user/getters';

import { ContactPersonEmail } from './ContactPersonEmail';

// This is a workaround until we add react-query. With react-query we can pass the query client to our validation functions
// and execute async queries inside our ValidationFunctions to avoid all of these hacky async redux thingies.
export function useCheckEmailValidity<T>({
  email,
  values,
}: {
  email: string | undefined;
  values: T;
}) {
  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });
  const {
    state,
    dispatch,
    formStateData: { conditions },
  } = useInquiryProcessConfig();
  const validations = useFormValidations();
  const validationsForCurrentStep = validations[state.currentStep];
  const form = useForm();
  const store = useStore();

  const isEmailAvailable = useSelector(isEmailAvailableSelector);
  const isEmailLoading = useSelector(isLoadingEmailAvailabilitySelector);

  //eslint-disable-next-line
  const checkEmailDebounce = useCallback(
    _debounce((email: string | undefined) => {
      if (email) {
        const isValidEmail = isEmailRegex.test(email);
        if (isValidEmail) {
          makeCall(checkEmailAvailabilityAction(email));
        }
      }
    }, 200),
    [],
  );

  useEffect(() => {
    checkEmailDebounce(email);
  }, [email, checkEmailDebounce]);

  useEffect(() => {
    if (!isEmailLoading) {
      const sectionValidations = getSectionValidations(
        validationsForCurrentStep,
        form,
        values,
        store,
        conditions,
        state.currentStep as string,
      );

      dispatch({
        type: PageStateActionTypes.SET_PROGRESS,
        payload: {
          ...state.pageValidations,
          [state.currentStep]: {
            ...sectionValidations,
          },
        },
      });
    }
    //eslint-disable-next-line
  }, [isEmailAvailable, isEmailLoading, dispatch]);
}

type Props = {
  fieldNames: {
    gender: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
};

export const ContactPersonSection = ({ fieldNames }: Props) => {
  return (
    <>
      <FormRow>
        <GenderWithField name={fieldNames.gender} />
        <InputWithField name={fieldNames.firstName} />
        <InputWithField name={fieldNames.lastName} />
      </FormRow>
      <FormRow>
        {/* Default validation needs to be removed here because it will overwrite the validation defined in
        the fieldValidationMaps (used for newer partners) and will therefore not be connected to the form validation */}
        <ContactPersonEmail name={fieldNames.email} useDefaultValidation={false} />
      </FormRow>
      <FormRow>
        <PhoneNumberField name={fieldNames.phone} />
      </FormRow>
    </>
  );
};

export const LoggedInContactPersonSection = () => {
  let user = getCurrentUser(store.getState());
  const t = useTranslations();
  const mode = useMode();
  const { error } = useToasts();

  const { userProfileName, userProfileEmail } =
    translations.pages.userProfile.sections.completingContactData.fields;

  if (!user) {
    error({ description: t('errors.actionFailed') });
  }

  user = user as { name: string; email: string };
  return (
    <FormRow alignItems={'flex-start'}>
      <StaticField caption={t(userProfileName.caption)} text={user.name} />
      <StaticField caption={t(userProfileEmail.caption)} text={user.email} />
      {mode === 'create' ? <LogoutButton /> : null}
    </FormRow>
  );
};
