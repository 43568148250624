import React from 'react';

import FormSection from 'components/FormSection';
import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

import SMSVerification from './components/SMSVerification';

type Props = {
  locationState?: string;
};

export const ContractLaneSection = ({ locationState }: Props) => {
  const t = useTranslations();
  const { sectionTitle, subheader } =
    translations.inquiryType.cbBank.pages.offer.lanes.contractLane;

  return (
    <>
      <PageHeading subheading={t(subheader)} />
      <FormSection title={t(sectionTitle)} hideSectionNumber>
        <SMSVerification locationState={locationState} />
      </FormSection>
    </>
  );
};
