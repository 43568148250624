import React from 'react';

import { Flex } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import {
  formValidator,
  useErrorTranslator,
} from 'modules/Inquiry/inquiryFieldValidation/useErrorTranslations';
import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import { DzbLegalRepresentativeFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/dzb/DzbLegalRepresentativeFields';
import { dzbLegalRepresentativeSchema } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/dzb/dzbLegalRepresentativeSchema';
import { DzbLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { usePatchDzbLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/legalRepresentatives/patchLegalRepresentative';
import ButtonComponent from 'theme/components/Button';
import { ModalComponent, ModalProps } from 'theme/components/Modal';
import { useTranslations } from 'utils/hooks/useTranslations';

const EDIT_LEGAL_REPRESENTATIVE_FORM_ID = 'editLegalRepresentativeForm';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  initialValues: DzbLegalRepresentative | undefined;
};

const DzbEditLegalRepresentativeModal = ({ isOpen, onClose, initialValues }: Props) => {
  const { mutateAsync, isLoading } = usePatchDzbLegalRepresentative();
  const { refetchInquiry } = useFetchInquiryData();

  const errorTranslator = useErrorTranslator();
  const t = useTranslations();
  const { actions } = translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives;

  const onSubmit = async (values: DzbLegalRepresentative) => {
    await mutateAsync(values);
    await refetchInquiry();
    onClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t(actions.edit.title)}
      footer={
        <Flex gap={4}>
          <ButtonComponent variant={'tertiary'} onClick={onClose} testId="cancelButton">
            {t(actions.edit.cancel)}
          </ButtonComponent>
          <ButtonComponent
            isLoading={isLoading}
            variant={'primary'}
            form={EDIT_LEGAL_REPRESENTATIVE_FORM_ID}
            type={'submit'}
            testId="confirmButton"
          >
            {t(actions.edit.confirm)}
          </ButtonComponent>
        </Flex>
      }
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(values) => formValidator(values, dzbLegalRepresentativeSchema, errorTranslator)}
        render={({ handleSubmit }) => {
          return (
            <form id={EDIT_LEGAL_REPRESENTATIVE_FORM_ID} onSubmit={handleSubmit}>
              <DzbLegalRepresentativeFields mode={'edit'} />
            </form>
          );
        }}
      />
    </ModalComponent>
  );
};

export default withAssociatedPeopleEditMode(DzbEditLegalRepresentativeModal);
