import Joi from '@hapi/joi';

import { CbBankFieldTypes } from 'cbbank/inquiry/fields';
import { DzbFieldTypes } from 'dzb/inquiry/fields';
import { HausbankFieldTypes } from 'hausbank/inquiry/fields';
import { MittweidaFieldTypes } from 'mittweida/inquiry/fields';
import { FieldTypes as MmvFieldTypes } from 'mmv/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { SummaryType } from 'new/form/formatters/units';
import de from 'shared/locale/de.json';

import { ValidateSectionFunction } from '../useValidateFields';

export type FieldTypes<Type> = Type extends InquiryType.mmv
  ? MmvFieldTypes
  : Type extends InquiryType.profiMittweida
  ? MittweidaFieldTypes
  : Type extends InquiryType.dzb
  ? DzbFieldTypes
  : Type extends InquiryType.hausbank
  ? HausbankFieldTypes
  : Type extends InquiryType.cbBank
  ? CbBankFieldTypes
  : never;

type ToString<T> = T extends undefined
  ? never
  : T extends Array<infer U>
  ? PropertiesToString<U>
  : T extends Date
  ? string
  : T extends object
  ? PropertiesToString<T>
  : string;

type ToSummaryType<T> = T extends undefined
  ? never
  : T extends Array<infer U>
  ? PropertiesToSummaryType<U>
  : T extends Date
  ? SummaryType
  : T extends object
  ? PropertiesToSummaryType<T>
  : SummaryType;

type MapFrontendToBackend = string | { mapping: string; keys: Record<string, string> } | symbol;

export type ToMapFrontendToBackend<T> =
  | (T extends undefined
      ? never
      : T extends Array<infer U>
      ? { mappingKey: string; keys: PropertiesToMapFrontendToBackend<U> }
      : T extends Date
      ? MapFrontendToBackend
      : T extends object
      ? PropertiesToMapFrontendToBackend<T>
      : MapFrontendToBackend)
  | Function;

export type PropertiesToString<T> = {
  [K in keyof T]: ToString<T[K]>;
};

export type PropertiesToSummaryType<T> = {
  [K in keyof T]: ToSummaryType<T[K]>;
};

export type PropertiesToMapFrontendToBackend<T> = {
  [K in keyof T]: ToMapFrontendToBackend<T[K]>;
};

export type ToUndefined<T> = T extends Date ? T | undefined : T extends object ? T : T | undefined;

export type ToNumber<T> = T extends object ? PropertiesToString<T> : number;
export type PropertiesToNumber<T> = {
  [K in keyof T]: ToNumber<T[K]>;
};

export type ValidationMapType<Values = MittweidaFieldTypes> = Record<
  string,
  | Joi.Schema
  | ((value: any, values: Values) => boolean)
  | ((value: any, values: Values) => Joi.Schema)
>;

export type LabelsMap = Record<string, string>;

type NestedJsonKeys<T> = {
  [P in keyof T]: T[P] extends object ? { sectionString: string } & NestedJsonKeys<T[P]> : P;
};

const germanTranslationKeys = {
  ...de,
} as const;

export function getKeyObject<T>(obj: any, parentKeyString: string = ''): NestedJsonKeys<T> {
  const keyObject: any = {};

  for (const key in obj) {
    const keyString = parentKeyString ? `${parentKeyString}.${key}` : key;
    if (typeof obj[key] === 'object') {
      keyObject[key] = {
        sectionString: keyString,
        ...getKeyObject(obj[key], keyString),
      };
    } else {
      keyObject[key] = keyString;
    }
  }

  return keyObject as NestedJsonKeys<T>;
}

export const translations = getKeyObject<typeof germanTranslationKeys>(germanTranslationKeys);

export type ValidationSection<Type> = Record<string, ValidateSectionFunction<Type>>;

export type ValidationRecord<Type, T = FieldTypes<Type>> = {
  [page in keyof T]: {
    [section in keyof T[page]]: ValidateSectionFunction<Type>;
  };
};

type Flatten<T> = T extends object
  ? {
      [K in keyof T]: K extends keyof any[] ? never : T[K] extends object ? Flatten<T[K]> : T[K];
    }[keyof T]
  : T;

export type SecondLevelKeys<T> = Flatten<{
  [K in keyof T]: T[K] extends object ? keyof T[K] : never;
}>;
