import React from 'react';

import { Divider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Condition from 'components/Condition';
import Page from 'components/Page/Page';
import { translations } from 'new/form/common/types';
import Header from 'pages/inquiryFlow/FinalPage/SpecializedFinalPage/Header';
import { isLeadProcessLane } from 'store/inquiryProcess/selectors';
import { useScrollToTop } from 'utils/hooks/useScrollToTop';
import { useTranslations } from 'utils/hooks/useTranslations';

import { CbBankFinalAccountActivationAction } from './CbBankFinalAccountActivationAction';
import { CbBankFinalDocumentsUploadAction } from './CbBankFinalDocumentsUploadAction';
import { CbBankFinalIndicativeOfferAction } from './CbBankFinalIndicativeOfferAction';
import { CbBankFinalSignContractAction } from './CbBankFinalSignContractAction';
import { CbBankFinalSubmitInvoiceAction } from './CbBankFinalSubmitInvoiceAction';

const divider = <Divider opacity="1" my={16} borderColor="border.lightGrey" />;

export const CbBankFinalPage = () => {
  const t = useTranslations();
  const isLead = useSelector(isLeadProcessLane);
  const {
    heading: { header, subheader },
  } = translations.inquiryType.cbBank.pages.final;
  useScrollToTop();

  return (
    <Page>
      <Header header={t(header)} subheader={t(subheader)} />
      {divider}
      <CbBankFinalAccountActivationAction />
      <Condition condition={isLead}>
        {divider}
        <CbBankFinalDocumentsUploadAction />
        {divider}
        <CbBankFinalIndicativeOfferAction />
      </Condition>
      {divider}
      <CbBankFinalSignContractAction />
      {divider}
      <CbBankFinalSubmitInvoiceAction />
      {divider}
    </Page>
  );
};
