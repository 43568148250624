import React from 'react';

import { useSelector } from 'react-redux';

import { FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { StyledDetailedHeaderSubtitle } from 'modules/InquiryDetails/InquiryDetailsHeader/StyledDetailsHeader';
import { translations } from 'new/form/common/types';
import { getCompanyDetails } from 'pages/operationPortal/CompaniesDetails/store/selectors';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = {
  showCreatedAt?: boolean;
};

export const AssociatedPersonHeading = ({ showCreatedAt }: Props) => {
  const companyDetails = useSelector(getCompanyDetails);
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const { title } = translations.pages.companiesDetails.associatedPerson.new;
  const t = useTranslations();

  return (
    <HeadingComponent as={'h2'} variant={'primary'} mb={8}>
      {t(title)}

      <TextComponent
        as={'span'}
        display={'block'}
        fontWeight={FONT_WEIGHT_REGULAR}
        color={'text.tertiary'}
      >
        {companyDetails.companyName}
      </TextComponent>
      {showCreatedAt && (
        <TextComponent
          as={'span'}
          display={'block'}
          fontWeight={FONT_WEIGHT_REGULAR}
          color={'text.tertiary'}
        >
          <StyledDetailedHeaderSubtitle>
            {t('pages.inquiryDetails.header.from')} {formatDateWithTime(inquiryDetails?.createdAt)}
          </StyledDetailedHeaderSubtitle>
        </TextComponent>
      )}
    </HeadingComponent>
  );
};
