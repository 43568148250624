import { AxiosResponse } from 'axios';
import { createMutation } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import queryKeys from 'constants/queryKeys';

import endpoints from '../endpoints';

type BankInfoResponse = {
  data: {
    attributes: {
      bic: string;
      bank_name: string;
    };
  };
};

type BankInfoQueryVariables = {
  iban: string;
};

// RQ Muatation is being used here because of the nature of the request as on-demand being more suited for a mutation instead of a query that will be executed as soon as the hook is called
export const useGetBankInfo = createMutation<
  AxiosResponse<BankInfoResponse>,
  BankInfoQueryVariables
>({
  mutationKey: [queryKeys.banks.getBankInfo],
  mutationFn: async ({ iban }) => {
    return EcoBankingAxiosClientAuthedInstance.get(endpoints.BANKS.BANK_INFO.compose(), {
      params: { data: { attributes: { iban } } },
    });
  },
});
