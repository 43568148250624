import { HausbankFieldTypes, hausbankFields } from 'hausbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  customValidations,
  fieldValidators,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { isEmailAvailableSelector } from 'store/emailAvailable/selectors';
import { isLoggedInUser } from 'utils/user/conditionals';

export const validateContactPersonSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  const isEmailAvailable = isEmailAvailableSelector(store.getState());
  const isLoggedIn = isLoggedInUser();

  if (isLoggedIn) {
    return true;
  }

  const areFieldsValid = validateFieldValidationMap<InquiryType.hausbank>({
    validationMap: contactPersonSectionValidationMap,
    form,
    values,
    conditions,
  });

  return areFieldsValid && isEmailAvailable;
};

const { salutation, firstName, lastName, email, phoneNumber } =
  hausbankFields.personalDataPage.contactPersonSection;

export const contactPersonSectionValidationMap = {
  [salutation]: fieldValidators.string().required(),
  [firstName]: fieldValidators.name().isValidName().required(),
  [lastName]: fieldValidators.name().isValidName().required(),
  [email]: customValidations.newIsEmail<HausbankFieldTypes>,
  [phoneNumber]: fieldValidators.phoneNumber().isMobilePhoneNumber().required(),
};
