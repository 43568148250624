import { CbBankFieldTypes } from 'cbbank/inquiry/fields';

type CbBankBusinessDetailsFieldTypes =
  CbBankFieldTypes['financingNeedPage']['businessDetailsSection'];

export const mapCbBankBusinessDetails = (values: CbBankBusinessDetailsFieldTypes) => ({
  turnover_share_of_largest_client: values.turnoverShareOfLargestClient,
  centralised_settlement: values.centralisedSettlement,
  trade_credit_insurance: values.tradeCreditInsurance,
  down_payment_or_installments: values.downPaymentOrInstallments,
  assigned_elsewhere: values.assignedElsewhere,
  customers_abroad: values.customersAbroad,
});
