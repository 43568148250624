import React from 'react';

import { CheckboxWithField } from 'components/Checkbox';
import paths from 'constants/paths';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  fieldName: string;
  labelTranslationKey: string;
  additionalTermsAndConditionsTextTranslationKey: string;
}

export const AdditionalTermsAndConditionsField = ({
  fieldName,
  labelTranslationKey,
  additionalTermsAndConditionsTextTranslationKey,
}: Props) => {
  const t = useTranslations();

  const additionalTermsAndConditionsText = t(additionalTermsAndConditionsTextTranslationKey);
  const templateData = {
    factoringTerms: (
      <a
        key="additionalTermsAndConditions"
        target="_blank"
        rel="noreferrer"
        href={paths.additionalTermsAndConditions}
      >
        {additionalTermsAndConditionsText}
      </a>
    ),
  };
  const label = t(labelTranslationKey, templateData);

  return <CheckboxWithField name={fieldName} text={<span>{label}</span>} />;
};
