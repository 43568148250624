import React from 'react';

import { EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';

import StaticField from 'components/StaticField';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import { CbBankPortalBeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import { PotentiallyManipulatedWarning } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/PotentiallyManipulatedWarning';
import { translateSource } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/utils';
import { BrandSubtitle, StyledOverviewFields } from 'pages/operationPortal/CompaniesDetails/styles';
import { AccordionComponent } from 'theme/components/Accordion';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { replaceDotWithComma } from 'utils/valueFormats';

type Props = {
  beneficialOwner: CbBankPortalBeneficialOwner;
  setSelectedBeneficialOwner: (beneficialOwner: CbBankPortalBeneficialOwner) => void;
  onEditModalOpen: () => void;
  onDeleteModalOpen: () => void;
  index: number;
};

const {
  beneficialOwners: { fields },
  subheadings,
} = translations.pages.companiesDetails.associatedPerson.new;

export const CbBankBeneficialOwnerAccordion = ({
  beneficialOwner,
  setSelectedBeneficialOwner,
  onEditModalOpen,
  onDeleteModalOpen,
  index,
}: Props) => {
  const t = useTranslations();

  return (
    <AccordionComponent
      key={beneficialOwner.id}
      headingLevel={'h3'}
      title={`${beneficialOwner.firstName} ${beneficialOwner.lastName}`}
      mb={8}
      data-testId={`beneficialOwnerAccordion-${index}`}
      action={
        <EditModeActions
          beneficialOwner={beneficialOwner}
          setSelectedBeneficialOwner={setSelectedBeneficialOwner}
          onEditModalOpen={onEditModalOpen}
          onDeleteModalOpen={onDeleteModalOpen}
        />
      }
    >
      <StyledOverviewFields>
        <StaticField
          caption={t(fields.source.caption)}
          text={translateSource(beneficialOwner.source, t)}
        />
      </StyledOverviewFields>
      <StyledOverviewFields>
        <StaticField caption={t(fields.scope.caption)} text={beneficialOwner.scope} />
        <StaticField
          caption={t(fields.capitalOrVotingRightsPercentage.caption)}
          text={
            beneficialOwner.capitalOrVotingRightsPercentage
              ? `${replaceDotWithComma(
                  Number(beneficialOwner.capitalOrVotingRightsPercentage).toFixed(2),
                )} ${t(translations.other.percent)}`
              : undefined
          }
        />
      </StyledOverviewFields>
      <BrandSubtitle>{t(subheadings.personalData)}</BrandSubtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t(fields.birthDate.caption)}
          text={beneficialOwner.birthDate ? formatDateDays(beneficialOwner.birthDate) : undefined}
        />
        <StaticField caption={t(fields.birthPlace.caption)} text={beneficialOwner.birthPlace} />
        <StaticField caption={t(fields.nationality.caption)} text={beneficialOwner.nationality} />
      </StyledOverviewFields>
      <StyledOverviewFields>
        <StaticField
          caption={t(fields.placeOfResidence.caption)}
          text={beneficialOwner.placeOfResidence}
        />
        <StaticField
          caption={t(fields.countryOfResidence.caption)}
          text={beneficialOwner.countryOfResidence}
        />
      </StyledOverviewFields>
    </AccordionComponent>
  );
};

type ActionsProps = {
  beneficialOwner: CbBankPortalBeneficialOwner;
  setSelectedBeneficialOwner: (beneficialOwner: CbBankPortalBeneficialOwner) => void;
  onEditModalOpen: () => void;
  onDeleteModalOpen: () => void;
};

const Actions = ({
  beneficialOwner,
  setSelectedBeneficialOwner,
  onEditModalOpen,
  onDeleteModalOpen,
}: ActionsProps) => {
  return (
    <Flex gap={4}>
      <PotentiallyManipulatedWarning
        potentiallyManipulated={beneficialOwner.potentiallyManipulated}
      />
      <div data-testId={'editBoIcon'}>
        <EditIcon
          boxSize={5}
          display={'block'}
          cursor={'pointer'}
          color={'brand.default'}
          onClick={() => {
            setSelectedBeneficialOwner(beneficialOwner);
            onEditModalOpen();
          }}
        />
      </div>

      <div data-testId={'deleteBoIcon'}>
        <DeleteIcon
          boxSize={5}
          display={'block'}
          color={'brand.default'}
          cursor={'pointer'}
          onClick={() => {
            setSelectedBeneficialOwner(beneficialOwner);
            onDeleteModalOpen();
          }}
        />
      </div>
    </Flex>
  );
};

const EditModeActions = withAssociatedPeopleEditMode(Actions);
