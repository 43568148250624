import React, { useCallback, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import endpoints from 'api/CompeonReverseApi/endpoints';
import { useStartCbBankBankSignatureProcess } from 'api/CompeonReverseApi/operation/queryHooks';
import { useDeleteProcessSpecificFile } from 'api/CompeonReverseApi/operation/queryHooks/files';
import { useGenerateCbBankContract } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import submitBankOfferAction from 'api/CompeonReverseApi/operation/submitBankOffer/actions';
import { useNewUploadFile } from 'components/UploadBlock/hooks/useNewUploadFile';
import { useSelectFile } from 'components/UploadBlock/hooks/useSelectFile';
import { UPLOADING_STATE } from 'components/UploadBlock/NewFileRequestBlock/FileRequest.service';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import queryKeys from 'constants/queryKeys';
import { FileType, IFile } from 'models/File.model';
import { UploadedFile } from 'models/FileApi.model';
import { FileKind } from 'models/FileKind.type';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { useProcessSpecificFiles } from 'pages/customerPortal/InquiryDetails/DocumentExchange/ProcessSpecificFiles/useProcessSpecificFiles';
import {
  chooseSelectedInquiryTypeSpecificValue,
  useSelectedInquiryTypeSpecificValue,
} from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import useParamsSafe from 'shared/hooks/useParamsSafe';
import { useToasts } from 'shared/hooks/useToasts';
import {
  generateContractAction,
  mapInquiryDetailsApiResponseAction,
  startSigningProcess,
} from 'store/inquiryDetails/actions';
import { getInquiryLane } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { UploadOfferTableActions } from './UploadOfferTableActions';

interface SelectedFileRow {
  id: string;
  file: File;
  cols: React.ReactNode[];
}

interface UploadedFileRow {
  id: string;
  cols: React.ReactNode[];
}

interface GeneratedContractFileRow {
  id: string;
}

const useSubmitOffers = (setFiles?: React.Dispatch<React.SetStateAction<UploadedFileRow[]>>) => {
  const { id } = useParamsSafe(['id']);
  const { makeCall } = useDispatchApiCall();
  const dispatch = useDispatch();
  const { selectedInquiryType } = useFormConfig();
  const { success, error: notifyError } = useToasts();
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles');
  const goBackToDashboard = useGoBackToDashboard();
  const { mutateAsync: startCbBankSignatureProcess, isLoading } =
    useStartCbBankBankSignatureProcess();

  const defaultSubmitHandler = async (allFileIds: string[]) => {
    await makeCall(
      submitBankOfferAction({ inquiryId: id, fileIds: allFileIds, type: 'multiple' }),
      ({ payload }: { payload: AxiosResponse }) => {
        dispatch(mapInquiryDetailsApiResponseAction(payload.data));
        const createdOffer = payload.data.included.find(
          (item: unknown & { type: string }) => item.type === 'offers',
        );

        if (selectedInquiryType !== InquiryType.bfs) {
          dispatch(startSigningProcess(createdOffer.id, allFileIds));
        }
        success({ description: t('success') });
        goBackToDashboard();
      },
    );
  };

  const cbBankSubmitHandler = async () => {
    try {
      await startCbBankSignatureProcess({ inquiryId: id });
      success({ description: t('success') });
      // Reset the files to empty state so submit button will be disabled
      if (setFiles) {
        setFiles([]);
      }
      setTimeout(() => {
        goBackToDashboard();
      }, 2000);
    } catch (err) {
      notifyError({
        description: t('error'),
      });
    }
  };

  const submitHandler = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: defaultSubmitHandler,
    [InquiryType.cbBank]: cbBankSubmitHandler,
  });

  return { handler: submitHandler, isLoading };
};

const useGoBackToDashboard = () => {
  const history = useHistory();
  const { id } = useParamsSafe(['id']);
  return useCallback(
    () => history.push(paths.operation.inquiryDetails.dashboard.replace(':id', id)),
    [history, id],
  );
};

// Functionality used by both deferred and immediate upload offer
export const useUploadOfferUtils = () => {
  const { id } = useParamsSafe(['id']);

  // Define which InquiryType should use the deferred upload offer => All files will be uploaded together once the user clicks the submit button
  const deferred = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: false,
    [InquiryType.hausbank]: true,
  });
  // True if InquiryType has to "register" the uplaoded files by posting the fileIDs to the backend
  const submitOffer = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: true,
    [InquiryType.hausbank]: false,
  });

  const multipleFiles = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: false,
    [InquiryType.hausbank]: true,
  });
  const isAssessmentFile = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: false,
    [InquiryType.hausbank]: true,
    [InquiryType.cbBank]: true,
  });

  const fileType = isAssessmentFile ? FileType.ASSESSMENT_FILE : FileType.FILE;

  const endpoint = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: endpoints.FILES.LIST.compose(),
    [InquiryType.hausbank]: endpoints.INQUIRIES.SPECIALIZED.HAUSBANK.CONTRACTS.UPLOAD.compose({
      params: { inquiryId: id },
    }),
    [InquiryType.cbBank]: endpoints.INQUIRIES.SPECIALIZED.CB_BANK.CONTRACTS.UPLOAD.compose({
      params: { id },
    }),
  });

  return { multipleFiles, isAssessmentFile, fileType, endpoint, deferred, submitOffer };
};

// Functionality used by the deferred upload offer
export const useDeferredUploadOffer = (submitOffer: boolean = true) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles');
  const [files, setFiles] = useState<SelectedFileRow[]>([]);
  const [fileIds, setFileIds] = useState<{ fileName: string; id: string }[]>([]);
  const { multipleFiles, isAssessmentFile, endpoint } = useUploadOfferUtils();
  const { handler: submitOffers } = useSubmitOffers();
  const goBackToDashboard = useGoBackToDashboard();

  const { success, error: errorToast } = useToasts();

  const { uploadProgress } = useNewUploadFile(endpoint);

  const handleRemove = (id: string) => setFiles((files) => files.filter((file) => file.id !== id));

  // Sets the files in state so they can be uploaded together and are available for preview
  const handleSetFiles = (selectedFiles: File[]) => {
    const newFiles: SelectedFileRow[] = [];
    selectedFiles.forEach((selectedFile) => {
      const id = uniqueId();
      newFiles.push({
        id,
        file: selectedFile,
        cols: [
          selectedFile.name,
          <UploadOfferTableActions
            key={id}
            selectedFile={{ id, file: selectedFile }}
            onRemove={handleRemove}
          />,
        ],
      });
    });
    setFiles((files) => [...files, ...newFiles]);
  };

  // Updates the fileIds with the uploaded fileIds
  const handleSetFileIds = (uploadedFile: UploadedFile, selectedFile: File) => {
    setFileIds((fileIds) => [...fileIds, { fileName: selectedFile.name, id: uploadedFile.id }]);
  };

  const { onFileSelected, onFilesSelected, errorMsg, isDropping, uploadingState, setIsDropping } =
    useSelectFile({ onSuccess: handleSetFileIds, url: endpoint, isAssessmentFile });

  const submitDocuments = async () => {
    // Choose the correct upload function depending on the number of files
    if (files.length > 1) {
      onFilesSelected(files.map((file) => file.file));
    } else {
      onFileSelected(files[0].file);
    }
    // Check if offers have to be submitted/registered to the backend
    if (submitOffer) {
      const allFileIds = fileIds.map((file) => file.id);
      await submitOffers(allFileIds);
    }
  };

  // Show success/error toast when uploading was finished or failed
  useEffect(() => {
    if (!submitOffer) {
      if (uploadingState === UPLOADING_STATE.ERROR) {
        errorToast({
          description: t('errors.fileUpload'),
        });
      } else if (uploadingState === UPLOADING_STATE.FINISHED) {
        success({ description: t('success') });
        goBackToDashboard();
      }
    }
  }, [errorMsg, errorToast, goBackToDashboard, submitOffer, success, t, uploadingState]);

  return {
    files,
    handleSetFiles,
    submitDocuments,
    uploadingState,
    uploadProgress,
    errorMsg,
    setIsDropping,
    isDropping,
    goBackToDashboard,
    multipleFiles,
  };
};

export const useUploadOffer = () => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.uploadOffer.uploadFiles');
  const { id } = useParamsSafe(['id']);
  const { makeCall } = useDispatchApiCall();
  const [files, setFiles] = useState<UploadedFileRow[]>([]);
  const [lastGeneratedcontract, setLastGeneratedcontract] = useState<GeneratedContractFileRow[]>(
    [],
  );
  const { multipleFiles, isAssessmentFile, fileType, endpoint } = useUploadOfferUtils();
  const { handler: submitOffers, isLoading: submitOffersLoading } = useSubmitOffers(setFiles);
  const goBackToDashboard = useGoBackToDashboard();
  const cbBankContracts = useProcessSpecificFiles([FileKind.CB_BANK_CONTRACT], {
    refetchInterval: files.length ? false : 3000,
  });
  const queryClient = useQueryClient();
  const { mutateAsync: deleteFile } = useDeleteProcessSpecificFile({
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.inquiries.processSpecificUploadedFiles],
      }),
  });
  const { mutateAsync: generateContract, isLoading: generateContractLoading } =
    useGenerateCbBankContract();
  const { selectedInquiryType } = useFormConfig();

  const lane = useSelector(getInquiryLane);
  const { error: notifyError, success } = useToasts();
  const isContractCreationInLead =
    useConfig(CONFIG.IS_AUTOMATIC_CONTRACT_CREATION_IN_LEAD) && lane === InquiryLane.lead;
  const isContractCreationInOffer =
    useConfig(CONFIG.IS_AUTOMATIC_CONTRACT_CREATION_IN_OFFER) && lane === InquiryLane.offer;

  const { uploadProgress } = useNewUploadFile(endpoint);

  const createContract = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: isContractCreationInLead || isContractCreationInOffer,
    [InquiryType.cbBank]: true,
  });

  const disableGenerateContract = Boolean(cbBankContracts.length);

  const defaultHandleRemove = (id: string) => {
    setFiles((files) => files.filter((file) => file.id !== id));
  };

  const cbBankHandleRemove = async (id: string) => {
    try {
      await deleteFile({ fileId: id });
      success({ description: t('deleteFile.success') });
    } catch (err) {
      notifyError({ description: t('deleteFile.error') });
    }
  };

  const handleRemove = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: defaultHandleRemove,
    [InquiryType.cbBank]: cbBankHandleRemove,
  });

  const handleSetFiles = (uploadedFile: UploadedFile, selectedFile: File) => {
    setFiles((files) => [
      ...files,
      {
        id: uploadedFile.id,
        cols: [
          selectedFile.name,
          <UploadOfferTableActions
            key={id}
            selectedFile={{
              id: uploadedFile.id,
              fileName: selectedFile.name,
              type: fileType,
            }}
            onRemove={handleRemove}
          />,
        ],
      },
    ]);
  };

  const handleSetGeneratedContractFiles = (uploadedFile: UploadedFile) => {
    if (lastGeneratedcontract.length) {
      setFiles((files) => files.filter((file) => file.id !== lastGeneratedcontract[0].id));
    }

    setFiles((files) => [
      ...files,
      {
        id: uploadedFile.id,
        cols: [
          uploadedFile.attributes.filename,
          <UploadOfferTableActions
            key={uploadedFile.id}
            selectedFile={{
              id: uploadedFile.id,
              fileName: uploadedFile.attributes.filename,
              type: fileType,
            }}
            onRemove={handleRemove}
          />,
        ],
      },
    ]);

    setLastGeneratedcontract([{ id: uploadedFile.id }]);
  };

  const setCbBankGeneratedContracts = () => {
    const mapIFileToUploadedFileRow = (file: IFile) => ({
      id: file.id,
      cols: [
        file.fileName,
        <UploadOfferTableActions
          key={file.id + Date.now()}
          selectedFile={{
            id: file.id,
            fileName: file.fileName,
            type: fileType,
          }}
          onRemove={handleRemove}
        />,
      ],
    });

    setFiles(cbBankContracts.map(mapIFileToUploadedFileRow));
  };

  const { onFileSelected, onFilesSelected, errorMsg, isDropping, uploadingState, setIsDropping } =
    useSelectFile({ onSuccess: handleSetFiles, url: endpoint, isAssessmentFile });

  const submitDocuments = async () => {
    const fileIds = files.map((file) => file.id);
    await submitOffers(fileIds);
  };

  const defaultHandleGenerateContract = async () => {
    const { error, payload } = await makeCall(generateContractAction(id));

    if (error) {
      notifyError({
        description: t('error'),
      });
    } else {
      success({ description: t('success') });
      handleSetGeneratedContractFiles(payload.data.data);
    }
  };

  const cbBankHandleGenerateContract = async () => {
    try {
      await generateContract({ id });
      success({ description: t('success') });
    } catch (err) {
      console.error('Error while generating contract:', err);
      notifyError({
        description: t('error'),
      });
    }
  };

  useEffect(() => {
    if (selectedInquiryType === InquiryType.cbBank) {
      setCbBankGeneratedContracts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cbBankContracts)]);

  const handleGenerateContract = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: defaultHandleGenerateContract,
    [InquiryType.cbBank]: cbBankHandleGenerateContract,
  });

  return {
    files,
    handleSetFiles,
    submitDocuments,
    submitOffersLoading,
    uploadingState,
    uploadProgress,
    errorMsg,
    setIsDropping,
    isDropping,
    goBackToDashboard,
    multipleFiles,
    createContract,
    handleGenerateContract,
    onFileSelected,
    onFilesSelected,
    disableGenerateContract,
    generateContractLoading,
  };
};
