import React from 'react';

import { Spinner } from '@chakra-ui/react';
import { useForm } from 'react-final-form';

import { useGetBankInfo } from 'api/CompeonReverseApi/shared/general';
import { cbBankFields } from 'cbbank/inquiry/fields';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField } from 'components/Input';
import { NewStaticFieldWithField } from 'components/StaticField';
import { translations } from 'new/form/common/types';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const BankAccountSection = () => {
  const t = useTranslations();
  const { change, getFieldState } = useForm();
  const { mutateAsync: getBankInfo, isLoading } = useGetBankInfo();
  const { title, description } =
    translations.inquiryType.cbBank.pages.companyData.sections.bankAccount;
  const { iban, bic, bankName } = cbBankFields.companyDataPage.bankAccountSection;

  const ibanChangeHandler = async (ibanValue: string | undefined) => {
    // Check if iban is valid so we don't make unnecessary requests
    if (getFieldState(iban)?.valid) {
      ibanValue = ibanValue as string;
      const response = await getBankInfo({ iban: ibanValue });
      change(bankName, response.data.data.attributes.bank_name ?? undefined);
      change(bic, response.data.data.attributes.bic ?? undefined);
    }
  };

  return (
    <FormSection title={t(title)} hideSectionNumber>
      <FormRow>
        <TextComponent color={'text.tertiary'}>{t(description)}</TextComponent>
      </FormRow>
      <FormRow>
        <InputWithField
          name={iban}
          onChange={ibanChangeHandler}
          sideSymbol={isLoading ? () => <Spinner /> : undefined}
        />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField name={bankName} />
        <NewStaticFieldWithField name={bic} />
      </FormRow>
    </FormSection>
  );
};
