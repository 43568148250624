import { cbBankFields } from 'cbbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { factoringLine, factoringFee, payoutRatio } = cbBankFields.offerPage.contractLaneSection;

const { indicativeConditions: fieldTranslations } =
  translationObject.inquiryType.cbBank.pages.offer.lanes.contractLane;

export const contractLaneSectionLabelsMap: LabelsMap = {
  [factoringLine]: fieldTranslations.factoringLine.caption,
  [factoringFee]: fieldTranslations.factoringFee.caption,
  [payoutRatio]: fieldTranslations.payoutRate.caption,
};
