import { createMutation, createQuery } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import queryKeys from 'constants/queryKeys';
import { IInquiryListOptions } from 'models/InquiryList.model';
import { ProcessLog } from 'models/ProcessLog.model';
import {
  InquiryRevision,
  InquiryRevisionListElement,
} from 'modules/InquiryDetails/InquiryRevision/InquiryRevision.model';

import OperationsApiClient from '../operationApiClient';
import {
  IInternalCommentsResponse,
  IProfitabilityResponse,
  IReeMarketDataResponse,
  TMarketDataResponse,
} from '../types';

type TVariables = {
  inquiryId: string;
  recordType?: string;
};

export const MAX_RETRIES = 3;

export const useLoadProfitabilityData = createQuery<IProfitabilityResponse, TVariables>({
  primaryKey: queryKeys.operation.profitabilityData,
  queryFn: ({ queryKey: [_, variables] }) =>
    OperationsApiClient.loadPlanningEvaluationProfitabilityAction(
      variables.inquiryId,
      variables.recordType,
    ),
});

export const useLoadMarketData = createQuery<TMarketDataResponse, TVariables>({
  primaryKey: queryKeys.operation.marketData,
  queryFn: ({ queryKey: [_, variables] }) =>
    OperationsApiClient.loadMarketDataAction(variables.inquiryId, variables.recordType),
});

export const useLoadProcessLog = createQuery<ProcessLog[], TVariables>({
  primaryKey: queryKeys.operation.processLog,
  queryFn: ({ queryKey: [_, variables] }) =>
    OperationsApiClient.loadProcessLogAction(variables.inquiryId),
});

export const useLoadMarketDataRee = createQuery<IReeMarketDataResponse, TVariables>({
  primaryKey: queryKeys.operation.marketDataRee,
  queryFn: ({ queryKey: [_, variables] }) =>
    OperationsApiClient.loadReeMarketDataAction(variables.inquiryId, variables.recordType),
});

export const useLoadInternalComments = createQuery<IInternalCommentsResponse, TVariables>({
  primaryKey: queryKeys.operation.internalComments,
  queryFn: ({ queryKey: [_, variables] }) =>
    OperationsApiClient.loadInternalCommentsAction(variables.inquiryId),
});

export const useLoadInquiryRevisions = createQuery<InquiryRevision[], TVariables>({
  primaryKey: queryKeys.operation.inquiryRevisions,
  queryFn: ({ queryKey: [_, variables] }) =>
    OperationsApiClient.loadInquiryRevisionsAction(variables.inquiryId),
});

export const useLoadInquiryRevision = createQuery<
  InquiryRevisionListElement[],
  { revisionId: string }
>({
  primaryKey: queryKeys.operation.inquiryRevision,
  queryFn: ({ queryKey: [_, variables] }) =>
    OperationsApiClient.loadInquiryRevisionAction(variables.revisionId),
});

export const useLoadInquiriesList = createQuery<any, { options: IInquiryListOptions }>({
  primaryKey: queryKeys.operation.inquiriesList,
  queryFn: ({ queryKey: [_, variables] }) =>
    OperationsApiClient.loadInquiriesListAction(variables.options),
});

export const useLoadInquiriesStatuses = createQuery({
  primaryKey: queryKeys.operation.inquiriesStatuses,
  queryFn: () => OperationsApiClient.loadInquiriesStatusesAction(),
});

export const useSubmitMarketValues = createMutation({
  mutationFn: async (variables: { data: Record<string, unknown>; inquiryId: string }) =>
    OperationsApiClient.submitMarketDataAction(variables.data, variables.inquiryId),
});

export const useSubmitProfitability = createMutation({
  mutationFn: async (variables: { data: Record<string, unknown>; inquiryId: string }) =>
    OperationsApiClient.submitPlanningEvaluationProfitabilityDataAction(
      variables.data,
      variables.inquiryId,
    ),
});

export const useDownloadPdf = createMutation({
  mutationFn: async (variables: { financingRole: string; usageType: string; inquiryId: string }) =>
    OperationsApiClient.downloadPropertyProfilePdfAction(
      variables.financingRole,
      variables.usageType,
      variables.inquiryId,
    ),
});

export const useUpdateProfile = createMutation({
  mutationFn: async (variables: { data: Record<string, unknown>; inquiryId: string }) =>
    OperationsApiClient.submitPlanningEvaluationProfitabilityDataAction(
      variables.data,
      variables.inquiryId,
    ),
});

export const useDeleteImage = createMutation({
  mutationFn: async (variables: { inquiryId: string; fileId: string }) =>
    OperationsApiClient.deleteObjectImageAction(variables.inquiryId, variables.fileId),
});

export const useAddInternalComment = createMutation({
  mutationFn: async (variables: { inquiryId: string; content: any }) =>
    OperationsApiClient.addInternalCommentAction(variables.inquiryId, variables.content),
});

export const useSkipKyc = createMutation({
  mutationFn: async (variables: { inquiryId: string }) =>
    OperationsApiClient.skipKyc(variables.inquiryId),
});

type StartCbBankSignatureProcessVariables = {
  inquiryId: string;
};

export const useStartCbBankBankSignatureProcess = createMutation<
  undefined,
  StartCbBankSignatureProcessVariables
>({
  mutationFn: ({ inquiryId }) =>
    EcoBankingAxiosClientAuthedInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.CONTRACTS.CONFIRM.compose({
        params: { id: inquiryId },
      }),
    ),
});
