import paths from 'constants/paths';
import { RouteToPageNameMap } from 'new/form/model';

import { CbBankFieldTypes } from '../fields';

export const cbBankRouteToPageNameMap: RouteToPageNameMap<CbBankFieldTypes> = {
  financingNeedPage: {
    create: paths.financingNeed,
    editCustomer: paths.customer.inquiryDetails.edit.financingNeed,
  },
  companyDataPage: {
    create: paths.companyDetails,
    editCustomer: paths.customer.inquiryDetails.edit.companyDetails,
  },
  offerPage: {
    create: paths.contractDetails,
    editCustomer: paths.customer.inquiryDetails.edit.contractDetails,
  },
  personalDataPage: {
    create: paths.peopleDetails,
    editCustomer: paths.customer.inquiryDetails.edit.peopleDetails,
  },
};
