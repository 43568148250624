import React from 'react';

import { cbBankFields } from 'cbbank/inquiry/fields';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { InputWithField, INPUT_TYPE_DECIMAL } from 'components/Input';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

const {
  turnoverShareOfLargestClient,
  centralisedSettlement,
  tradeCreditInsurance,
  downPaymentOrInstallments,
  assignedElsewhere,
  customersAbroad,
} = cbBankFields.financingNeedPage.businessDetailsSection;

export const BusinessDetailsSection = () => {
  const t = useTranslations();
  const {
    title,
    fields: {
      centralisedSettlement: { tooltip: centralisedSettlementTooltip },
      tradeCreditInsurance: { tooltip: tradeCreditInsuranceTooltip },
      downPaymentOrInstallments: { tooltip: downPaymentOrInstallmentsTooltip },
      assignedElsewhere: { tooltip: assignedElsewhereTooltip },
      customersAbroad: { tooltip: customersAbroadTooltip },
    },
  } = translations.inquiryType.cbBank.pages.financingNeed.sections.businessDetails;

  return (
    <FormSection title={t(title)} sectionNumber={2}>
      <FormRow>
        <InputWithField
          name={turnoverShareOfLargestClient}
          type={INPUT_TYPE_DECIMAL}
          sideSymbol={() => t('other.percent')}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={centralisedSettlement}
          tooltip={t(centralisedSettlementTooltip)}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={tradeCreditInsurance}
          tooltip={t(tradeCreditInsuranceTooltip)}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField
          name={downPaymentOrInstallments}
          tooltip={t(downPaymentOrInstallmentsTooltip)}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={assignedElsewhere} tooltip={t(assignedElsewhereTooltip)} />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={customersAbroad} tooltip={t(customersAbroadTooltip)} />
      </FormRow>
    </FormSection>
  );
};
