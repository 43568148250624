import React from 'react';

import { Box, Divider } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { useGetIndicativeConditionSuggestion } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import { IndicativeConditionsApiDataProps } from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import FormRow from 'components/FormRow';
import Content from 'components/FormSection/Content';
import Section from 'components/FormSection/Section';
import Title from 'components/FormSection/Title';
import { INPUT_TYPE_DECICMAL_WITH_ZERO, INPUT_TYPE_NUMBER, InputWithField } from 'components/Input';
import { Container } from 'components/PortalPage/styles';
import { StyledActions } from 'components/StyledActions';
import Text from 'components/Text/Text';
import paths from 'constants/paths';
import {
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_PAYOUT_RATIO,
} from 'modules/Inquiry/Form/formFields';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { TextComponent } from 'theme/components/Text';
import { useScrollToTop } from 'utils/hooks/useScrollToTop';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import IndicativeConditionCalculation from '../Steps/CbBankIndicativeConditions/IndicativeConditionCalculation';
import { useCreateCbBankIndicativeConditions } from '../Steps/CbBankIndicativeConditions/useCreateCbBankIndicativeConditions';

const CbBankNonBindingOffer = () => {
  useScrollToTop();
  const t = useTranslations();
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { required, maxValue, minValue } = useFieldValidators();
  const { mutateAsync } = useCreateCbBankIndicativeConditions();

  const { data } = useGetIndicativeConditionSuggestion({
    variables: { id: inquiryId },
    enabled: !!inquiryId,
    refetchOnWindowFocus: false,
  });

  const submitOffer = async (formValues: IndicativeConditionsApiDataProps) => {
    await mutateAsync(formValues);
  };

  const {
    headline,
    summary,
    conditions,
    offerParameter,
    factoringFee,
    factoringLine,
    payoutRate,
    submit,
  } = translations.pages.inquiryDetails.dashboard.actions.cbBankNonBindingOffer;

  return (
    <Container>
      <Box pt="12" pb="20">
        <Box pl={[null, null, null, '20rem']}>
          <HeadingComponent as="h2" variant="secondary" mb={3}>
            {t(headline)}
          </HeadingComponent>

          <TextComponent color="text.tertiary">{t(summary)}</TextComponent>
        </Box>

        <>
          <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
          <Section>
            <Title>
              <Text as="span">{t(conditions)}</Text>
            </Title>

            <Content>{data && <IndicativeConditionCalculation data={data} />}</Content>
          </Section>
        </>
        <Section>
          <Title>
            <Text as="span">{t(offerParameter)}</Text>
          </Title>

          <Content>
            <Form onSubmit={submitOffer}>
              {({ valid, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <FormRow>
                      <InputWithField
                        name={OFFER_FACTORING_FEE}
                        caption={t(factoringFee)}
                        sideSymbol={() => t(translations.other.percent)}
                        type={INPUT_TYPE_DECICMAL_WITH_ZERO}
                        validate={combineValidators(required, maxValue('100'), minValue('0,01'))}
                      />
                    </FormRow>
                    <FormRow>
                      <InputWithField
                        name={OFFER_FACTORING_LINE}
                        caption={t(factoringLine)}
                        sideSymbol={() => t(translations.other.defaultCurrency)}
                        type={INPUT_TYPE_NUMBER}
                        validate={combineValidators(required, minValue('1'))}
                      />
                    </FormRow>
                    <FormRow>
                      <InputWithField
                        name={OFFER_PAYOUT_RATIO}
                        caption={t(payoutRate)}
                        sideSymbol={() => t(translations.other.percent)}
                        type={INPUT_TYPE_DECICMAL_WITH_ZERO}
                        validate={combineValidators(required, maxValue('100'), minValue('0,01'))}
                      />
                    </FormRow>
                  </Box>
                  <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
                  <StyledActions>
                    <ButtonComponent
                      mr={4}
                      onClick={() =>
                        history.push(paths.operation.inquiryDetails.dashboard.replace(':id', id))
                      }
                      variant="tertiary"
                    >
                      {t('buttons.cancel')}
                    </ButtonComponent>
                    <ButtonComponent
                      leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                      type="submit"
                      disabled={!valid}
                      data-testid="offer-submit-button"
                    >
                      {t(submit)}
                    </ButtonComponent>
                  </StyledActions>
                </form>
              )}
            </Form>
          </Content>
        </Section>
      </Box>
    </Container>
  );
};

export default CbBankNonBindingOffer;
