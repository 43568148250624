import { bankAccountSectionValidationMap } from './sections/bankAccount/validations';
import { companyDetailsCompletionSectionValidationMap } from './sections/companyDetailsCompletion/validations';
import { companySearchSectionValidationMap } from './sections/companySearch/validations';
import { complianceSectionValidationMap } from './sections/compliance/validations';

export const cbBankCompanyDataFieldValidation = {
  ...companySearchSectionValidationMap,
  ...companyDetailsCompletionSectionValidationMap,
  ...bankAccountSectionValidationMap,
  ...complianceSectionValidationMap,
};
