import {
  REPRESENTATIVE_BIRTHDATE,
  REPRESENTATIVE_EMAIL,
  REPRESENTATIVE_FIRST_NAME,
  REPRESENTATIVE_GENDER,
  REPRESENTATIVE_LAST_NAME,
  REPRESENTATIVE_PHONE_NUMBER,
  SELF_USER_REPRESENTATIVE,
  USER_REPRESENTATION,
  USER_REPRESENTATIVE,
  USER_SOLE_REPRESENTATION,
} from 'modules/Inquiry/Form/formFields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { mmvPeopleDetailsFieldValidations } from 'modules/Inquiry/inquiryFieldValidation/peopleDetails.fieldValidationMap';

const userRepresentativeSchema = fieldValidators
  .object({
    [REPRESENTATIVE_GENDER]: mmvPeopleDetailsFieldValidations[REPRESENTATIVE_GENDER],
    [REPRESENTATIVE_FIRST_NAME]: mmvPeopleDetailsFieldValidations[REPRESENTATIVE_FIRST_NAME],
    [REPRESENTATIVE_LAST_NAME]: mmvPeopleDetailsFieldValidations[REPRESENTATIVE_LAST_NAME],
    [REPRESENTATIVE_EMAIL]: mmvPeopleDetailsFieldValidations[REPRESENTATIVE_EMAIL],
    [REPRESENTATIVE_PHONE_NUMBER]: fieldValidators.phoneNumber().required().isMobilePhoneNumber(),
    [REPRESENTATIVE_BIRTHDATE]: mmvPeopleDetailsFieldValidations[REPRESENTATIVE_BIRTHDATE],
  })
  .unknown();

export const authorizationOfRepresentationValidationMap = fieldValidators
  .object({
    [USER_REPRESENTATION]: fieldValidators.stringBoolean().exist(),
    [USER_SOLE_REPRESENTATION]: fieldValidators.when(USER_REPRESENTATION, {
      is: fieldValidators.stringBoolean().mustBeTrue().exist(),
      then: fieldValidators.stringBoolean().exist(),
      otherwise: fieldValidators.stringBoolean(),
    }),
    [USER_REPRESENTATIVE]: fieldValidators.array().exist(),
  })
  .when(
    fieldValidators
      .object({
        [USER_REPRESENTATION]: fieldValidators.stringBoolean().mustBeTrue().exist(),
        [USER_SOLE_REPRESENTATION]: fieldValidators.stringBoolean().mustBeTrue().exist(),
      })
      .unknown(),
    {
      then: fieldValidators.object({
        [SELF_USER_REPRESENTATIVE]: fieldValidators
          .object({
            [REPRESENTATIVE_PHONE_NUMBER]: fieldValidators
              .phoneNumber()
              .required()
              .isMobilePhoneNumber(),
            [REPRESENTATIVE_BIRTHDATE]: mmvPeopleDetailsFieldValidations[REPRESENTATIVE_BIRTHDATE],
          })
          .required()
          .unknown(),
        [USER_REPRESENTATIVE]: fieldValidators.array().optional().items(userRepresentativeSchema),
      }),
      otherwise: fieldValidators.object({
        [USER_REPRESENTATIVE]: fieldValidators
          .array()
          .min(1)
          .exist()
          .items(userRepresentativeSchema),
      }),
    },
  )
  .unknown();
