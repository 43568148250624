import React from 'react';

import SpinnerWrapper from 'components/App/PartnerProvider/SpinnerWrapper';
import Condition from 'components/Condition';
import Spinner from 'components/Spinner';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import InquiryDetailsHeader from 'modules/InquiryDetails/InquiryDetailsHeader';
import FileRequestsFromBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FileRequestsFromBank/FileRequestsFromBank';
import FilesFromBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesFromBank/FilesFromBank';
import FilesToBank from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToBank/FilesToBank';
import FilesToCompeon from 'pages/customerPortal/InquiryDetails/DocumentExchange/FilesToCompeon/FilesToCompeon';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import { CbBankCustomerDocumentExchange } from './CbBankCustomerDocumentExchange';
import { DZBCustomerDocumentExchange } from './DZBCustomerDocumentExchange';
import { HausbankCustomerDocumentExchange } from './HausbankCustomerDocumentExchange';
import { LeasePlanCustomerDocumentExchange } from './LeasePlanCustomerDocumentExchange';
import { MittweidaCustomerDocumentExchange } from './MittweidaCustomerDocumentExchange';
import {
  useCustomerDocumentExchange,
  useCustomerDocumentExchangeUpdatedFilesWebSocket,
  useCustomerDocumentExchangeUpdatedInquiryDetailsWebsocket,
} from './useCustomerDocumentExchange';
import { useShowLatestSignedContract } from './useShowDocExchangeSections';
import InquiryTypeSpecificHeader from '../InquiryDetailsHeaderButton';
import LatestSignedContractFromBank from '../LatestSignedContractFromBank';
import ProcessSpecificFiles from '../ProcessSpecificFiles/ProcessSpecificFiles';

const CustomerDocumentExchange = () => {
  const { isLoading } = useCustomerDocumentExchange();
  const showLatestSignedContract = useShowLatestSignedContract();
  useCustomerDocumentExchangeUpdatedInquiryDetailsWebsocket();
  useCustomerDocumentExchangeUpdatedFilesWebSocket();

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <article>
      <InquiryDetailsHeader>
        <InquiryTypeSpecificHeader />
      </InquiryDetailsHeader>
      <ProcessSpecificFiles />
      <Condition condition={showLatestSignedContract}>
        <LatestSignedContractFromBank />
      </Condition>
      <FilesFromBank />
      <FileRequestsFromBank />
      <FilesToBank />
      <FilesToCompeon />
    </article>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.hausbank]: HausbankCustomerDocumentExchange,
  [InquiryType.dzb]: DZBCustomerDocumentExchange,
  [InquiryType.profiMittweida]: MittweidaCustomerDocumentExchange,
  [InquiryType.leaseplan]: LeasePlanCustomerDocumentExchange,
  [InquiryType.cbBank]: CbBankCustomerDocumentExchange,
  [InquiryType.default]: CustomerDocumentExchange,
});
