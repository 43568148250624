import React, { useEffect } from 'react';

import { HStack, Spinner, VStack } from '@chakra-ui/react';

import { Subheading } from 'components/Text';
import { HeadingComponent } from 'theme/components/Heading';
import { ModalComponent } from 'theme/components/Modal';
import { TextComponent } from 'theme/components/Text';

export type LoadingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: string;
  description?: string;
  displayDelay?: number;
};

/**
 *
 * @param isOpen - Whether the modal is open
 * @param onClose - Function to close the modal
 * @param title - Title of the modal
 * @param subtitle - Subtitle of the modal
 * @param description - Description of the modal
 * @param displayDelay - Delay in milliseconds before the modal is displayed. Can be used to show the modal only if the loading takes longer than the delay
 */
const LoadingModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  description,
  displayDelay,
}: LoadingModalProps) => {
  const [isDisplayed, setIsDisplayed] = React.useState(displayDelay ? false : isOpen);

  // Display the modal after the delay. Can be used to prevent flickering if the loading is fast
  useEffect(() => {
    if (displayDelay) {
      if (isOpen) {
        const timeout = setTimeout(() => {
          setIsDisplayed(isOpen);
        }, displayDelay);

        return () => {
          clearTimeout(timeout);
        };
      }
      setIsDisplayed(false);
    } else {
      setIsDisplayed(isOpen);
    }
  }, [displayDelay, isOpen]);

  return (
    <ModalComponent isOpen={isDisplayed} onClose={onClose} size="lg" canBeClosed={false}>
      <HStack
        justifyContent={'center'}
        alignItems={'flex-start'}
        gap={16}
        paddingY={16}
        paddingX={28}
      >
        <Spinner color={'brand.default'} height={14} width={14} padding={6} />
        <VStack alignItems={'flex-start'} justifyContent={'center'} flexGrow={1}>
          <HeadingComponent color="brand.default" marginBottom={8}>
            {title}
          </HeadingComponent>
          <Subheading>{subtitle}</Subheading>
          <TextComponent>{description}</TextComponent>
        </VStack>
      </HStack>
    </ModalComponent>
  );
};

export default LoadingModal;
