import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryProcessConfig } from 'new/form/model';

import { cbBankConditions } from './conditions';
import { cbBankInitialFormState } from './initialFormState';
import { cbBankInitialFormValues } from './initialFormValues';
import { cbBankRouteToPageNameMap } from './routeToPageNameMap';
import { cbBankStepData } from './stepData';
import { cbBankStepSequence } from './stepSequence';
import { cbBankSummaryFields } from './summaryFields';
import { cbBankSummaryPath } from './summaryPath';
import { cbBankValidations } from './validations';
import { CB_BANK_FORM } from '..';
import { cbBankFields } from '../fields';

export const cbBankInquiryProcessConfig: InquiryProcessConfig<InquiryType.cbBank> = {
  validations: cbBankValidations,
  fields: cbBankFields,
  summaryFields: cbBankSummaryFields,
  summaryPath: cbBankSummaryPath,
  stepData: cbBankStepData,
  stepSequence: cbBankStepSequence,
  routeToPageNameMap: cbBankRouteToPageNameMap,
  initialFormState: cbBankInitialFormState,
  initialFormValues: cbBankInitialFormValues,
  conditions: cbBankConditions,
  formName: CB_BANK_FORM,
  paths: {
    editPaths: {
      editCustomer: cbBankRouteToPageNameMap.financingNeedPage.editCustomer,
    },
    listPaths: {
      editCustomer: paths.customer.inquiryList,
      editOperation: paths.operation.inquiryList,
    },
    createPath: cbBankRouteToPageNameMap.financingNeedPage.create,
  },
};
