import React from 'react';

import FormRow from 'components/FormRow';

import { BeneficialOwnerFieldsProps } from '../BeneficialOwnerFields';
import { useGenerateCbBankkBeneficialOwnerFields } from '../formFields/useGenerateCbBankBeneficialOwnerFields';

export const CbBankBeneficialOwnerFields = ({ mode, prefix }: BeneficialOwnerFieldsProps) => {
  const fields = useGenerateCbBankkBeneficialOwnerFields(mode, prefix);

  return (
    <>
      <FormRow>
        {fields.firstName}
        {fields.lastName}
      </FormRow>
      <FormRow>
        {fields.nationality}
        {fields.capitalOrVotingRightsPercentage}
      </FormRow>
      <FormRow>
        {fields.placeOfResidence}
        {fields.countryOfResidence}
      </FormRow>
      <FormRow>
        {fields.birthPlace}
        {fields.birthDate}
      </FormRow>
    </>
  );
};
