import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { EnvironmentVariable, getEnvironmentFeatureFlag } from 'utils/environment';
import { userIsBankAdvisor } from 'utils/user/conditionals';

const withPortalInquiry = getEnvironmentFeatureFlag(EnvironmentVariable.PORTAL_INQUIRY);

/**
 * Computes whenever a "New Inquiry" button should be shown in the portal navigation.
 */
const useShowNewInquiryButton = () => {
  const { selectedInquiryType } = useFormConfig();
  const hasBankAdvisorRole = userIsBankAdvisor();

  const inquiryTypes = [
    InquiryType.bfs,
    InquiryType.profiRLL,
    InquiryType.bfsService,
    InquiryType.mmv,
    InquiryType.hausbank,
    InquiryType.cbBank,
  ];

  return !inquiryTypes.includes(selectedInquiryType) && withPortalInquiry && hasBankAdvisorRole;
};

export default useShowNewInquiryButton;
