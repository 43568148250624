import styled from 'styled-components/macro';

import { mqLarge, mqSmall } from 'styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0.25rem;
  font-size: 0.75rem;

  ${mqSmall`
    margin: 0.5rem 0.75rem;
  `};

  ${mqLarge`
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  `}
`;

export default Wrapper;
