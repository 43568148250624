import { StepSequence } from 'new/form/model';

import { CbBankFieldTypes } from '../fields';
import { cbBankSteps } from '../steps';

export const cbBankStepSequence: StepSequence<CbBankFieldTypes> = [
  cbBankSteps.financingNeedPage,
  cbBankSteps.companyDataPage,
  cbBankSteps.offerPage,
  cbBankSteps.personalDataPage,
];
