import { useForm, useFormState } from 'react-final-form';

import { ConditionsType } from 'new/form/model';

export const createUseConditions = <InquiryType>(conditions: ConditionsType<InquiryType>) => {
  return () => {
    const { values } = useFormState<InquiryType>();
    const form = useForm();

    const conditionValues = {} as Record<string, boolean>;
    Object.entries(conditions).forEach(([fieldName, conditionFunction]) => {
      if (conditionFunction) {
        conditionValues[fieldName] = conditionFunction({ values, form });
      } else {
        conditionValues[fieldName] = true;
      }
    });

    return conditionValues;
  };
};
