import { useState, useCallback, useEffect } from 'react';

import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { cbBankFields } from 'cbbank/inquiry/fields';
import { usePartnerConfig } from 'config/partner/hooks';
import { useToasts } from 'shared/hooks/useToasts';
import { getCreatedInquiryId } from 'store/progress/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import {
  getParsedItemFromSessionStorage,
  saveObjectToSessionStorage,
  removeObjectFromSessionStorage,
} from 'utils/sessionStorage/helpers';
import { INSTALMENT_PROCESS } from 'utils/sessionStorage/keys';

import { useConfirmPhone, useConfirmToken } from './useConfirmPhoneCall';
import { prefetchIndicativeConditions } from './useRequestIndicativeConditions';

const PHONE_SEND = 'phoneSend';
const CODE_SEND = 'codeSend';

export const useInstalmentHandler = () => {
  const location = useLocation();
  const history = useHistory();

  const { crefoId: crefoIdValue } = cbBankFields.companyDataPage.companySearchSection;
  const { phoneNumber: phoneNumberValue } = cbBankFields.offerPage.contractLaneSection;
  const t = useTranslations();
  const {
    input: { value: phoneNumber },
  } = useField(phoneNumberValue);

  const {
    input: { value: crefoId },
  } = useField(crefoIdValue);

  const {
    details: { id: partnerId },
  } = usePartnerConfig();
  const inquiryId = useSelector(getCreatedInquiryId);
  const [isPendingPhoneNumberConfirmation, setPendingPhoneNumberConfirmation] = useState(false);
  const { error: errorToast } = useToasts();

  const [phoneSend, setPhoneSend] = useState(false);
  const [codeSend, setCodeSend] = useState(false);

  const updateSearchParams = useCallback(
    (key: string, value: string) => {
      const newSearchParams = new URLSearchParams(location.search);
      if (value === 'false') {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, value);
      }
      history.replace({ search: newSearchParams.toString() });
    },
    [location.search, history],
  );

  const syncStateWithSearchParams = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    setPhoneSend(searchParams.get(PHONE_SEND) === 'true');
    setCodeSend(searchParams.get(CODE_SEND) === 'true');
  }, [location.search]);

  useEffect(() => {
    syncStateWithSearchParams();
  }, [syncStateWithSearchParams]);

  const confirmPhoneMutation = useConfirmPhone({
    onMutate: () => {
      setPendingPhoneNumberConfirmation(true);
    },
    onSuccess: (data) => {
      setPendingPhoneNumberConfirmation(false);
      if (data.status === 204 || data.status === 200) {
        updateSearchParams(PHONE_SEND, 'true');
      }
    },
    onError: (error) => {
      setPendingPhoneNumberConfirmation(false);
      const response = error.response;
      if (response?.status === 429) {
        errorToast({ description: t('components.smsVerification.errorAttempts') });
      } else if (response?.status === 422) {
        errorToast({ description: t('components.smsVerification.errorPhoneNumber') });
      } else {
        updateSearchParams(PHONE_SEND, 'false');
      }
    },
  });

  const confirmTokenMutation = useConfirmToken({
    onMutate: () => {
      setPendingPhoneNumberConfirmation(true);
    },
    onSuccess: (data) => {
      setPendingPhoneNumberConfirmation(false);
      if (data.status === 200) {
        updateSearchParams(CODE_SEND, 'true');
        setInstalmentAccess({ accessToken: data.data.access_token });
        prefetchIndicativeConditionsData(data.data.access_token);
      }
    },
    onError: () => {
      setPendingPhoneNumberConfirmation(false);
      errorToast({ description: t('components.smsVerification.errorToken') });
      updateSearchParams(CODE_SEND, 'false');
    },
  });

  const getInstalmentAccess = useCallback(
    () => getParsedItemFromSessionStorage(INSTALMENT_PROCESS) ?? {},
    [],
  );

  const prefetchIndicativeConditionsData = async (accessToken: string) => {
    const params = { inquiryId, accessToken };
    await prefetchIndicativeConditions(params);
  };

  const dropInstalment = useCallback(() => {
    const instalmentAccess = getInstalmentAccess();
    if (instalmentAccess.crefoId !== crefoId || instalmentAccess.inquiryId !== inquiryId) {
      removeObjectFromSessionStorage(INSTALMENT_PROCESS);
      updateSearchParams(PHONE_SEND, 'false');
      updateSearchParams(CODE_SEND, 'false');
    }
  }, [crefoId, getInstalmentAccess, inquiryId, updateSearchParams]);

  const setInstalmentAccess = ({ accessToken }: { accessToken: string }) =>
    saveObjectToSessionStorage(INSTALMENT_PROCESS, { accessToken, crefoId, inquiryId });

  const submitPhoneNumber = async () => {
    await confirmPhoneMutation.mutateAsync({
      phoneNumber,
      partnerId: partnerId ? partnerId : '',
      inquiryId,
    });
  };

  const resetPhoneNumber = useCallback(() => {
    history.replace({ search: '' });
    setPhoneSend(false);
    setCodeSend(false);
  }, [history]);

  const submitPinNumber = async (pin: string) => {
    await confirmTokenMutation.mutateAsync({
      phoneNumber,
      pin,
    });
  };

  return {
    dropInstalment,
    getInstalmentAccess,
    resetPhoneNumber,
    submitPinNumber,
    submitPhoneNumber,
    isPendingPhoneNumberConfirmation,
    data: {
      phoneNumber,
    },
    state: {
      phoneSend,
      codeSend,
    },
  };
};
