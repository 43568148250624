import { CbBankIndustryValues } from 'cbbank/inquiry/fields';
import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { translations } from 'new/form/common/types';
import { industryValues } from 'types/Company';
import { FormatMessage } from 'utils/intl';

export const getCbBankIndustryOptions = (
  formatMessage: FormatMessage,
): SelectOption<CbBankIndustryValues>[] => {
  const {
    service,
    finance_and_insurance,
    trade,
    craft,
    manufacturing,
    processing,
    transport,
    food,
    other,
  } =
    translations.pages.userProfile.sections.completingCompanyDetails.fields.userProfileIndustry
      .selectOptions;

  const options: Array<{ id: string; value: CbBankIndustryValues }> = [
    {
      id: service,
      value: industryValues.service,
    },
    {
      id: finance_and_insurance,
      value: industryValues.financeAndInsurance,
    },
    {
      id: trade,
      value: industryValues.trade,
    },
    {
      id: craft,
      value: industryValues.craft,
    },
    {
      id: manufacturing,
      value: industryValues.manufacturing,
    },
    {
      id: processing,
      value: industryValues.processing,
    },
    {
      id: transport,
      value: industryValues.transport,
    },
    {
      id: food,
      value: industryValues.food,
    },
    {
      id: other,
      value: industryValues.other,
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
