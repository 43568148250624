import { AssetConfig } from 'config/formConfig/types';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';

// Use ProgressSectionType here for IDs since using DashboardActionsType will result in a circular dependency
const dashboardIcons: AssetConfig[] = [
  {
    id: ProgressSectionType.ADDITIONAL_FIELDS,
    icon: 'addAdditionalDataImage',
  },
  {
    id: ProgressSectionType.DOCUMENT_UPLOAD,
    icon: 'documentUploadImage',
  },
  {
    id: ProgressSectionType.VIDEO_IDENT,
    icon: 'acceptOfferImage',
  },
  {
    id: ProgressSectionType.NON_BINDING_OFFER,
    icon: 'acceptOfferImage',
  },
  {
    id: ProgressSectionType.CREFO_QUERY,
    icon: 'crefoQueryImage',
  },
  {
    id: ProgressSectionType.DIGITAL_PROCESSING,
    icon: 'digitalProcessingImage',
  },
  {
    id: ProgressSectionType.TRIGGER_KYC,
    icon: 'kycTriggerImage',
  },
  {
    id: ProgressSectionType.SUCCESS,
    icon: 'successImage',
  },
  {
    id: ProgressSectionType.UPLOAD_OFFER,
    icon: 'documentFinish',
  },
  {
    id: ProgressSectionType.CONFIRM_OBJECT_TAKEOVER,
    icon: 'confirmObjectTakeOver',
  },
  {
    id: ProgressSectionType.OBJECT_DATA,
    icon: 'objectData',
  },
  {
    id: ProgressSectionType.PLACEHOLDER,
    icon: 'acceptOfferImage',
  },
  {
    id: ProgressSectionType.AUDIT_REPRESENTATIVES,
    icon: 'confirmAssociatedPeopleIcon',
  },
  {
    id: ProgressSectionType.START_SIGNATURE_PROCESS,
    icon: 'startSigningProcessIcon',
  },
  {
    id: ProgressSectionType.UPLOAD_WELCOME_PACKAGE,
    icon: 'welcomePackageIcon',
  },
  {
    id: ProgressSectionType.CREATE_INDICATIVE_CONDITIONS,
    icon: 'indicativeConditionsIcon',
  },
];

export default dashboardIcons;
