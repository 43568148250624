import React from 'react';

import FormRow from 'components/FormRow';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

import { useGenerateCbBankLegalRepresentativeFields } from '../formFields/useGenerateCbBankLegalRepresentativeFields';

type Props = {
  mode: AssociatedPersonMode;
  prefix?: string;
};

export const CbBankLegalRepresentativeFields = ({ mode, prefix }: Props) => {
  const fields = useGenerateCbBankLegalRepresentativeFields(mode, prefix);

  return (
    <>
      <FormRow>{fields.salutation}</FormRow>
      <FormRow>
        {fields.firstName}
        {fields.lastName}
      </FormRow>
      <FormRow>{fields.birthDate}</FormRow>
      <FormRow>{fields.email}</FormRow>
      <FormRow>{fields.phoneNumber}</FormRow>
      <FormRow space={'1.5rem'}>{fields.soleSignatureAuthorized}</FormRow>
    </>
  );
};
