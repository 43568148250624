import { cbBankFields } from 'cbbank/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

const {
  name,
  street,
  zipCode,
  city,
  legalForm,
  industry,
  subjectToAccounting,
  olderThan2Years,
  positiveEquity,
  positiveAnnualResult,
} = cbBankFields.companyDataPage.companyDetailsCompletionSection;

const fieldTranslations =
  translations.inquiryType.cbBank.pages.companyData.sections.companyDetailsCompletion.fields;

export const companyDetailsCompletionSectionLabelsMap: LabelsMap = {
  [name]: fieldTranslations.name.caption,
  [street]: fieldTranslations.street.caption,
  [zipCode]: fieldTranslations.zipCode.caption,
  [city]: fieldTranslations.city.caption,
  [legalForm]: fieldTranslations.legalForm.caption,
  [industry]: fieldTranslations.industry.caption,
  [subjectToAccounting]: fieldTranslations.subjectToAccounting.caption,
  [olderThan2Years]: fieldTranslations.olderThan2Years.caption,
  [positiveEquity]: fieldTranslations.positiveEquity.caption,
  [positiveAnnualResult]: fieldTranslations.positiveAnnualResult.caption,
};
