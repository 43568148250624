import Joi from '@hapi/joi';

import { cbBankFields } from 'cbbank/inquiry/fields';
import { beneficialOwnersSectionValidationMap as cbBankBeneficialOwnerValidations } from 'cbbank/inquiry/steps/personalData/sections/beneficialOwners/validations';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';

import { beneficialOwnerFields } from '../fields';

const {
  firstName,
  lastName,
  nationality,
  capitalOrVotingRightsPercentage,
  countryOfResidence,
  placeOfResidence,
  birthPlace,
  birthDate,
} = beneficialOwnerFields;

const cbBankFieldNames = cbBankFields.personalDataPage.beneficialOwnersSection.beneficialOwners;

export const cbBankBeneficialOwnerSchema: Joi.ObjectSchema<any> = fieldValidators
  .object()
  .keys({
    [firstName]: cbBankBeneficialOwnerValidations[cbBankFieldNames.firstName],
    [lastName]: cbBankBeneficialOwnerValidations[cbBankFieldNames.lastName],
    [nationality]: cbBankBeneficialOwnerValidations[cbBankFieldNames.nationality],
    [capitalOrVotingRightsPercentage]:
      cbBankBeneficialOwnerValidations[cbBankFieldNames.capitalOrVotingRightsPercentage],
    [countryOfResidence]: cbBankBeneficialOwnerValidations[cbBankFieldNames.countryOfResidence],
    // We have to allow null for the next fields because they will be initiated with null
    // We also have to allow '' because this will be the value of the fields when the user deletes the prefilled value or changes the field generally
    [placeOfResidence]: cbBankBeneficialOwnerValidations[cbBankFieldNames.placeOfResidence].allow(
      null,
      '',
    ),
    [birthPlace]: cbBankBeneficialOwnerValidations[cbBankFieldNames.birthPlace].allow(null, ''),
    [birthDate]: cbBankBeneficialOwnerValidations[cbBankFieldNames.birthDate].allow(null, ''),
  })
  .unknown(true)
  .strip();
