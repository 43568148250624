import React from 'react';

import { HStack, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import paths from 'constants/paths';
import { translations } from 'new/form/common/types';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const { caption, linkLabel } = translations.pages.loginPage.registration;
const { registration } = paths;

export const RegistrationLink = () => {
  const t = useTranslations();

  return (
    <HStack justify="end">
      <TextComponent color="text.tertiary">{t(caption)}</TextComponent>
      <ChakraLink as={RouterLink} variant="secondary" to={registration}>
        {t(linkLabel)}
      </ChakraLink>
    </HStack>
  );
};
