import { cbBankFields } from 'cbbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const validateBusinessDetailsSection: ValidateSectionFunction<InquiryType.cbBank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap<InquiryType.cbBank>({
    validationMap: businessDetailsSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const {
  turnoverShareOfLargestClient,
  centralisedSettlement,
  tradeCreditInsurance,
  downPaymentOrInstallments,
  assignedElsewhere,
  customersAbroad,
} = cbBankFields.financingNeedPage.businessDetailsSection;

export const businessDetailsSectionValidationMap = {
  [turnoverShareOfLargestClient]: fieldValidators
    .number()
    .required()
    .positive()
    .max(InquiryConstants.MAX_PERCENT_VALUE),
  [centralisedSettlement]: fieldValidators.boolean().required(),
  [tradeCreditInsurance]: fieldValidators.boolean().required(),
  [downPaymentOrInstallments]: fieldValidators.boolean().required(),
  [assignedElsewhere]: fieldValidators.boolean().required(),
  [customersAbroad]: fieldValidators.boolean().required(),
};
