import { CbBankBeneficialOwner } from 'cbbank/inquiry/fields';

export function mapCbBankBeneficialOwners(beneficialOwners: CbBankBeneficialOwner[]) {
  return beneficialOwners.map((bO) => ({
    id: bO.id,
    first_name: bO.firstName,
    last_name: bO.lastName,
    birth_date: bO.birthDate,
    country: bO.countryOfResidence,
    city: bO.placeOfResidence,
    nationality: bO.nationality,
    birth_place: bO.birthPlace,
    company_share: bO.capitalOrVotingRightsPercentage,
  }));
}
