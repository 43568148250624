import { cbBankFields } from 'cbbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const { annualFactoringRelevantTurnover, paymentTerm, factoringLine } =
  cbBankFields.financingNeedPage.factoringNeedSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.cbBank.pages.financingNeed.sections.factoringNeed;

export const factoringNeedSectionLabelsMap: LabelsMap = {
  [annualFactoringRelevantTurnover]: fieldTranslations.annualFactoringRelevantTurnover.caption,
  [paymentTerm]: fieldTranslations.paymentTerm.caption,
  [factoringLine]: fieldTranslations.factoringLine.caption,
};
