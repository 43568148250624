import { cbBankFields } from 'cbbank/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

const {
  allInformationAvailable,
  beneficialOwners: {
    firstName,
    lastName,
    nationality,
    capitalOrVotingRightsPercentage,
    birthDate,
    birthPlace,
    countryOfResidence,
    placeOfResidence,
  },
} = cbBankFields.personalDataPage.beneficialOwnersSection;
const { fields: fieldTranslations } =
  translations.inquiryType.cbBank.pages.personalData.sections.beneficialOwners.fields
    .beneficialOwner;

export const beneficialOwnersSectionLabelsMap: LabelsMap = {
  [allInformationAvailable]: fieldTranslations.allInformationAvailable.caption,
  [firstName]: fieldTranslations.firstName.caption,
  [lastName]: fieldTranslations.lastName.caption,
  [nationality]: fieldTranslations.nationality.caption,
  [capitalOrVotingRightsPercentage]: fieldTranslations.capitalOrVotingRightsPercentage.caption,
  [birthDate]: fieldTranslations.birthDate.caption,
  [birthPlace]: fieldTranslations.birthPlace.caption,
  [countryOfResidence]: fieldTranslations.countryOfResidence.caption,
  [placeOfResidence]: fieldTranslations.placeOfResidence.caption,
};
