import React from 'react';

import { Box, chakra, GridItem, useMultiStyleConfig } from '@chakra-ui/react';

interface SummaryCardProps {
  caption: string;
  value?: string | number;
  testId?: string;
}

const SummaryCard = ({ caption, value, testId }: SummaryCardProps) => {
  const styles = useMultiStyleConfig('SummaryCard', {});
  return (
    <GridItem __css={styles.card} data-testId={testId}>
      <Box __css={styles.contentContainer}>
        <chakra.dl>
          <chakra.dt __css={styles.caption}>{caption}</chakra.dt>
          <chakra.dd __css={styles.value}>{value ? value : 0}</chakra.dd>
        </chakra.dl>
      </Box>
    </GridItem>
  );
};

export default SummaryCard;
