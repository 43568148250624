import { get as _get } from 'lodash';

import { ConditionFunction, ConditionsType } from 'new/form/model';
import { createUseConditions } from 'shared/inquiry/createUseConditions';
import { parseStringBoolean } from 'utils/helpers';

import { HausbankFieldTypes, hausbankFields } from '../fields';

const {
  objectInformationPage: {
    projectInformationSection: { isContractor, majorityOwnerShares },
  },
} = hausbankFields;

type HausbankConditionFunction = ConditionFunction<HausbankFieldTypes>;

const hasOwnerWithMoreThanThirdOfShares: HausbankConditionFunction = ({ values, form }) => {
  const {
    objectInformationPage: {
      projectInformationSection: { hasOwnerWithMoreThanThirdOfShares },
    },
  } = values;

  return parseStringBoolean(hasOwnerWithMoreThanThirdOfShares);
};

const majorityOwnerIsContractor: HausbankConditionFunction = ({ values, form }) => {
  return parseStringBoolean(_get(values, isContractor));
};

export const hausbankConditions: ConditionsType<HausbankFieldTypes> = {
  [isContractor]: hasOwnerWithMoreThanThirdOfShares,
  showContractorNotice: majorityOwnerIsContractor,
  [majorityOwnerShares]: hasOwnerWithMoreThanThirdOfShares,
};

export const useHausbankConditions = createUseConditions(hausbankConditions);
