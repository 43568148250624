import {
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_COMPANY_STREET,
  USER_PROFILE_COMPANY_ZIP_CODE,
  USER_PROFILE_COMPANY_CITY,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_INDUSTRY,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_FOUNDING_YEAR,
  INCOME_SURPLUS,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  USER_PROFILE_NEGATIVE_EQUITY_CAPITAL,
  USER_PROFILE_INNOVATIVE_COMPANY,
  USER_PROFILE_ENERGY_EFFICIENT,
  END_USER_EMAIL,
  END_USER_GENDER,
  END_USER_FIRST_NAME,
  END_USER_LAST_NAME,
  USER_PROFILE_LEGAL_FORM_DESCRIPTION,
  USER_PROFILE_PHONE_NUMBER,
  END_USER_CONDITION_AMOUNT,
  END_USER_CONDITION_INTEREST_RATE,
  EXCLUDING_QUESTION_FIRST,
  EXCLUDING_QUESTION_SECOND,
  EXCLUDING_QUESTION_THIRD,
  EXCLUDING_QUESTION_FOURTH,
  EXCLUDING_QUESTION_FIFTH,
  USER_PROFILE_BIRTHDATE,
  USER_PROFILE_BALANCE_SHEET,
  USER_PROFILE_DETAILED_ANNUAL_TURNOVER,
  USER_PROFILE_EMPLOYEE_COUNT,
  USER_PROFILE_COMPANY_RATING_KIND,
  USER_PROFILE_FOUNDING_TWO_YEAR_OLD,
  COMPANY_DETAILS_COMPANY_TAX_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import {
  fieldValidators,
  customValidations,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const userProfileFieldValidations = {
  [USER_PROFILE_COMPANY_NAME]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.COMPANY_NAME_MAX_LENGTH),
  [USER_PROFILE_COMPANY_STREET]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.COMPANY_STREET_MAX_LENGTH),
  [USER_PROFILE_COMPANY_CITY]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.COMPANY_CITY_MAX_LENGTH),
  [USER_PROFILE_COMPANY_ZIP_CODE]: fieldValidators.postalCode().required().isValid(),
  [USER_PROFILE_LEGAL_FORM]: fieldValidators.string().required(),
  [USER_PROFILE_INDUSTRY]: fieldValidators.string().required(),
  [USER_PROFILE_TURNOVER_CLASS]: fieldValidators.string().required(),
  [USER_PROFILE_DETAILED_ANNUAL_TURNOVER]: fieldValidators.price().min(0).max(999999999).required(),
  [USER_PROFILE_BALANCE_SHEET]: fieldValidators.price().min(0).max(999999999).required(),
  [USER_PROFILE_EMPLOYEE_COUNT]: fieldValidators.number().min(0).max(999999999).required(),
  [USER_PROFILE_FOUNDING_YEAR]: fieldValidators
    .number()
    .required()
    .max(new Date().getUTCFullYear())
    .min(InquiryConstants.MIN_FOUNDING_YEAR),
  [USER_PROFILE_FOUNDING_TWO_YEAR_OLD]: fieldValidators.boolean().required(),
  [INCOME_SURPLUS]: fieldValidators.boolean().required(),
  [USER_PROFILE_COMPANY_ALREADY_CUSTOMER]: fieldValidators.stringBoolean().required(),
  [COMPANY_DETAILS_COMPANY_TAX_NUMBER]: fieldValidators.custom(customValidations.companyTaxNumber),
  [EXCLUDING_QUESTION_FIRST]: fieldValidators.boolean().required(),
  [EXCLUDING_QUESTION_SECOND]: fieldValidators.boolean().required(),
  [EXCLUDING_QUESTION_THIRD]: fieldValidators.boolean().required(),
  [EXCLUDING_QUESTION_FOURTH]: fieldValidators.boolean().required(),
  [EXCLUDING_QUESTION_FIFTH]: fieldValidators.boolean().required(),
  [USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY]: fieldValidators
    .string()
    .max(InquiryConstants.MAX_USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY),
  [USER_PROFILE_ANNUAL_NET_PROFIT_OR_LOSS_CLASS]: fieldValidators.string().required(),
  [USER_PROFILE_NEGATIVE_EQUITY_CAPITAL]: fieldValidators.boolean().required(),
  [USER_PROFILE_INNOVATIVE_COMPANY]: fieldValidators.boolean().required(),
  [USER_PROFILE_ENERGY_EFFICIENT]: fieldValidators.boolean().required(),
  [USER_PROFILE_COMPANY_RATING_KIND]: fieldValidators.string().required(),
  [END_USER_EMAIL]: fieldValidators.string().required().custom(customValidations.isEmail),
  [END_USER_GENDER]: fieldValidators.string().required(),
  [END_USER_FIRST_NAME]: fieldValidators
    .name()
    .isValidName()
    .required()
    .max(InquiryConstants.MAX_END_USER_FIRST_NAME),
  [END_USER_LAST_NAME]: fieldValidators
    .name()
    .isValidName()
    .required()
    .max(InquiryConstants.MAX_END_USER_LAST_NAME),
  [END_USER_CONDITION_AMOUNT]: fieldValidators.number().required().positive(),
  [END_USER_CONDITION_INTEREST_RATE]: fieldValidators.number().required().positive(),
  [USER_PROFILE_LEGAL_FORM_DESCRIPTION]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.MAX_LEGAL_FORM_DESCRIPTION),
  [USER_PROFILE_PHONE_NUMBER]: fieldValidators
    .phoneNumber()
    .required()
    .hasPhonePrefix()
    .isPhoneNumber()
    .maxPhoneNumberLength()
    .minPhoneNumberLength(),
  [USER_PROFILE_BIRTHDATE]: fieldValidators
    .dateFormat()
    .isDateFormat()
    .minDate(InquiryConstants.MIN_BIRTHDATE)
    .maxDate(InquiryConstants.YESTERDAY)
    .required(),
};
