import * as React from 'react';

import { Center, Grid, GridItem, useDisclosure } from '@chakra-ui/react';

import { DashboardActionState } from 'api/CompeonReverseApi/customer/queryHooks/inquiries';
import { Asset } from 'components/Asset';
import { translations } from 'new/form/common/types';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { WelcomePackageModal } from './WelcomePackageModal';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  state: string;
}

export const WelcomePackageStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
  state,
}: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <ProgressSection
        isFirst={isFirst}
        isLast={isLast}
        isCompleted={isCompleted}
        completedValue={completedValue}
        progressIncrement={progressIncrement}
        progressTotal={progressTotal}
        id={id}
      >
        <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
          <GridItem>
            <WelcomePackageItem onOpen={onOpen} isCompleted={isCompleted} state={state} />
          </GridItem>

          <GridItem>
            <Center height="100%">
              <Asset
                htmlHeight="200"
                type="dashboard"
                value={ProgressSectionType.UPLOAD_WELCOME_PACKAGE}
              />
            </Center>
          </GridItem>
        </Grid>
      </ProgressSection>
      <WelcomePackageModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

type WelcomePackageItemProps = Pick<Props, 'state' | 'isCompleted'> & {
  onOpen: () => void;
};

const WelcomePackageItem = ({ onOpen, isCompleted, state }: WelcomePackageItemProps) => {
  const { title, description, action } =
    translations.pages.inquiryDetails.dashboard.actions.welcomePackage;
  const t = useTranslations();

  return (
    <>
      <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
        {t(title)}
      </HeadingComponent>
      <TextComponent mb={3}>{t(description)}</TextComponent>
      {isCompleted ? null : (
        <ButtonComponent
          onClick={(event) => {
            onOpen();
            event.preventDefault();
          }}
          leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
          variant="primary"
          disabled={state === DashboardActionState.INACTIVE}
        >
          {t(action)}
        </ButtonComponent>
      )}
    </>
  );
};
