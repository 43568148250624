import { LegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';

export function mapCBbankLegalRepresentatives(legalRepresentatives: LegalRepresentative[]) {
  return legalRepresentatives.map((legalRep) => ({
    id: legalRep.id,
    salutation: legalRep.salutation,
    first_name: legalRep.firstName,
    last_name: legalRep.lastName,
    email: legalRep.email,
    phone_number: legalRep.phoneNumber,
    birth_date: legalRep.birthDate,
    sole_signature_authorized: legalRep.soleSignatureAuthorized,
  }));
}
