import { PaymentTermOptions } from 'cbbank/inquiry/fields';
import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { translations } from 'new/form/common/types';
import { FormatMessage } from 'utils/intl';

const { up_to_30_days, up_to_45_days, up_to_60_days, up_to_90_days, more_than_90_days } =
  translations.inquiryType.cbBank.pages.financingNeed.sections.factoringNeed.fields.paymentTerm
    .options;

export const paymentTermOptions: SelectOption<PaymentTermOptions>[] = [
  {
    label: up_to_30_days,
    value: PaymentTermOptions.UP_TO_30_DAYS,
  },
  {
    label: up_to_45_days,
    value: PaymentTermOptions.UP_TO_45_DAYS,
  },
  {
    label: up_to_60_days,
    value: PaymentTermOptions.UP_TO_60_DAYS,
  },
  {
    label: up_to_90_days,
    value: PaymentTermOptions.UP_TO_90_DAYS,
  },
  {
    label: more_than_90_days,
    value: PaymentTermOptions.MORE_THAN_90_DAYS,
  },
];

export const getCbBankPaymentTermOptions = (
  formatMessage: FormatMessage,
): SelectOption<PaymentTermOptions>[] => {
  return paymentTermOptions.map(({ label, value }) => {
    return {
      value,
      label: formatMessage({ id: label }),
    };
  });
};
