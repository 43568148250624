import { showBeneficialOwnersSection } from 'cbbank/inquiry/config/conditions';
import {
  cbBankFields,
  cbBankFieldsWithSectionString,
  cbBankFieldsWithoutParents,
} from 'cbbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import {
  validateArrayFields,
  validateField,
  ValidateSectionFunction,
} from 'new/form/useValidateFields';
import { beneficialOwnerValidations } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/validations';

export const validateBeneficialOwnersSection: ValidateSectionFunction<InquiryType.cbBank> = (
  form,
  values,
  store,
  conditions,
) => {
  if (showBeneficialOwnersSection({ values })) {
    const {
      firstName,
      lastName,
      nationality,
      capitalOrVotingRightsPercentage,
      countryOfResidence,
      placeOfResidence,
      birthPlace,
      birthDate,
    } = cbBankFieldsWithoutParents.personalDataPage.beneficialOwnersSection.beneficialOwners;

    const normalFieldsValid = validateField({
      field: allInformationAvailable,
      values,
      form,
      conditions,
    });

    const arrayFieldsValid = validateArrayFields({
      arrayValues: values.personalDataPage.beneficialOwnersSection.beneficialOwners || [],
      fieldsWithoutParent: [
        firstName,
        lastName,
        nationality,
        capitalOrVotingRightsPercentage,
        countryOfResidence,
        placeOfResidence,
        birthPlace,
        birthDate,
      ],
      parentName:
        cbBankFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
          .sectionString,
      form,
      values,
      conditions,
    });

    return normalFieldsValid && arrayFieldsValid;
  }

  return validateField({
    field: allInformationAvailable,
    values,
    form,
    conditions,
  });
};

const {
  allInformationAvailable,
  beneficialOwners: {
    firstName,
    lastName,
    nationality,
    birthDate,
    capitalOrVotingRightsPercentage,
    birthPlace,
    countryOfResidence,
    placeOfResidence,
  },
} = cbBankFields.personalDataPage.beneficialOwnersSection;

export const beneficialOwnersSectionValidationMap = {
  allInformationAvailable: fieldValidators.stringBoolean().required(),
  [firstName]: beneficialOwnerValidations.firstName.required(),
  [lastName]: beneficialOwnerValidations.lastName.required(),
  [nationality]: beneficialOwnerValidations.nationality.required(),
  [capitalOrVotingRightsPercentage]:
    beneficialOwnerValidations.capitalOrVotingRightsPercentage.required(),
  [countryOfResidence]: beneficialOwnerValidations.countryOfResidence.required(),
  [placeOfResidence]: beneficialOwnerValidations.placeOfResidence,
  [birthPlace]: beneficialOwnerValidations.birthPlace,
  [birthDate]: beneficialOwnerValidations.birthDate,
};
