import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

import { IInquiryListOptions } from 'models/InquiryList.model';
import { INQUIRY_TABLE_HEADERS } from 'modules/InquiryTable/constants';
import { SORT_DIRECTION } from 'pages/operationPortal/CompaniesList/helpers/useCompaniesListTableHeaders';

interface IInquiriesListContextType {
  tableOptions: IInquiryListOptions;
  setTableOptions: (values: Partial<IInquiryListOptions>) => void;
  resetTableOptions: () => void;
}

const INQUIRIES_TABLE_OPTIONS = 'persist:inquiriesTableOptions';

export const getInquiriesTableOptionsFromStorage = (): IInquiryListOptions =>
  JSON.parse(sessionStorage.getItem(INQUIRIES_TABLE_OPTIONS) || '{}');

const initialStoredOptions = getInquiriesTableOptionsFromStorage();

const defaultValues: IInquiryListOptions = {
  currentPage: 1,
  pageCount: 1,
  pageSize: 5,
  sortDirection: SORT_DIRECTION.DESC,
  sortBy: INQUIRY_TABLE_HEADERS.CREATED_AT,
  touched: false,
  filterBy: {
    status: 'all',
    amountFrom: undefined,
    amountTo: undefined,
    companyName: undefined,
    createdAtFrom: undefined,
    createdAtTo: undefined,
    inquiryType: undefined,
    purposeKind: undefined,
    productKind: undefined,
    id: undefined,
    inquiryManagerId: undefined,
    customerVBNumber: undefined,
    zipCodeArea: undefined,
    vppId: undefined,
  },
};

const initialValues: IInquiryListOptions = {
  ...defaultValues,
  ...initialStoredOptions,
};

const InquiriesListContext = createContext<IInquiriesListContextType>({
  tableOptions: initialValues,
  setTableOptions: () => {},
  resetTableOptions: () => {},
});

export const useInquiriesListContext = () => {
  const context = useContext(InquiriesListContext);
  if (!context) {
    throw new Error('useInquiriesListContext must be used within a InquiriesListProvider');
  }
  return context;
};

export const InquiriesListProvider = ({ children }: { children: React.ReactNode }) => {
  const [tableOptions, setTableOptionsState] = useState<IInquiryListOptions>(initialValues);

  const setTableOptions = useCallback((updatedValues: Partial<IInquiryListOptions>) => {
    setTableOptionsState((prevState) => {
      const newOptions = { ...prevState, ...updatedValues };
      sessionStorage.setItem(INQUIRIES_TABLE_OPTIONS, JSON.stringify(newOptions));
      return newOptions;
    });
  }, []);

  const resetTableOptions = useCallback(() => {
    sessionStorage.removeItem(INQUIRIES_TABLE_OPTIONS);
    setTableOptionsState({
      ...defaultValues,
      touched: true,
      filterBy: {
        ...defaultValues.filterBy,
        status: '',
      },
    });
  }, []);

  useEffect(() => {
    const storedOptions = getInquiriesTableOptionsFromStorage();
    setTableOptionsState({
      ...defaultValues,
      ...storedOptions,
    });
  }, []);

  return (
    <InquiriesListContext.Provider value={{ tableOptions, setTableOptions, resetTableOptions }}>
      {children}
    </InquiriesListContext.Provider>
  );
};
