import { useState } from 'react';

import { useDisclosure } from '@chakra-ui/hooks';

import { ExternalLoadingModalProps } from 'new/form/model';

/**
 * Exposes helpers to control the loading modal.
 * For example: This hook can be connected to a **LoadingModal** using the *isOpen*, *onOpen*, *onClose* and *loadingModalProps* variables
 * in combination with a *beforeNextStepAction* or *beforeSubmitAction*. The *setLoadingModalProps* function can be passed to the action
 * to set the *title*, *subtitle* and *description* of the modal externally.
 */
export const useLoadingModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingModalProps, setLoadingModalProps] = useState<ExternalLoadingModalProps>({});

  return {
    isLoadingModalOpen: isOpen,
    onLoadingModalOpen: onOpen,
    onLoadingModalClose: onClose,
    loadingModalProps,
    setLoadingModalProps,
  };
};
