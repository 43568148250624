import React from 'react';

import Separator from 'components/FormRow/Separator';
import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';

import { BeneficialOwnersSection } from './sections/beneficialOwners';
import { LegalRepresentativesSection } from './sections/legalRepresentatives';
import { UserDataSection } from './sections/userData';

export const CbBankPersonalDataPage = () => {
  const t = useTranslations();

  const {
    progressBarTitle,
    heading: { header, subheader },
  } = translations.inquiryType.cbBank.pages.personalData;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      <Separator />
      <UserDataSection />
      <LegalRepresentativesSection />
      <BeneficialOwnersSection />
    </Page>
  );
};
