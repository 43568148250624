import React, { useEffect } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
  Container,
  Box,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { cbBankSteps } from 'cbbank/inquiry/steps';
import paths from 'constants/paths';
import { translations } from 'new/form/common/types';
import ButtonComponent from 'theme/components/Button';
import ArrowLeftIcon from 'theme/components/Icon/ArrowLeftIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { GetCodeNumber } from './GetCodeNumber';
import { GetPhoneNumber } from './GetPhoneNumber';
import { ShowCondition } from './ShowCondition';
import { useInstalmentHandler } from '../../hooks/useInstalmentHandler';

export const INSTALMENT = 'instalment';

type Props = {
  locationState?: string;
};

const SMSVerification = ({ locationState }: Props) => {
  const t = useTranslations();
  const history = useHistory();
  const {
    dropInstalment,
    data,
    submitPinNumber,
    submitPhoneNumber,
    resetPhoneNumber,
    state,
    isPendingPhoneNumberConfirmation,
  } = useInstalmentHandler();

  const { onClose } = useDisclosure();

  const submitPin = async (pin: string) => {
    await submitPinNumber(pin);
    onClose();
  };

  useEffect(() => {
    dropInstalment();
  }, [dropInstalment]);

  if (state.codeSend || (locationState && locationState !== cbBankSteps.companyDataPage)) {
    return <ShowCondition />;
  }
  return (
    <Modal onClose={onClose} isOpen>
      <ModalOverlay />
      <ModalContent maxW={800}>
        <Container maxW={800} mt={'16'}>
          <Box mb={'4rem'} p={'1.5rem 2rem'}>
            {state.phoneSend ? (
              <GetCodeNumber
                onSubmit={submitPin}
                phoneNumber={data.phoneNumber}
                submitRetry={resetPhoneNumber}
                pending={isPendingPhoneNumberConfirmation}
              />
            ) : (
              <>
                <GetPhoneNumber
                  onSubmit={submitPhoneNumber}
                  pending={isPendingPhoneNumberConfirmation}
                />
              </>
            )}
          </Box>
        </Container>
        <ModalFooter justifyContent={'flex-start'}>
          <ButtonComponent
            onClick={() =>
              history.push(state.phoneSend ? paths.contractDetails : paths.companyDetails)
            }
            leftIcon={<ArrowLeftIcon boxSize={6} />}
            variant={'tertiary'}
          >
            {t(translations.buttons.back)}
          </ButtonComponent>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SMSVerification;
