import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import { cbBankFieldsWithSectionString } from 'cbbank/inquiry/fields';
import Separator from 'components/FormRow/Separator';
import { translations } from 'new/form/common/types';
import { CbBankBeneficialOwnerFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/cbbank/CbBankBeneficialOwnerFields';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import BeneficialOwnersCards from './BeneficialOwnersCards';

const arrayFieldName =
  cbBankFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
    .sectionString;

export const CbBankBeneficialOwnersCards = ({
  fields,
}: {
  fields: FieldArrayRenderProps<any, any>['fields'];
}) => {
  const { mutators } = useForm();
  const t = useTranslations();
  const { additionalInformation } =
    translations.inquiryType.cbBank.pages.personalData.sections.beneficialOwners;

  useEffect(() => {
    if (fields.length === 0) {
      mutators.push(arrayFieldName);
    }
  }, [fields.length, mutators]);

  if (!fields) {
    return null;
  }

  return (
    <>
      <TextComponent color={'text.tertiary'} mb={8}>
        {t(additionalInformation)}
      </TextComponent>
      {fields.map((name, index) => {
        const beneficialOwner = fields.value[index];
        const fieldLength = fields.length;
        return (
          <React.Fragment key={name}>
            <BeneficialOwnersCards.Container>
              {index !== 0 ? (
                <BeneficialOwnersCards.DeleteButton fields={fields} index={index} variant="link">
                  {t(
                    translations.inquiryType.cbBank.pages.personalData.sections.beneficialOwners
                      .fields.beneficialOwner.remove,
                  )}
                </BeneficialOwnersCards.DeleteButton>
              ) : null}
              {beneficialOwner?.id ? (
                <CbBankBeneficialOwnerFields mode={'edit'} prefix={name} />
              ) : (
                <CbBankBeneficialOwnerFields mode={'add'} prefix={name} />
              )}
            </BeneficialOwnersCards.Container>
            {fieldLength ? index < fieldLength - 1 && <Separator /> : null}
          </React.Fragment>
        );
      })}
    </>
  );
};
