import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { useInquiryProcessConfig } from '../form/state/inquiryProcessConfig/hooks';
import { InquiryMode } from '../form/state/types';

type InquiryEditMode = Exclude<InquiryMode, 'create'>;
export function useRootRedirect(mode: InquiryEditMode) {
  const inquiryId = useSelector(getInquiryIdSelector);
  const history = useHistory();
  const {
    formStateData: { paths },
  } = useInquiryProcessConfig();

  const editPath = paths.editPaths[mode];
  const inquiryListPath = paths.listPaths[mode];

  useEffect(() => {
    if (inquiryId && editPath) {
      history.push(editPath.replace(':id', inquiryId));
    } else if (inquiryListPath) {
      history.push(inquiryListPath);
    }
  }, [editPath, history, inquiryId, inquiryListPath]);
}
