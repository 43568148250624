import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import PortalPage from 'components/PortalPage';
import ElementsPerPage from 'components/PortalTable/ElementsPerPage/ElementsPerPage';
import Pagination from 'components/PortalTable/Pagination/Pagination';
import PortalTable from 'components/PortalTable/PortalTable';
import { ConfigController } from 'config/ConfigController';
import { IInquiryListElement } from 'models/InquiryList.model';
import { InquiriesListProvider } from 'modules/InquiriesListProvider';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import { mapInquiriesToTableData } from 'modules/InquiryTable/mapFromApi';
import { useInquiryTable } from 'modules/InquiryTable/useInquiryTable';
import { resetPersist } from 'new/form/persist';
import NoInquiry from 'pages/customerPortal/CustomerInquiryList/NoInquiry';
import {
  showSomeSubStatusesForInquiries,
  useCustomerInquiryTableHeaders,
} from 'pages/customerPortal/CustomerInquiryList/useCustomerInquiryTableHeaders';
import { clearInquiryDraftId, setInquiryType } from 'store/inquiryProcess/actions';
import { useRedirectOnSingleInquiry } from 'utils/hooks/useRedirectToInquiryEdit';
import { useTranslations } from 'utils/hooks/useTranslations';

import PageHeading from './PageHeading';

const InquiryListContent = () => {
  const t = useTranslations();
  const {
    totalPages,
    currentPage,
    pageSize,
    onPageSizeChange,
    onPaginationChange,
    isInitialized,
    onSortChange,
    sortDirection,
    sortBy,
    data,
    isLoading,
    onSearchChange,
  } = useInquiryTable();
  const { redirect, checkAfterInquiriesLoad } = useRedirectOnSingleInquiry();
  const dispatch = useDispatch();
  const tableHeaders = useCustomerInquiryTableHeaders();

  const handleRowClick = (row: IInquiryListElement) => {
    const { id, status } = row;
    const selectedInquiry = data?.inquiries.find(
      (inquiry: IInquiryListElement) => inquiry.id === id,
    );
    dispatch(setInquiryType(selectedInquiry.formType));
    ConfigController.form.selectInquiryType(selectedInquiry.formType, true);

    redirect(status as INQUIRY_STATUSES, id);
  };

  const tableData = data
    ? mapInquiriesToTableData(data?.inquiries).map(showSomeSubStatusesForInquiries)
    : [];

  useEffect(() => {
    checkAfterInquiriesLoad(data?.inquiries, currentPage);
    dispatch(clearInquiryDraftId()); // clear if any draft id already saved for edit inquiry/draft case. If there is any id then trash button will be shown on edit/new inquiry.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, checkAfterInquiriesLoad, currentPage, data?.inquiries]);

  // Reset the persisted form data as well as the progress bar
  useEffect(() => {
    resetPersist();
  }, []);

  const noInquiries = !data?.inquiries?.length && !isLoading;
  return (
    <PortalPage
      title={t('pages.inquiryList.pageTitle')}
      heading={<PageHeading onSearchChange={onSearchChange} />}
    >
      {noInquiries ? (
        <NoInquiry />
      ) : (
        <PortalTable
          headers={tableHeaders}
          tableData={tableData}
          onRowClick={handleRowClick}
          isInitialized={isInitialized}
          isLoading={isLoading}
          onSortChange={onSortChange}
          sortBy={sortBy}
          sortDirection={sortDirection}
        >
          <ElementsPerPage onPageSizeChange={onPageSizeChange} pageSize={pageSize} />

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPaginationChange={onPaginationChange}
          />
        </PortalTable>
      )}
    </PortalPage>
  );
};

const InquiryList = () => (
  <InquiriesListProvider>
    <InquiryListContent />
  </InquiriesListProvider>
);

export default InquiryList;
