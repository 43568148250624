import React from 'react';

import { Center } from '@chakra-ui/react';

import { Asset } from 'components/Asset';
import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const LoadingPage = () => {
  const t = useTranslations();
  const { title, description } = translations.inquiryType.cbBank.pages.companyData.loadingModal;

  return (
    <>
      <PageHeading heading={t(title)} />
      <Center mt={16}>
        <Asset type="inquiry" value={InquiryAssetKeys.OFFER_PROVIDED} />
      </Center>
      <TextComponent mt={16} textAlign="center" color={'brand.default'} whiteSpace={'pre-line'}>
        {t(description)}
      </TextComponent>
    </>
  );
};
