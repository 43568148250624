import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { FieldTypes } from 'new/form/common/types';
import { ConditionFunction, ConditionsType } from 'new/form/model';
import { createUseConditions } from 'shared/inquiry/createUseConditions';
import { parseStringBoolean } from 'utils/helpers';

import { CbBankFieldTypes } from '../fields';

export const showBeneficialOwnersAddButton: ConditionFunction<CbBankFieldTypes> = ({ values }) => {
  return (
    parseStringBoolean(values.personalDataPage.beneficialOwnersSection?.allInformationAvailable) ===
    true
  );
};

export const showBeneficialOwnersSection = ({
  values,
}: {
  values: FieldTypes<InquiryType.cbBank>;
}) => {
  const allInformationAvailable =
    values.personalDataPage.beneficialOwnersSection.allInformationAvailable;
  if (allInformationAvailable) {
    return parseStringBoolean(allInformationAvailable);
  }
};

export const cbBankConditions: ConditionsType<CbBankFieldTypes> = {
  showBeneficialOwnersAddButton,
  showBeneficialOwnersSection,
};

export const useCbBankConditions = createUseConditions(cbBankConditions);
