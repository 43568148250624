import * as React from 'react';

import { Center, Flex } from '@chakra-ui/react';

import { usePartnerConfig } from 'config/partner/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface IconHeadingHeaderProps {
  asset: React.ReactNode;
  heading: string;
  color?: string;
}

interface DefaultHeaderProps {
  header?: string;
  subheader?: string;
}

const Header = (props: DefaultHeaderProps) => {
  const t = useTranslations('pages.finalPage.heading');
  const {
    details: { fullName: partnerFullName },
  } = usePartnerConfig();

  const header = props.header || t('header', { partnerFullName });
  const subheader = props.subheader || t('subheader');

  return (
    <>
      <HeadingComponent as="h2" mb={2} textAlign="center">
        {header}
      </HeadingComponent>
      <TextComponent textAlign="center" color="text.tertiary">
        {subheader}
      </TextComponent>
    </>
  );
};

const BfssHeader = () => {
  const t = useTranslations('pages.finalPage.heading');
  const {
    details: { fullName: partnerFullName },
  } = usePartnerConfig();

  return (
    <>
      <HeadingComponent as="h2" mb={2} textAlign="center">
        {t('header', { partnerFullName })}
      </HeadingComponent>
      <TextComponent textAlign="center" color="text.tertiary">
        {t('subheader')}
      </TextComponent>
    </>
  );
};

const IconHeadingHeader = ({
  asset,
  heading,
  color = 'status.success',
}: IconHeadingHeaderProps) => {
  return (
    <Flex direction="column" gap="2rem">
      <Center>{asset}</Center>
      <HeadingComponent as="h2" textAlign="center" color={color} whiteSpace="pre-line">
        {heading}
      </HeadingComponent>
    </Flex>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.hausbank]: IconHeadingHeader,
  [InquiryType.bfsService]: BfssHeader,
  default: Header,
});
