import { CbBankFieldTypes } from 'cbbank/inquiry/fields';
import { parseStringBoolean } from 'utils/helpers';

type CbBankCompanyDetailsCompletionSectionFieldTypes =
  CbBankFieldTypes['companyDataPage']['companyDetailsCompletionSection'];

export const mapCbBankCompanyDetailsCompletion = (
  values: CbBankCompanyDetailsCompletionSectionFieldTypes,
) => ({
  name: values.name,
  street: values.street,
  zip_code: values.zipCode,
  city: values.city,
  legal_form: values.legalForm,
  industry: values.industry,
  additional_details: {
    older_than_2_years: parseStringBoolean(values.olderThan2Years),
    subject_to_accounting: parseStringBoolean(values.subjectToAccounting),
    positive_equity: parseStringBoolean(values.positiveEquity),
    positive_annual_result: parseStringBoolean(values.positiveAnnualResult),
  },
});
