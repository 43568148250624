import { get as _get } from 'lodash';
import { useFormState } from 'react-final-form';

import { StaticCompany } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/CompanyInformationForm/StaticCompanyInformation';

/**
 * Creates a static company object from the form values
 * @param config defines the mapping between the static company object and the form values (indexed by the field names)
 * @returns A new the static company object
 */
export const useGetCompany = <FieldTypes>(config: StaticCompany) => {
  const { values } = useFormState<FieldTypes>();

  const company = {} as StaticCompany;
  Object.entries(config).forEach(([key, fieldName]) => {
    company[key as keyof StaticCompany] = _get(values, fieldName);
  });

  return company;
};
