import React from 'react';

import { DayPickerWithField } from 'components/DatePicker';
import { INPUT_TYPE_DECIMAL, InputWithField } from 'components/Input';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { translations } from 'new/form/common/types';
import {
  FieldConfig,
  useGenerateField,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/formFields/useGenerateField';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

import { BeneficialOwner } from '../fields';

const { fields } = translations.pages.companiesDetails.associatedPerson.new.beneficialOwners;

type BeneficialOwnerFieldsWithoutId = Omit<BeneficialOwner, 'id'>;

type BeneficialOwnerFields = Record<keyof BeneficialOwnerFieldsWithoutId, React.ReactNode>;

export const beneficialOwnerFieldConfig: FieldConfig<BeneficialOwnerFieldsWithoutId> = {
  firstName: {
    component: InputWithField,
    caption: fields.firstName.caption,
  },
  lastName: {
    component: InputWithField,
    caption: fields.lastName.caption,
  },
  nationality: {
    component: InputWithField,
    caption: fields.nationality.caption,
    optional: true,
  },
  capitalOrVotingRightsPercentage: {
    componentProps: (translation) => ({
      type: INPUT_TYPE_DECIMAL,
      sideSymbol: () => translation(translations.other.percent),
    }),
    component: InputWithField,
    caption: fields.capitalOrVotingRightsPercentage.caption,
    optional: true,
  },
  placeOfResidence: {
    component: InputWithField,
    caption: fields.placeOfResidence.caption,
    optional: true,
  },
  countryOfResidence: {
    component: InputWithField,
    caption: fields.countryOfResidence.caption,
    optional: true,
  },
  birthPlace: {
    component: InputWithField,
    caption: fields.birthPlace.caption,
    optional: true,
  },
  birthDate: {
    componentProps: () => ({
      maxDate: new Date(),
    }),
    component: DayPickerWithField,
    caption: fields.birthDate.caption,
    optional: true,
  },
  fictional: {
    component: YesNoRadioGroupWithField,
    caption: fields.fictional.caption,
    optional: true,
  },
};

export function useGenerateBeneficialOwnerFields(
  mode: AssociatedPersonMode,
  prefix?: string,
): BeneficialOwnerFields {
  const generateField = useGenerateField<BeneficialOwnerFieldsWithoutId>(
    beneficialOwnerFieldConfig,
    mode,
    prefix,
  );

  return {
    firstName: generateField('firstName'),
    lastName: generateField('lastName'),
    nationality: generateField('nationality'),
    capitalOrVotingRightsPercentage: generateField('capitalOrVotingRightsPercentage'),
    placeOfResidence: generateField('placeOfResidence'),
    countryOfResidence: generateField('countryOfResidence'),
    birthPlace: generateField('birthPlace'),
    birthDate: generateField('birthDate'),
    fictional: generateField('fictional'),
  };
}
