import normalize from 'json-api-normalizer';
import _values from 'lodash/values';

import { removeNullValuesRecursively } from 'utils/removeNullValuesRecursively';

import { CbBankFieldTypes } from '../fields';

/**
 * Using any for the types here as the type from the API does not match the type from the FE. Additionally, some numbers
 * are strings or boolean are strings so these types do not help anyway. Internally everything is converted to the
 * correct type.
 */

interface InquiryDetails {
  formType: any;
  lane: any;
  annualFactoringRelevantTurnover: any;
  paymentTerm: any;
  turnoverShareOfLargestClient: any;
  centralisedSettlement: any;
  tradeCreditInsurance: any;
  downPaymentOrInstallments: any;
  assignedElsewhere: any;
  customersAbroad: any;
  iban: any;
  bic: any;
  bankName: any;
}

interface Inquiry {
  id: any;
  type: any;
  attributes: {
    compeonStatus: any;
    createdAt: any;
    updatedAt: any;
    formType: any;
    welcomeCallPerformedAt: any;
    insuranceOfferRequestedAt: any;
    sentToCompeonAt: any;
    externalReference: any;
    expirationInDays: any;
    videoIdentUrl: any;
    allowDigitalProcessing: any;
    customerLegalRepresentativeId: any;
    status: any;
    substatus: any;
    externalId: any;
    details: InquiryDetails;
    videoIdentStatus: any;
    customerIdentificationType: any;
    legalRepresentativesRequireManualMerging: any;
    customersSoleSignatureAuthorized: any;
    allRequiredFilesUploaded: any;
    collaterals: any;
    completedSignsCount: any;
    currentTypeOfUsage: any;
    futureTypeOfUsage: any;
    isQuickCheckStarted: any;
  };
  relationships: {
    customer: {
      data: {
        id: any;
        type: any;
      };
    };
    endCustomer: {
      data: any;
    };
    company: {
      data: {
        id: any;
        type: any;
      };
    };
    bankAdvisor: {
      data: any;
    };
    inquiryManager: {
      data: any;
    };
    realEstateExpert: {
      data: any;
    };
    offers: {
      data: any[];
    };
    legalRepresentatives: {
      data: {
        id: any;
        type: any;
      }[];
    };
    beneficiaryOwners: {
      data: {
        id: any;
        type: any;
      }[];
    };
    videoIdentifications: {
      data: any[];
    };
    indicativeCondition: {
      data: any;
    };
    transparencyRegister: {
      data: any;
    };
  };
}

interface IndicativeCondition {
  id: any;
  type: any;
  attributes: {
    factoringFee: any;
    factoringLine: any;
    payoutRatio: any;
  };
}

interface Customer {
  id: any;
  type: any;
  attributes: {
    adpRootNumber: any;
    archived: any;
    birthDate: any;
    email: any;
    existsInCompeon: any;
    firstName: any;
    lastName: any;
    locale: any;
    phoneNumber: any;
    salutation: any;
    structureNumber: any;
    vbNumber: any;
    zipCode: any;
    invitationAccepted: any;
    invitationSent: any;
  };
}

interface Company {
  id: any;
  type: any;
  attributes: {
    crefoId: any;
    name: any;
    street: any;
    zipCode: any;
    city: any;
    legalForm: any;
    industry: any;
    additionalDetails: {
      olderThan2Years: any;
      subjectToAccounting: any;
      positiveEquity: any;
      positiveAnnualResult: any;
    };
  };
  relationships: {
    kycCompanies: {
      data: any[];
    };
  };
}

interface LegalRepresentative {
  id: any;
  type: any;
  attributes: {
    additionalInfo: {
      soleSignatureAuthorized: any;
    };
    address: any;
    birthDate: any;
    birthName: any;
    birthPlace: any;
    city: any;
    country: any;
    email: any;
    firstName: any;
    lastName: any;
    function: any;
    phoneNumber: any;
    powerOfRepresentation: any;
    salutation: any;
    source: any;
    successfullyMerged: any;
    zipCode: any;
    createdAt: any;
    potentiallyManipulated: any;
  };
}

interface BeneficiaryOwner {
  id: any;
  type: any;
  attributes: {
    additionalInfo: {
      nationality: any;
    };
    address: any;
    birthDate: any;
    birthPlace: any;
    birthname: any;
    city: any;
    companyShare: any;
    country: any;
    email: any;
    fictional: any;
    firstName: any;
    lastName: any;
    ownerType: any;
    phoneNumber: any;
    salutation: any;
    scope: any;
    source: any;
    successfullyMerged: any;
    taxId: any;
    updatedAt: any;
    zipCode: any;
    potentiallyManipulated: any;
  };
}

interface NormalizedResponse {
  companies: Record<string, Company>;
  customers: Record<string, Customer>;
  inquiries: Record<string, Inquiry>;
  indicativeConditions?: Record<string, IndicativeCondition>;
  legalRepresentatives: Record<string, LegalRepresentative>;
  beneficiaryOwners: Record<string, BeneficiaryOwner>;
}

interface CbBankMappingFieldTypes {
  financingNeedPage: CbBankFieldTypes['financingNeedPage'];
  companyDataPage: Omit<
    CbBankFieldTypes['companyDataPage'],
    'companySearchSection' | 'complianceSection'
  >;
  offerPage: CbBankFieldTypes['offerPage'];
  personalDataPage: CbBankFieldTypes['personalDataPage'];
}

export const cbBankFeTobeMapping = (beResponse: any): CbBankFieldTypes => {
  const normalizedResponse: NormalizedResponse = normalize(beResponse, {
    camelizeKeys: true,
  });

  const company = _values(normalizedResponse.companies)[0];
  const customer = _values(normalizedResponse.customers)[0];
  const inquiry = _values(normalizedResponse.inquiries)[0];
  const inquiryDetails = inquiry.attributes.details;
  const indicativeCondition = _values(normalizedResponse.indicativeConditions)[0] as
    | IndicativeCondition
    | undefined;
  const companyAdditionalDetails = company.attributes.additionalDetails;
  const legalRepresentatives = _values(normalizedResponse.legalRepresentatives);
  const beneficiaryOwners = _values(normalizedResponse.beneficiaryOwners);

  const mappedObject: CbBankMappingFieldTypes = {
    financingNeedPage: {
      factoringNeedSection: {
        annualFactoringRelevantTurnover: inquiryDetails.annualFactoringRelevantTurnover,
        paymentTerm: inquiryDetails.paymentTerm,
        factoringLine: indicativeCondition?.attributes.factoringLine,
      },
      businessDetailsSection: {
        turnoverShareOfLargestClient: inquiryDetails.turnoverShareOfLargestClient,
        centralisedSettlement: inquiryDetails.centralisedSettlement,
        tradeCreditInsurance: inquiryDetails.tradeCreditInsurance,
        downPaymentOrInstallments: inquiryDetails.downPaymentOrInstallments,
        assignedElsewhere: inquiryDetails.assignedElsewhere,
        customersAbroad: inquiryDetails.customersAbroad,
      },
    },
    companyDataPage: {
      companyDetailsCompletionSection: {
        name: company.attributes.name,
        street: company.attributes.street,
        zipCode: company.attributes.zipCode,
        city: company.attributes.city,
        legalForm: company.attributes.legalForm,
        industry: company.attributes.industry[0],
        olderThan2Years: companyAdditionalDetails.olderThan2Years,
        subjectToAccounting: companyAdditionalDetails.subjectToAccounting,
        positiveEquity: companyAdditionalDetails.positiveEquity,
        positiveAnnualResult: companyAdditionalDetails.positiveAnnualResult,
      },
      bankAccountSection: {
        iban: inquiryDetails.iban,
        bic: inquiryDetails.bic,
        bankName: inquiryDetails.bankName,
      },
    },
    offerPage: {
      contractLaneSection: {
        phoneNumber: undefined,
        factoringFee: indicativeCondition?.attributes.factoringFee,
        factoringLine: indicativeCondition?.attributes.factoringLine,
        payoutRatio: indicativeCondition?.attributes.payoutRatio,
        pinNumber: undefined,
      },
    },
    personalDataPage: {
      userDataSection: {
        salutation: customer.attributes.salutation,
        firstName: customer.attributes.firstName,
        lastName: customer.attributes.lastName,
        email: customer.attributes.email,
        phoneNumber: customer.attributes.phoneNumber,
      },
      legalRepresentativesSection: {
        legalRepresentatives: legalRepresentatives.map((legalRep) => {
          const attributes = legalRep.attributes;
          return {
            id: legalRep.id,
            salutation: attributes.salutation,
            firstName: attributes.firstName,
            lastName: attributes.lastName,
            birthDate: attributes.birthDate,
            email: attributes.email,
            phoneNumber: attributes.phoneNumber,
            soleSignatureAuthorized: attributes.additionalInfo.soleSignatureAuthorized,
          };
        }),
      },
      beneficialOwnersSection: {
        allInformationAvailable: undefined,
        beneficialOwners: beneficiaryOwners.map((beneficiaryOwner) => {
          const attributes = beneficiaryOwner.attributes;
          return {
            id: beneficiaryOwner.id,
            firstName: attributes.firstName,
            lastName: attributes.lastName,
            nationality: attributes.additionalInfo.nationality,
            capitalOrVotingRightsPercentage: attributes.companyShare,
            placeOfResidence: attributes.city,
            countryOfResidence: attributes.country,
            birthPlace: attributes.birthPlace,
            birthDate: attributes.birthDate,
          };
        }),
      },
    },
  };

  return removeNullValuesRecursively(mappedObject) as CbBankFieldTypes;
};
