import { cbBankFields } from 'cbbank/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

const { salutation, firstName, lastName, email, phoneNumber } =
  cbBankFields.personalDataPage.userDataSection;
const { fields: fieldTranslations } =
  translations.inquiryType.cbBank.pages.personalData.sections.userData;

export const userDateSectionLabelsMap: LabelsMap = {
  [salutation]: fieldTranslations.salutation.caption,
  [firstName]: fieldTranslations.firstName.caption,
  [lastName]: fieldTranslations.lastName.caption,
  [email]: fieldTranslations.email.caption,
  [phoneNumber]: fieldTranslations.phoneNumber.caption,
};
