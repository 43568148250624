import { createQuery } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { queryClient } from 'components/App';
import queryKeys from 'constants/queryKeys';

interface IndicativeConditionsParams {
  inquiryId: string;
  accessToken: string;
}

interface IndicativeConditionsResponse {
  attributes: {
    factoring_fee: string;
    factoring_line: string;
    payout_ratio: string;
  };
}

export const fetchIndicativeConditions = async ({
  queryKey,
}: {
  queryKey: [string, IndicativeConditionsParams];
}): Promise<IndicativeConditionsResponse> => {
  const [, variables] = queryKey;

  const response = await EcoBankingAxiosClientAuthedInstance.post(
    endpoints.INQUIRIES.SPECIALIZED.CB_BANK.CALCULATE_INDICATIVE_CONDITIONS.compose({
      params: { id: variables.inquiryId },
      query: { access_token: variables.accessToken },
    }),
  );

  return response.data.data;
};

export const useCalculateIndicativeConditions = createQuery<
  IndicativeConditionsResponse,
  IndicativeConditionsParams
>({
  primaryKey: queryKeys.partners.cbBank.calculateIndicativeConditions,
  queryFn: fetchIndicativeConditions,
  refetchOnWindowFocus: false,
});

export const prefetchIndicativeConditions = async (params: IndicativeConditionsParams) => {
  await queryClient.prefetchQuery(
    [queryKeys.partners.cbBank.calculateIndicativeConditions, params],
    fetchIndicativeConditions,
  );
};
