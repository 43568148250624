import React, { useCallback, useRef } from 'react';

import { useSelector } from 'react-redux';

import { ValidationRecord } from 'new/form/common/types';
import { InitialFormStateType } from 'new/form/model';
import { INQUIRY_PROGRESS_STORAGE_KEY } from 'new/form/persist';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { InquiryConfigContext } from './context';
import { PageStateActionTypes } from '../pageStateReducer';

export function useInquiryProcessConfig() {
  const context = React.useContext(InquiryConfigContext);
  if (context === undefined) {
    throw new Error('useInquiryProcessConfig must be used within a PageStateProvider');
  }
  return context;
}

export function useMode() {
  const { state } = useInquiryProcessConfig();
  return state.inquiryMode;
}

export function useFormValidations() {
  const {
    state: { inquiryMode },
    formStateData: { validations },
  } = useInquiryProcessConfig();

  return validations[inquiryMode];
}

export function useInquiryPathForMode(pageName: any) {
  const {
    state: { inquiryMode },
    formStateData: { routeToPageNameMap },
  } = useInquiryProcessConfig();
  const route = routeToPageNameMap[pageName][inquiryMode];
  const id = useSelector(getInquiryIdSelector);

  if (inquiryMode === 'create') {
    return route;
  }
  if (route) {
    return route.replace(':id', id);
  }
}

export const useResetInquiryProgress = () => {
  const {
    dispatch,
    formStateData: { validations, initialFormState },
  } = useInquiryProcessConfig();
  const initialStateWithSections = useInitialState(
    validations[initialFormState.inquiryMode],
    initialFormState,
    true,
  );

  const resetFn = useCallback(() => {
    dispatch({
      type: PageStateActionTypes.SET_PROGRESS,
      payload: initialStateWithSections.pageValidations,
    });
  }, [dispatch, initialStateWithSections.pageValidations]);

  return resetFn;
};

export const useSetCurrentStepValid = () => {
  const {
    dispatch,
    state: { pageValidations, currentStep },
  } = useInquiryProcessConfig();

  const setValid = useCallback(() => {
    // Extract current step pageValidation object
    const currentPageValidations = pageValidations[currentStep as string];
    // Create new validations object and set all sections to true
    const newValidations = { ...currentPageValidations.validations };
    Object.keys(newValidations).forEach((key) => {
      newValidations[key] = true;
    });

    const newPageValidations = {
      ...pageValidations,
      [currentStep]: {
        progress: {
          ...currentPageValidations.progress,
          numberOfValidSections: currentPageValidations.progress.numberOfSections,
          isSectionValid: true,
        },
        validations: newValidations,
      },
    };
    dispatch({
      type: PageStateActionTypes.SET_PROGRESS,
      payload: newPageValidations,
    });
  }, [currentStep, dispatch, pageValidations]);

  return setValid;
};

const useStableFunction = (fn: () => void) => {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  return useCallback(() => fnRef.current(), []);
};

export const useSetCurrentStepValidOnce = () => {
  const setCurrentStepValid = useSetCurrentStepValid();
  const stableSetCurrentStepValid = useStableFunction(setCurrentStepValid);
  return stableSetCurrentStepValid;
};

export function useInitialState<T>(
  validations: ValidationRecord<T>,
  initialState: InitialFormStateType<T>,
  ignoreLocalStorage: boolean = false,
) {
  const stateFromStorage = window.sessionStorage.getItem(INQUIRY_PROGRESS_STORAGE_KEY);
  const currentPageValidations = initialState.pageValidations;
  if (stateFromStorage && !ignoreLocalStorage) {
    return JSON.parse(stateFromStorage);
  }

  const initialNumberOfSections = Object.keys(validations).reduce((acc, key) => {
    const numberOfSections = Object.keys((validations as any)[key]).length;
    const currentValidations = (currentPageValidations as any)[key].validations;

    return {
      ...acc,
      [key]: {
        progress: {
          ...(currentPageValidations as any)[key].progress,
          numberOfSections,
        },
        validations: currentValidations,
      },
    };
  }, {});

  return {
    inquiryMode: initialState.inquiryMode,
    currentStep: initialState.currentStep,
    pageValidations: initialNumberOfSections,
  };
}
