import _invert from 'lodash/invert';

import { IInquiryListOptions } from 'models/InquiryList.model';
import { API_TO_HEADERS_MAP } from 'modules/InquiryTable/constants';
import { formatDateBE } from 'utils/date';

export const tableOptionsToQueryParams = (tableOptions: IInquiryListOptions) => ({
  data: {
    attributes: {
      page: {
        size: tableOptions.pageSize,
        number: tableOptions.currentPage,
      },
      sort_by: _invert(API_TO_HEADERS_MAP)[tableOptions.sortBy],
      sort_direction: tableOptions?.sortDirection
        ? tableOptions.sortDirection.toLowerCase()
        : undefined,
      filters: {
        id: tableOptions.filterBy.id,
        created_at_from: formatDateBE(tableOptions.filterBy.createdAtFrom),
        created_at_to: formatDateBE(tableOptions.filterBy.createdAtTo),
        association_name: tableOptions.filterBy.associationName,
        company_name: tableOptions.filterBy.companyName,
        company_country: tableOptions.filterBy.companyCountry,
        bank_advisor_full_name: tableOptions.filterBy.bankAdvisorFullName,
        company_partner_reference_id: tableOptions.filterBy.companyPartnerReferenceId,
        purpose_kind: tableOptions.filterBy.purposeKind,
        product_kind: tableOptions.filterBy.productKind,
        amount_from: tableOptions.filterBy.amountFrom,
        amount_to: tableOptions.filterBy.amountTo,
        status: tableOptions.filterBy.status,
        inquiry_manager_id: tableOptions.filterBy.inquiryManagerId,
        customer_vb_number: tableOptions.filterBy.customerVBNumber,
        form_type: [tableOptions.filterBy.inquiryType],
        zip_code_area: tableOptions.filterBy.zipCodeArea,
        vpp_id: tableOptions.filterBy.vppId,
        lane: tableOptions.filterBy.lane,
      },
    },
  },
});
