import { AxiosResponse, AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';

interface ConfirmPhoneVariables {
  phoneNumber: string;
  partnerId: string;
  inquiryId: string;
}

interface ConfirmTokenVariables {
  phoneNumber: string;
  pin: string;
}

interface ApiConfirmTokenResponse {
  access_token: string;
}

export const useConfirmPhone = createMutation<
  AxiosResponse<null>,
  ConfirmPhoneVariables,
  AxiosError
>({
  mutationFn: async ({ phoneNumber, partnerId, inquiryId }) => {
    return EcoBankingAxiosClientAuthedInstance.post(endpoints.SMS_TOKEN.LIST.compose(), {
      data: {
        attributes: {
          phone_number: phoneNumber,
          partner_name: partnerId,
          inquiry_id: inquiryId,
        },
      },
    });
  },
});

export const useConfirmToken = createMutation<
  AxiosResponse<ApiConfirmTokenResponse>,
  ConfirmTokenVariables,
  AxiosError
>({
  mutationFn: async ({ phoneNumber, pin }) => {
    return EcoBankingAxiosClientAuthedInstance.post(endpoints.SMS_TOKEN.EXCHANGE.compose(), {
      data: {
        attributes: {
          phone_number: phoneNumber,
          token: pin,
        },
      },
    });
  },
});
