import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { cbBankFieldsWithSectionString } from 'cbbank/inquiry/fields';
import FormSection from 'components/FormSection';
import { translations } from 'new/form/common/types';
import { CbBankLegalRepresentativeCards } from 'new/legalRepresentatives/CbBankLegalRepresentativeCards';
import { CbBankLegalRepresentativeFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/cbbank/CbBankLegalRepresentativeFields';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const LegalRepresentativesSection = () => {
  const t = useTranslations();
  const { mutators } = useForm();

  const { title, additionalInformation } =
    translations.inquiryType.cbBank.pages.personalData.sections.legalRepresentatives;
  const arrayFieldName =
    cbBankFieldsWithSectionString.personalDataPage.legalRepresentativesSection.legalRepresentatives
      .sectionString;

  return (
    <FormSection title={t(title)} hideSectionNumber>
      <TextComponent color={'text.tertiary'} mb={12}>
        {t(additionalInformation)}
      </TextComponent>
      <VStack gap={8}>
        <FieldArray name={arrayFieldName}>
          {({ fields }) => {
            return (
              <CbBankLegalRepresentativeCards
                fields={fields}
                FieldComponent={CbBankLegalRepresentativeFields}
                arrayFieldName={arrayFieldName}
              />
            );
          }}
        </FieldArray>

        <ButtonComponent
          leftIcon={<AddIcon boxSize={6} display={'block'} />}
          onClick={() => mutators.push(arrayFieldName)}
          variant={'primary'}
          alignSelf={'flex-end'}
          testId="addLegalRepresentativeButton"
        >
          {t(
            translations.inquiryType.cbBank.pages.personalData.sections.legalRepresentatives.fields
              .add,
          )}
        </ButtonComponent>
      </VStack>
    </FormSection>
  );
};
