import { useCbBankBeforeNextStep } from 'cbbank/inquiry/useCbBankBeforeNextStep';
import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType, NewFormInquiryType } from 'modules/Inquiry/Inquiry.type';

import { UseBeforeNextStepAction } from './model';

type BeforeNextStepConfig = {
  [inquiryType in NewFormInquiryType]: UseBeforeNextStepAction | (() => void);
};

const beforeNextStepConfig: BeforeNextStepConfig = {
  [InquiryType.mmv]: () => {},
  [InquiryType.profiMittweida]: () => {},
  [InquiryType.dzb]: () => {},
  [InquiryType.hausbank]: () => {},
  [InquiryType.cbBank]: useCbBankBeforeNextStep,
};

export const useBeforeNextStep = () => {
  const selectedInquiryType = useFormConfig().selectedInquiryType as NewFormInquiryType;

  const useBeforeNextStepAction = beforeNextStepConfig[selectedInquiryType];
  const beforeNextStepAction = useBeforeNextStepAction();
  return beforeNextStepAction;
};
