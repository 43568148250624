import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import FormSection from 'components/FormSection';
import { dzbFieldsWithSectionString } from 'dzb/inquiry/fields';
import { translations } from 'new/form/common/types';
import { DefaultLegalRepresentativeCards } from 'new/legalRepresentatives/LegalRepresentativeCards';
import { DzbLegalRepresentativeFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/dzb/DzbLegalRepresentativeFields';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const LegalRepresentativesSection = () => {
  const t = useTranslations();
  const { mutators } = useForm();
  const {
    title,
    subheadings: { additionalInformation },
  } = translations.inquiryType.dzb.pages.personalData.sections.legalRepresentatives;

  const arrayFieldName =
    dzbFieldsWithSectionString.personalDataPage.legalRepresentativesSection.legalRepresentatives
      .sectionString;

  return (
    <FormSection title={t(title)} sectionNumber={2}>
      <TextComponent color={'text.tertiary'} mb={12}>
        {t(additionalInformation)}
      </TextComponent>
      <VStack gap={8}>
        <FieldArray name={arrayFieldName}>
          {({ fields }) => {
            return (
              <DefaultLegalRepresentativeCards
                fields={fields}
                FieldComponent={DzbLegalRepresentativeFields}
                arrayFieldName={arrayFieldName}
              />
            );
          }}
        </FieldArray>

        <ButtonComponent
          leftIcon={<AddIcon boxSize={6} display={'block'} />}
          onClick={() => mutators.push(arrayFieldName)}
          variant={'secondary'}
          alignSelf={'flex-end'}
          testId="addLegalRepresentativeButton"
        >
          {t(
            translations.inquiryType.dzb.pages.personalData.sections.legalRepresentatives.fields
              .add,
          )}
        </ButtonComponent>
      </VStack>
    </FormSection>
  );
};
