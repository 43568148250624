import { CbBankLegalFormValues } from 'cbbank/inquiry/fields';
import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { translations } from 'new/form/common/types';
import { legalFormValues } from 'types/Company';
import { FormatMessage } from 'utils/intl';

export const getCbBankLegalFormOptions = (
  formatMessage: FormatMessage,
): SelectOption<CbBankLegalFormValues>[] => {
  const {
    einzelunternehmung,
    eingetragenerKaufmann,
    gesellschaftBurgerlichenRechts,
    offeneHandelsgesellschaft,
    kommanditgesellschaft,
    gmbh,
    unternehmergesellschaftHaftungsbeschrankte,
    aktiengesellschaft,
    eingetrageneGenossenschaft,
    haftungsbeschrankte,
  } =
    translations.pages.userProfile.sections.completingCompanyDetails.fields.userProfileLegalForm
      .options;

  const options: Array<{ id: string; value: CbBankLegalFormValues }> = [
    {
      id: einzelunternehmung,
      value: legalFormValues.einzelunternehmung,
    },
    {
      id: eingetragenerKaufmann,
      value: legalFormValues.eingetragenerKaufmann,
    },
    {
      id: gesellschaftBurgerlichenRechts,
      value: legalFormValues.gesellschaftBurgerlichenRechts,
    },
    {
      id: offeneHandelsgesellschaft,
      value: legalFormValues.offeneHandelsgesellschaft,
    },
    {
      id: kommanditgesellschaft,
      value: legalFormValues.kommanditgesellschaft,
    },
    {
      id: gmbh,
      value: legalFormValues.gmbh,
    },
    {
      id: unternehmergesellschaftHaftungsbeschrankte,
      value: legalFormValues.unternehmergesellschaftHaftungsbeschrankte,
    },
    {
      id: aktiengesellschaft,
      value: legalFormValues.aktiengesellschaft,
    },
    {
      id: eingetrageneGenossenschaft,
      value: legalFormValues.eingetrageneGenossenschaft,
    },
    {
      id: haftungsbeschrankte,
      value: legalFormValues.haftungsbeschrankte,
    },
  ];

  return options.map(({ id, value }) => {
    return {
      value,
      label: formatMessage({ id }),
    };
  });
};
