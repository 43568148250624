import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  useCreateCbBankIndicativeConditionsMutation,
  IndicativeConditionsApiDataProps,
} from 'api/CompeonReverseApi/operation/queryHooks/inquiries';
import paths from 'constants/paths';
import queryKeys from 'constants/queryKeys';
import { translations } from 'new/form/common/types';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

export function useCreateCbBankIndicativeConditions() {
  const history = useHistory();
  const inquiryId = useSelector(getInquiryIdSelector);
  const toasts = useToasts();
  const t = useTranslations();
  const { success, error } =
    translations.pages.inquiryDetails.dashboard.actions.cbBankNonBindingOffer;
  const queryClient = useQueryClient();

  const mutation = useCreateCbBankIndicativeConditionsMutation({
    onSuccess: () => {
      toasts.success({ description: t(success) });
      queryClient.invalidateQueries([queryKeys.partners.cbBank.indicativeConditionsSuggestion]);
      history.push(paths.operation.inquiryDetails.dashboard.replace(':id', inquiryId));
    },
    onError: () => {
      toasts.error({ description: t(error) });
    },
  });

  const mutateAsync = useCallback(
    (apiData: IndicativeConditionsApiDataProps) => {
      return mutation.mutateAsync({
        inquiryId,
        apiData,
      });
    },
    [mutation, inquiryId],
  );

  return {
    ...mutation,
    mutateAsync,
  };
}
