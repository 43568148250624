import { LabelsMap } from 'new/form/common/types';

import { bankAccountSectionLabelsMap } from './sections/bankAccount/labels';
import { companyDetailsCompletionSectionLabelsMap } from './sections/companyDetailsCompletion/labels';
import { companySearchSectionLabelsMap } from './sections/companySearch/labels';

export const companyDataPageLabelsMap: LabelsMap = {
  ...companySearchSectionLabelsMap,
  ...companyDetailsCompletionSectionLabelsMap,
  ...bankAccountSectionLabelsMap,
};
