import {
  ICompactReport,
  ITrafficLightReport,
  IShortReport,
  IRatingReport,
  ReportType,
  Report,
  ICompanyBaseData,
  IBlackWhiteReport,
} from 'models/CompanyDetails.model';
import { IState } from 'store';

export const getCompanyDetails = (state: IState): ICompanyBaseData => state.companyDetails.baseData;
export const getCompanyAssociatedPersonsSelector = (state: IState) =>
  state.companyDetails.associatedPersons;
export const getCompanyRiskAnalysis = (state: IState) => state.companyDetails.riskAnalysis;
export const getTrafficLightReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.trafficLight,
  ) as ITrafficLightReport | undefined;

export const getShortReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.latestScoringShort,
  ) as IShortReport | undefined;

export const getCompactReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.latestScoringCompact,
  ) as ICompactReport | undefined;

export const getRatingReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.latestRating,
  ) as IRatingReport | undefined;

export const getBlackAndWhiteReport = (state: IState) =>
  state.companyDetails.riskAnalysis?.find(
    (item: Report) => item.type === ReportType.blackAndWhite,
  ) as IBlackWhiteReport | undefined;
