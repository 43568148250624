import { cbBankFields } from 'cbbank/inquiry/fields';
import { LabelsMap, translations } from 'new/form/common/types';

const { salutation, firstName, lastName, birthDate, email, phoneNumber, soleSignatureAuthorized } =
  cbBankFields.personalDataPage.legalRepresentativesSection.legalRepresentatives;
const { fields: fieldTranslations } =
  translations.inquiryType.cbBank.pages.personalData.sections.legalRepresentatives.fields
    .legalRepresentative;

export const legalRepresentativesSectionLabelsMap: LabelsMap = {
  [salutation]: fieldTranslations.salutation.caption,
  [firstName]: fieldTranslations.firstName.caption,
  [lastName]: fieldTranslations.lastName.caption,
  [birthDate]: fieldTranslations.birthDate.caption,
  [email]: fieldTranslations.email.caption,
  [phoneNumber]: fieldTranslations.phoneNumber.caption,
  [soleSignatureAuthorized]: fieldTranslations.soleSignatureAuthorized.inquiryCaption,
};
