import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType, NewFormInquiryType } from 'modules/Inquiry/Inquiry.type';

import { UseBeforeSubmitAction } from './model';

type BeforeSubmitConfig = {
  [inquiryType in NewFormInquiryType]: UseBeforeSubmitAction | (() => void);
};

const beforeSubmitConfig: BeforeSubmitConfig = {
  [InquiryType.mmv]: () => {},
  [InquiryType.profiMittweida]: () => {},
  [InquiryType.dzb]: () => {},
  [InquiryType.hausbank]: () => {},
  [InquiryType.cbBank]: () => {},
};

export const useBeforeSubmit = () => {
  const selectedInquiryType = useFormConfig().selectedInquiryType as NewFormInquiryType;

  const useBeforeSubmitAction = beforeSubmitConfig[selectedInquiryType];
  const beforeSubmitAction = useBeforeSubmitAction();
  return beforeSubmitAction;
};
