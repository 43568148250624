import { useIntl } from 'react-intl';

import { useFormConfig } from 'config/formConfig/hooks';
import { IntlFormatMessage } from 'models/Intl.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { ValueMap } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { userIsCustomer } from 'utils/user/conditionals';

export enum INQUIRY_STATUSES {
  ALL = 'all',
  PARTIAL = 'partial',
  DRAFT = 'draft',
  PENDING = 'pending',
  NEW = 'new',
  INITIATED_EXTERNALLY = 'initiated_externally',
  IN_PROGRESS = 'in_process',
  ASSIGNED = 'assigned',
  FORWARDED_TO_CUSTOMER = 'forwarded_to_customer',
  FORWARDED_TO_COMPEON = 'forwarded_to_compeon',
  FORWARDED_TO_COMPEON_SHORTHAND = 'forwarded_to_compeon_shorthand',
  FAILED = 'failed',
  IDENT_AND_SIGN = 'ident_and_sign',
  OBJECT_CONFIRMATION_OPEN = 'object_confirmation_open',
  OBJECT_CONFIRMATION_DONE = 'object_confirmation_done',
  PAYMENT_PLAN_RECEIVED = 'payment_plan_received',
  AUDIT_REPRESENTATIVES = 'audit_representatives',
  SIGNING_BY_REPRESENTATIVES_IN_PROGRESS = 'signing_by_representatives_in_progress',
  SIGNING_BY_BANK_IN_PROGRESS = 'signing_by_bank_in_progress',
  AWAITING_BANK_APPROVAL = 'awaiting_bank_approval',
  CHECK_BY_BANK = 'check_by_bank',
  AWAITING_CREFO_CHECK = 'awaiting_crefo_check',
  AWAITING_INDICATIVE_CONDITIONS = 'awaiting_indicative_conditions',
  AWAITING_CONTRACT_PROVISION = 'awaiting_contract_provision',
  CLOSED = 'closed',
  FULFILLED = 'fulfilled',
  SIGNED = 'signed',
  ARCHIVED = 'archived',
}

export enum MMV_INQUIRY_STATUSES {
  INITIATED_EXTERNALLY = INQUIRY_STATUSES.INITIATED_EXTERNALLY,
  IDENT_AND_SIGN = INQUIRY_STATUSES.IDENT_AND_SIGN,
  FULFILLED = INQUIRY_STATUSES.FULFILLED,
  OBJECT_CONFIRMATION_OPEN = INQUIRY_STATUSES.OBJECT_CONFIRMATION_OPEN,
  OBJECT_CONFIRMATION_DONE = INQUIRY_STATUSES.OBJECT_CONFIRMATION_DONE,
  PAYMENT_PLAN_RECEIVED = INQUIRY_STATUSES.PAYMENT_PLAN_RECEIVED,
  ARCHIVED = INQUIRY_STATUSES.ARCHIVED,
}

export enum DZB_INQUIRY_STATUSES {
  INITIATED_EXTERNALLY = INQUIRY_STATUSES.INITIATED_EXTERNALLY,
  AUDIT_REPRESENTATIVES = INQUIRY_STATUSES.AUDIT_REPRESENTATIVES,
  SIGNING_BY_REPRESENTATIVES_IN_PROGRESS = INQUIRY_STATUSES.SIGNING_BY_REPRESENTATIVES_IN_PROGRESS,
  AWAITING_BANK_APPROVAL = INQUIRY_STATUSES.AWAITING_BANK_APPROVAL,
  SIGNING_BY_BANK_IN_PROGRESS = INQUIRY_STATUSES.SIGNING_BY_BANK_IN_PROGRESS,
  SIGNED = INQUIRY_STATUSES.SIGNED,
  FULFILLED = INQUIRY_STATUSES.FULFILLED,
  ARCHIVED = INQUIRY_STATUSES.ARCHIVED,
}

export enum CB_BANK_INQUIRY_STATUSES {
  PARTIAL = INQUIRY_STATUSES.PARTIAL,
  AWAITING_CREFO_CHECK = INQUIRY_STATUSES.AWAITING_CREFO_CHECK,
  CHECK_BY_BANK = INQUIRY_STATUSES.CHECK_BY_BANK,
  AWAITING_INDICATIVE_CONDITIONS = INQUIRY_STATUSES.AWAITING_INDICATIVE_CONDITIONS,
  AUDIT_REPRESENTATIVES = INQUIRY_STATUSES.AUDIT_REPRESENTATIVES,
  AWAITING_CONTRACT_PROVISION = INQUIRY_STATUSES.AWAITING_CONTRACT_PROVISION,
  SIGNING_BY_REPRESENTATIVES_IN_PROGRESS = INQUIRY_STATUSES.SIGNING_BY_REPRESENTATIVES_IN_PROGRESS,
  AWAITING_BANK_APPROVAL = INQUIRY_STATUSES.AWAITING_BANK_APPROVAL,
  SIGNING_BY_BANK_IN_PROGRESS = INQUIRY_STATUSES.SIGNING_BY_BANK_IN_PROGRESS,
  SIGNED = INQUIRY_STATUSES.SIGNED,
  FULFILLED = INQUIRY_STATUSES.FULFILLED,
  ARCHIVED = INQUIRY_STATUSES.ARCHIVED,
}

export const inquiryTypeSpecificStatusesMap: ValueMap<
  | typeof INQUIRY_STATUSES
  | typeof MMV_INQUIRY_STATUSES
  | typeof DZB_INQUIRY_STATUSES
  | typeof CB_BANK_INQUIRY_STATUSES
> = {
  [InquiryType.default]: INQUIRY_STATUSES,
  [InquiryType.mmv]: MMV_INQUIRY_STATUSES,
  [InquiryType.dzb]: DZB_INQUIRY_STATUSES,
  [InquiryType.cbBank]: CB_BANK_INQUIRY_STATUSES,
};

export enum INQUIRY_SUBSTATUSES {
  FORWARDED_TO_CUSTOMER = 'waiting_for_accept_to_forward_to_compeon',
  AUTOMATIC_PROCESS_IDENT_AND_SIGN = 'automatic_process_ident_and_sign',
  AUTOMATIC_PROCESS_START = 'automatic_process_start',
  AUTOMATIC_PROCESS_FINAL_INFO_REQUEST = 'automatic_process_final_info_request',
  AUTOMATIC_PROCESS_FINAL_INFO_PROVIDED = 'automatic_process_final_info_provided',
  UNOPENED = 'unopened',
  OPENED = 'opened',
  WELCOME_CALL_PERFORMED = 'welcome_call_performed',
  ASSIGNED = 'assigned',
  WITH_BANK_OFFERS = 'with_bank_offers',
  WITH_COMPEON_OFFERS = 'with_compeon_offers',
  WITH_OFFER_INTERESTED_IN = 'with_offer_interested_in',
  OFFER_ACCEPTED = 'offer_accepted',
  OFFER_EXPIRED = 'offer_expired',
  INITIATED_EXTERNALLY = 'initiated_externally',
  IN_PROCESS = 'in_process',
  FAILED = 'failed',
  OBJECT_CONFIRMATION_DONE = 'object_confirmation_done',
  OBJECT_CONFIRMATION_PENDING = 'object_confirmation_pending',
  OBJECT_CONFIRMATION_DATA_RECEIVED = 'object_confirmation_data_received',
  PAYMENT_PLAN_RECEIVED = 'payment_plan_received',
  AUDIT_REPRESENTATIVES = 'audit_representatives',
}

export type EveryStatus = Status | Substatus;

export type Status =
  | INQUIRY_STATUSES
  | MMV_INQUIRY_STATUSES
  | DZB_INQUIRY_STATUSES
  | CB_BANK_INQUIRY_STATUSES;

export type Substatus = INQUIRY_SUBSTATUSES;

const COMMON_MAP = {
  [INQUIRY_STATUSES.ALL]: 'all',
};

// API -> app
const TRANSLATION_MAP = {
  [INQUIRY_STATUSES.PENDING]: 'pending',
  [INQUIRY_STATUSES.NEW]: 'new',
  [INQUIRY_STATUSES.ASSIGNED]: 'assigned',
  [INQUIRY_STATUSES.IN_PROGRESS]: 'inProgress',
  [INQUIRY_STATUSES.FORWARDED_TO_CUSTOMER]: 'forwardedToCustomer',
  [INQUIRY_STATUSES.FORWARDED_TO_COMPEON]: 'forwardedToCompeon',
  [INQUIRY_STATUSES.FORWARDED_TO_COMPEON_SHORTHAND]: 'forwardedToCompeonShorthand',
  [INQUIRY_STATUSES.CLOSED]: 'closed',
  [INQUIRY_STATUSES.FULFILLED]: 'fulfilled',
  [INQUIRY_STATUSES.ARCHIVED]: 'archived',
  [INQUIRY_STATUSES.DRAFT]: 'draft',
  [INQUIRY_STATUSES.INITIATED_EXTERNALLY]: 'initiatedExternally',
  [INQUIRY_SUBSTATUSES.WITH_BANK_OFFERS]: 'withBankOffers',
  [INQUIRY_SUBSTATUSES.WITH_COMPEON_OFFERS]: 'withCompeonOffers',
  [INQUIRY_SUBSTATUSES.WITH_OFFER_INTERESTED_IN]: 'withOfferInterestedIn',
  [INQUIRY_SUBSTATUSES.UNOPENED]: 'unopened',
  [INQUIRY_SUBSTATUSES.OPENED]: 'opened',
  [INQUIRY_SUBSTATUSES.WELCOME_CALL_PERFORMED]: 'welcomeCallPerformed',
  [INQUIRY_SUBSTATUSES.OFFER_ACCEPTED]: 'offerAccepted',
  [INQUIRY_SUBSTATUSES.OFFER_EXPIRED]: 'offerExpired',
  [INQUIRY_SUBSTATUSES.FORWARDED_TO_CUSTOMER]: 'forwardedToCustomer',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_IDENT_AND_SIGN]: 'automaticProcessIdentAndSign',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_FINAL_INFO_REQUEST]: 'automaticProcessFinalInfoRequest',
  [INQUIRY_SUBSTATUSES.AUTOMATIC_PROCESS_FINAL_INFO_PROVIDED]: 'automaticProcessFinalInfoProvided',
  [INQUIRY_SUBSTATUSES.FAILED]: 'failed',
  default: 'partial',
};

const MMV_TRANSLATION_MAP = {
  [MMV_INQUIRY_STATUSES.INITIATED_EXTERNALLY]: 'mmv.initiatedExternally',
  [MMV_INQUIRY_STATUSES.IDENT_AND_SIGN]: 'mmv.identAndSign',
  [MMV_INQUIRY_STATUSES.FULFILLED]: 'mmv.fulfilled',
  [MMV_INQUIRY_STATUSES.OBJECT_CONFIRMATION_OPEN]: 'mmv.objectConfirmationOpen',
  [MMV_INQUIRY_STATUSES.OBJECT_CONFIRMATION_DONE]: 'mmv.objectConfirmationDone',
  [MMV_INQUIRY_STATUSES.PAYMENT_PLAN_RECEIVED]: 'mmv.paymentPlanReceived',
  [MMV_INQUIRY_STATUSES.ARCHIVED]: 'mmv.archived',
};

const DZB_TRANSLATION_MAP = {
  [DZB_INQUIRY_STATUSES.INITIATED_EXTERNALLY]: 'dzb.initiatedExternally',
  [DZB_INQUIRY_STATUSES.AUDIT_REPRESENTATIVES]: 'dzb.auditRepresentatives',
  [DZB_INQUIRY_STATUSES.SIGNING_BY_REPRESENTATIVES_IN_PROGRESS]:
    'dzb.signingByRepresentativesInProgress',
  [DZB_INQUIRY_STATUSES.AWAITING_BANK_APPROVAL]: 'dzb.awaitingBankApproval',
  [DZB_INQUIRY_STATUSES.SIGNING_BY_BANK_IN_PROGRESS]: 'dzb.signingByBankInProgress',
  [DZB_INQUIRY_STATUSES.SIGNED]: 'dzb.signed',
  [DZB_INQUIRY_STATUSES.FULFILLED]: 'dzb.fulfilled',
  [DZB_INQUIRY_STATUSES.ARCHIVED]: 'dzb.archived',
};

const CB_BANK_CUSTOMER_TRANSLATION_MAP = {
  [CB_BANK_INQUIRY_STATUSES.PARTIAL]: 'cbBank.customer.partial',
  [CB_BANK_INQUIRY_STATUSES.CHECK_BY_BANK]: 'cbBank.customer.checkByBank',
  [CB_BANK_INQUIRY_STATUSES.SIGNING_BY_REPRESENTATIVES_IN_PROGRESS]:
    'cbBank.customer.signingByRepresentativesInProgress',
  [CB_BANK_INQUIRY_STATUSES.AWAITING_BANK_APPROVAL]: 'cbBank.customer.awaitingBankApproval',
  [CB_BANK_INQUIRY_STATUSES.SIGNING_BY_BANK_IN_PROGRESS]: 'cbBank.customer.signingByBankInProgress',
  [CB_BANK_INQUIRY_STATUSES.SIGNED]: 'cbBank.customer.signed',
  [CB_BANK_INQUIRY_STATUSES.FULFILLED]: 'cbBank.customer.fulfilled',
  [CB_BANK_INQUIRY_STATUSES.ARCHIVED]: 'cbBank.customer.archived',
};

const CB_BANK_OPERATION_TRANSLATION_MAP = {
  [CB_BANK_INQUIRY_STATUSES.PARTIAL]: 'cbBank.operation.partial',
  [CB_BANK_INQUIRY_STATUSES.AWAITING_CREFO_CHECK]: 'cbBank.operation.awaitingCrefoCheck',
  [CB_BANK_INQUIRY_STATUSES.AWAITING_INDICATIVE_CONDITIONS]:
    'cbBank.operation.awaitingIndicativeConditions',
  [CB_BANK_INQUIRY_STATUSES.AUDIT_REPRESENTATIVES]: 'cbBank.operation.auditRepresentatives',
  [CB_BANK_INQUIRY_STATUSES.AWAITING_CONTRACT_PROVISION]:
    'cbBank.operation.awaitingContractProvision',
  [CB_BANK_INQUIRY_STATUSES.SIGNING_BY_REPRESENTATIVES_IN_PROGRESS]:
    'cbBank.operation.signingByRepresentativesInProgress',
  [CB_BANK_INQUIRY_STATUSES.AWAITING_BANK_APPROVAL]: 'cbBank.operation.awaitingBankApproval',
  [CB_BANK_INQUIRY_STATUSES.SIGNING_BY_BANK_IN_PROGRESS]:
    'cbBank.operation.signingByBankInProgress',
  [CB_BANK_INQUIRY_STATUSES.SIGNED]: 'cbBank.operation.signed',
  [CB_BANK_INQUIRY_STATUSES.FULFILLED]: 'cbBank.operation.fulfilled',
  [CB_BANK_INQUIRY_STATUSES.ARCHIVED]: 'cbBank.operation.archived',
};

const customerTranslationMaps: Partial<Record<InquiryType, { [key: string]: string }>> = {
  [InquiryType.mmv]: MMV_TRANSLATION_MAP,
  [InquiryType.dzb]: DZB_TRANSLATION_MAP,
  [InquiryType.cbBank]: CB_BANK_CUSTOMER_TRANSLATION_MAP,
  [InquiryType.bfs]: TRANSLATION_MAP,
  [InquiryType.bfsService]: TRANSLATION_MAP,
  [InquiryType.default]: TRANSLATION_MAP,
};

const operationTranslationMaps: Partial<Record<InquiryType, { [key: string]: string }>> = {
  ...customerTranslationMaps,
  [InquiryType.cbBank]: CB_BANK_OPERATION_TRANSLATION_MAP,
};

export const allStatuses = Object.values({ ...INQUIRY_STATUSES, ...INQUIRY_SUBSTATUSES });

export const translateInquiryStatus = (
  intlFormatMessage: IntlFormatMessage,
  status: EveryStatus | undefined,
  inquiryType: InquiryType = InquiryType.default,
) => {
  const isCustomer = userIsCustomer();
  const inquiryTypeMap = isCustomer
    ? customerTranslationMaps[inquiryType]
    : operationTranslationMaps[inquiryType];
  const translationMap = {
    ...COMMON_MAP,
    ...inquiryTypeMap,
  };

  if (!status) {
    return intlFormatMessage({
      id: `inquiry.statuses.${TRANSLATION_MAP.default}`,
    });
  }

  return intlFormatMessage({
    id: `inquiry.statuses.${(translationMap as any)[status] || TRANSLATION_MAP.default}`,
  });
};

export const useInquiryStatusTranslator = () => {
  const { selectedInquiryType } = useFormConfig();
  const { formatMessage } = useIntl();
  return (status: EveryStatus | undefined) =>
    translateInquiryStatus(formatMessage, status, selectedInquiryType);
};

export const isInquirySubstatus = (
  inquirySubstatus: string,
): inquirySubstatus is INQUIRY_SUBSTATUSES => inquirySubstatus !== undefined;
