import { CbBankFieldTypes } from 'cbbank/inquiry/fields';

type CbBankUserDataTypes = CbBankFieldTypes['personalDataPage']['userDataSection'];

export const mapCbBankUserData = (values: CbBankUserDataTypes) => ({
  email: values.email,
  salutation: values.salutation,
  first_name: values.firstName,
  last_name: values.lastName,
  phone_number: values.phoneNumber,
});
