import React, { useEffect } from 'react';

import { useField, useForm } from 'react-final-form';

import { cbBankFields, PaymentTermOptions } from 'cbbank/inquiry/fields';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { SelectWithField } from 'components/Selects/Select';
import { NewStaticFieldWithField } from 'components/StaticField';
import { AmountWithField } from 'modules/Inquiry/Form/Steps/FinancingNeed/Amount/Amount';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { translations } from 'new/form/common/types';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import { useCalculateFactoringLine } from './hooks/useCalculateFactoringLine';

const { annualFactoringRelevantTurnover, paymentTerm, factoringLine } =
  cbBankFields.financingNeedPage.factoringNeedSection;

export const FactoringNeedSection = () => {
  const t = useTranslations();
  const { change } = useForm();
  const paymentTermOptions = useInquirySelectOptions(paymentTerm);
  const {
    title,
    fields: {
      annualFactoringRelevantTurnover: { tooltip: annualFactoringRelevantTurnoverTooltip },
      paymentTerm: { tooltip: paymentTermTooltip },
    },
  } = translations.inquiryType.cbBank.pages.financingNeed.sections.factoringNeed;

  const {
    input: { value: annualFactoringRelevantTurnoverValue },
  } = useField(annualFactoringRelevantTurnover);

  const {
    input: { value: paymentTermValue },
  } = useField(paymentTerm);

  const debouncedAnnualFactoringRelevantTurnoverValue = useDebounce(
    annualFactoringRelevantTurnoverValue,
  );

  const { data: factoringLineValue } = useCalculateFactoringLine({
    variables: {
      annualFactoringRelevantTurnoverValue: debouncedAnnualFactoringRelevantTurnoverValue,
      paymentTermValue,
    },
    enabled: !!debouncedAnnualFactoringRelevantTurnoverValue && !!paymentTermValue,
  });

  useEffect(() => {
    const factoringLineDisplayValue =
      paymentTermValue === PaymentTermOptions.UP_TO_90_DAYS ||
      paymentTermValue === PaymentTermOptions.MORE_THAN_90_DAYS
        ? undefined
        : factoringLineValue;
    change(
      factoringLine,
      debouncedAnnualFactoringRelevantTurnoverValue ? factoringLineDisplayValue : '',
    );
  }, [change, debouncedAnnualFactoringRelevantTurnoverValue, factoringLineValue, paymentTermValue]);

  return (
    <FormSection title={t(title)}>
      <FormRow>
        <AmountWithField
          name={annualFactoringRelevantTurnover}
          tooltip={t(annualFactoringRelevantTurnoverTooltip)}
        />
      </FormRow>
      <FormRow>
        <SelectWithField
          name={paymentTerm}
          options={paymentTermOptions}
          placeholder={t(translations.placeholders.pleaseChoose)}
          tooltip={t(paymentTermTooltip)}
        />
      </FormRow>
      <FormRow>
        <NewStaticFieldWithField
          name={factoringLine}
          parseValue={(value: number) => formatPrice(value, true, 0)}
        />
      </FormRow>
    </FormSection>
  );
};
