import React, { useEffect } from 'react';

import { Center } from '@chakra-ui/react';

import { Asset } from 'components/Asset';
import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import {
  useInquiryProcessConfig,
  useSetCurrentStepValid,
} from 'new/form/state/inquiryProcessConfig/hooks';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const LeadLaneSection = () => {
  const t = useTranslations();
  const { header, text1, text2 } = translations.inquiryType.cbBank.pages.offer.lanes.leadLane;
  const setCurrentStepValid = useSetCurrentStepValid();
  const {
    state: { currentStep, pageValidations },
  } = useInquiryProcessConfig();
  const isStepValid = pageValidations[currentStep as any].progress.isSectionValid;

  useEffect(() => {
    if (!isStepValid) {
      setCurrentStepValid();
    }
  }, [isStepValid, setCurrentStepValid]);

  return (
    <>
      <PageHeading heading={t(header)} />
      <Center mt={16}>
        <Asset type="inquiry" value={InquiryAssetKeys.OFFER_PROVIDED} />
      </Center>
      <TextComponent mt={16} textAlign="center" color={'brand.default'} whiteSpace={'pre-line'}>
        {t(text1)}
      </TextComponent>
      <TextComponent mt={16} textAlign="center" color={'text.tertiary'} whiteSpace={'pre-line'}>
        {t(text2)}
      </TextComponent>
    </>
  );
};
