import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { useCbBankConditions } from 'cbbank/inquiry/config/conditions';
import { cbBankFields, cbBankFieldsWithSectionString } from 'cbbank/inquiry/fields';
import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { translations } from 'new/form/common/types';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import { CbBankBeneficialOwnersCards } from '../../../../../../new/beneficialOwners/CbBankBeneficialOwnersCards';

export const BeneficialOwnersSection = () => {
  const t = useTranslations();
  const { mutators } = useForm();
  const { showBeneficialOwnersAddButton, showBeneficialOwnersSection } = useCbBankConditions();

  const {
    title,
    fields: {
      allInformationAvailable: { tooltip },
    },
  } = translations.inquiryType.cbBank.pages.personalData.sections.beneficialOwners;
  const { allInformationAvailable } = cbBankFields.personalDataPage.beneficialOwnersSection;
  const arrayFieldName =
    cbBankFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
      .sectionString;

  return (
    <FormSection title={t(title)} hideSectionNumber>
      <FormRow>
        <YesNoRadioGroupWithField name={allInformationAvailable} tooltip={t(tooltip)} />
      </FormRow>
      <VStack gap={8}>
        <Condition condition={showBeneficialOwnersSection}>
          <FieldArray name={arrayFieldName}>
            {({ fields }) => {
              return <CbBankBeneficialOwnersCards fields={fields} />;
            }}
          </FieldArray>
        </Condition>

        <Condition condition={showBeneficialOwnersAddButton}>
          <ButtonComponent
            leftIcon={<AddIcon boxSize={6} display={'block'} />}
            onClick={() => mutators.push(arrayFieldName)}
            variant={'primary'}
            alignSelf={'flex-end'}
          >
            {t(
              translations.inquiryType.cbBank.pages.personalData.sections.beneficialOwners.fields
                .add,
            )}
          </ButtonComponent>
        </Condition>
      </VStack>
    </FormSection>
  );
};
