import React from 'react';

import { Flex } from '@chakra-ui/react';

import { Seperator } from 'components/Seperator';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

import CbBankAddBeneficialOwnerModal from './CbBankAddBeneficialOwnerModal';
import { CbBankBeneficialOwnerAccordion } from './CbBankBeneficialOwnerAccordion';
import CbBankDeleteBeneficialOwnerModal from './CbBankDeleteBeneficialOwnerModal';
import CbBankEditBeneficialOwnerModal from './CbBankEditBeneficialOwnerModal';
import { useEditAssociatedPeople } from '../../../hooks/useEditAssociatedPeople';
import { CbBankPortalBeneficialOwner } from '../fields';

type Props = {
  beneficialOwners: Array<CbBankPortalBeneficialOwner>;
};

export const CbBankBeneficialOwners = ({ beneficialOwners }: Props) => {
  const t = useTranslations();
  const { heading, noBeneficialOwners } =
    translations.pages.companiesDetails.associatedPerson.new.beneficialOwners;

  const {
    isAddModalOpen,
    onAddModalOpen,
    onAddModalClose,
    isEditModalOpen,
    onEditModalOpen,
    onEditModalClose,
    isDeleteModalOpen,
    onDeleteModalOpen,
    onDeleteModalClose,
    selectedPerson: selectedBeneficialOwner,
    setSelectedPerson: setSelectedBeneficialOwner,
  } = useEditAssociatedPeople<CbBankPortalBeneficialOwner>();

  return (
    <>
      <Seperator
        title={`${t(heading)} (${beneficialOwners.length})`}
        action={<EditModeActions onOpen={onAddModalOpen} />}
      >
        {beneficialOwners.length > 0 ? (
          beneficialOwners.map((beneficialOwner) => {
            return (
              <CbBankBeneficialOwnerAccordion
                key={beneficialOwner.id}
                beneficialOwner={beneficialOwner}
                setSelectedBeneficialOwner={setSelectedBeneficialOwner}
                onEditModalOpen={onEditModalOpen}
                onDeleteModalOpen={onDeleteModalOpen}
                index={beneficialOwners.indexOf(beneficialOwner)}
              />
            );
          })
        ) : (
          <Flex justifyContent={'center'} w={'full'} p={12} color="text.tertiary">
            {t(noBeneficialOwners)}
          </Flex>
        )}
      </Seperator>
      <CbBankAddBeneficialOwnerModal isOpen={isAddModalOpen} onClose={onAddModalClose} />
      <CbBankEditBeneficialOwnerModal
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        initialValues={selectedBeneficialOwner}
      />
      <CbBankDeleteBeneficialOwnerModal
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        beneficialOwner={selectedBeneficialOwner}
      />
    </>
  );
};

type ActionsProps = {
  onOpen: () => void;
};

const Actions = ({ onOpen }: ActionsProps) => {
  const t = useTranslations();

  return (
    <ButtonComponent
      variant={'link'}
      onClick={(event) => {
        onOpen();
        event.preventDefault();
      }}
      leftIcon={<AddIcon boxSize={6} display={'block'} />}
      testId="addBeneficialOwnerButton"
    >
      {t(
        translations.pages.companiesDetails.associatedPerson.new.beneficialOwners.actions.add
          .caption,
      )}
    </ButtonComponent>
  );
};

const EditModeActions = withAssociatedPeopleEditMode(Actions);
