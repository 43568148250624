import { concatenateKeys } from 'new/form/common/concatenateKeys';
import { PropertiesToString, SecondLevelKeys, getKeyObject } from 'new/form/common/types';
import {
  LegalRepresentative,
  legalRepresentativeFields,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { IndustryValuesBase, LegalFormValuesBase } from 'types/Company';
import { CountryIsoCode } from 'types/Country';
import { EnsureDefined, StringBoolean } from 'types/General';

export enum PaymentTermOptions {
  UP_TO_30_DAYS = 'up_to_30_days',
  UP_TO_45_DAYS = 'up_to_45_days',
  UP_TO_60_DAYS = 'up_to_60_days',
  UP_TO_90_DAYS = 'up_to_90_days',
  MORE_THAN_90_DAYS = 'more_than_90_days',
}

export type CbBankBeneficialOwner = {
  id: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  nationality: CountryIsoCode | undefined;
  capitalOrVotingRightsPercentage: number | undefined;
  placeOfResidence: string | undefined;
  countryOfResidence: CountryIsoCode | undefined;
  birthPlace: string | undefined;
  birthDate: Date | undefined;
};

export type CbBankLegalFormValues = Pick<
  LegalFormValuesBase,
  | 'einzelunternehmung'
  | 'eingetragenerKaufmann'
  | 'gesellschaftBurgerlichenRechts'
  | 'offeneHandelsgesellschaft'
  | 'kommanditgesellschaft'
  | 'gmbh'
  | 'unternehmergesellschaftHaftungsbeschrankte'
  | 'aktiengesellschaft'
  | 'eingetrageneGenossenschaft'
  | 'haftungsbeschrankte'
> extends infer T
  ? T[keyof T]
  : never;

export type CbBankIndustryValues = Pick<
  IndustryValuesBase,
  | 'service'
  | 'financeAndInsurance'
  | 'trade'
  | 'craft'
  | 'manufacturing'
  | 'processing'
  | 'transport'
  | 'food'
  | 'other'
> extends infer T
  ? T[keyof T]
  : never;

export type CbBankFieldTypes = {
  financingNeedPage: {
    factoringNeedSection: {
      annualFactoringRelevantTurnover: number | undefined;
      paymentTerm: PaymentTermOptions | undefined;
      factoringLine: number | undefined;
    };
    businessDetailsSection: {
      turnoverShareOfLargestClient: number | undefined;
      centralisedSettlement: StringBoolean | undefined;
      tradeCreditInsurance: StringBoolean | undefined;
      downPaymentOrInstallments: StringBoolean | undefined;
      assignedElsewhere: StringBoolean | undefined;
      customersAbroad: StringBoolean | undefined;
    };
  };
  companyDataPage: {
    companySearchSection: {
      crefoId: string | undefined;
      selectedCompanyName: string | undefined;
    };
    companyDetailsCompletionSection: {
      name: string | undefined;
      street: string | undefined;
      zipCode: string | undefined;
      city: string | undefined;
      legalForm: CbBankLegalFormValues | undefined;
      industry: CbBankIndustryValues | undefined;
      olderThan2Years: StringBoolean | undefined;
      subjectToAccounting: StringBoolean | undefined;
      positiveEquity: StringBoolean | undefined;
      positiveAnnualResult: StringBoolean | undefined;
    };
    bankAccountSection: {
      iban: string | undefined;
      bic: string | undefined;
      bankName: string | undefined;
    };
    complianceSection: {
      termsOfService: StringBoolean | undefined;
      dataProtection: StringBoolean | undefined;
      factoringTerms: StringBoolean | undefined;
    };
  };
  offerPage: {
    contractLaneSection: {
      phoneNumber: string | undefined;
      factoringFee: string | undefined;
      factoringLine: string | undefined;
      payoutRatio: string | undefined;
      pinNumber: string | undefined;
    };
  };
  personalDataPage: {
    userDataSection: {
      salutation: string | undefined;
      firstName: string | undefined;
      lastName: string | undefined;
      email: string | undefined;
      phoneNumber: string | undefined;
    };
    legalRepresentativesSection: {
      legalRepresentatives: Array<LegalRepresentative>;
    };
    beneficialOwnersSection: {
      allInformationAvailable: StringBoolean | undefined;
      beneficialOwners: Array<CbBankBeneficialOwner>;
    };
  };
};

type CbBankStringFieldTypes = PropertiesToString<EnsureDefined<CbBankFieldTypes>>;

const cbBankBeneficialOwnerFields: PropertiesToString<CbBankBeneficialOwner> = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  nationality: 'nationality',
  capitalOrVotingRightsPercentage: 'capitalOrVotingRightsPercentage',
  placeOfResidence: 'placeOfResidence',
  countryOfResidence: 'countryOfResidence',
  birthPlace: 'birthPlace',
  birthDate: 'birthDate',
};

export const cbBankFieldsWithoutParents: CbBankStringFieldTypes = {
  financingNeedPage: {
    factoringNeedSection: {
      annualFactoringRelevantTurnover: 'annualFactoringRelevantTurnover',
      paymentTerm: 'paymentTerm',
      factoringLine: 'factoringLine',
    },
    businessDetailsSection: {
      turnoverShareOfLargestClient: 'turnoverShareOfLargestClient',
      centralisedSettlement: 'centralisedSettlement',
      tradeCreditInsurance: 'tradeCreditInsurance',
      downPaymentOrInstallments: 'downPaymentOrInstallments',
      assignedElsewhere: 'assignedElsewhere',
      customersAbroad: 'customersAbroad',
    },
  },
  companyDataPage: {
    companySearchSection: {
      crefoId: 'crefoId',
      selectedCompanyName: 'selectedCompanyName',
    },
    companyDetailsCompletionSection: {
      name: 'name',
      street: 'street',
      zipCode: 'zipCode',
      city: 'city',
      legalForm: 'legalForm',
      industry: 'industry',
      olderThan2Years: 'olderThan2Years',
      subjectToAccounting: 'subjectToAccounting',
      positiveEquity: 'positiveEquity',
      positiveAnnualResult: 'positiveAnnualResult',
    },
    bankAccountSection: {
      iban: 'iban',
      bic: 'bic',
      bankName: 'bankName',
    },
    complianceSection: {
      termsOfService: 'termsOfService',
      dataProtection: 'dataProtection',
      factoringTerms: 'factoringTerms',
    },
  },
  offerPage: {
    contractLaneSection: {
      phoneNumber: 'phoneNumber',
      factoringFee: 'factoringFee',
      factoringLine: 'factoringLine',
      payoutRatio: 'payoutRatio',
      pinNumber: 'pinNumber',
    },
  },
  personalDataPage: {
    userDataSection: {
      salutation: 'salutation',
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      phoneNumber: 'phoneNumber',
    },
    legalRepresentativesSection: {
      legalRepresentatives: legalRepresentativeFields,
    },
    beneficialOwnersSection: {
      allInformationAvailable: 'allInformationAvailable',
      beneficialOwners: cbBankBeneficialOwnerFields,
    },
  },
};

export const cbBankFields: CbBankStringFieldTypes = concatenateKeys(cbBankFieldsWithoutParents);
export const cbBankFieldsWithSectionString = getKeyObject<CbBankFieldTypes>(
  cbBankFieldsWithoutParents,
);

export type CbBankPages = keyof CbBankFieldTypes;
export type CbBankSections = SecondLevelKeys<typeof cbBankFieldsWithoutParents>;
