import { useCallback } from 'react';

import { createMutation } from 'react-query-kit';
import { useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const useDzbApprovalAssociatedPeopleMutation = createMutation({
  mutationFn: (variables: { inquiryId: string }) =>
    EcoBankingAxiosClientAuthedInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.DZB.ASSOCIATED_PEOPLE.APPROVAL.compose({
        params: {
          id: variables.inquiryId,
        },
      }),
    ),
});

const useCbBankApprovalAssociatedPeopleMutation = createMutation({
  mutationFn: (variables: { inquiryId: string }) =>
    EcoBankingAxiosClientAuthedInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.ASSOCIATED_PEOPLE.APPROVAL.compose({
        params: {
          id: variables.inquiryId,
        },
      }),
    ),
});

export function useApprovalAssociatedPeople() {
  const inquiryId = useSelector(getInquiryIdSelector);
  const { refetchInquiry } = useFetchInquiryData();
  const toasts = useToasts();
  const t = useTranslations();
  const { successMessage, errorMessage } =
    translations.pages.companiesDetails.associatedPerson.actions.confirm;

  const mutationHook = useSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: useDzbApprovalAssociatedPeopleMutation,
    [InquiryType.cbBank]: useCbBankApprovalAssociatedPeopleMutation,
  });

  const mutation = mutationHook({
    onSuccess: async () => {
      await refetchInquiry();
      toasts.success({ description: t(successMessage) });
    },
    onError: async () => {
      await refetchInquiry();
      toasts.error({ description: t(errorMessage) });
    },
  });

  const mutateAsync = useCallback(() => {
    return mutation.mutateAsync({
      inquiryId,
    });
  }, [mutation, inquiryId]);

  return {
    ...mutation,
    mutateAsync,
  };
}
