import { useState } from 'react';

import { useDisclosure } from '@chakra-ui/hooks';

export const useEditAssociatedPeople = <TPerson>() => {
  const [selectedPerson, setSelectedPerson] = useState<TPerson | undefined>(undefined);

  const {
    isOpen: isAddModalOpen,
    onClose: onAddModalClose,
    onOpen: onAddModalOpen,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onClose: onEditModalClose,
    onOpen: onEditModalOpen,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
  } = useDisclosure();

  return {
    // add modal
    isAddModalOpen,
    onAddModalClose,
    onAddModalOpen,
    // edit modal
    isEditModalOpen,
    onEditModalClose,
    onEditModalOpen,
    // delete modal
    isDeleteModalOpen,
    onDeleteModalClose,
    onDeleteModalOpen,
    // selected associated person
    selectedPerson,
    setSelectedPerson,
  };
};
