import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import PERMISSIONS from 'constants/user/permissions';
import { USER_ROLE_INQUIRY_MANAGER, USER_ROLE_BANK_ADVISOR } from 'constants/user/userRoles';
import { CompeonStatus } from 'models/types/CompeonStatus.type';
import {
  PROPERTY_LOAN_KIND__CLASSIC,
  PRODUCT_KIND__PROPERTY_LOAN,
} from 'modules/Inquiry/Form/formFields';
import { INQUIRY_STATUSES, INQUIRY_SUBSTATUSES } from 'modules/Inquiry/inquiryStatuses';
import { translations } from 'new/form/common/types';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { doArraysHaveCommonPart } from 'utils/helpers';
import { hasPermission } from 'utils/user/conditionals';
import { getUserRoles } from 'utils/user/getters';

type NotForwardableToCompeonReason = Exclude<
  keyof typeof translations.pages.inquiryDetails.dashboard.actions.forwardToCompeon.error,
  'title' | 'sectionString'
>;

export const isCompeonForwardable = (inquiry: any, userRoles: any) => {
  const propertyLoan =
    _get(inquiry, 'inquiryDetailFields.product-kind') === PRODUCT_KIND__PROPERTY_LOAN;
  const loanType =
    _get(inquiry, 'inquiryDetailFields.property-loan-kind') === PROPERTY_LOAN_KIND__CLASSIC;
  const favoredDecision = _get(inquiry, 'inquiryDetailFields.favored-decision-date');
  const loanTermsInMonths = _get(inquiry, 'inquiryDetailFields.property-loan-term-in-months');
  const notForwardedToCustomer = inquiry.substatus !== INQUIRY_SUBSTATUSES.FORWARDED_TO_CUSTOMER;
  const notForwardedCompeon = !inquiry.wasForwardToCompeon;

  const validStatuses = [
    INQUIRY_STATUSES.NEW,
    INQUIRY_STATUSES.ASSIGNED,
    INQUIRY_STATUSES.IN_PROGRESS,
  ];

  const conditions: Record<NotForwardableToCompeonReason, boolean> = {
    wrongStatus:
      !(notForwardedCompeon && notForwardedToCustomer) || validStatuses.includes(inquiry.status),
    wasForwardToCompeon: notForwardedCompeon,
    wasForwardToCustomer: notForwardedToCustomer,
    alreadyAssigned:
      userRoles.includes(USER_ROLE_BANK_ADVISOR) ||
      !userRoles.includes(USER_ROLE_INQUIRY_MANAGER) ||
      !inquiry.bankAdvisor,
    loanKindWrong: propertyLoan ? loanType : true,
    favoredDecisionDateIsMissing: propertyLoan ? favoredDecision : true,
    loanTermInMonthIsMissing: propertyLoan ? loanTermsInMonths : true,
    noPermission: doArraysHaveCommonPart(PERMISSIONS.INQUIRY.FORWARD_TO_CUSTOMER, userRoles),
  };

  const reasons = Object.keys(conditions).filter(
    (key) => !conditions[key as NotForwardableToCompeonReason],
  ) as Array<NotForwardableToCompeonReason>;
  const canForwardToCompeon = reasons.length === 0;

  return {
    canForwardToCompeon,
    reasons,
  };
};

const canArchive = (inquiry: any) =>
  inquiry.status !== INQUIRY_STATUSES.ARCHIVED && hasPermission()(PERMISSIONS.INQUIRY.ARCHIVE);

const canShowAdditionalFinancingPartners = (inquiry: any) =>
  inquiry.substatus === INQUIRY_SUBSTATUSES.FORWARDED_TO_CUSTOMER;

const canShowCompeonOffers = (inquiry: any) =>
  inquiry.status === INQUIRY_STATUSES.FORWARDED_TO_COMPEON || inquiry.wasForwardToCompeon;

const canMoveToNonFastlane = (inquiry: any) =>
  inquiry.compeonStatus === CompeonStatus.awaitingDecision && !inquiry.fastlaneCancelledDate;

const useInquiryPermissions = () => {
  const userRoles = getUserRoles();
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const { canForwardToCompeon, reasons } = isCompeonForwardable(inquiryDetails, userRoles);

  return {
    canForwardToCompeon,
    reasons,
    canArchive: canArchive(inquiryDetails),
    canShowAdditionalFinancingPartners: canShowAdditionalFinancingPartners(inquiryDetails),
    canShowCompeonOffers: canShowCompeonOffers(inquiryDetails),
    canMoveToNonFastlane: canMoveToNonFastlane(inquiryDetails),
  };
};
export default useInquiryPermissions;
