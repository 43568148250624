import Joi from '@hapi/joi';

import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import {
  dzbLegalRepresentativeFields,
  legalRepresentativeFields,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { dzbLegalRepresentativesValidations } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/validations';

const { salutation, firstName, lastName, birthDate, email, phoneNumber, soleSignatureAuthorized } =
  legalRepresentativeFields;

const { taxIdentificationNumber } = dzbLegalRepresentativeFields;

const legalRepresentativeSchema: Joi.ObjectSchema<any> = fieldValidators.object().keys({
  [salutation]: dzbLegalRepresentativesValidations.salutation,
  [firstName]: dzbLegalRepresentativesValidations.firstName,
  [lastName]: dzbLegalRepresentativesValidations.lastName,
  [birthDate]: dzbLegalRepresentativesValidations.birthDate,
  [email]: dzbLegalRepresentativesValidations.email,
  [phoneNumber]: dzbLegalRepresentativesValidations.phoneNumber,
  [soleSignatureAuthorized]: dzbLegalRepresentativesValidations.soleSignatureAuthorized,
});

export const dzbLegalRepresentativeSchema: Joi.ObjectSchema<any> = legalRepresentativeSchema
  .concat(
    fieldValidators.object().keys({
      [taxIdentificationNumber]: dzbLegalRepresentativesValidations.taxIdentificationNumber,
    }),
  )
  .unknown(true)
  .strip();
