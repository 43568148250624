import normalize from 'json-api-normalizer';
import _get from 'lodash/get';

import {
  COMPANY_DETAILS,
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import { API_TO_HEADERS_MAP, INQUIRY_TABLE_HEADERS } from 'modules/InquiryTable/constants';
import { pipe } from 'utils/helpers';
import { deepCamelcaseKeys } from 'utils/object';
import { FINANCING_NEED, REQUEST_DETAILS } from 'utils/sessionStorage/keys';

const mapDraftInquiryToTableData = (inquiry) => {
  const { id, attributes, customerData, inquiryManagerData, formType } = inquiry;
  const financingNeedsData = attributes.details[FINANCING_NEED] || {};
  const companyDetails = attributes.details[COMPANY_DETAILS] || {};
  const requestDetailsData = attributes.details[REQUEST_DETAILS] || {};

  const purposeBasedOnType = {
    [InquiryType.default]: financingNeedsData['purpose-kind'],
  };
  const productKind = purposeBasedOnType[formType] ?? financingNeedsData['purpose-kind'];

  return {
    [INQUIRY_TABLE_HEADERS.ID]: id,
    [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
    [INQUIRY_TABLE_HEADERS.COMPANY_NAME]:
      _get(companyDetails, [COMPANY_DETAILS_COMPANY, 'name']) ||
      _get(companyDetails, [COMPANY_DETAILS_EXISTING_USER_COMPANY, 'name']),
    [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: productKind,
    [INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT]: requestDetailsData['product-kind'],
    [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: financingNeedsData.amount,
    [INQUIRY_TABLE_HEADERS.PROCESSOR]: undefined,
    [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
    [INQUIRY_TABLE_HEADERS.SUB_STATUS]: attributes.substatus,
    [INQUIRY_TABLE_HEADERS.VB_NAME]: customerData,
    [INQUIRY_TABLE_HEADERS.INQUIRY_MANAGER]: inquiryManagerData,
  };
};
const mapInquiryToTableData = ({
  id,
  attributes,
  processor,
  company,
  customerData,
  inquiryManagerData,
  bankAdvisorData,
  formType,
  indicativeCondition,
}) => {
  const inquiryTypeMapping = {
    [InquiryType.onlinefactoring]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: _get(company, 'attributes.name'),
      // purposeKind and financingProduct hardcoded according to https://jira.finstreet.de/browse/CRV-3045
      [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: 'factoring',
      [INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT]: 'factoring',
      [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: attributes.details['factoring-sales-gross'],
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
      [INQUIRY_TABLE_HEADERS.SUB_STATUS]: attributes.substatus,
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: processor && processor.attributes,
    },
    [InquiryType.leaseplan]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
      [INQUIRY_TABLE_HEADERS.VEHICLE_KIND]: attributes.details['vehicle-kind'],
      [INQUIRY_TABLE_HEADERS.VEHICLE_DRIVE_TYPE]: attributes.details['vehicle-drive-type'],
      [INQUIRY_TABLE_HEADERS.VEHICLE_MANUFACTURER]:
        attributes.details['vehicle-manufacturer'] + ' ' + attributes.details['vehicle-model'],
      [INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE]: attributes['external_reference'],
    },
    [InquiryType.profiRLL]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: _get(company, 'attributes.name'),
      [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: attributes.details['purpose-kind'],
      [INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT]: attributes.details['product-kind'],
      [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: attributes.details.amount,
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: processor && processor.attributes,
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
      [INQUIRY_TABLE_HEADERS.SUB_STATUS]: attributes.substatus,
      [INQUIRY_TABLE_HEADERS.VB_NAME]: customerData,
      [INQUIRY_TABLE_HEADERS.INQUIRY_MANAGER]: inquiryManagerData,
      [INQUIRY_TABLE_HEADERS.EXPECTED_ELIGIBLE_COSTS]:
        attributes.details['expected-eligible-costs-sum'],
      [INQUIRY_TABLE_HEADERS.EXPECTED_GRANT_AMOUNT]:
        attributes.details['expected-grant-amount-sum'],
      [INQUIRY_TABLE_HEADERS.FINANCING_ROLE]: attributes.details['project-financing-role'],
    },
    [InquiryType.profiMittweida]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: _get(company, 'attributes.name'),
      [INQUIRY_TABLE_HEADERS.FINANCING_ROLE]: attributes.details['project-financing-role'],
      [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: attributes.details.amount,
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: processor && processor.attributes,
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
      [INQUIRY_TABLE_HEADERS.SUB_STATUS]: attributes.substatus,
    },
    [InquiryType.bfsService]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: `${_get(company, 'attributes.name')}, ${_get(
        company,
        'attributes.legalForm',
      )}`,
      [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: attributes.details['purpose-kind'],
      [INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT]: attributes.details['product-kind'],
      [INQUIRY_TABLE_HEADERS.FACTORING_LINE]: {
        line: attributes.details['lane'],
        factoringLine: indicativeCondition ? indicativeCondition.factoringLine : '',
      },
      [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: attributes.details.amount,
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
      [INQUIRY_TABLE_HEADERS.SUB_STATUS]: attributes.status,
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: processor && processor.attributes,
    },
    [InquiryType.mmv]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.VPP_ID]: attributes.details['vpp-id'],
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: _get(company, 'attributes.name'),
      [INQUIRY_TABLE_HEADERS.CONTRACT_NUMBER]: attributes.details['contract-number'],
      [INQUIRY_TABLE_HEADERS.OBJECT_DESCRIPTION]: attributes.details['object-description'],
      [INQUIRY_TABLE_HEADERS.OBJECT_VALUE]: attributes.details['object-value'],
      [INQUIRY_TABLE_HEADERS.SUPPLIER]: attributes.details['supplier'],
      [INQUIRY_TABLE_HEADERS.AGENT]: attributes.details['agent'],
      [INQUIRY_TABLE_HEADERS.ZIP_CODE_AREA]: attributes.details['zip-code-area'],
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
      [INQUIRY_TABLE_HEADERS.SUB_STATUS]: attributes.substatus,
    },
    [InquiryType.dzb]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: _get(company, 'attributes.name'),
      [INQUIRY_TABLE_HEADERS.PARTNER_REFERENCE_ID]: _get(
        company,
        'attributes.additionalDetails.partnerReferenceId',
      ),
      [INQUIRY_TABLE_HEADERS.ASSOCIATION_NAME]: _get(attributes, 'details.association.name'),
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: processor && processor.attributes,
      [INQUIRY_TABLE_HEADERS.COMPANY_COUNTRY]: _get(
        company,
        'attributes.additionalDetails.country',
      ),
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
    },
    [InquiryType.hausbank]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: _get(company, 'attributes.name'),
      [INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_NAME]: _get(
        attributes,
        'details.homeowner-association.name',
      ),
      [INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_ZIPCODE]: _get(
        attributes,
        'details.homeowner-association.zip-code',
      ),
      [INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_CITY]: _get(
        attributes,
        'details.homeowner-association.city',
      ),
      [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: attributes.details['financing-amount'],
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: processor && processor.attributes,
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
    },
    [InquiryType.cbBank]: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: _get(company, 'attributes.name'),
      [INQUIRY_TABLE_HEADERS.FACTORING_LINE]: indicativeCondition?.factoringLine,
      [INQUIRY_TABLE_HEADERS.LANE]: attributes['specialized_lane'],
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: bankAdvisorData,
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
    },
    default: {
      [INQUIRY_TABLE_HEADERS.ID]: id,
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: attributes.created_at,
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: _get(company, 'attributes.name'),
      [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: attributes.details['purpose-kind'],
      [INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT]: attributes.details['product-kind'],
      [INQUIRY_TABLE_HEADERS.FACTORING_LINE]: {
        line: attributes.details['lane'],
        factoringLine: indicativeCondition ? indicativeCondition.factoringLine : '',
      },
      [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: attributes.details.amount,
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: processor && processor.attributes,
      [INQUIRY_TABLE_HEADERS.STATUS]: attributes.status,
      [INQUIRY_TABLE_HEADERS.SUB_STATUS]: attributes.substatus,
      [INQUIRY_TABLE_HEADERS.VB_NAME]: customerData,
      [INQUIRY_TABLE_HEADERS.INQUIRY_MANAGER]: inquiryManagerData,
      [INQUIRY_TABLE_HEADERS.EXPECTED_ELIGIBLE_COSTS]:
        attributes.details['expected-eligible-costs-sum'],
      [INQUIRY_TABLE_HEADERS.EXPECTED_GRANT_AMOUNT]:
        attributes.details['expected-grant-amount-sum'],
    },
  };
  return inquiryTypeMapping[formType] || inquiryTypeMapping.default;
};

export const mapInquiriesToTableData = (inquiries) =>
  inquiries.map((inquiry) =>
    inquiry.attributes.status === INQUIRY_STATUSES.DRAFT
      ? mapDraftInquiryToTableData(inquiry)
      : mapInquiryToTableData(inquiry),
  );

const getCompany = (normalizedResponse) => (inquiry) => {
  const companyId = _get(inquiry, 'relationships.company.data.id');
  const company = _get(normalizedResponse, `companies.${companyId}`);
  return { ...inquiry, company };
};

const getIndicativeCondition = (normalizedResponse) => (inquiry) => {
  const conditionId = _get(inquiry, 'relationships.indicative_condition.data.id');
  const indicativeCondition = conditionId
    ? normalizedResponse.indicativeConditions[conditionId].attributes
    : null;
  return { ...inquiry, indicativeCondition };
};

const getBankAdvisor = (normalizedResponse) => (inquiry) => {
  const bankAdvisorId = _get(inquiry, 'relationships.bank_advisor.data.id');
  const bankAdvisor = bankAdvisorId ? normalizedResponse.portalUsers[bankAdvisorId] : null;
  return { ...inquiry, processor: bankAdvisor };
};

const getCustomerData = (normalizedResponse) => (inquiry) => {
  // Customer
  const customerId = _get(inquiry, 'relationships.customer.data.id');
  const vbData = customerId ? normalizedResponse.customers[customerId].attributes : null;
  return { ...inquiry, customerData: vbData };
};

const getInquiryManagerData = (normalizedResponse) => (inquiry) => {
  const inquiryManagerId = _get(inquiry, 'relationships.inquiry_manager.data.id');
  const data = inquiryManagerId
    ? normalizedResponse.portalUsers[inquiryManagerId].attributes
    : null;
  return { ...inquiry, inquiryManagerData: data };
};

const getBankAdvisorData = (normalizedResponse) => (inquiry) => {
  const bankAdvisorId = _get(inquiry, 'relationships.bank_advisor.data.id');
  const data = bankAdvisorId ? normalizedResponse.portalUsers[bankAdvisorId].attributes : null;
  return { ...inquiry, bankAdvisorData: data };
};

const getFormType = (inquiry) => {
  const formType = _get(inquiry, 'attributes.form_type', InquiryType.default);
  return { ...inquiry, formType };
};

export const mapCountersToTabs = (countersByStatus) =>
  Object.keys(countersByStatus).map((status) => ({
    status,
    counter: countersByStatus[status],
  }));

export const mapInquiriesResponse = (response) => {
  const normalizedResponse = normalize(response.data);
  const inquiries = _get(response, 'data.data').map(
    pipe(
      getCompany(normalizedResponse),
      getBankAdvisor(normalizedResponse),
      getCustomerData(normalizedResponse),
      getInquiryManagerData(normalizedResponse),
      getBankAdvisorData(normalizedResponse),
      getIndicativeCondition(normalizedResponse),
      getFormType,
    ),
  );

  const options = _get(response, 'data.meta');
  const mappedOptions = {
    currentPage: options.pagination.current_page,
    pageCount: options.pagination.total_pages,
    pageSize: options.pagination.current_per_page,
    sortBy: API_TO_HEADERS_MAP[options.sort.sort_by],
    sortDirection: options.sort.direction,
    filterBy: {
      id: options.filter.id,
      companyName: options.filter.company_name,
      associationName: options.filter.association_name,
      companyCountry: options.filter.company_country,
      bankAdvisorFullName: options.filter.bank_advisor_full_name,
      companyPartnerReferenceId: options.filter.company_partner_reference_id,
      createdAtFrom: options.filter.created_at_from,
      createdAtTo: options.filter.created_at_to,
      purposeKind: options.filter.purpose_kind,
      productKind: options.filter.product_kind,
      amountFrom: options.filter.amount_from,
      amountTo: options.filter.amount_to,
      status: options.filter.status,
      inquiryType: options.filter.form_type,
      inquiryManagerId: options.filter.inquiry_manager_id,
      customerVBNumber: options.filter.customer_vb_number,
      zipCodeArea: options.filter.zip_code_area,
      vppId: options.filter.vpp_id,
      lane: options.filter.lane,
    },
    touched: true,
  };

  const statuses = _get(response, 'data.meta.count_by_status');
  const selectBoxOptions = deepCamelcaseKeys(_get(response, 'data.meta.select_box_options'));

  return {
    options: mappedOptions,
    inquiries,
    statuses: statuses ? mapCountersToTabs(statuses) : [],
    selectBoxOptions,
  };
};

export const mapInquiryManagerResponse = (response) => {
  return _get(response, 'data.data').map(({ id, attributes }) => ({
    id,
    name: [attributes.first_name, attributes.last_name].join(' '),
    hasAssignedZipCodes: attributes.related_companies_zip_code_ranges.length,
  }));
};
