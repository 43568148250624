import React from 'react';

import { Flex } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import { CbBankBeneficialOwner } from 'cbbank/inquiry/fields';
import {
  formValidator,
  useErrorTranslator,
} from 'modules/Inquiry/inquiryFieldValidation/useErrorTranslations';
import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import ButtonComponent from 'theme/components/Button';
import { ModalComponent, ModalProps } from 'theme/components/Modal';
import { useTranslations } from 'utils/hooks/useTranslations';

import { CbBankBeneficialOwnerFields } from './CbBankBeneficialOwnerFields';
import { cbBankBeneficialOwnerSchema } from './cbBankBeneficialOwnerSchema';
import { usePatchCbBankBeneficialOwner } from '../../../hooks/beneficialOwner/patchBeneficialOwners';

const EDIT_BENEFICIAL_OWNER_FORM_ID = 'editBeneficialOwnerForm';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  initialValues: CbBankBeneficialOwner | undefined;
};

const CbBankEditBeneficialOwnerModal = ({ isOpen, onClose, initialValues }: Props) => {
  const { mutateAsync, isLoading } = usePatchCbBankBeneficialOwner();
  const { refetchInquiry } = useFetchInquiryData();

  const errorTranslator = useErrorTranslator();
  const t = useTranslations();
  const { actions } = translations.pages.companiesDetails.associatedPerson.new.beneficialOwners;

  const onSubmit = async (values: CbBankBeneficialOwner) => {
    await mutateAsync(values);
    await refetchInquiry();
    onClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t(actions.edit.title)}
      footer={
        <Flex gap={4}>
          <ButtonComponent variant={'tertiary'} onClick={onClose} testId="cancelButton">
            {t(actions.edit.cancel)}
          </ButtonComponent>
          <ButtonComponent
            isLoading={isLoading}
            variant={'primary'}
            form={EDIT_BENEFICIAL_OWNER_FORM_ID}
            type={'submit'}
            testId="confirmButton"
          >
            {t(actions.edit.confirm)}
          </ButtonComponent>
        </Flex>
      }
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(values) => formValidator(values, cbBankBeneficialOwnerSchema, errorTranslator)}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} id={EDIT_BENEFICIAL_OWNER_FORM_ID}>
              <CbBankBeneficialOwnerFields mode={'edit'} />
            </form>
          );
        }}
      />
    </ModalComponent>
  );
};

export default withAssociatedPeopleEditMode(CbBankEditBeneficialOwnerModal);
