import * as React from 'react';

import { Text, TextProps } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
} & Pick<
  TextProps,
  | 'as'
  | 'display'
  | 'mr'
  | 'mb'
  | 'ml'
  | 'mt'
  | 'textStyle'
  | 'fontWeight'
  | 'textAlign'
  | 'color'
  | 'textTransform'
  | 'className'
  | 'transition'
  | 'whiteSpace'
>;

export const TextComponent = ({ children, ...props }: Props) => {
  return <Text {...props}>{children}</Text>;
};
