import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PortalPage from 'components/PortalPage';
import ElementsPerPage from 'components/PortalTable/ElementsPerPage/ElementsPerPage';
import Pagination from 'components/PortalTable/Pagination/Pagination';
import PortalTable from 'components/PortalTable/PortalTable';
import paths from 'constants/paths';
import { IInquiryListElement } from 'models/InquiryList.model';
import ErrorBoundary from 'modules/ErrorBoundary';
import { InquiriesListProvider } from 'modules/InquiriesListProvider';
import InquirySettingsProvider from 'modules/Inquiry/InquirySettingsProvider';
import { mapInquiriesToTableData } from 'modules/InquiryTable/mapFromApi';
import { useInquiryTable } from 'modules/InquiryTable/useInquiryTable';
import { resetPersist } from 'new/form/persist';
import NoInquiry from 'pages/customerPortal/CustomerInquiryList/NoInquiry';
import InquiryStateFilter from 'pages/operationPortal/OperationInquiryList/InquiryStateFilter/InquiryStateFilter';
import { useOperationInquiryTableHeaders } from 'pages/operationPortal/OperationInquiryList/useOperationInquiryTableHeaders';
import { AppDispatch } from 'store';
import { useTranslations } from 'utils/hooks/useTranslations';

import { RESET_MARKETDATA_FILTER } from '../PlanningEvaluation/SharedComponent/filterManagementReducer';

const OperationInquiryListContent = () => {
  const t = useTranslations();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const handleRowClick = (row: IInquiryListElement) => {
    dispatch({ type: RESET_MARKETDATA_FILTER });
    history.push(paths.operation.inquiryDetails.root.replace(':id', row.id));
  };
  const {
    onPaginationChange,
    onPageSizeChange,
    pageSize,
    currentPage,
    totalPages,
    isLoading,
    isInitialized,
    onSortChange,
    sortDirection,
    sortBy,
    data,
  } = useInquiryTable();

  const tableData = data ? mapInquiriesToTableData(data?.inquiries) : [];
  const noInquiries = !tableData?.length && !isLoading;

  const tableHeaders = useOperationInquiryTableHeaders();

  // Reset the persisted form data as well as the progress bar
  useEffect(() => {
    resetPersist();
  }, []);

  return (
    <InquirySettingsProvider>
      <PortalPage pageTitle={t('pages.inquiryList.pageTitle')}>
        <InquiryStateFilter />
        <ErrorBoundary>
          {noInquiries ? (
            <NoInquiry />
          ) : (
            <PortalTable
              headers={tableHeaders}
              tableData={tableData}
              sortBy={sortBy}
              sortDirection={sortDirection}
              isInitialized={isInitialized}
              isLoading={isLoading}
              onSortChange={onSortChange}
              onRowClick={handleRowClick}
            >
              <ElementsPerPage onPageSizeChange={onPageSizeChange} pageSize={pageSize} />
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
              />
            </PortalTable>
          )}
        </ErrorBoundary>
      </PortalPage>
    </InquirySettingsProvider>
  );
};

const OperationInquiryList = () => (
  <InquiriesListProvider>
    <OperationInquiryListContent />
  </InquiriesListProvider>
);

export default OperationInquiryList;
