import _get from 'lodash/get';

import { cbBankFields } from 'cbbank/inquiry/fields';
import { COMPANY_DETAILS_COMPANY__NOT_FOUND } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFields, ValidateSectionFunction } from 'new/form/useValidateFields';
import { isLoggedInUser } from 'utils/user/conditionals';

const { selectedCompanyName: selectedCompanyNameFieldName } =
  cbBankFields.companyDataPage.companySearchSection;

export const validateCompanySearchSection: ValidateSectionFunction<InquiryType.cbBank> = (
  form,
  values,
  store,
  conditions,
) => {
  const isLoggedIn = isLoggedInUser();
  const selectedCompanyName = _get(values, selectedCompanyNameFieldName);

  if (!isLoggedIn && selectedCompanyName !== COMPANY_DETAILS_COMPANY__NOT_FOUND) {
    return validateFields({
      fields: [selectedCompanyNameFieldName],
      values,
      form,
      conditions,
    });
  }
  return true;
};

export const companySearchSectionValidationMap = {
  [selectedCompanyNameFieldName]: fieldValidators.string().required(),
};
