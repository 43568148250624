import * as React from 'react';

import { Box } from '@chakra-ui/react';

import { FONT_WEIGHT_BOLD, FONT_WEIGHT_REGULAR } from 'constants/globalConstants';
import { ICompactReport } from 'models/CompanyDetails.model';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { RiskCard, RiskContainer, RiskHeading } from './RiskCard/RiskCard';
import { textStyle } from '../../../../theme/themeConstants';

type PropType = {
  isRiskAnalysisPage?: boolean;
  report: ICompactReport | undefined;
};

export const Score: React.FC<PropType> = ({ isRiskAnalysisPage, report }) => {
  const t = useTranslations('pages.companiesDetails.riskAnalysis');
  const probabilityOfDefault = report?.solvencyIndex?.dueToSolvencyIndex
    ? `${report?.solvencyIndex?.dueToSolvencyIndex}%`
    : '';

  return (
    <RiskCard
      probabilityOfDefault={probabilityOfDefault}
      timeOfMeasurement={report?.createdAt}
      isRiskAnalysisPage={isRiskAnalysisPage}
    >
      <RiskHeading>{t('score')}</RiskHeading>
      <RiskContainer>
        <TextComponent
          as="div"
          {...textStyle.sizeXxl}
          fontWeight={FONT_WEIGHT_BOLD}
          color="text.primary"
        >
          {report?.solvencyIndex?.solvencyIndexValue ?? '–'}

          {report?.solvencyIndex?.solvencyIndexValue && (
            <Box
              as="span"
              ml="3"
              {...textStyle.sizeM}
              fontWeight={
                !report?.solvencyIndex?.solvencyIndexExplanation
                  ? FONT_WEIGHT_BOLD
                  : FONT_WEIGHT_REGULAR
              }
              color="text.secondary"
            >
              {report?.solvencyIndex?.solvencyIndexExplanation}
            </Box>
          )}
        </TextComponent>
      </RiskContainer>
    </RiskCard>
  );
};
