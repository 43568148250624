import { cbBankFields } from 'cbbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateComplianceSection: ValidateSectionFunction<InquiryType.cbBank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap<InquiryType.cbBank>({
    validationMap: complianceSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { termsOfService, dataProtection, factoringTerms } =
  cbBankFields.companyDataPage.complianceSection;

export const complianceSectionValidationMap = {
  [termsOfService]: fieldValidators.equal(true).required(),
  [dataProtection]: fieldValidators.equal(true).required(),
  [factoringTerms]: fieldValidators.equal(true).required(),
};
