import React from 'react';

import { bool, number } from 'prop-types';

import CheckmarkIcon from 'theme/components/Icon/CheckmarkIcon';

import NumericIndicator from './NumericIndicator';
import ProgressIndicator from './ProgressIndicator';
import Wrapper from './Wrapper';

const StageProgressCounter = ({
  active,
  current,
  finished,
  totalCount,
  onlyShowFinishedProgressCounter,
}) => (
  <Wrapper visible={onlyShowFinishedProgressCounter ? finished : true}>
    <ProgressIndicator
      active={active}
      diameter={40}
      progress={current / totalCount}
      thickness={3}
    />
    <NumericIndicator>
      {finished ? <CheckmarkIcon boxSize={8} /> : `${current} | ${totalCount}`}
    </NumericIndicator>
  </Wrapper>
);

StageProgressCounter.propTypes = {
  active: bool.isRequired,
  current: number.isRequired,
  totalCount: number.isRequired,
  finished: bool.isRequired,
  onlyShowFinishedProgressCounter: bool.isRequired,
};

export default StageProgressCounter;
