import React from 'react';

import { Flex, ModalProps } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import { CbBankBeneficialOwner } from 'cbbank/inquiry/fields';
import {
  formValidator,
  useErrorTranslator,
} from 'modules/Inquiry/inquiryFieldValidation/useErrorTranslations';
import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';
import { translations } from 'new/form/common/types';
import { useCreateCbBankBeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/beneficialOwner/createBeneficialOwner';
import ButtonComponent from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { useTranslations } from 'utils/hooks/useTranslations';

import { CbBankBeneficialOwnerFields } from './CbBankBeneficialOwnerFields';
import { cbBankBeneficialOwnerSchema } from './cbBankBeneficialOwnerSchema';
import { withAssociatedPeopleEditMode } from '../../associatedPeopleMode/withAssociatedPeopleEditMode';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'>;

const ADD_BENEFICIAL_OWNER_FORM_ID = 'addBeneficialOwnerForm';

const CbBankAddBeneficialOwnerModal = ({ isOpen, onClose }: Props) => {
  const { mutateAsync, isLoading } = useCreateCbBankBeneficialOwner();
  const { refetchInquiry } = useFetchInquiryData();
  const errorTranslator = useErrorTranslator();
  const t = useTranslations();
  const { actions } = translations.pages.companiesDetails.associatedPerson.new.beneficialOwners;

  const onSubmit = async (values: CbBankBeneficialOwner) => {
    await mutateAsync(values);
    await refetchInquiry();
    onClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t(actions.add.title)}
      footer={
        <Flex gap={4}>
          <ButtonComponent variant={'tertiary'} onClick={onClose} testId="cancelButton">
            {t(actions.add.cancel)}
          </ButtonComponent>
          <ButtonComponent
            variant={'primary'}
            type="submit"
            form={ADD_BENEFICIAL_OWNER_FORM_ID}
            isLoading={isLoading}
            testId="confirmButton"
          >
            {t(actions.add.confirm)}
          </ButtonComponent>
        </Flex>
      }
    >
      <Form
        onSubmit={onSubmit}
        validate={(values) => formValidator(values, cbBankBeneficialOwnerSchema, errorTranslator)}
        render={({ handleSubmit }) => {
          return (
            <form id={ADD_BENEFICIAL_OWNER_FORM_ID} onSubmit={handleSubmit}>
              <CbBankBeneficialOwnerFields mode={'add'} />
            </form>
          );
        }}
      />
    </ModalComponent>
  );
};

export default withAssociatedPeopleEditMode(CbBankAddBeneficialOwnerModal);
