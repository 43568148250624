import {
  INDUSTRY__AGRICULTURE,
  INDUSTRY__BUILDING,
  INDUSTRY__COMMUNICATION,
  INDUSTRY__CRAFT,
  INDUSTRY__ENERGY_AND_WATER_SUPPLY,
  INDUSTRY__FINANCE_AND_INSURANCE,
  INDUSTRY__FOOD,
  INDUSTRY__FOUNDATION,
  INDUSTRY__FUNDRAISER,
  INDUSTRY__HEALTH,
  INDUSTRY__HEALTH_AND_SOCIAL,
  INDUSTRY__HOSPITALITY,
  INDUSTRY__MAINTENANCE,
  INDUSTRY__MANUFACTURING,
  INDUSTRY__MINING,
  INDUSTRY__OTHER,
  INDUSTRY__PROCESSING,
  INDUSTRY__REAL_ESTATE,
  INDUSTRY__RELIGIOUS,
  INDUSTRY__RESIDE,
  INDUSTRY__SERVICE,
  INDUSTRY__SOCIAL,
  INDUSTRY__TRADE,
  INDUSTRY__TRANSPORT,
  INDUSTRY__UPBRINGING,
} from 'modules/Inquiry/Form/formFields';

export const legalFormValues = {
  freiberuflich: 'Freiberuflich',
  gewerblich: 'Gewerblich',
  einzelunternehmung: 'Einzelunternehmung',
  gmbh: 'Gesellschaft mit beschränkter Haftung (GmbH)',
  aktiengesellschaft: 'Aktiengesellschaft (AG)',
  eingetrageneGenossenschaft: 'Eingetragene Genossenschaft (eG)',
  unternehmergesellschaftHaftungsbeschrankte: 'Unternehmergesellschaft (haftungsbeschränkt)',
  kommanditgesellschaftAufAktien: 'Kommanditgesellschaft auf Aktien (KGaA)',
  gemeinnutzigeAktiengesellschaft: 'Gemeinnützige Aktiengesellschaft (gAG)',
  gmbhInRundung: 'GmbH in Gründung (i.G.)',
  haftungsbeschrankte: 'Haftungsbeschränkte Kommanditgesellschaft (GmbH & Co. KG)',
  kommanditgesellschaft: 'Kommanditgesellschaft (KG)',
  offeneHandelsgesellschaft: 'Offene Handelsgesellschaft (OHG)',
  gesellschaftBurgerlichenRechts: 'Gesellschaft bürgerlichen Rechts (GbR)',
  partnergesellschaft: 'Partnergesellschaft',
  eingetragenerVerein: 'Eingetragener Verein (e.V.)',
  gesellschaftStiftungDesPrivatenRechts: 'Stiftung des privaten Rechts',
  korperschaftDesOffentlichenRechts: 'Körperschaft des öffentlichen Rechts',
  anstaltDesOffentlichenRechts: 'Anstalt des öffentlichen Rechts',
  eingetragenerKaufmann: 'Eingetragener Kaufmann (e.K.)',
  naturlichePerson: 'Natürliche Person',
  einzelunternehmer: 'Einzelunternehmer',
  gemeinnutzige: 'gemeinnützige GmbH (gGmbH)',
  stiftungDesOffentlichenRechts: 'Stiftung des öffentlichen Rechts',
  ordensgemeinschaft: 'Ordensgemeinschaft',
  other: 'Sonstige',
} as const;

export type LegalFormValuesBase = typeof legalFormValues;

export const industryValues = {
  building: INDUSTRY__BUILDING,
  mining: INDUSTRY__MINING,
  energyAndWaterSupply: INDUSTRY__ENERGY_AND_WATER_SUPPLY,
  service: INDUSTRY__SERVICE,
  financeAndInsurance: INDUSTRY__FINANCE_AND_INSURANCE,
  hospitality: INDUSTRY__HOSPITALITY,
  healthAndSocial: INDUSTRY__HEALTH_AND_SOCIAL,
  trade: INDUSTRY__TRADE,
  craft: INDUSTRY__CRAFT,
  realEstate: INDUSTRY__REAL_ESTATE,
  communication: INDUSTRY__COMMUNICATION,
  agriculture: INDUSTRY__AGRICULTURE,
  manufacturing: INDUSTRY__MANUFACTURING,
  processing: INDUSTRY__PROCESSING,
  transport: INDUSTRY__TRANSPORT,
  food: INDUSTRY__FOOD,
  maintenance: INDUSTRY__MAINTENANCE,
  social: INDUSTRY__SOCIAL,
  health: INDUSTRY__HEALTH,
  reside: INDUSTRY__RESIDE,
  fundraiser: INDUSTRY__FUNDRAISER,
  foundation: INDUSTRY__FOUNDATION,
  religious: INDUSTRY__RELIGIOUS,
  upbringing: INDUSTRY__UPBRINGING,
  other: INDUSTRY__OTHER,
} as const;

export type IndustryValuesBase = typeof industryValues;
