import { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { cbBankFieldsWithSectionString, cbBankFields } from 'cbbank/inquiry/fields';

const { allInformationAvailable } = cbBankFields.personalDataPage.beneficialOwnersSection;

const checkEmptyObject = (value: any[]) =>
  value.length === 0 ||
  (value.length === 1 && typeof value[0] === 'object' && Object.keys(value[0]).length === 0);

const beSectionString =
  cbBankFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
    .sectionString;

export const useUpdateAndRemoveFields = (fieldName: string, value: any) => {
  const { mutators, change } = useForm();

  useEffect(() => {
    if (value && !checkEmptyObject(value)) {
      for (let i = 0; i < value.length; i++) {
        if (i === 0) {
          mutators.update(fieldName, i, {});
        } else {
          mutators.remove(fieldName, i);
        }
        if (fieldName === beSectionString) {
          change(allInformationAvailable, 'false');
        }
      }
    }
  }, [fieldName, value, mutators, change]);
};
