import { ReportType as CompanyReportType } from 'models/CompanyDetails.model';
import { ICrefoQueryDashboardData } from 'models/DashboardActionData.model';
import { ReportsMap, ReportType } from 'models/InquiryDetails/DefaultInquiryDetails.model';

export const mapDashboardActionReports = (data: ICrefoQueryDashboardData) => {
  const reports = {} as Partial<ReportsMap>;
  if (data?.shortReport) {
    const shortReport = data.shortReport;
    reports[ReportType.SHORT] = {
      createdAt: shortReport.createdAt,
      type: CompanyReportType.latestScoringShort,
      solvencyFinanceIndustry: {
        class: shortReport.solvencyClass,
        pdRange: shortReport.probabilityOfDefaultRange,
      },
    };
  }
  if (data?.compactReport) {
    const compactReport = data.compactReport;
    reports[ReportType.COMPACT] = {
      createdAt: compactReport.createdAt,
      type: CompanyReportType.latestScoringCompact,
      solvencyIndex: {
        solvencyIndexValue: compactReport?.solvencyIndex,
        solvencyIndexExplanation: '',
        dueToSolvencyIndex: Number(compactReport.probabilityOfDefault),
      },
    };
  }
  if (data?.commercialReport) {
    const commercialReport = data.commercialReport;
    reports[ReportType.COMMERCIAL] = {
      type: CompanyReportType.commercial,
      ...commercialReport,
    };
  }

  return reports;
};
