import React from 'react';

import { InputWithField } from 'components/Input';
import { translations } from 'new/form/common/types';
import { DzbLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import {
  legalRepresentativeFieldConfig,
  useGenerateLegalRepresentativeFields,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/formFields/useGenerateLegalRepresentativeFields';
import {
  FieldConfig,
  useGenerateField,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/formFields/useGenerateField';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

type DzbLegalRepresentativeFieldsWithoutId = Omit<DzbLegalRepresentative, 'id'>;

type DzbLegalRepresentativeFields = Record<
  keyof DzbLegalRepresentativeFieldsWithoutId,
  React.ReactNode
>;

const { fields } = translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives;

const dzbLegalRepresentativeFieldConfig: FieldConfig<DzbLegalRepresentativeFieldsWithoutId> = {
  ...legalRepresentativeFieldConfig,
  taxIdentificationNumber: {
    component: InputWithField,
    caption: fields.taxIdentificationNumber.caption,
    optional: true,
  },
};

export function useGenerateDzbLegalRepresentativeFields(
  mode: AssociatedPersonMode,
  prefix?: string,
): DzbLegalRepresentativeFields {
  const legalRepresentativeFields = useGenerateLegalRepresentativeFields(mode, prefix);
  const generateField = useGenerateField<DzbLegalRepresentativeFieldsWithoutId>(
    dzbLegalRepresentativeFieldConfig,
    mode,
    prefix,
  );

  return {
    ...legalRepresentativeFields,
    taxIdentificationNumber: generateField('taxIdentificationNumber'),
  };
}
