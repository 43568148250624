import React from 'react';

import { FileKind } from 'models/FileKind.type';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';
import { userIsCustomer } from 'utils/user/conditionals';

import ProcessSpecificFiles from './ProcessSpecificFiles';

export const CbBankProcessSpecificFiles = () => {
  const t = useTranslations();
  const isCustomer = userIsCustomer();
  const { contractsTitle, contractsTitleBank } =
    translations.pages.inquiryDetails.documentExchange.processSpecificFiles;

  const contractKinds = [FileKind.CB_BANK_SIGNED_CONTRACT];
  if (!isCustomer) {
    contractKinds.push(FileKind.CB_BANK_VIDEO_LEGITIMATION);
  }
  const title = isCustomer ? t(contractsTitle) : t(contractsTitleBank);

  return <ProcessSpecificFiles title={title} kinds={contractKinds} />;
};
