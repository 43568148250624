import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';

import { useInquiryLabelTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryLabelTranslator';
import { useInquiryValueTranslator } from 'modules/Inquiry/inquiryFieldsTranslations/useInquiryValueTranslator';
import { SummaryType, unitFormatters, unitTypeSuffixMap } from 'new/form/formatters/units';
import { StepSequence, SummaryFieldsType } from 'new/form/model';
import { AnyFields } from 'new/form/state/types';
import { useGeneratePagesWithSectionsAndFieldNames } from 'new/summary/hooks/useGeneratePagesWithSectionsAndFieldNames';
import { useSummaryStepContext } from 'new/summary/SummaryPageContext';
import { useTranslations } from 'utils/hooks/useTranslations';

export type SummaryPageField = {
  text: string;
  caption: string;
  testId?: string;
};

export type SummarySection = {
  [sectionName: string]: Array<SummaryPageField | Array<SummaryPageField>>;
};

export type SummaryPage = {
  [pageName: string]: SummarySection;
};

export type FieldsForSummaryPage = SummaryPage;
/**
 * On the inquiry summary page and the inquiry detail page in the operations / customer portal we need to display the
 * label and the value of the fields - additionally the label should be translated and the value should be formatted
 * according to a unit mapping from fieldName to unitFormatter. This hook generates an object with the pageName as key
 * and an array of SummaryPageField as value.
 */
export function useBuildFieldsForSummaryPage({
  stepSequence,
  summaryFields,
  fields,
}: {
  summaryFields: SummaryFieldsType<any> | undefined;
  stepSequence: StepSequence<any> | undefined;
  fields: AnyFields;
}): FieldsForSummaryPage {
  const t = useTranslations();
  const labelTranslator = useInquiryLabelTranslator();
  const valueTranslator = useInquiryValueTranslator();
  const { values } = useSummaryStepContext();
  const pagesWithSectionsAndFieldNames = useGeneratePagesWithSectionsAndFieldNames(
    stepSequence,
    fields,
  );

  function translateField(values: AnyFields, field: string, arrayField?: string) {
    let fieldValue;
    let summaryType;

    if (arrayField) {
      fieldValue = _get(values, arrayField);
    } else {
      fieldValue = _get(values, field);
    }
    summaryType = _get(summaryFields, field) as SummaryType;

    const shouldShow = summaryType?.showEmpty ? true : !_isNil(fieldValue);

    if (summaryType && shouldShow) {
      const { unit, showOnSummaryPage, order } = summaryType;
      if (showOnSummaryPage) {
        const unitFormatter = unitFormatters[unit];
        const translatedValue = valueTranslator(fieldValue, field);
        const formattedValue = unitFormatter
          ? unitFormatter(translatedValue, false)
          : translatedValue || '-';
        const suffix = unitTypeSuffixMap[unit](t);
        const label = labelTranslator(field);
        const testId = field;

        const isEmpty = _isNil(fieldValue) || fieldValue === '';
        const text = !isEmpty ? `${formattedValue} ${suffix || ''}` : '-';

        return {
          text,
          caption: label,
          order,
          testId,
        };
      }
    }
    return undefined;
  }

  if (stepSequence && summaryFields) {
    return Object.entries(pagesWithSectionsAndFieldNames).reduce(
      (accumulator, [pageName, sections]) => {
        const sectionsWithFields = Object.entries(sections).reduce(
          (sectionsAccumulator, [sectionName, fields]) => {
            const fieldsWithCaptionAndValue: Array<
              SummaryPageField | Array<SummaryPageField | undefined> | undefined
            > = fields
              .map((field) => {
                if (typeof field === 'string') {
                  return translateField(values, field);
                } else if (typeof field === 'object') {
                  const nestedValues = _get(values, field.fieldName);
                  const nestedFields = _get(summaryFields, field.fieldName);
                  if (Array.isArray(nestedValues)) {
                    const result = nestedValues.map((arrayObject: any, index) => {
                      if (arrayObject) {
                        return Object.keys(nestedFields).map((key) => {
                          const objectField = `${field.fieldName}.${key}`;
                          const arrayField = `${field.fieldName}[${index}].${key}`;
                          return translateField(values, objectField, arrayField);
                        });
                      }

                      return undefined;
                    });

                    return result.map((subArray) => subArray?.filter((item) => item !== undefined));
                  } else if (_isObject(nestedValues)) {
                    return Object.keys(nestedFields).map((key) => {
                      const objectField = `${field.fieldName}.${key}`;
                      return translateField(values, objectField);
                    });
                  }

                  return undefined;
                }

                return undefined;
              })
              .flat()
              .sort((a, b) => {
                if (!Array.isArray(a) && !Array.isArray(b)) {
                  if (a?.order && b?.order) {
                    return a.order - b.order;
                  }
                  return 0;
                }

                return 0;
              })
              .filter(Boolean);

            if (fieldsWithCaptionAndValue.length > 0) {
              return {
                ...sectionsAccumulator,
                [sectionName]: fieldsWithCaptionAndValue,
              };
            }

            return sectionsAccumulator;
          },
          {},
        );

        return {
          ...accumulator,
          [pageName]: sectionsWithFields,
        };
      },
      {},
    );
  }

  return {};
}
