import React from 'react';

import { useSelector } from 'react-redux';
import { compose } from 'redux';

import { useLoadProcessLog } from 'api/CompeonReverseApi/operation/queryHooks';
import Spinner from 'components/Spinner';
import PERMISSIONS from 'constants/user/permissions';
import { ProcessLog } from 'models/ProcessLog.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import mapFromApiInterface from 'pages/operationPortal/InquiryDetails/Dashboard/Communication/ProcessLog/mapActivityFromApi';
import ProcessLogItem from 'pages/operationPortal/InquiryDetails/Dashboard/Communication/ProcessLog/ProcessLogItem';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import { StyledHeading } from '../../styles';

const ProcessLogList = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const { data, isLoading } = useLoadProcessLog({
    variables: { inquiryId },
    select: (response) => ({
      processLogEntries: mapFromApiInterface(response),
    }),
    refetchOnWindowFocus: false,
  });

  const t = useTranslations('pages.inquiryDetails.dashboard.communication.processLog');

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <StyledHeading data-testid={'Comment'}>{t('title')}</StyledHeading>

      {data?.processLogEntries.length
        ? data.processLogEntries.map((processLog: ProcessLog) => (
            <ProcessLogItem key={processLog.id} log={processLog} />
          ))
        : t('noEntries')}
    </div>
  );
};

export default compose(
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.DASHBOARD.COMMUNICATION.PROCESS_LOG),
)(
  chooseSelectedInquiryTypeSpecificComponent({
    [InquiryType.default]: ProcessLogList,
    [InquiryType.cbBank]: () => null,
  }),
);
