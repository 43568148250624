import { LabelsMap } from 'new/form/common/types';

import { companyDataPageLabelsMap } from './steps/companyData/labels';
import { financingNeedPageLabelsMap } from './steps/financingNeed/labels';
import { offerPageLabelsMap } from './steps/offer/labels';
import { personalDatePageLabelsMap } from './steps/personalData/labels';

export const cbBankLabelsMap: LabelsMap = {
  ...financingNeedPageLabelsMap,
  ...companyDataPageLabelsMap,
  ...offerPageLabelsMap,
  ...personalDatePageLabelsMap,
};
