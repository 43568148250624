import React, { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { filterXSS } from 'xss';

import { userIsBankAdvisor } from 'utils/user/conditionals';

const LINK_REGEXP = /\[(blank:)?(.+)](.+)/;
export const useTranslations = (prefix, options = { tranformLinks: true }) => {
  const { formatMessage } = useIntl();
  const prefixWithDot = prefix ? `${prefix}.` : '';

  // switch translation based on user role, use `roleSpecific` in usage
  // use simply condition because of this currently only 2 cases
  const bankAdvisor = userIsBankAdvisor();
  const roleSuffix = bankAdvisor ? '.bankAdvisor' : '.customer';

  /*
   * Find any <link> tags and allow to transform them to url's
   * format: <link>[(blank:)URL]Text</link>
   * This way we can add links through BE translations
   */
  const linkTransformation = useMemo(
    () => ({
      link: (args) => {
        const [, blank, link, text] = args[0].match(LINK_REGEXP);
        const blankProp = blank ? { target: '_blank', rel: 'noopener noreferrer' } : {};
        return (
          <a key="link" href={filterXSS(link)} {...blankProp}>
            {filterXSS(text)}
          </a>
        );
      },
    }),
    [],
  );

  const simpleLinkTransformation = useMemo(
    () => ({
      link: (args) => {
        const [, link, text] = args[0].match(LINK_REGEXP);
        return `${filterXSS(text)}(${link})`;
      },
    }),
    [],
  );

  const transformUtilities = useMemo(
    () => ({
      strong: (args) => {
        return <strong>{filterXSS(args[0])}</strong>;
      },
      br: <br />,
    }),
    [],
  );

  const transformLinks = options.tranformLinks ? linkTransformation : simpleLinkTransformation;
  return useCallback(
    /**
     * Avoid on production to show id for custom translation if transfered to `roleSpecific`
     * return roleSpecific message if not exist try to return message without roleSuffix
     * i.e.: try `my.message.customer` if not exist, than `my.message`
     * @param msgId message id
     * @param [values] `{ roleSpecific: true }` for roleSpecific translations with `.customer`/`.bankAdvisor` suffix
     * @param {{defaultMessage?: string | undefined}} [opts] for custom default message
     * @returns {string}
     */
    (msgId, values = {}, opts = { defaultMessage: undefined }) => {
      if (!msgId) {
        return msgId;
      }
      const getMessageFor = (id) =>
        formatMessage(
          { id, defaultMessage: opts.defaultMessage },
          { ...transformLinks, ...transformUtilities, ...values },
        );

      const roleId = `${prefixWithDot}${values.roleSpecific ? msgId.concat(roleSuffix) : msgId}`;
      const id = `${prefixWithDot}${msgId}`;

      // try get roleSpecific message if roleSpecific flag
      const roleMessage = values.roleSpecific ? getMessageFor(roleId) : roleId;

      // fallback to message without suffix for roleSpecific if not exists
      return roleMessage === roleId ? getMessageFor(id) : roleMessage;
    },
    [formatMessage, prefixWithDot, transformLinks, transformUtilities, roleSuffix],
  );
};
