import React from 'react';

import { Flex, ModalProps } from '@chakra-ui/react';

import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';
import { StyledDescription } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/styles';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import { DzbLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { useDeleteLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/legalRepresentatives/deleteLegalRepresentative';
import ButtonComponent from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  legalRep: DzbLegalRepresentative | undefined;
};

const DzbDeleteLegalRepresentativeModal = ({ isOpen, onClose, legalRep }: Props) => {
  const { mutateAsync, isLoading } = useDeleteLegalRepresentative(legalRep?.id as string);
  const { refetchInquiry } = useFetchInquiryData();

  const t = useTranslations();
  const { actions } = translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives;

  const onSubmit = async () => {
    await mutateAsync();
    await refetchInquiry();
    onClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t(actions.delete.title)}
      footer={
        <Flex gap={4}>
          <ButtonComponent variant={'tertiary'} onClick={onClose} testId="cancelButton">
            {t(actions.delete.cancel)}
          </ButtonComponent>
          <ButtonComponent
            variant={'primary'}
            type="submit"
            onClick={onSubmit}
            isLoading={isLoading}
            testId="confirmButton"
          >
            {t(actions.delete.confirm)}
          </ButtonComponent>
        </Flex>
      }
    >
      <StyledDescription>
        {t(actions.delete.caption, {
          firstName: legalRep?.firstName,
          lastName: legalRep?.lastName,
        })}
      </StyledDescription>
    </ModalComponent>
  );
};

export default withAssociatedPeopleEditMode(DzbDeleteLegalRepresentativeModal);
