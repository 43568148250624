import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { useFormConfig } from 'config/formConfig/hooks';
import useInquiryReset from 'modules/InquiryFlowReset/useInquiryReset';
import { startUrlForInquiryType } from 'store/config/reducer';
import { getInquiryFlowStartUrl } from 'store/config/selectors';

const InquiryNew = ({ location }) => {
  const { isReset } = useInquiryReset();
  const { selectedInquiryType } = useFormConfig();
  const startRoute = useSelector(getInquiryFlowStartUrl);
  // Also redirecting traffic of inquiry types which are blocked for new inquiries to financial need page so they will land on the defined substitute component
  const startUrl = startUrlForInquiryType[selectedInquiryType] || startRoute;
  return isReset ? <Redirect to={{ ...location, pathname: startUrl }} /> : null;
};

InquiryNew.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};

export default InquiryNew;
