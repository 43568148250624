import * as React from 'react';

import {
  Box,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';

import { InfoButton } from 'components/InfoButton/InfoButton';
import { Signee } from 'models/types/Signee.type';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { HeadingComponent } from 'theme/components/Heading';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { SignContractStepSignee } from './SignContractStepSignee';

interface SignContractStepProps {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  data: Signee[];
  isReadonly?: boolean;
}

interface DescriptionWithListProps {
  text: string;
  item1: string;
  item2: string;
  t: Function;
}

const DescriptionWithList = ({ text, item1, item2, t }: DescriptionWithListProps) => (
  <Box color={'text.secondary'} mb={12} mt={6}>
    <TextComponent mb={3}>{t(text)}</TextComponent>
    <UnorderedList pl={3}>
      <ListItem>{t(item1)}</ListItem>
      <ListItem>{t(item2)}</ListItem>
    </UnorderedList>
  </Box>
);

export const SignContractStep = ({
  isFirst,
  isLast,
  isCompleted,
  isPending,
  completedValue,
  progressIncrement,
  progressTotal,
  isReadonly,
  id,
  data,
}: SignContractStepProps) => {
  const t = useTranslations();
  const {
    alternativeTitle,
    title: titleTranslationKey,
    description,
    pendingDescription,
    table: { columns },
    listDescription: {
      text,
      list: { item1, item2 },
    },
  } = translations.pages.inquiryDetails.dashboard.actions.signContract;
  const title = useSelectedInquiryTypeSpecificValue({
    [InquiryType.dzb]: t(alternativeTitle),
    [InquiryType.hausbank]: t(alternativeTitle),
    [InquiryType.default]: t(titleTranslationKey),
    [InquiryType.cbBank]: t(alternativeTitle),
  });
  const Description = useSelectedInquiryTypeSpecificValue({
    [InquiryType.hausbank]: <DescriptionWithList text={text} item1={item1} item2={item2} t={t} />,
    [InquiryType.dzb]: <TextComponent mb={3}>{t(description)}</TextComponent>,
    [InquiryType.default]: <TextComponent mb={3}>{t(description)}</TextComponent>,
    [InquiryType.cbBank]: <DescriptionWithList text={text} item1={item1} item2={item2} t={t} />,
  });

  const getSigneeKey = (signee: Signee) =>
    signee.id || signee.attributes.firstName + signee.attributes.lastName;

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
        {title}
      </HeadingComponent>
      {isReadonly ? <TextComponent mb={3}>{t(pendingDescription)}</TextComponent> : Description}
      {isPending ? null : (
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>{t(columns.legalRepresentative)}</Th>
                <Th>
                  <Box as="span" verticalAlign="middle">
                    {t(columns.deadline)}
                  </Box>
                  <InfoButton text={t(columns.deadlineTooltip)} boxSize={4} />
                </Th>
                <Th>
                  <Box as="span" verticalAlign="middle">
                    {t(columns.status)}
                  </Box>
                  <InfoButton text={t(columns.statusTooltip)} boxSize={4} />
                </Th>
                <Th>{t(columns.action)}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((signee) => (
                <SignContractStepSignee
                  key={getSigneeKey(signee)}
                  {...signee}
                  isReadonly={isReadonly}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </ProgressSection>
  );
};
