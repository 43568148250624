import { cbBankFields } from 'cbbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateContractLaneSection: ValidateSectionFunction<InquiryType.cbBank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap<InquiryType.cbBank>({
    validationMap: contractLaneSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { phoneNumber } = cbBankFields.offerPage.contractLaneSection;

export const contractLaneSectionValidationMap = {
  [phoneNumber]: fieldValidators.phoneNumber().isMobilePhoneNumber().required(),
};
