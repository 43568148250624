import { CbBankBeneficialOwner } from 'cbbank/inquiry/fields';
import { BeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import { formatDateDays } from 'utils/date';

export const mapBeneficialOwnerToBackend = (beneficialOwner: BeneficialOwner) => {
  return {
    ...mapCbBankBeneficialOwnerToBackend(beneficialOwner as CbBankBeneficialOwner),
    fictional: beneficialOwner.fictional,
  };
};

export const mapCbBankBeneficialOwnerToBackend = (beneficialOwner: CbBankBeneficialOwner) => {
  return {
    id: beneficialOwner.id,
    first_name: beneficialOwner.firstName,
    last_name: beneficialOwner.lastName,
    nationality: beneficialOwner.nationality || '',
    company_share: beneficialOwner.capitalOrVotingRightsPercentage || '',
    city: beneficialOwner.placeOfResidence || '',
    country: beneficialOwner.countryOfResidence || '',
    birth_place: beneficialOwner.birthPlace || '',
    birth_date: beneficialOwner.birthDate ? formatDateDays(beneficialOwner.birthDate) : null,
  };
};
