import React, { useEffect } from 'react';

import { Grid, Box } from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { cbBankFields } from 'cbbank/inquiry/fields';
import { Rate, ValueBox } from 'components/SMSVerification/styles';
import { NewStaticFieldWithField } from 'components/StaticField';
import { translations } from 'new/form/common/types';
import { getCreatedInquiryId } from 'store/progress/selectors';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal, formatPrice } from 'utils/valueFormats';

import { useInstalmentHandler } from '../../hooks/useInstalmentHandler';
import { useCalculateIndicativeConditions } from '../../hooks/useRequestIndicativeConditions';

const { factoringFee, factoringLine, payoutRatio } = cbBankFields.offerPage.contractLaneSection;

export const ShowCondition = () => {
  const t = useTranslations();
  const { change } = useForm();
  const conditionsT =
    translations.inquiryType.cbBank.pages.offer.lanes.contractLane.indicativeConditions;
  const { getInstalmentAccess } = useInstalmentHandler();
  const inquiryId = useSelector(getCreatedInquiryId);
  const instalmentAccess = getInstalmentAccess();
  const { data: calculations } = useCalculateIndicativeConditions({
    variables: {
      inquiryId,
      accessToken: instalmentAccess.accessToken,
    },
    enabled: !!inquiryId && !!instalmentAccess.accessToken,
  });

  useEffect(() => {
    if (calculations) {
      change(factoringLine, calculations?.attributes.factoring_line);
      change(factoringFee, calculations?.attributes.factoring_fee);
      change(payoutRatio, calculations?.attributes.payout_ratio);
    }
  }, [
    calculations,
    calculations?.attributes.factoring_fee,
    calculations?.attributes.factoring_line,
    calculations?.attributes.payout_ratio,
    change,
  ]);

  return (
    <Box mt={'1.5rem'} mb={'4rem'} p={'1.5rem 2rem'} boxShadow="default">
      <Grid templateColumns="1fr 1fr 1fr" gap="1rem" autoFlow="column">
        <ValueBox>
          <Rate>
            <PopoverComponent
              ml={1}
              transform="translateY(-1px)"
              text={t(conditionsT.factoringFee.tooltip)}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={5} display="block" />}
                  label={t(translations.buttons.moreInfos)}
                />
              }
            />
            <NewStaticFieldWithField
              name={factoringFee}
              caption={t(conditionsT.factoringFee.caption)}
              parseValue={(value: number) =>
                formatDecimal(value, { digits: 1, unit: '%', locale: 'de' })
              }
              dataTestId="factoringFeeWrapper"
              nowrap
            />
          </Rate>
        </ValueBox>
        <ValueBox>
          <Rate>
            <PopoverComponent
              ml={1}
              transform="translateY(-1px)"
              text={t(conditionsT.factoringLine.tooltip)}
              trigger={
                <IconButtonComponent
                  icon={<InfoIcon boxSize={5} display="block" />}
                  label={t(translations.buttons.moreInfos)}
                />
              }
            />
            <NewStaticFieldWithField
              name={factoringLine}
              caption={t(conditionsT.factoringLine.caption)}
              parseValue={(value: number) => formatPrice(value, true, 0)}
              dataTestId="factoringLineWrapper"
            />
          </Rate>
        </ValueBox>
        <ValueBox>
          <PopoverComponent
            ml={1}
            transform="translateY(-1px)"
            text={t(conditionsT.payoutRate.tooltip)}
            trigger={
              <IconButtonComponent
                icon={<InfoIcon boxSize={5} display="block" />}
                label={t(translations.buttons.moreInfos)}
              />
            }
          />
          <Rate>
            <NewStaticFieldWithField
              name={payoutRatio}
              caption={t(conditionsT.payoutRate.caption)}
              parseValue={(value: number) =>
                formatDecimal(Number(value), { digits: 0, unit: '%', locale: 'de' })
              }
              dataTestId="payoutRatioWrapper"
            />
          </Rate>
        </ValueBox>
      </Grid>
    </Box>
  );
};
