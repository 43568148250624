import React, { useState, createRef, useEffect } from 'react';

import { Box, Heading } from '@chakra-ui/react';

import { cbBankFields } from 'cbbank/inquiry/fields';
import { FieldWrapper } from 'components/ControlWrapper';
import {
  PinEntryWrapper,
  PinEntryGroup,
  TwoSectionPinEntry,
} from 'components/SMSVerification/styles';
import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { ButtonComponent } from 'theme/components/Button';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import withFormField from 'utils/form/withFormField';
import { useTranslations } from 'utils/hooks/useTranslations';

interface GetCodeNumberProps {
  onSubmit: any;
  phoneNumber: string;
  submitRetry: any;
  pending?: boolean;
}

const PinEntry = withFormField(TwoSectionPinEntry);

export const GetCodeNumber = ({
  onSubmit,
  phoneNumber,
  submitRetry,
  pending,
}: GetCodeNumberProps) => {
  const t = useTranslations();
  const [pin, setPin] = useState(false);
  const pinRef = createRef<HTMLInputElement[]>();
  const { pinNumber } = cbBankFields.offerPage.contractLaneSection;

  useEffect(() => {
    // Focus first input
    if (pinRef.current) {
      pinRef.current[0].focus();
    }

    // Set pin (ready to check) if every input contain number
    if (pinRef.current && pinRef.current.every((v: HTMLInputElement) => v.value)) {
      setPin(true);
    } else {
      setPin(false);
    }
  }, [pin, pinRef]);

  const cleanCode = () => {
    if (pinRef.current) {
      pinRef.current.forEach((input: HTMLInputElement) => {
        input.value = ''; // eslint-disable-line no-param-reassign
      });
    }
  };

  const submitPin = (code: string) => {
    if (code.length === 6) {
      setPin(true);
      onSubmit(code);
    }
    cleanCode();
  };

  const retryPin = () => {
    cleanCode();
    submitRetry();
  };

  return (
    <>
      <Heading
        as="h3"
        mb="1.5rem"
        fontSize="1.33rem"
        fontWeight={FONT_WEIGHT_MEDIUM}
        color={'text.primary'}
        marginBottom={20}
      >
        {t('components.smsVerification.header2', { phoneNumber })}
      </Heading>
      <FieldWrapper>
        <PinEntryWrapper>
          <PinEntryGroup>
            <PinEntry
              name={pinNumber}
              ref={pinRef}
              length={6}
              validate={/^[0-9]$/}
              onComplete={submitPin}
              data-testId="pinNumberInput"
            />
          </PinEntryGroup>
          <ButtonComponent disabled={!pin} isLoading={pending} onClick={onSubmit} type="submit">
            {t('components.smsVerification.fields.submitCondtion')}
          </ButtonComponent>
        </PinEntryWrapper>
      </FieldWrapper>
      <Box display="flex" justifyContent="flex-end" marginTop={5}>
        <ButtonComponent
          leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
          type="button"
          variant={'tertiary'}
          onClick={retryPin}
        >
          {t('components.smsVerification.fields.submitCodeRetry')}
        </ButtonComponent>
      </Box>
    </>
  );
};
