import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _values from 'lodash/values';

import { IBankOffer } from 'models/BankOffer.model';
import { CompeonOfferProductType, ICompeonOffer } from 'models/CompeonOffer.model';
import { IInternalFile } from 'models/File.model';
import {
  ICustomerDataFields,
  IIndicativeOffer,
  IUserProfile,
} from 'models/InquiryDetails/DefaultInquiryDetails.model';
import {
  ITransparencyRegister,
  ITransparencyRegisterAttributes,
  ITransparencyRegisterStatus,
} from 'models/types/TransparencyRegister.types';
import {
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_GENDER__MAN,
  COMPANY_DETAILS_GENDER__WOMAN,
  COMPANY_DETAILS_LAST_NAME,
  CUSTOMER_BIRTH_DATE,
  CUSTOMER_COMPANY_ADDRESS,
  CUSTOMER_COMPANY_NAME,
  CUSTOMER_EMAIL,
  CUSTOMER_FIRST_NAME,
  CUSTOMER_LAST_NAME,
  CUSTOMER_PHONE_NUMBER,
  CUSTOMER_SALUTATION,
  GENDER_MAN,
  HIRE_PURCHASE_TERM_IN_MONTHS,
  LEASING_TERM_IN_MONTHS,
  LOAN_TERM,
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_INSTALLMENT_RATE,
  OFFER_MONTHLY_INSTALLMENT,
  OFFER_PAYOUT_RATIO,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__LEASING,
  PRODUCT_KIND__LOAN,
  USER_PROFILE_BIRTHDATE,
  USER_PROFILE_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { COMPEON_OFFER_PROPS } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/getPropertiesForProduct';
import { COMPEON_OFFER_PRODUCT_NAME } from 'modules/Offers/InquiryOffersSection/CompeonOffers/withCompeonOffers/productNames.contants';
import {
  KycCompaniesAttributes,
  LegalRepresentativeAttributes,
  VideoIdentificationAttributes,
} from 'pages/operationPortal/InquiryDetails/Dashboard/LegalRepresentativeCorrection/types';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { formatDateDays } from 'utils/date';
import {
  replaceDotWithComma,
  formatPrice,
  formatPercentage,
  formatPriceForFactoringLine,
} from 'utils/valueFormats';

import { InquiryType } from '../Inquiry.type';

export const getInquiry = (normalizedResponse: any) => {
  return _values(normalizedResponse.inquiries)[0];
};

export const getLegalRepresentatives = (normalizedResponse: {
  legal_representatives: { id: string; attributes: LegalRepresentativeAttributes }[];
}) => {
  return _values(normalizedResponse.legal_representatives).map(({ id, attributes }) => ({
    id,
    firstName: attributes.first_name,
    lastName: attributes.last_name,
    email: attributes.email,
    birthDate: attributes.birth_date,
    salutation: attributes.salutation,
    phoneNumber: attributes.phone_number,
  }));
};

export const getKycCompanies = (normalizedResponse: {
  kyc_companies: { id: string; attributes: KycCompaniesAttributes }[];
}) => {
  return _values(normalizedResponse.kyc_companies).map(({ id, attributes }) => ({
    id,
    city: attributes.city,
    kyc_company_id: attributes.kyc_company_id,
    name: attributes.name,
    street: attributes.street,
    zip_code: attributes.zip_code,
  }));
};

export const getCompany = (normalizedResponse: any) => {
  return _values(normalizedResponse.companies)[0];
};
export const getCustomerForInvitation = (normalizedResponse: any) => {
  return _values(normalizedResponse.customers)[0];
};

export const getCustomer = (normalizedResponse: any) => {
  const id = _get(getInquiry(normalizedResponse), 'relationships.customer.data.id');
  return id ? normalizedResponse.customers[id] : null;
};

const getVideoIdentification = (normalizedResponse: any) => {
  const id = _get(getInquiry(normalizedResponse), 'relationships.video_identification.data.id');

  return id ? normalizedResponse.video_identifications[id] : null;
};

export const getVideoIdentificationsDetail = (normalizedResponse: {
  video_identifications: { id: string; attributes: VideoIdentificationAttributes }[];
}) => {
  return _values(normalizedResponse.video_identifications).map(({ id, attributes }) => ({
    id,
    signedContractUrl: attributes.signed_contract_url,
    success: attributes.success,
  }));
};

export const getEndCustomer = (normalizedResponse: any) => {
  const inquiry = getInquiry(normalizedResponse);
  const id = _get(inquiry, 'relationships.end_customer.data.id');
  if (id) {
    return normalizedResponse.customers[id];
  }

  return _get(inquiry, 'attributes.details.end-customer-attributes');
};

export const mapCustomer = (normalizedResponse: any) => {
  const { id, attributes } = getCustomer(normalizedResponse);
  return {
    id,
    existsInCompeon: !!attributes.exists_in_compeon,
    firstName: attributes.first_name,
    lastName: attributes.last_name,
    phoneNumber: attributes.phone_number,
  };
};

export const mapVideoIdentification = (normalizedResponse: any): IInternalFile | undefined => {
  const videoIdentification = getVideoIdentification(normalizedResponse);
  if (!videoIdentification) {
    return undefined;
  }

  const { type, attributes } = videoIdentification;
  const url = attributes.signed_contract_url;
  const fileName = url && url.split('/').pop();
  return {
    id: type,
    fileName,
    downloadUrl: url,
    contentType: 'application/pdf',
  };
};

export const mapUserProfile = (normalizedResponse: any, type: InquiryType): IUserProfile => {
  const customer = getCustomer(normalizedResponse);
  const company = getCompany(normalizedResponse);
  const result: IUserProfile = {
    [COMPANY_DETAILS_GENDER]:
      customer.attributes.salutation === GENDER_MAN
        ? COMPANY_DETAILS_GENDER__MAN
        : COMPANY_DETAILS_GENDER__WOMAN,
    [COMPANY_DETAILS_FIRST_NAME]: customer.attributes.first_name,
    [COMPANY_DETAILS_LAST_NAME]: customer.attributes.last_name,
    [COMPANY_DETAILS_EMAIL]: customer.attributes.email,
    [COMPANY_DETAILS_COMPANY]: _get(company, 'attributes.name'),
    [USER_PROFILE_PHONE_NUMBER]: _get(customer, 'attributes.phone_number'),
    [USER_PROFILE_BIRTHDATE]: formatDateAndNull(customer.attributes.birth_date),
  };

  const typeReturn: {
    [keys in InquiryType]?: IUserProfile;
  } = {
    [InquiryType.bfsService]: {
      ...result,
      [CUSTOMER_BIRTH_DATE]: formatDateAndNull(customer.attributes.birth_date),
    },
    default: result,
  };
  return typeReturn[type] || typeReturn.default!;
};

export const mapCustomerData = (normalizedResponse: any): ICustomerDataFields => {
  const customer = getCustomer(normalizedResponse);
  const company = getCompany(normalizedResponse);
  return {
    [CUSTOMER_COMPANY_NAME]: company.attributes.name,
    [CUSTOMER_COMPANY_ADDRESS]: `${company.attributes.street} \n ${company.attributes.zip_code} ${company.attributes.city}`,
    [CUSTOMER_SALUTATION]: customer.attributes.salutation,
    [CUSTOMER_FIRST_NAME]: customer.attributes.first_name,
    [CUSTOMER_LAST_NAME]: customer.attributes.last_name,
    [CUSTOMER_EMAIL]: customer.attributes.email,
    [CUSTOMER_PHONE_NUMBER]: customer.attributes.phone_number,
    [CUSTOMER_BIRTH_DATE]: customer.attributes.birth_date,
  };
};

export const filterObjectWithKeys = (obj: any, keys: string[]) => {
  return _pickBy(obj, (_, key) => keys.includes(key));
};

export const parseDate = (inquiry: any, dateFieldKey: string[]) => {
  // using array path as accessor to allow nested keys
  const date = _get(inquiry, ['attributes', ...dateFieldKey]);
  return date && new Date(date);
};

export const mapCompany = (normalizedResponse: any) => {
  const company = getCompany(normalizedResponse);
  return {
    id: _get(company, 'id'),
    name: _get(company, 'attributes.name'),
    compeonId: _get(company, 'attributes.compeon_id'),
    street: _get(company, 'attributes.street'),
    city: _get(company, 'attributes.city'),
    zip_code: _get(company, 'attributes.zip_code'),
  };
};

export const mapAssociatedCompany = (normalizedResponse: any) => {
  const inquiry = getInquiry(normalizedResponse);
  return {
    contactPerson: _get(inquiry, 'attributes.details.association.contact-person'),
    externalId: _get(inquiry, 'attributes.details.association.external-id'),
    name: _get(inquiry, 'attributes.details.association.name'),
  };
};

export const mapBankOffers = (normalizedResponse: any): IBankOffer[] => {
  const offersArray = _values(normalizedResponse.offers);
  const bankOffers = offersArray.filter(
    (offer) => offer.relationships.offer_data.data.type === 'bank_offers',
  );

  return _values(bankOffers).map((offer) => {
    const { attributes, relationships } =
      normalizedResponse.bank_offers[offer.relationships.offer_data.data.id];
    return {
      id: offer.id,
      type: 'BankOffer',
      bankName: attributes.bank_name,
      fileId: _get(relationships, 'uploaded_file.data.id'),
      createdAt: offer.attributes.created_at,
      status: offer.attributes.status,
    };
  });
};

type Product =
  | typeof PRODUCT_KIND__LOAN
  | typeof PRODUCT_KIND__LEASING
  | typeof PRODUCT_KIND__HIRE_PURCHASE;

type Term = typeof LOAN_TERM | typeof LEASING_TERM_IN_MONTHS | typeof HIRE_PURCHASE_TERM_IN_MONTHS;

const TERMS_IN_MONTHS: Record<Product, Term> = {
  [PRODUCT_KIND__LOAN]: LOAN_TERM,
  [PRODUCT_KIND__LEASING]: LEASING_TERM_IN_MONTHS,
  [PRODUCT_KIND__HIRE_PURCHASE]: HIRE_PURCHASE_TERM_IN_MONTHS,
};

const isProduct = (item: string): item is Product => {
  return [PRODUCT_KIND__LOAN, PRODUCT_KIND__LEASING, PRODUCT_KIND__HIRE_PURCHASE].includes(item);
};

export const mapTransparencyRegister = (normalizedResponse: {
  [key: string]: {
    transparency_register?: {
      attributes: ITransparencyRegisterAttributes;
      id: string;
      type: string;
    };
  };
}): ITransparencyRegister | undefined => {
  const [transparencyRegister] = _values(normalizedResponse.transparency_register);
  const multiResults =
    transparencyRegister?.attributes.status === ITransparencyRegisterStatus.MULTIPLE_SEARCH_RESULTS;

  const results = multiResults && {
    searchResults: transparencyRegister?.attributes.search_results?.map((result: any) => ({
      info: result.info,
      orderId: result.order_id,
    })),
  };

  return (
    transparencyRegister && {
      status: transparencyRegister.attributes.status,
      selectedOrderId: transparencyRegister.attributes.selected_order_id,
      inconsistencyFoundAt: transparencyRegister.attributes.inconsistency_found_at,
      ...results,
    }
  );
};

export const mapCompeonOffers = (normalizedResponse: any): ICompeonOffer[] => {
  const inquiry = getInquiry(normalizedResponse);
  const offersArray = _values(normalizedResponse.offers);
  const compeonOffers = offersArray.filter(
    (offer) => offer.relationships.offer_data.data.type === 'compeon_offers',
  );

  const translateOfferType = (apiOfferType: string) => {
    const translations = {
      FinancingOffer: COMPEON_OFFER_PRODUCT_NAME.LOAN,
      LeasingOffer: COMPEON_OFFER_PRODUCT_NAME.LEASING,
      HirePurchaseOffer: COMPEON_OFFER_PRODUCT_NAME.HIRE_PURCHASE,
      AlternativeFinancingOffer: COMPEON_OFFER_PRODUCT_NAME.OTHER,
      FactoringOffer: COMPEON_OFFER_PRODUCT_NAME.FACTORING,
      InvestmentLoanOffer: COMPEON_OFFER_PRODUCT_NAME.INVESTMENT_LOAN,
      MezzanineOffer: COMPEON_OFFER_PRODUCT_NAME.MEZZANINE,
      ProjectFinancingOffer: COMPEON_OFFER_PRODUCT_NAME.PROJECT_FINANCING,
      OverdraftOffer: COMPEON_OFFER_PRODUCT_NAME.OVERDRAFT,
    };
    return (
      (translations[apiOfferType as keyof typeof translations] as CompeonOfferProductType) ||
      COMPEON_OFFER_PRODUCT_NAME.OTHER
    );
  };

  return compeonOffers.map(({ relationships: offerRelations, id, attributes: offerAttributes }) => {
    const { id: compeonOfferId } = offerRelations.offer_data.data;
    const { attributes, relationships } = normalizedResponse.compeon_offers[compeonOfferId];
    const termInMonths = isProduct(inquiry.attributes.details['product-kind'])
      ? TERMS_IN_MONTHS[inquiry.attributes.details['product-kind']]
      : 0;

    return {
      id,
      type: 'CompeonOffer',
      status: offerAttributes.status,
      questionnaireIds: attributes.compeon_questionnaire_id,
      bankName: attributes.bank_name,
      productName: translateOfferType(attributes.offer_type),
      fulfilled: attributes.fulfilled,
      fulfilledAt: attributes.fulfilled_at,
      transactionAmount: attributes.transaction_amount,
      won: attributes.won,
      fileId: _get(relationships, 'uploaded_file.data.id'),
      requiredFiles: attributes.files_required_by_questionnaire || [],
      filesRequiredByBank: attributes.files_required_by_bank || [],
      fromPremiumPartner: attributes.from_premium_partner || false,
      fromSpecialPartner: attributes.from_special_partner || false,
      hasShownInterest: !!attributes.customer_signaled_interest_at,
      createdAt: attributes.created_at,
      state: attributes.state,
      [COMPEON_OFFER_PROPS.value]: attributes.value,
      [COMPEON_OFFER_PROPS.indicativeMonthlyRate]: attributes.indicative_monthly_rate,
      [COMPEON_OFFER_PROPS.termInMonths]:
        attributes.term_in_months ?? inquiry.attributes.details[termInMonths],
      [COMPEON_OFFER_PROPS.amount]: attributes.amount ?? inquiry.attributes.details.amount,
      [COMPEON_OFFER_PROPS.advancePayment]: attributes.advance_payment,
      [COMPEON_OFFER_PROPS.amortisationType]: attributes.amortisation_type,
      [COMPEON_OFFER_PROPS.considerSubsidies]: attributes.considered_subsidies,
      [COMPEON_OFFER_PROPS.fulfilledFactoringVolume]: attributes.fulfilled_factoring_volume,
      [COMPEON_OFFER_PROPS.leasingFactor]: attributes.leasing_factor,
      [COMPEON_OFFER_PROPS.prerentalCalculated]: attributes.prerental_calculated,
      [COMPEON_OFFER_PROPS.processingFee]: attributes.processing_fee,
      [COMPEON_OFFER_PROPS.residualValue]: attributes.residual_value,
      [COMPEON_OFFER_PROPS.loanGuarantee]: attributes.loan_guarantee,
    };
  });
};

interface Offer {
  attributes: Partial<{
    ['factoring_fee']: string;
    ['factoring_line']: number;
    ['instalment_rate']: string;
    ['monthly_instalment']: string;
    ['payout_ratio']: number;
  }>;
}

export const getIndicativeConditions = (normalizedResponse: any): Partial<IIndicativeOffer> => {
  const { indicative_conditions: indicativeConditions } = normalizedResponse;
  const [indicativeOffer] = Object.values(indicativeConditions) as Offer[];
  const fee = Number(indicativeOffer.attributes.factoring_fee);
  const line = Number(indicativeOffer.attributes.factoring_line);
  const instalment = Number(indicativeOffer.attributes.monthly_instalment);
  const payoutRatio = Number(indicativeOffer.attributes.payout_ratio);

  const lineByType = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: formatPriceForFactoringLine(line, false),
    [InquiryType.bfsService]: line?.toString(),
  });

  const indicativeCondition = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.default]: {
      [OFFER_FACTORING_FEE]: formatPercentage(fee).toString(),
      [OFFER_FACTORING_LINE]: lineByType,
      [OFFER_INSTALLMENT_RATE]: indicativeOffer.attributes.instalment_rate
        ? replaceDotWithComma(indicativeOffer.attributes.instalment_rate)
        : '',
      [OFFER_MONTHLY_INSTALLMENT]: formatPrice(instalment, false),
      // WARNING: payout_ratio should be a number, but is a string in the API response.
      // Additionally, payout_ratio is a percentage, so it has to be divided by 100
      // in order to work with the formatPercentage function (99.12 => 0.9912 => 99.12%).
      // See "[OFFER_FACTORING_FEE]: formatPercentage(fee).toString()" for reference,
      // where fee value is a decimal value (e.g. 0.0912 => 9.12%).
      [OFFER_PAYOUT_RATIO]: formatPercentage(
        Number(indicativeOffer.attributes.payout_ratio) / 100,
      ).toString(),
    },
    [InquiryType.cbBank]: {
      factoringLine: line,
      factoringFee: fee,
      payoutRatio,
    },
  });

  return indicativeCondition;
};

export const isKycDateValid = (kycRequestDate: string) => {
  if (!kycRequestDate) {
    return false;
  }

  const thisYear = new Date().toDateString();
  const requestDate = new Date(kycRequestDate).toDateString();

  const distanceInDays = Math.floor((Date.parse(thisYear) - Date.parse(requestDate)) / 86400000);

  return distanceInDays < 365;
};

export const formatDateAndNull = (date: string | Date) => {
  if (date) {
    return formatDateDays(date);
  }
  return date;
};
