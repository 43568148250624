import {
  BENEFICIARY_BIRTHDAY,
  BENEFICIARY_COMPANY_SHARE,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_PRIVATE_ADDRESS,
  BENEFICIARY_PRIVATE_CITY,
  BENEFICIARY_ZIP_CODE,
  HAS_BENEFICIARY_OWNER,
  IS_BENEFICIARY_OWNER,
  NO_BENEFICIARY_CHECKBOX,
  BENEFICIARY_PRIVATE_COUNTRY,
  BENEFICIARY_TAX_ID,
  BENEFICIARY_GENDER,
  BENEFICIARY_BIRTH_COUNTRY,
} from 'modules/Inquiry/Form/formFields';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const beneficiaryOwnerFieldValidations = {
  [HAS_BENEFICIARY_OWNER]: fieldValidators.boolean().required(),
  [IS_BENEFICIARY_OWNER]: fieldValidators.boolean().required(),
  [NO_BENEFICIARY_CHECKBOX]: fieldValidators.boolean().required(),
  [BENEFICIARY_GENDER]: fieldValidators.string().required(),
  [BENEFICIARY_FIRST_NAME]: fieldValidators
    .name()
    .isValidName()
    .required()
    .max(InquiryConstants.MAX_END_USER_FIRST_NAME),
  [BENEFICIARY_LAST_NAME]: fieldValidators
    .name()
    .isValidName()
    .required()
    .max(InquiryConstants.MAX_END_USER_LAST_NAME),
  [BENEFICIARY_COMPANY_SHARE]: fieldValidators
    .number()
    .allow(0)
    .min(InquiryConstants.MIN_BENEFICIARY_COMPANY_PERCENTAGE)
    .max(InquiryConstants.MAX_BENEFICIARY_COMPANY_PERCENTAGE)
    .required(),
  [BENEFICIARY_BIRTHDAY]: fieldValidators
    .dateFormat()
    .isDateFormat()
    .minDate(InquiryConstants.MIN_BIRTHDATE)
    .maxDate(InquiryConstants.YESTERDAY),
  [BENEFICIARY_TAX_ID]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.COMPANY_STREET_MAX_LENGTH),
  [BENEFICIARY_PRIVATE_ADDRESS]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.COMPANY_STREET_MAX_LENGTH),
  [BENEFICIARY_PRIVATE_CITY]: fieldValidators
    .string()
    .required()
    .max(InquiryConstants.COMPANY_CITY_MAX_LENGTH),
  [BENEFICIARY_ZIP_CODE]: fieldValidators.postalCode().required().isValidInternational(),
  [BENEFICIARY_PRIVATE_COUNTRY]: fieldValidators.string().required(),
  [BENEFICIARY_BIRTH_COUNTRY]: fieldValidators.string().required(),
};

export const bfsServiceBeneficiaryOwnerFieldValidations = {
  ...beneficiaryOwnerFieldValidations,
  [BENEFICIARY_COMPANY_SHARE]: fieldValidators
    .number()
    .min(InquiryConstants.MIN_BFS_SERVICE_BENEFICIARY_COMPANY_PERCENTAGE)
    .max(InquiryConstants.MAX_BENEFICIARY_COMPANY_PERCENTAGE)
    .required(),
};
