import Joi from '@hapi/joi';

import { cbBankFields } from 'cbbank/inquiry/fields';
import { legalRepresentativesSectionValidationMap as cbBankLegalRepresentativeValidations } from 'cbbank/inquiry/steps/personalData/sections/legalRepresentatives/validations';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';

import { legalRepresentativeFields } from '../fields';

const { salutation, firstName, lastName, email, birthDate, phoneNumber, soleSignatureAuthorized } =
  legalRepresentativeFields;

const cbBankFieldNames =
  cbBankFields.personalDataPage.legalRepresentativesSection.legalRepresentatives;

export const cbBankLegalRepresentativeSchema: Joi.ObjectSchema<any> = fieldValidators
  .object()
  .keys({
    [salutation]: cbBankLegalRepresentativeValidations[cbBankFieldNames.salutation],
    [firstName]: cbBankLegalRepresentativeValidations[cbBankFieldNames.firstName],
    [lastName]: cbBankLegalRepresentativeValidations[cbBankFieldNames.lastName],
    [email]: cbBankLegalRepresentativeValidations[cbBankFieldNames.email],
    [birthDate]: cbBankLegalRepresentativeValidations[cbBankFieldNames.birthDate],
    [phoneNumber]: cbBankLegalRepresentativeValidations[cbBankFieldNames.phoneNumber],
    [soleSignatureAuthorized]:
      cbBankLegalRepresentativeValidations[cbBankFieldNames.soleSignatureAuthorized],
  })
  .unknown(true)
  .strip();
