import { useIntl } from 'react-intl';

import Unit from 'components/Unit/Unit';
import { OFFER_FIELD_TYPE, StaticField } from 'modules/helpers/useOfferFields';
import { OFFER_FACTORING_LINE, OFFER_PAYOUT_RATIO } from 'modules/Inquiry/Form/formFields';
import { FIELD_UNIT_TYPE } from 'modules/Inquiry/inquiryFieldsUnits/inquiryFieldsUnitTypes';
import {
  formatPriceForFactoringLine,
  replaceDotWithComma,
  formatPercentage,
} from 'utils/valueFormats';

const MIN_DECIMAL_PLACES = 0;
const MAX_DECIMAL_PLACES = 2;

export const useGetIndicativeOfferFormattedValueMap = () => {
  const { locale } = useIntl();

  return (item: StaticField, value: any) => {
    if (typeof value === 'string') {
      value = value.replace(/\s/g, '');
    }

    if (Object.values(OFFER_FIELD_TYPE).includes(item.name as OFFER_FIELD_TYPE)) {
      const itemName = item.name as OFFER_FIELD_TYPE;
      const itemUnit = item.unit as FIELD_UNIT_TYPE;

      let localeValue = value;
      if (!isNaN(Number(value))) {
        localeValue = Intl.NumberFormat(locale, {
          minimumFractionDigits: MIN_DECIMAL_PLACES,
          maximumFractionDigits: MAX_DECIMAL_PLACES,
        }).format(value);
      }

      switch (itemName) {
        case OFFER_FIELD_TYPE.FACTORING_FEE:
        case OFFER_FIELD_TYPE.PAYOUT_RATIO:
          return `${localeValue}${Unit({ type: itemUnit })}`;
        case OFFER_FIELD_TYPE.FACTORING_LINE:
          return formatPriceForFactoringLine(Number(value));
        default:
          return value;
      }
    }
    switch (item.name) {
      case OFFER_FACTORING_LINE:
        return `${formatPriceForFactoringLine(parseFloat(value))}`;
      case OFFER_PAYOUT_RATIO:
        // see /src/modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers.ts for reference
        return `${replaceDotWithComma(formatPercentage(Number(value) / 100).toString())}${
          item.unit
        }`;
      default:
        return `${replaceDotWithComma(value)}${item.unit}`;
    }
  };
};
