import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import {
  combineWithOptionalValidators,
  containsLowerCase,
  containsNonAlphaNumeric,
  containsNumericChar,
  containsUpperCase,
  hasMinimalLength,
} from 'utils/validators';

import { useTranslations } from './useTranslations';

const {
  passwordMinLength,
  passwordMustHaveLowerCase,
  passwordMustHaveNonAlphanumeric,
  passwordMustHaveNumber,
  passwordMustHaveUpperCase,
} = translations.errors;

const createStrictPasswordValidator = (
  t: ReturnType<typeof useTranslations>,
  { required }: ReturnType<typeof useFieldValidators>,
) =>
  combineWithOptionalValidators(
    [required, hasMinimalLength(t(passwordMinLength, { number: 10 }), 10)],
    [
      containsLowerCase(t(passwordMustHaveLowerCase)),
      containsUpperCase(t(passwordMustHaveUpperCase)),
      containsNumericChar(t(passwordMustHaveNumber)),
      containsNonAlphaNumeric(t(passwordMustHaveNonAlphanumeric)),
    ],
    1,
  );

export const usePartnerSpecificPasswordValidators = () => {
  const t = useTranslations();
  const fieldValidators = useFieldValidators();
  const { required, isValidPassword } = fieldValidators;

  const strictPasswordValidator = createStrictPasswordValidator(t, fieldValidators);

  return useSelectedInquiryTypeSpecificValue({
    [InquiryType.mmv]: strictPasswordValidator,
    [InquiryType.hausbank]: strictPasswordValidator,
    [InquiryType.cbBank]: strictPasswordValidator,
    [InquiryType.default]: combineWithOptionalValidators([required, isValidPassword]),
  });
};
