import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { IMessageEvent } from 'websocket';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import DetailedInquiryInformation from 'modules/InquiryDetails/DetailedInformation/DetailedInquiryInformation';
import OnlineFactoringDetailedInquiryInformation from 'modules/InquiryDetails/DetailedInformation/OnlineFactoringDetailedInquiryInformation';
import { DzbDetailedInquiryInformation } from 'pages/customerPortal/InquiryDetails/DetailedInformation/dzb';
import { Channel, useWebSocket } from 'shared/useWebsocket';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

import { BfsServiceDetailedInquiryInformation } from './bfsService';
import { CbBankDetailedInquiryInformation } from './cbBank';
import { HausbankDetailedInquiryInformation } from './hausbank';
import { MittweidaDetailedInquiryInformation } from './mittweida';
import { MmvDetailedInquiryInformation } from './mmv';

const InquirySpecificDisplayCondition = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();

  useWebSocket({
    channel: Channel.INQUIRY,
    params: {
      inquiry_id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.type !== 'ping' && data.type !== 'welcome' && data.message) {
        dispatch(mapInquiryDetailsApiResponseAction(data.message));
      }
    },
  });

  return (
    <InquiryTypeCondition
      portal
      cases={{
        [InquiryType.onlinefactoring]: <OnlineFactoringDetailedInquiryInformation />,
        [InquiryType.profiMittweida]: (
          <MittweidaDetailedInquiryInformation
            pathToEditInquiry={paths.customer.inquiryDetails.edit.root}
          />
        ),
        [InquiryType.hausbank]: <HausbankDetailedInquiryInformation />,
        [InquiryType.dzb]: <DzbDetailedInquiryInformation />,
        [InquiryType.mmv]: <MmvDetailedInquiryInformation />,
        [InquiryType.bfsService]: (
          <BfsServiceDetailedInquiryInformation
            pathToEditInquiry={paths.customer.inquiryDetails.edit.root}
          />
        ),
        [InquiryType.cbBank]: <CbBankDetailedInquiryInformation />,
        default: (
          <DetailedInquiryInformation pathToEditInquiry={paths.customer.inquiryDetails.edit.root} />
        ),
      }}
    />
  );
};

export default InquirySpecificDisplayCondition;
