import { HausbankLegalRepresentative } from 'hausbank/inquiry/fields';

export function mapHausbankLegalRepresentatives(
  legalRepresentatives: HausbankLegalRepresentative[],
) {
  return legalRepresentatives.map((legalRep) => ({
    salutation: legalRep.salutation,
    first_name: legalRep.firstName,
    last_name: legalRep.lastName,
    email: legalRep.email,
    phone_number: legalRep.phoneNumber,
    birth_date: legalRep.birthDate,
    sole_signature_authorized: legalRep.soleSignatureAuthorized,
  }));
}
