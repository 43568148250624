import { DayPickerWithField } from 'components/DatePicker';
import { GenderWithField } from 'components/GenderButtons/GenderButtons';
import { InputWithField } from 'components/Input';
import { PhoneNumberField } from 'components/PhoneNumberField';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { translations } from 'new/form/common/types';
import {
  FieldConfig,
  useGenerateField,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/formFields/useGenerateField';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';

import {
  LegalRepresentativeFields,
  LegalRepresentativeFieldsWithoutId,
} from './useGenerateLegalRepresentativeFields';

const { salutation, firstName, lastName, birthDate, email, phoneNumber, soleSignatureAuthorized } =
  translations.inquiryType.cbBank.pages.personalData.sections.legalRepresentatives.fields
    .legalRepresentative.fields;

export const cbBankLegalRepresentativeFieldConfig: FieldConfig<LegalRepresentativeFieldsWithoutId> =
  {
    salutation: {
      component: GenderWithField,
      caption: salutation.caption,
    },
    firstName: {
      component: InputWithField,
      caption: firstName.caption,
    },
    lastName: {
      component: InputWithField,
      caption: lastName.caption,
    },
    birthDate: {
      componentProps: () => ({
        maxDate: Date.now(),
      }),
      component: DayPickerWithField,
      caption: birthDate.caption,
    },
    email: {
      component: InputWithField,
      caption: email.caption,
    },
    phoneNumber: {
      component: PhoneNumberField,
      caption: phoneNumber.caption,
    },
    soleSignatureAuthorized: {
      component: YesNoRadioGroupWithField,
      caption: soleSignatureAuthorized.inquiryCaption,
    },
  };

export function useGenerateCbBankLegalRepresentativeFields(
  mode: AssociatedPersonMode,
  prefix?: string,
): LegalRepresentativeFields {
  const generateField = useGenerateField<LegalRepresentativeFieldsWithoutId>(
    cbBankLegalRepresentativeFieldConfig,
    mode,
    prefix,
  );

  return {
    salutation: generateField('salutation'),
    firstName: generateField('firstName'),
    lastName: generateField('lastName'),
    birthDate: generateField('birthDate'),
    email: generateField('email'),
    phoneNumber: generateField('phoneNumber'),
    soleSignatureAuthorized: generateField('soleSignatureAuthorized'),
  };
}
