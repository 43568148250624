import React from 'react';

import { cbBankFields } from 'cbbank/inquiry/fields';
import FormRow from 'components/FormRow';
import { INPUT_TYPE_NUMBER, InputWithField } from 'components/Input';

const {
  companyDataPage: {
    companyDetailsCompletionSection: { name, street, zipCode, city },
  },
} = cbBankFields;

const CbBankFillCompanyInformation = () => {
  return (
    <>
      <FormRow>
        <InputWithField name={name} />
      </FormRow>
      <FormRow>
        <InputWithField name={street} />
      </FormRow>
      <FormRow>
        <InputWithField name={zipCode} type={INPUT_TYPE_NUMBER} separateThousands={false} />
        <InputWithField name={city} />
      </FormRow>
    </>
  );
};

export default CbBankFillCompanyInformation;
