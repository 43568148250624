import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryProcessConfig } from 'new/form/model';

import { hausbankConditions } from './conditions';
import { hausbankInitialFormState } from './initialFormState';
import { hausbankInitialFormValues } from './initialFormValues';
import { hausbankRouteToPageNameMap } from './routeToPageNameMap';
import { hausbankStepData } from './stepData';
import { hausbankStepSequence } from './stepSequence';
import { hausbankSummaryFields } from './summaryFields';
import { hausbankSummaryPath } from './summaryPath';
import { hausbankValidations } from './validations';
import { HAUSBANK_FORM } from '..';
import { hausbankFields } from '../fields';

export const hausbankInquiryProcessConfig: InquiryProcessConfig<InquiryType.hausbank> = {
  validations: hausbankValidations,
  fields: hausbankFields,
  summaryFields: hausbankSummaryFields,
  summaryPath: hausbankSummaryPath,
  stepData: hausbankStepData,
  stepSequence: hausbankStepSequence,
  routeToPageNameMap: hausbankRouteToPageNameMap,
  initialFormState: hausbankInitialFormState,
  initialFormValues: hausbankInitialFormValues,
  conditions: hausbankConditions,
  formName: HAUSBANK_FORM,
  paths: {
    editPaths: {
      editCustomer: hausbankRouteToPageNameMap.objectInformationPage.editCustomer,
    },
    listPaths: {
      editCustomer: paths.customer.inquiryList,
      editOperation: paths.operation.inquiryList,
    },
  },
};
