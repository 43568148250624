import { useState } from 'react';

import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import {
  FileDownloadMutationResult,
  FileDownloadMutationVariables,
  useAssessmentFileDownload,
  useFileDownload,
  usePrivateFileDownload,
} from 'api/CompeonReverseApi/operation/queryHooks/files';
import { FileType } from 'models/File.model';
import { downloadFileFromAxiosResponse } from 'utils/file';

import {
  UseObjectConfirmationFilledDocumentMutationVariables,
  useObjectConfirmationFilledDocumentMutation,
} from '../../api/CompeonReverseApi/operation/queryHooks/inquiriesSpecializedMmv';
import { translations } from '../../new/form/common/types';
import { useToasts } from '../../shared/hooks/useToasts';
import { useTranslations } from '../../utils/hooks/useTranslations';

export type FileTypeMap = Record<
  FileType,
  | UseMutateFunction<FileDownloadMutationResult, unknown, FileDownloadMutationVariables, unknown>
  | UseMutateFunction<
      AxiosResponse<Blob, any>,
      unknown,
      UseObjectConfirmationFilledDocumentMutationVariables,
      unknown
    >
>;

interface UseDownloadFileArgs {
  fileId: string;
  fileName: string;
  type?: FileType;
}

export const useDownloadFiles = (files: UseDownloadFileArgs[]) => {
  const t = useTranslations();
  const { error: errorToast } = useToasts();

  const { mutateAsync: fileMutation } = useFileDownload();
  const { mutateAsync: asessmentMutation } = useAssessmentFileDownload();
  const { mutateAsync: privateMutation } = usePrivateFileDownload();
  const { mutateAsync: filledDocumentMutation } = useObjectConfirmationFilledDocumentMutation();

  const [isLoading, setLoading] = useState(false);

  // Returns callback for a single file
  const getCallback =
    ({ fileId, fileName, type = FileType.FILE }: UseDownloadFileArgs) =>
    // Callback which gets file download details and downloads the file
    async () => {
      // Mutation map for different file types
      // We are using mutations here to be able to set the file id and inquiry id on every call
      const fileTypeMap = {
        [FileType.FILE]: fileMutation,
        [FileType.PRIVATE_FILE]: privateMutation,
        [FileType.ASSESSMENT_FILE]: asessmentMutation,
        [FileType.MMV_OBJECT_CONFIRMATION_FILLED_DOCUMENT]: filledDocumentMutation,
      } satisfies FileTypeMap;

      setLoading(true);
      try {
        // Get file data
        const response = await fileTypeMap[type]({ fileId, inquiryId: fileId });
        if (!response.data) {
          throw new Error();
        }
        // Download file
        downloadFileFromAxiosResponse(response, { filename: fileName });
      } catch {
        errorToast({ description: t(translations.pages.errorPage.statuses.statusDefault) });
      } finally {
        setLoading(false);
      }
    };

  return {
    // Get callback for every file
    fileDownloads: files.map((file) => getCallback(file)),
    isLoading,
  };
};

export const useDownloadFile = (file: UseDownloadFileArgs) => {
  // Get the first element of the file downloads array since we want to download only one file
  const {
    fileDownloads: [downloadFile],
    ...rest
  } = useDownloadFiles([file]);
  return { downloadFile, ...rest };
};
