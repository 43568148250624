import normalize from 'json-api-normalizer';
import _get from 'lodash/get';
import _values from 'lodash/values';

import { WHITE_LABEL_API_CLIENT_NAME } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { InquiryDetailsType } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import mapInquiryDraft from 'modules/Inquiry/DraftMode/mapInquiryDraft';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { INQUIRY_STATUSES } from 'modules/Inquiry/inquiryStatuses';
import mapDefault from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi';
import { getInquiry } from 'modules/Inquiry/mapInquiryFromApi/mapInquiryFromApi.helpers';
import { getInquiryValueFormatter } from 'new/form/formatters';
import { removeNullValuesRecursively } from 'utils/removeNullValuesRecursively';

type InquiryMapperType = {
  [key in InquiryType]: {
    [key in INQUIRY_STATUSES]?: (response: any) => any;
  } & { default: (response: any) => InquiryDetailsType };
};

const INQUIRY_MAPPERS_BY_TYPE: InquiryMapperType = {
  [InquiryType.default]: {
    [INQUIRY_STATUSES.DRAFT]: mapInquiryDraft,
    default: mapDefault,
  },
  [InquiryType.profiRLL]: {
    [INQUIRY_STATUSES.DRAFT]: mapInquiryDraft,
    default: mapDefault,
  },
  [InquiryType.dzb]: {
    default: mapDefault,
  },
  [InquiryType.profiMittweida]: {
    default: mapDefault,
  },
  [InquiryType.onlinefactoring]: {
    default: mapDefault,
  },
  [InquiryType.leaseplan]: {
    default: mapDefault,
  },
  [InquiryType.bfs]: {
    default: mapDefault,
  },
  [InquiryType.bfsService]: {
    default: mapDefault,
  },
  [InquiryType.mmv]: {
    default: mapDefault,
  },
  [InquiryType.hausbank]: {
    default: mapDefault,
  },
  [InquiryType.cbBank]: {
    default: mapDefault,
  },
};

export type FeToBeMapping = { [key: string]: any } | ((beResponse: any) => any);

/**
 * Transforms an array of backend objects using the provided mapping object.
 *
 * @param {FeToBeMapping} arrayMapping - The mapping object containing keys for transforming backend objects in the array.
 * @param {FeToBeMapping[]} backendArray - The array of backend objects to be transformed.
 * @returns {FeToBeMapping[]} - An array of transformed objects.
 */
const transformArray = (
  arrayMapping: FeToBeMapping,
  backendArray: FeToBeMapping[],
): FeToBeMapping[] => {
  // Iterate through the items in the backend array, calling transformObject
  // for each item using the provided arrayMapping.
  return backendArray.map((item) => transformObject(arrayMapping, item));
};

/**
 * Transforms a backend object using the provided mapping object.
 *
 * @param {FeToBeMapping} mapping - The mapping object containing keys and nested structures for transforming the backend object.
 * @param {FeToBeMapping} backendObject - The backend object to be transformed.
 * @returns {FeToBeMapping} - A transformed object.
 */
const transformObject = (
  mapping: { [key: string]: any },
  backendObject: { [key: string]: any },
): FeToBeMapping => {
  const result: FeToBeMapping = {};

  // Iterate through the keys in the mapping object.
  for (const key in mapping) {
    // If the value for the key in the mapping object is an object and not an array.
    if (typeof mapping[key] === 'object' && !Array.isArray(mapping[key])) {
      // If the mapping object has a 'mappingKey' property, it is an array mapping.
      if ('mappingKey' in mapping[key]) {
        // Get the 'mappingKey' value and the 'keys' property from the mapping object.
        const arrayKey = mapping[key].mappingKey;
        const arrayMapping = mapping[key].keys;

        // Check if the backend object has a property matching the arrayKey and if it's an array.
        if (arrayKey in backendObject && Array.isArray(backendObject[arrayKey])) {
          // Transform the array using the 'transformArray' function.
          result[key] = transformArray(arrayMapping, backendObject[arrayKey]);
        }
      } else {
        // If it's not an array mapping, recursively call the 'transformObject' function.
        result[key] = transformObject(mapping[key], backendObject);
      }
    } else if (typeof mapping[key] === 'symbol') {
      result[key] = getInquiryValueFormatter(mapping[key])(backendObject);
    } else if (mapping[key] in backendObject) {
      // If the backend object has a property matching the mapping key.
      // Assign the value from the backend object to the result object using the key.
      let value = backendObject[mapping[key]];

      /**
       * Initial data for our form may contain values set as empty strings ("").
       * In React-Final-Form, these empty strings are treated as valid values, which can trigger
       * validations like minimum length checks. To avoid unwanted validation errors,
       * we convert empty strings to `null`. `null` values will be removed from the result in the next step anyways
       * and now React-Final-Form can correctly handle the initial values.
       */
      if (Array.isArray(value)) {
        value = value.length > 0 && value[0] !== '' ? value[0] : null;
      }

      if (value === '') {
        value = null;
      }

      result[key] = value;
    }
  }

  return result;
};

export const mapNewInquiryDetailsFromApi = (rawResponse: any, feToBeMapping: FeToBeMapping) => {
  if (typeof feToBeMapping === 'function') {
    return feToBeMapping(rawResponse);
  }

  const normalizedResponse = normalize(rawResponse, {
    camelizeKeys: false,
    camelizeTypeValues: false,
  });

  const customer = _values(normalizedResponse.customers)[0];
  const company = _values(normalizedResponse.companies)[0];
  const inquiry = getInquiry(normalizedResponse);

  //flatten companyAttributes
  const companyAttributes = { ...company.attributes, ...company.attributes['additional_details'] };
  delete companyAttributes['additional_details'];

  const details = {
    ...inquiry.attributes.details,
    ...customer.attributes,
    ...companyAttributes,
  };

  const newResult = transformObject(feToBeMapping, details);

  /**
   * Values that are not set in the backend are set to null. We are using these values to set the initial values
   * for our edit mode. React-Final-Form treats null values as a "value" in the field and the validation for a number
   * throws an error if the value is null. Removing all null values resolves this issue.
   */
  return removeNullValuesRecursively(newResult);
};

export const mapInquiryDetailsFromApi = (rawResponse: any) => {
  const normalizedResponse = normalize(rawResponse, {
    camelizeKeys: false,
    camelizeTypeValues: false,
  });
  const {
    attributes: { status, form_type: inquiryType },
  } = getInquiry(normalizedResponse);

  const inquiryMapperByType =
    INQUIRY_MAPPERS_BY_TYPE[inquiryType as InquiryType] ??
    INQUIRY_MAPPERS_BY_TYPE[InquiryType.default];
  const inquiryMapperByStatus =
    inquiryMapperByType[status as INQUIRY_STATUSES] || inquiryMapperByType.default!;

  return inquiryMapperByStatus(normalizedResponse);
};

export const mapCompeonFilesFromApi = (response: any) => {
  return response.included.map(({ id, attributes }: any) => {
    return {
      id,
      fileName: attributes.title,
      createdAt: new Date(attributes['created-at']),
    };
  });
};

export const mapCompeonStateFromApi = (response: any) => {
  return _get(response, 'data[attributes][state]');
};

export const fetchInquiryAction =
  (inquiryId: string) => (dispatch: Function, getState: Function) => {
    return dispatch({
      type: 'FETCH_INQUIRY',
      payload: {
        client: WHITE_LABEL_API_CLIENT_NAME,
        request: {
          method: 'get',
          url: endpoints.INQUIRIES.DETAILS.compose({ params: { id: inquiryId } }),
        },
      },
    });
  };
