import { get as _get } from 'lodash';

import { UserRoleObject } from 'constants/user/permissions';
import {
  USER_ROLE_BANK_ADVISOR,
  USER_ROLE_CALL_CENTER_AGENT,
  USER_ROLE_CUSTOMER,
  USER_ROLE_INQUIRY_MANAGER,
  USER_ROLE_REAL_ESTATE_EXPERT,
  UserRole,
} from 'constants/user/userRoles';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { RawUserData } from 'types/User';
import { getAccessToken } from 'utils/auth';

import { getUserAttributes, getUserRoles } from './getters';

export const isUser = (item: unknown): item is RawUserData => {
  return typeof item === 'object' && item !== null && hasOwnProps(item, 'roles');
};
export const isLoggedInUser = () => !!(getAccessToken() && _get(getUserAttributes(), 'email'));

const hasOwnProps = <T extends object>(obj: T, ...args: string[]) => {
  return args.every((key) => Object.prototype.hasOwnProperty.call(obj, key));
};

export const userIsCustomer = () => getUserRoles() && getUserRoles().includes(USER_ROLE_CUSTOMER);

export const userIsCallCenterAgent = () => getUserRoles().includes(USER_ROLE_CALL_CENTER_AGENT);

export const userIsInquiryManager = () => getUserRoles().includes(USER_ROLE_INQUIRY_MANAGER);

export const userIsRealEstateExpert = () => getUserRoles().includes(USER_ROLE_REAL_ESTATE_EXPERT);

export const userIsBankAdvisor = () => getUserRoles().includes(USER_ROLE_BANK_ADVISOR);

export const userIsAgent = () => {
  return (
    userIsCallCenterAgent() ||
    userIsInquiryManager() ||
    userIsRealEstateExpert() ||
    userIsBankAdvisor()
  );
};

export const hasPermission = () => (availableForRoles: UserRole[] | UserRoleObject) => {
  if (!availableForRoles) {
    throw new Error('Permission does not exist in PERMISSIONS object');
  }

  let roles;
  if (typeof availableForRoles === 'object' && !Array.isArray(availableForRoles)) {
    availableForRoles = availableForRoles as UserRoleObject;
    roles = chooseSelectedInquiryTypeSpecificValue(availableForRoles);
  } else {
    availableForRoles = availableForRoles as UserRole[];
    roles = [...availableForRoles];
  }
  return roles.some((role) => getUserRoles().includes(role));
};
