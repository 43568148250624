import React from 'react';

import { Box, Grid, GridItem } from '@chakra-ui/react';

import {
  DescriptionList,
  DescriptionListTerm,
  DescriptionListDescription,
} from 'theme/components/DescriptionList';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal, formatPrice } from 'utils/valueFormats';

type Props = {
  data: {
    factoringFee: string;
    factoringLine: number;
    payoutRatio: string;
  };
};

const IndicativeConditionCalculation = ({ data: calculations }: Props) => {
  const t = useTranslations();

  return (
    <Grid templateColumns={['repeat(1, 1fr)', null, null, '1fr 1fr 1fr']} gap={6}>
      <GridItem>
        <Box
          border="1px"
          borderColor="border.lightGrey"
          p="8"
          bgColor="background.white"
          boxShadow="default"
        >
          <DescriptionList>
            <DescriptionListTerm display="flex" alignItems="center">
              {t(
                `pages.inquiryDetails.dashboard.actions.createIndicativeConditions.factoringFee.title`,
              )}
              <PopoverComponent
                ml="1px"
                transform="translateY(-1px)"
                text={t(
                  `pages.inquiryDetails.dashboard.actions.createIndicativeConditions.factoringFee.info`,
                )}
                trigger={
                  <IconButtonComponent
                    icon={<InfoIcon boxSize={6} display="block" />}
                    label={t('buttons.moreInfos')}
                  />
                }
              />
            </DescriptionListTerm>
            <DescriptionListDescription>
              {formatDecimal(calculations.factoringFee, { digits: 1, unit: '%', locale: 'de' })}
            </DescriptionListDescription>
          </DescriptionList>
        </Box>
      </GridItem>
      <GridItem>
        <Box
          border="1px"
          borderColor="border.lightGrey"
          p="8"
          bgColor="background.white"
          boxShadow="default"
        >
          <DescriptionList>
            <DescriptionListTerm display="flex" alignItems="center">
              {t(
                `pages.inquiryDetails.dashboard.actions.createIndicativeConditions.factoringLine.title`,
              )}
              <PopoverComponent
                ml="1px"
                transform="translateY(-1px)"
                text={t(
                  `pages.inquiryDetails.dashboard.actions.createIndicativeConditions.factoringLine.info`,
                )}
                trigger={
                  <IconButtonComponent
                    icon={<InfoIcon boxSize={6} display="block" />}
                    label={t('buttons.moreInfos')}
                  />
                }
              />
            </DescriptionListTerm>
            <DescriptionListDescription>
              {formatPrice(calculations.factoringLine, true, 0)}
            </DescriptionListDescription>
          </DescriptionList>
        </Box>
      </GridItem>
      <GridItem>
        <Box
          border="1px"
          borderColor="border.lightGrey"
          p="8"
          bgColor="background.white"
          boxShadow="default"
        >
          <DescriptionList>
            <DescriptionListTerm display="flex" alignItems="center">
              {t(
                `pages.inquiryDetails.dashboard.actions.createIndicativeConditions.payoutRatio.title`,
              )}
              <PopoverComponent
                ml="1px"
                transform="translateY(-1px)"
                text={t(
                  `pages.inquiryDetails.dashboard.actions.createIndicativeConditions.payoutRatio.info`,
                )}
                trigger={
                  <IconButtonComponent
                    icon={<InfoIcon boxSize={6} display="block" />}
                    label={t('buttons.moreInfos')}
                  />
                }
              />
            </DescriptionListTerm>
            <DescriptionListDescription>
              {formatDecimal(Number(calculations.payoutRatio), {
                digits: 0,
                unit: '%',
                locale: 'de',
              })}
            </DescriptionListDescription>
          </DescriptionList>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default IndicativeConditionCalculation;
