import React from 'react';

import { IconProps } from '@chakra-ui/react';

import { ReactComponent as IndicativeConditionsIcon } from 'assets/icons/cbbank/indicative-conditions.svg';
import { ReactComponent as ConfirmAssociatedPeopleIcon } from 'assets/icons/dzb/confirm-associated-people.svg';
import { ReactComponent as StartSigningProcessIcon } from 'assets/icons/dzb/start-signing-process.svg';
import { ReactComponent as WelcomePackageIcon } from 'assets/icons/dzb/welcome-package.svg';
import acceptOfferImage from 'assets/images/accept-offer_210@2x.png';
import activateAccountBfsService from 'assets/images/activate-account-bfs-service.png';
import activateAccount from 'assets/images/activate-account.png';
import videoLegitimationImage from 'assets/images/authentification@2x.png';
import kycTriggerImage from 'assets/images/authentification_210@2x.png';
import bankCheckImage from 'assets/images/bank-check.svg';
import accountActivation from 'assets/images/cb-account-activation.png';
import offerProvided from 'assets/images/cb-offer-provided.png';
import submitInvoice from 'assets/images/cb-submit-invoice.png';
import crefoQueryImage from 'assets/images/dashboard-action-01_210@2x.png';
import digitalProcessingImage from 'assets/images/dashboard-action-02_210@2x.png';
import addAdditionalDataImage from 'assets/images/dashboard-action-03_210@2x.png';
import dashboardAction from 'assets/images/dashboard-action@2x.png';
import documentFinish from 'assets/images/document-finish_210@2x.png';
import documentUploadImage from 'assets/images/document-upload@2x.png';
import indicativeOffer from 'assets/images/indicative-offer.png';
import nextSteps from 'assets/images/next-steps.png';
import signContractImage from 'assets/images/sign-contract.svg';
import successImage from 'assets/images/tasks-done_210@2x.png';
import verifyAccount from 'assets/images/verify-account@2x.png';
import videoIdent from 'assets/images/video-ident.png';
import AnhaengerIcon from 'theme/components/Icon/KindOfVehicle/AnhaengerIcon';
import KraftradIcon from 'theme/components/Icon/KindOfVehicle/KraftradIcon';
import LkwIcon from 'theme/components/Icon/KindOfVehicle/LkwIcon';
import PkwIcon from 'theme/components/Icon/KindOfVehicle/PkwIcon';
import SonstigesFahrzeugIcon from 'theme/components/Icon/KindOfVehicle/SonstigesFahrzeugIcon';
import MrMaleIcon from 'theme/components/Icon/MrMaleIcon';
import MsFemaleIcon from 'theme/components/Icon/MsFemaleIcon';
import CoronaIcon from 'theme/components/Icon/Purposes/CoronaIcon';
import DebtReschedulingIcon from 'theme/components/Icon/Purposes/DebtReschedulingIcon';
import GoodsIcon from 'theme/components/Icon/Purposes/GoodsIcon';
import GuarantyIcon from 'theme/components/Icon/Purposes/GuarantyIcon';
import MachineIcon from 'theme/components/Icon/Purposes/MachineIcon';
import OfficeEquipmentIcon from 'theme/components/Icon/Purposes/OfficeEquipmentIcon';
import OtherIcon from 'theme/components/Icon/Purposes/OtherIcon';
import OverdraftLiquidityIcon from 'theme/components/Icon/Purposes/OverdraftLiquidityIcon';
import ProjectFinancingIcon from 'theme/components/Icon/Purposes/ProjectFinancingIcon';
import RealEstateIcon from 'theme/components/Icon/Purposes/RealEstateIcon';
import VehicleIcon from 'theme/components/Icon/Purposes/VehicleIcon';
import AnschlussfinanzierungIcon from 'theme/components/Icon/RealEstate/AnschlussfinanzierungIcon';
import KaufIcon from 'theme/components/Icon/RealEstate/KaufIcon';
import NeubauIcon from 'theme/components/Icon/RealEstate/NeubauIcon';
import ProjectentwicklungIcon from 'theme/components/Icon/RealEstate/ProjectentwicklungIcon';
import UmbauIcon from 'theme/components/Icon/RealEstate/UmbauIcon';

const iconKeyToImage: { [key: string]: string } = {
  // dashboard assets
  acceptOfferImage,
  signContractImage,
  bankCheckImage,
  addAdditionalDataImage,
  digitalProcessingImage,
  crefoQueryImage,
  kycTriggerImage,
  successImage,
  documentFinish,

  // inquiry assets
  dashboardAction,
  verifyAccount,
  documentUploadImage,
  videoLegitimationImage,
  videoIdent,
  nextSteps,
  indicativeOffer,
  activateAccount,
  activateAccountBfsService,
  accountActivation,
  offerProvided,
  submitInvoice,
};

export const getIconValue = (key: string): string => iconKeyToImage[key] || key;

const svgToValues: { [key: string]: React.ElementType<IconProps> } = {
  // purpose-icons
  realEstateIcon: RealEstateIcon,
  goodsIcon: GoodsIcon,
  vehicleIcon: VehicleIcon,
  debtReschedulingIcon: DebtReschedulingIcon,
  machineIcon: MachineIcon,
  officeEquipmentIcon: OfficeEquipmentIcon,
  otherIcon: OtherIcon,
  guarantyIcon: GuarantyIcon,
  overdraftLiquidity: OverdraftLiquidityIcon,
  coronaIcon: CoronaIcon,

  // real-estate-icons
  neubauIcon: NeubauIcon,
  kaufIcon: KaufIcon,
  anschlussfinanzierungIcon: AnschlussfinanzierungIcon,
  umbauIcon: UmbauIcon,
  projectFinancing: ProjectFinancingIcon,
  projectentwicklung: ProjectentwicklungIcon,
  // vehicle-icons
  anhaenger: AnhaengerIcon,
  kraftrad: KraftradIcon,
  lkw: LkwIcon,
  pkw: PkwIcon,
  sonstigesFahrzeug: SonstigesFahrzeugIcon,
  // gender-icons
  womanIcon: MsFemaleIcon,
  manIcon: MrMaleIcon,
  // dzb-dashboard-actions
  confirmAssociatedPeopleIcon: ConfirmAssociatedPeopleIcon,
  startSigningProcessIcon: StartSigningProcessIcon,
  welcomePackageIcon: WelcomePackageIcon,
  // cb-bank-dashboard-actions
  indicativeConditionsIcon: IndicativeConditionsIcon,
};

export const getSvgValue = (key: string): React.ElementType<IconProps> | string =>
  svgToValues[key] || key;
