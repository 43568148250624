import React from 'react';

import { Box, Flex, VStack } from '@chakra-ui/react';

import Condition from 'components/Condition';
import { AccordionComponent } from 'theme/components/Accordion';
import ButtonComponent from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import EditIcon from 'theme/components/Icon/EditIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

interface CardProps {
  title: string;
  isExpandable?: boolean;
  isExpanded?: boolean;
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  editButtonTestId?: string;
}

export const Card = ({
  title,
  children,
  isExpandable = false,
  isExpanded = false,
  headingLevel = 'h3',
  editButtonTestId,
  onEdit,
}: CardProps) => {
  const t = useTranslations();

  const editButton = React.useMemo(() => {
    return (
      <Flex justifyContent="flex-end">
        <ButtonComponent
          onClick={onEdit}
          leftIcon={<EditIcon boxSize={6} display="block" />}
          variant="tertiary"
          testId={editButtonTestId}
        >
          {t('buttons.edit')}
        </ButtonComponent>
      </Flex>
    );
  }, [editButtonTestId, onEdit, t]);

  return isExpandable ? (
    <AccordionComponent
      headingLevel={headingLevel}
      title={title}
      defaultIndex={isExpanded ? [0] : undefined}
    >
      <VStack spacing={12} align="stretch">
        {children}
        <Condition condition={Boolean(onEdit)} children={editButton} />
      </VStack>
    </AccordionComponent>
  ) : (
    <Box
      border="1px"
      borderColor="border.lightGrey"
      padding={8}
      backgroundColor="background.white"
      boxShadow="default"
      height="100%"
    >
      <VStack spacing={12} align="stretch">
        {title && (
          <HeadingComponent variant="secondary" color="brand.default">
            {title}
          </HeadingComponent>
        )}
        {children}
        <Condition condition={Boolean(onEdit)} children={editButton} />
      </VStack>
    </Box>
  );
};
