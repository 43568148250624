import React from 'react';

import { CheckboxWithField } from 'components/Checkbox';
import paths from 'constants/paths';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  fieldName: string;
  labelTranslationKey: string;
  privacyPolicyTextTranslationKey: string;
}

export const PrivacyPolicyField = ({
  fieldName,
  labelTranslationKey,
  privacyPolicyTextTranslationKey,
}: Props) => {
  const t = useTranslations();

  const privacyPolicyText = t(privacyPolicyTextTranslationKey);
  const templateData = {
    privacyPolicy: (
      <a key="privacyPolicy" target="_blank" rel="noreferrer" href={paths.privacyPolicy}>
        {privacyPolicyText}
      </a>
    ),
  };
  const label = t(labelTranslationKey, templateData);

  return <CheckboxWithField name={fieldName} text={<span>{label}</span>} />;
};
