import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createMutation } from 'react-query-kit';
import { useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { CbBankBeneficialOwner } from 'cbbank/inquiry/fields';
import { translations } from 'new/form/common/types';
import { BeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import {
  mapBeneficialOwnerToBackend,
  mapCbBankBeneficialOwnerToBackend,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/mappers';
import { ASSOCIATED_PEOPLE_QUERY_KEY } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/useFetchAssociatedPeople';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

const usePatchDzbBeneficialOwnerMutation = createMutation({
  mutationFn: async (variables: { inquiryId: string; beneficialOwner: BeneficialOwner }) => {
    return EcoBankingAxiosClientAuthedInstance.patch(
      endpoints.INQUIRIES.SPECIALIZED.DZB.BENEFICIAL_OWNERS.PATCH.compose({
        params: {
          id: variables.inquiryId,
          beneficialOwnerId: variables.beneficialOwner.id ?? '',
        },
      }),
      {
        data: {
          attributes: mapBeneficialOwnerToBackend(variables.beneficialOwner),
        },
      },
    );
  },
});

export function usePatchDzbBeneficialOwner() {
  const inquiryId = useSelector(getInquiryIdSelector);
  const toasts = useToasts();
  const t = useTranslations();
  const { successMessage, errorMessage } =
    translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives.actions.edit;
  const queryClient = useQueryClient();

  const mutation = usePatchDzbBeneficialOwnerMutation({
    onSuccess: () => {
      toasts.success({ description: t(successMessage) });
      queryClient.invalidateQueries([ASSOCIATED_PEOPLE_QUERY_KEY]);
    },
    onError: () => {
      toasts.error({ description: t(errorMessage) });
    },
  });

  const mutateAsync = useCallback(
    (beneficialOwner: BeneficialOwner) => {
      return mutation.mutateAsync({
        inquiryId,
        beneficialOwner,
      });
    },
    [mutation, inquiryId],
  );

  return {
    ...mutation,
    mutateAsync,
  };
}

const usePatchCbBankBeneficialOwnerMutation = createMutation({
  mutationFn: async (variables: { inquiryId: string; beneficialOwner: CbBankBeneficialOwner }) => {
    return EcoBankingAxiosClientAuthedInstance.patch(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.BENEFICIAL_OWNERS.PATCH.compose({
        params: {
          id: variables.inquiryId,
          beneficialOwnerId: variables.beneficialOwner.id ?? '',
        },
      }),
      {
        data: {
          attributes: mapCbBankBeneficialOwnerToBackend(variables.beneficialOwner),
        },
      },
    );
  },
});

export function usePatchCbBankBeneficialOwner() {
  const inquiryId = useSelector(getInquiryIdSelector);
  const toasts = useToasts();
  const t = useTranslations();
  const { successMessage, errorMessage } =
    translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives.actions.edit;
  const queryClient = useQueryClient();

  const mutation = usePatchCbBankBeneficialOwnerMutation({
    onSuccess: () => {
      toasts.success({ description: t(successMessage) });
      queryClient.invalidateQueries([ASSOCIATED_PEOPLE_QUERY_KEY]);
    },
    onError: () => {
      toasts.error({ description: t(errorMessage) });
    },
  });

  const mutateAsync = useCallback(
    (beneficialOwner: CbBankBeneficialOwner) => {
      return mutation.mutateAsync({
        inquiryId,
        beneficialOwner,
      });
    },
    [mutation, inquiryId],
  );

  return {
    ...mutation,
    mutateAsync,
  };
}
