import React from 'react';

import { useMultiStyleConfig } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { StyledWrapper, StyledLabelWrapper, StyledLabel } from 'components/Label/styles';
import { Optional } from 'components/Text';
import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { IconButtonComponent } from 'theme/components/IconButton';
import { PopoverComponent } from 'theme/components/Popover';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

const FieldGroup = ({
  error,
  label,
  children,
  optional,
  tooltip,
  tooltipTransform,
  tooltipType,
  labelTooltipTriggerText,
}) => {
  const t = useTranslations();
  const labelStyles = useMultiStyleConfig('FieldWrapper');

  let tooltipTrigger;
  switch (tooltipType) {
    case 'icon':
      tooltipTrigger = (
        <IconButtonComponent
          icon={<InfoIcon boxSize={6} display="block" />}
          label={t('buttons.moreInfos')}
        />
      );
      break;
    case 'label':
      tooltipTrigger = (
        <TextComponent
          color={'text.secondary'}
          _hover={{
            color: 'text.tertiary',
            textDecoration: 'none',
          }}
          textDecoration={'underline'}
          cursor={'pointer'}
        >
          {labelTooltipTriggerText}
        </TextComponent>
      );
      break;
    default:
  }

  return (
    <StyledWrapper>
      <StyledLabelWrapper>
        <StyledLabel $fontCase={labelStyles?.caption?.textTransform}>{label}</StyledLabel>
        {tooltip && (
          <PopoverComponent
            ml={1}
            transform={tooltipTransform}
            text={tooltip}
            trigger={tooltipTrigger}
          />
        )}
        {optional && <Optional>{t('other.optional')}</Optional>}
      </StyledLabelWrapper>
      {children}
      <FieldError message={error} />
    </StyledWrapper>
  );
};

FieldGroup.defaultProps = {
  error: '',
  label: '',
  optional: false,
  tooltip: null,
  tooltipTransform: 'translateY(-1px)',
  tooltipType: 'icon',
  labelTooltipTriggerText: '',
};

FieldGroup.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  optional: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipTransform: PropTypes.string,
  tooltipType: PropTypes.oneOf(['icon', 'label']),
  labelTooltipTriggerText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FieldGroup;
