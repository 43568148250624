// API names
export enum InquiryType {
  default = 'default',
  onlinefactoring = 'onlinefactoring',
  leaseplan = 'leaseplan',
  bfs = 'bfs',
  profiRLL = 'profi',
  profiMittweida = 'profi_mittweida',
  bfsService = 'bfs_service',
  mmv = 'mmv',
  dzb = 'dzb',
  hausbank = 'hausbank',
  cbBank = 'cb_bank',
}

export type NewFormInquiryType = Exclude<
  InquiryType,
  'default' | 'onlinefactoring' | 'leaseplan' | 'bfs' | 'profi' | 'bfs_service'
>;
