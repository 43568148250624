import React from 'react';

import Condition from 'components/Condition';
import { YearPickerWithField } from 'components/DatePicker';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { INPUT_TYPE_NUMBER, InputWithField } from 'components/Input';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { HausbankFieldTypes, hausbankFields } from 'hausbank/inquiry/fields';
import { EXISTING_USER_COMPANY_NAME } from 'modules/Inquiry/Form/formFields';
import { translations } from 'new/form/common/types';
import { useGetCompany } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useGetCompany';
import StaticCompanyInformation, {
  StaticCompany,
} from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/CompanyInformationForm/StaticCompanyInformation';
import LegalForm from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/LegalForm/LegalForm';
import { InquiryConstants } from 'schema/inquirySchema.constants';
import { hasExistingCompany, useGetValidCompanyNameFieldName } from 'utils/company';
import { useTranslations } from 'utils/hooks/useTranslations';

import HausbankFillCompanyInformation from './HausbankFillCompanyInformation';

export const WegDataCompletionSection = () => {
  const t = useTranslations();
  const {
    address: { caption: addressCaption },
    name: { caption: nameCaption },
  } = translations.inquiryType.hausbank.pages.companyData.sections.wegDataCompletionSection.fields;

  const {
    wegDataCompletionSection: {
      city,
      street,
      zipCode,
      managedUnits,
      foundingYear,
      isExistingCustomer,
      legalForm,
    },
    wegSearchSection: {
      selectedCompanyName: selectedCompanyNameFieldName,
      loggedInUserCompanyName: loggedInUserCompanyNameFieldName,
    },
  } = hausbankFields.companyDataPage;

  const companyNameFieldName = useGetValidCompanyNameFieldName<HausbankFieldTypes>(
    selectedCompanyNameFieldName,
    loggedInUserCompanyNameFieldName,
  );
  const config: StaticCompany = {
    name: companyNameFieldName,
    city,
    street,
    'zip-code': zipCode,
  };
  const company = useGetCompany(config);
  const { [EXISTING_USER_COMPANY_NAME]: companyName } = company;
  const existingCompany = hasExistingCompany(companyName);

  return (
    <FormSection
      title={t(
        translations.inquiryType.hausbank.pages.companyData.sections.wegDataCompletionSection.title,
      )}
      sectionNumber={2}
    >
      <Condition condition={existingCompany}>
        <StaticCompanyInformation
          company={company}
          companyNameCaption={t(nameCaption)}
          companyAddressCaption={t(addressCaption)}
        />
      </Condition>
      <Condition condition={!existingCompany}>
        <HausbankFillCompanyInformation />
      </Condition>
      <FormRow>
        <LegalForm fieldName={legalForm} />
      </FormRow>
      <FormRow>
        <InputWithField type={INPUT_TYPE_NUMBER} name={managedUnits} />
      </FormRow>
      <FormRow>
        <YearPickerWithField
          name={foundingYear}
          minDate={InquiryConstants.MIN_HAUSBANK_COMPANY_FOUNDING_YEAR_DATE}
          maxDate={new Date()}
        />
      </FormRow>
      <FormRow>
        <YesNoRadioGroupWithField name={isExistingCustomer} />
      </FormRow>
    </FormSection>
  );
};
