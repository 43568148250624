import React, { useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import {
  useLoadInternalComments,
  useAddInternalComment,
} from 'api/CompeonReverseApi/operation/queryHooks';
import Spinner from 'components/Spinner';
import { ErrorText } from 'components/Text';
import { useFormConfig } from 'config/formConfig/hooks';
import queryKeys from 'constants/queryKeys';
import PERMISSIONS from 'constants/user/permissions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import withRolesGuard from 'shared/guard/withRolesGuard';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';

import Form from './Form';
import InternalCommentsItem from './Item';
import mapFromApiInterface from './service';
import { StyledHeading } from '../../styles';

const InternalComments = () => {
  const { selectedInquiryType } = useFormConfig();
  const isCbBank = selectedInquiryType === InquiryType.cbBank;
  const [isSendingNewComment, setIsSendingNewComment] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const inquiryId = useSelector(getInquiryIdSelector);
  const queryClient = useQueryClient();
  const { data, isLoading } = useLoadInternalComments({
    variables: { inquiryId },
    select: (response) => ({
      internalComments: mapFromApiInterface(response),
    }),
    refetchOnWindowFocus: false,
  });
  const addInternalCommentMutation = useAddInternalComment({
    onMutate: () => setIsSendingNewComment(true),
    onSuccess: () => {
      setIsSendingNewComment(false);
      queryClient.invalidateQueries([queryKeys.operation.internalComments]);
      success({ description: t('commentAdded') });
    },
  });

  const t = useTranslations('pages.inquiryDetails.dashboard.communication.internalComments');
  const { success } = useToasts();

  async function addInternalComment({ internalComment }: { internalComment: any }) {
    try {
      await addInternalCommentMutation.mutateAsync({ inquiryId, content: internalComment });
    } catch (error) {
      setError(error as Error);
      setIsSendingNewComment(false);
    }
  }
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      position="relative"
      _before={{
        content: "''",
        position: 'absolute',
        top: { base: '-24px', md: '0' },
        right: { base: '0', md: 'initial' },
        bottom: { base: undefined, md: '0' },
        left: { base: '0', md: '-24px' },
        width: { base: undefined, md: isCbBank ? undefined : '1px' },
        height: { base: '1px', md: 'initial' },
        backgroundColor: 'background.darkGrey',
      }}
    >
      <StyledHeading data-testid={'Comment-Internal-Header'}>{t('title')}</StyledHeading>

      {data?.internalComments.map(({ id, user, createdAt, content }) => (
        <InternalCommentsItem
          key={id}
          firstName={user.firstName}
          lastName={user.lastName}
          date={createdAt}
          comment={content}
          testId={'Comment-Internal'}
        />
      ))}
      <Form onSubmit={addInternalComment} isSendButtonDisabled={isSendingNewComment} />
      {error && <ErrorText>{(error as { message?: string })?.message}</ErrorText>}
    </Box>
  );
};

export default compose(
  withRolesGuard(PERMISSIONS.INQUIRY.DETAILS.DASHBOARD.COMMUNICATION.INTERNAL_COMMENTS),
)(
  chooseSelectedInquiryTypeSpecificComponent({
    [InquiryType.default]: InternalComments,
    [InquiryType.mmv]: () => null,
  }),
);
