import Joi from '@hapi/joi';

import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { BeneficialOwner } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/beneficialOwners/fields';
import { InquiryConstants } from 'schema/inquirySchema.constants';

export const beneficialOwnerValidations: Record<keyof Omit<BeneficialOwner, 'id'>, Joi.AnySchema> =
  {
    firstName: fieldValidators.name().isValidName().optional(),
    lastName: fieldValidators.name().isValidName().optional(),
    nationality: fieldValidators.string().optional(),
    capitalOrVotingRightsPercentage: fieldValidators
      .number()
      .optional()
      .max(InquiryConstants.MAX_PERCENT_VALUE),
    countryOfResidence: fieldValidators.string().optional(),
    placeOfResidence: fieldValidators.string().optional(),
    birthPlace: fieldValidators.string().optional(),
    birthDate: fieldValidators.date().max(Date.now()).optional(),
    fictional: fieldValidators.boolean().optional(),
  };

export const dzbBeneficialOwnerValidations: Record<
  keyof Omit<BeneficialOwner, 'id'>,
  Joi.AnySchema
> = {
  ...beneficialOwnerValidations,
  firstName: beneficialOwnerValidations.firstName.required(),
  lastName: beneficialOwnerValidations.lastName.required(),
  nationality: beneficialOwnerValidations.nationality.optional().allow(null).allow(''),
  capitalOrVotingRightsPercentage: beneficialOwnerValidations.capitalOrVotingRightsPercentage
    .optional()
    .allow(null)
    .allow(''),
  countryOfResidence: beneficialOwnerValidations.countryOfResidence
    .optional()
    .allow(null)
    .allow(''),
  placeOfResidence: beneficialOwnerValidations.placeOfResidence.optional().allow(null).allow(''),
  birthPlace: beneficialOwnerValidations.birthPlace.optional().allow(null).allow(''),
  birthDate: beneficialOwnerValidations.birthDate.optional().allow(null).allow(''),
};
