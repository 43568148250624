import React from 'react';

import { cbBankFields } from 'cbbank/inquiry/fields';
import FormSection from 'components/FormSection';
import { ContactPersonSection } from 'new/contactPerson';
import { translations } from 'new/form/common/types';
import { useTranslations } from 'utils/hooks/useTranslations';

export const UserDataSection = () => {
  const t = useTranslations();
  const { title } = translations.inquiryType.cbBank.pages.personalData.sections.userData;

  const { salutation, firstName, lastName, email, phoneNumber } =
    cbBankFields.personalDataPage.userDataSection;

  return (
    <FormSection title={t(title)} hideSectionNumber>
      <ContactPersonSection
        fieldNames={{
          gender: salutation,
          firstName,
          lastName,
          email,
          phone: phoneNumber,
        }}
      />
    </FormSection>
  );
};
