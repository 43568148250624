import { cbBankFields } from 'cbbank/inquiry/fields';
import { LabelsMap, translations as translationObject } from 'new/form/common/types';

const {
  turnoverShareOfLargestClient,
  centralisedSettlement,
  tradeCreditInsurance,
  downPaymentOrInstallments,
  assignedElsewhere,
  customersAbroad,
} = cbBankFields.financingNeedPage.businessDetailsSection;

const { fields: fieldTranslations } =
  translationObject.inquiryType.cbBank.pages.financingNeed.sections.businessDetails;

export const businessDetailsSectionLabelsMap: LabelsMap = {
  [turnoverShareOfLargestClient]: fieldTranslations.turnoverShareOfLargestClient.caption,
  [centralisedSettlement]: fieldTranslations.centralisedSettlement.caption,
  [tradeCreditInsurance]: fieldTranslations.tradeCreditInsurance.caption,
  [downPaymentOrInstallments]: fieldTranslations.downPaymentOrInstallments.caption,
  [assignedElsewhere]: fieldTranslations.assignedElsewhere.caption,
  [customersAbroad]: fieldTranslations.customersAbroad.caption,
};
