import React, { PropsWithChildren, useEffect } from 'react';

import { Flex } from '@chakra-ui/react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

import Separator from 'components/FormRow/Separator';
import FieldError from 'modules/Inquiry/Form/Field/FieldError';
import { translations } from 'new/form/common/types';
import { DzbLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';
import ButtonComponent, { VariantType } from 'theme/components/Button';
import DeleteIcon from 'theme/components/Icon/DeleteIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

export const LEGAL_REPRESENTATIVE_ERROR = `legalRepresentativesSection.legalRepresentatives.error`;

const Container: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex
    width={'full'}
    data-aos={'fade-bottom'}
    data-aos-delay={100}
    flexDirection={'column'}
    backgroundColor={'background.lightGrey'}
    p={6}
    borderRadius={5}
    borderColor={'border.lightGrey'}
    borderWidth={1}
    borderStyle={'solid'}
  >
    {children}
  </Flex>
);

const DeleteButton: React.FC<
  PropsWithChildren & {
    variant: VariantType;
    index: number;
    fields: FieldArrayRenderProps<any, any>['fields'];
  }
> = ({ children, variant, index, fields }) => (
  <ButtonComponent
    alignSelf={'flex-end'}
    leftIcon={<DeleteIcon boxSize={4} display={'block'} />}
    onClick={() => {
      fields.remove(index);
    }}
    variant={variant}
  >
    {children}
  </ButtonComponent>
);

export const DefaultLegalRepresentativeCards = ({
  fields,
  FieldComponent,
  arrayFieldName,
}: {
  fields: FieldArrayRenderProps<any, any>['fields'];
  FieldComponent: React.ComponentType<{ mode: AssociatedPersonMode; prefix: string }>;
  arrayFieldName: string;
}) => {
  const { errors } = useFormState();
  const legalRepError = errors?.[LEGAL_REPRESENTATIVE_ERROR];
  const t = useTranslations();
  const { mutators } = useForm();

  useEffect(() => {
    if (fields.length === 0) {
      mutators.push(arrayFieldName);
    }
  }, [mutators, arrayFieldName, fields, fields.length]);

  if (!fields) {
    return null;
  }

  return (
    <>
      {fields.map((name, index) => {
        const legalRep: DzbLegalRepresentative = fields.value[index];
        const fieldLength = fields.length;
        return (
          <React.Fragment key={name}>
            <Container>
              <DeleteButton fields={fields} index={index} variant="secondary">
                {t(
                  translations.inquiryType.dzb.pages.personalData.sections.legalRepresentatives
                    .fields.remove,
                )}
              </DeleteButton>
              {legalRep?.id ? (
                <FieldComponent mode={'edit'} prefix={name} />
              ) : (
                <FieldComponent mode={'add'} prefix={name} />
              )}
            </Container>
            {fieldLength ? index < fieldLength - 1 && <Separator /> : null}
          </React.Fragment>
        );
      })}
      <FieldError message={legalRepError} name={LEGAL_REPRESENTATIVE_ERROR} />
    </>
  );
};

export default {
  Container,
  DeleteButton,
};
