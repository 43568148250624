import React from 'react';

import { useForm } from 'react-final-form';

import { cbBankFields, cbBankFieldsWithSectionString } from 'cbbank/inquiry/fields';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { usePartnerConfig } from 'config/partner/hooks';
import CompanySuggestion from 'mittweida/inquiry/steps/company/sections/companyDetails/components/companySuggestion';
import { shouldShowCompanySuggestion } from 'mittweida/inquiry/steps/company/sections/companyDetails/shouldShowCompanySuggestion';
import { translations } from 'new/form/common/types';
import {
  UseCompanySuggestionConfig,
  useCompanySuggestion,
} from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useCompanySuggestion';
import { SuggestionOption } from 'pages/inquiryFlow/CompanyDetails/sections/Company/CompanySuggestion/useFetchCompanySuggestions';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser } from 'utils/user/conditionals';

export const CompanySearchSection = () => {
  const t = useTranslations();
  const { change } = useForm();
  const {
    details: { fullName: partnerName },
  } = usePartnerConfig();
  const {
    title,
    fields: {
      selectedCompanyName: { tooltipTriggerText },
    },
  } = translations.inquiryType.cbBank.pages.companyData.sections.companySearch;
  const companySearchFieldCaption = t(
    'pages.companyDetails.sections.company.fields.companyDetailsCompany.captionV2',
  );

  const {
    companyDataPage: {
      companySearchSection: { crefoId, selectedCompanyName: selectedCompanyNameFieldName },
      companyDetailsCompletionSection: { name, street, zipCode, city },
    },
  } = cbBankFields;

  const config: UseCompanySuggestionConfig = {
    companySearchSectionString:
      cbBankFieldsWithSectionString.companyDataPage.companySearchSection.sectionString,
    companySearchFieldNames: {
      selectedCompanyNameFieldName,
    },
    companyCompletionFieldNames: {
      name,
      street,
      zipCode,
      city,
    },
  };
  const { handleSelect, handleClearCompanyForm } = useCompanySuggestion(config);

  const isLoggedIn = isLoggedInUser();
  const showCompanySuggestions = shouldShowCompanySuggestion(isLoggedIn);

  const crefoIdChangeHandler = (value: string, companies: SuggestionOption[]) => {
    const company = companies.find((company) => company.label === value);
    if (company) {
      change(crefoId, company.value['crefo-id']);
    }
  };

  const selectHandler = (value: any, companies: SuggestionOption[]) => {
    crefoIdChangeHandler(value, companies);
    handleSelect(value, companies);
  };

  return (
    <FormSection title={t(title)} hideSectionNumber>
      {showCompanySuggestions && (
        <FormRow>
          <CompanySuggestion
            fieldName={selectedCompanyNameFieldName}
            onClearCompanyForm={handleClearCompanyForm}
            onSelect={selectHandler}
            tooltip={t(
              'pages.companyDetails.sections.company.fields.companyDetailsExistingUserCompany.notice',
              {
                partnerName,
                fieldCaption: companySearchFieldCaption,
                roleSpecific: true,
              },
            )}
            tooltipType="label"
            labelTooltipTriggerText={t(tooltipTriggerText)}
          />
        </FormRow>
      )}
    </FormSection>
  );
};
