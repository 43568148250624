import { get as _get } from 'lodash';
import { useFormState } from 'react-final-form';

import {
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
} from 'modules/Inquiry/Form/formFields';

import { isLoggedInUser } from './user/conditionals';

/**
 * Check if the company name is an existing company or some placeholder value
 * @param {string} companyName Company name
 */
export const hasExistingCompany = (companyName: string) => {
  return (
    !!companyName &&
    companyName !== COMPANY_DETAILS_COMPANY__NOT_FOUND &&
    companyName !== COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
  );
};

/**
 * Used to automatically get the valid company name field name depending on the existing user/new company/existing company logic
 * @param {string} loggedInUserCompanyNameFieldName Field name of the company name field for the logged in user
 * @param {string} selectedCompanyNameFieldName Field name of the company name field for a selected company from the FirmenWissen API
 * @returns {string}
 */
export const useGetValidCompanyNameFieldName = <FieldTypes>(
  selectedCompanyNameFieldName: string,
  loggedInUserCompanyNameFieldName?: string,
) => {
  const { values } = useFormState<FieldTypes>();
  return getValidCompanyNameFieldName(
    values,
    selectedCompanyNameFieldName,
    loggedInUserCompanyNameFieldName,
  );
};

// Get the correct company name field name depending on the existing user/new company/existing company logic
export const getValidCompanyNameFieldName = <FieldTypes>(
  values: FieldTypes,
  selectedCompanyNameFieldName: string,
  loggedInUserCompanyNameFieldName?: string,
) => {
  if (loggedInUserCompanyNameFieldName && isLoggedInUser()) {
    const loggedInUserCompanyName = _get(values, loggedInUserCompanyNameFieldName);
    return loggedInUserCompanyName === COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY
      ? selectedCompanyNameFieldName
      : loggedInUserCompanyNameFieldName;
  }
  return selectedCompanyNameFieldName;
};
