import { createQuery } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import queryKeys from 'constants/queryKeys';

interface FactoringLineParams {
  annualFactoringRelevantTurnoverValue: number;
  paymentTermValue: number;
}

interface FactoringLineResponse {
  factoring_line: number;
}

export const useCalculateFactoringLine = createQuery<FactoringLineResponse, FactoringLineParams>({
  primaryKey: queryKeys.partners.cbBank.calculateFactoringLine,
  queryFn: async ({ queryKey: [_, variables] }) => {
    const response = await EcoBankingAxiosClientAuthedInstance.post(
      endpoints.INQUIRIES.SPECIALIZED.CB_BANK.CALCULATE_FACTORING_LINE.compose(),
      {
        data: {
          attributes: {
            annual_factoring_relevant_turnover: variables.annualFactoringRelevantTurnoverValue,
            payment_term: variables.paymentTermValue,
          },
        },
      },
    );

    return response.data.data.attributes.factoring_line;
  },
  refetchOnWindowFocus: false,
});
