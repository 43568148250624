import React, { useEffect } from 'react';

import { useField } from 'react-final-form';

import { cbBankFieldsWithSectionString } from 'cbbank/inquiry/fields';
import PageHeading from 'components/PageHeading';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { store } from 'store';
import { setProcessLane } from 'store/inquiryProcess/actions';
import { useTranslations } from 'utils/hooks/useTranslations';

import { BankAccountSection } from './sections/bankAccount';
import { CompanyDetailsCompletionSection } from './sections/companyDetailsCompletion';
import { CompanySearchSection } from './sections/companySearch';
import { ComplianceSection } from './sections/compliance';
import { useUpdateAndRemoveFields } from './useUpdateAndRemoveFields';

const boArrayFieldName =
  cbBankFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
    .sectionString;
const lrArrayFieldName =
  cbBankFieldsWithSectionString.personalDataPage.legalRepresentativesSection.legalRepresentatives
    .sectionString;

export const CbBankCompanyDataPage = () => {
  const t = useTranslations();
  const {
    input: { value: boValue },
  } = useField(boArrayFieldName);

  const {
    input: { value: lrValue },
  } = useField(lrArrayFieldName);

  useUpdateAndRemoveFields(boArrayFieldName, boValue);
  useUpdateAndRemoveFields(lrArrayFieldName, lrValue);

  useEffect(() => {
    setProcessLane(InquiryLane.lead)(store.dispatch);
  }, []);

  const {
    progressBarTitle,
    heading: { header, subheader },
  } = translations.inquiryType.cbBank.pages.companyData;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} subheading={t(subheader)} />
      <CompanySearchSection />
      <CompanyDetailsCompletionSection />
      <BankAccountSection />
      <ComplianceSection />
    </Page>
  );
};
