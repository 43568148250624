import { useMemo } from 'react';

import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { translations } from 'new/form/common/types';
import { CountryIsoCode, countryIsoCodes } from 'types/Country';
import { FormatMessage } from 'utils/intl';

export const getNationalityOptions = (
  formatMessage: FormatMessage,
): SelectOption<CountryIsoCode>[] =>
  countryIsoCodes
    .map((code) => {
      return {
        value: code,
        label: formatMessage({ id: translations.nationalities[code] }),
      };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));

export const useGetNationalityOptions = (formatMessage: FormatMessage) => {
  return useMemo(() => getNationalityOptions(formatMessage), [formatMessage]);
};
