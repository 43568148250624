import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import { getObjectFromQueryParamsString } from 'utils/paths';

const {
  operation: { login: operationLogin, root: operationRoot },
  customer: { login: customerLogin, root: customerRoot, inquiryDetails },
} = paths;

const ALLOWED_SCOPES = ['documentExchange', 'details'] as const;

type AllowedScope = (typeof ALLOWED_SCOPES)[number];

export const useRedirectOnRegistrationSuccess = () => {
  const history = useHistory();
  const { inquiry_id: inquiryId, scope } = getObjectFromQueryParamsString(history.location.search);

  const isOperationPortalUser = history.location.pathname.startsWith(operationRoot);

  return useCallback(() => {
    if (inquiryId && scope && ALLOWED_SCOPES.includes(scope as AllowedScope)) {
      history.push(inquiryDetails[scope as AllowedScope].replace(':id', inquiryId as string));
    } else {
      history.push(isOperationPortalUser ? operationLogin : customerLogin);
    }
  }, [history, inquiryId, scope, isOperationPortalUser]);
};

export const useInquiryTypeSpecificTermsAndConditions = () => {
  const history = useHistory();
  const isCustomerPortalUser = history.location.pathname.startsWith(customerRoot);
  const isCustomerActivationEnabled = useConfig(CONFIG.CUSTOMER_ACTIVATION_ON_INVITATION);

  const implicitlyAccepted = useSelectedInquiryTypeSpecificValue({
    [InquiryType.cbBank]: true,
    [InquiryType.default]: false,
  });

  const alwaysTrue = () => true;
  const getInquiryTypeSpecificTerms = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: (consent?: boolean) => isCustomerActivationEnabled,
    [InquiryType.default]: (consent?: boolean) => (isCustomerPortalUser ? consent : undefined),
  });

  return {
    shouldDisplayTermsAndConditions: isCustomerPortalUser && !implicitlyAccepted,
    getInquiryTypeSpecificTerms: implicitlyAccepted ? alwaysTrue : getInquiryTypeSpecificTerms,
  };
};
