import React from 'react';

import { Flex } from '@chakra-ui/react';
import { Form } from 'react-final-form';

import {
  formValidator,
  useErrorTranslator,
} from 'modules/Inquiry/inquiryFieldValidation/useErrorTranslations';
import { useFetchInquiryData } from 'modules/InquiryDetails/FetchInquiryDetails';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import { DzbLegalRepresentativeFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/dzb/DzbLegalRepresentativeFields';
import { DzbLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { useCreateDzbLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/hooks/legalRepresentatives/createLegalRepresentative';
import ButtonComponent from 'theme/components/Button';
import { ModalComponent, ModalProps } from 'theme/components/Modal';
import { useTranslations } from 'utils/hooks/useTranslations';

import { dzbLegalRepresentativeSchema } from './dzbLegalRepresentativeSchema';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'>;

const ADD_LEGAL_REPRESENTATIVE_FORM_ID = 'addLegalRepresentativeForm';

const DzbAddLegalRepresentativeModal = ({ isOpen, onClose }: Props) => {
  const { mutateAsync, isLoading } = useCreateDzbLegalRepresentative();
  const { refetchInquiry } = useFetchInquiryData();
  const errorTranslator = useErrorTranslator();
  const t = useTranslations();
  const { actions } = translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives;

  const onSubmit = async (values: DzbLegalRepresentative) => {
    await mutateAsync(values);
    await refetchInquiry();
    onClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={t(actions.add.title)}
      footer={
        <Flex gap={4}>
          <ButtonComponent variant="tertiary" onClick={onClose} testId="cancelButton">
            {t(actions.add.cancel)}
          </ButtonComponent>
          <ButtonComponent
            isLoading={isLoading}
            variant={'primary'}
            form={ADD_LEGAL_REPRESENTATIVE_FORM_ID}
            type={'submit'}
            testId="confirmButton"
          >
            {t(actions.add.confirm)}
          </ButtonComponent>
        </Flex>
      }
    >
      <Form
        onSubmit={onSubmit}
        validate={(values) => formValidator(values, dzbLegalRepresentativeSchema, errorTranslator)}
        render={({ handleSubmit }) => {
          return (
            <form id={ADD_LEGAL_REPRESENTATIVE_FORM_ID} onSubmit={handleSubmit}>
              <DzbLegalRepresentativeFields mode={'add'} />
            </form>
          );
        }}
      />
    </ModalComponent>
  );
};

export default withAssociatedPeopleEditMode(DzbAddLegalRepresentativeModal);
