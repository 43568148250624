import { useSelector } from 'react-redux';

import {
  DashboardActionState,
  InquiryDashboardAction,
  useGetDashboardActions,
} from 'api/CompeonReverseApi/customer/queryHooks/inquiries';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

/**
 * Custom hook for managing dashboard actions.
 *
 * @returns {Object} Contains the isLoading state, count of actions done, count of actions open,
 * the type of the first open task, and the calculated completed value.
 */
export const useActionCalculations = () => {
  // Fetching the inquiry ID from the Redux store
  const inquiryId = useSelector(getInquiryIdSelector);

  // Fetching the dashboard actions data
  const { data, isLoading } = useGetDashboardActions({
    variables: { id: inquiryId },
    enabled: !!inquiryId,
    refetchOnWindowFocus: true,
  });

  // Initializing default values for action calculations
  let actionsDone = 0; // Number of completed actions
  let actionsOpen = 0; // Number of open actions
  let firstOpenTask = ''; // Type of the first open task
  let completedValue = 0; // Completion percentage
  let lastItemIndex = 0; // Index of the last item
  let progressTotal = 0; // Total number of actions (completed + open)
  let isCompleted = false; // Whether all actions are completed
  let canShowProgress = false; // Whether to show progress
  let dashboardActions: InquiryDashboardAction[] = []; // List of dashboard actions

  // Function to check if an item is the last open item
  const isLastItem = (index: number) => actionsOpen !== 0 && index === lastItemIndex;

  // Perform calculations only if data is available and it's not in a loading state
  if (!isLoading && data?.dashboardActions) {
    // Extracting actions from the data object
    const actions = data.dashboardActions;

    // Calculating the number of completed actions
    actionsDone = actions.filter(
      (action) => action.attributes.state === DashboardActionState.COMPLETED,
    ).length;

    // Calculating the number of open actions
    actionsOpen = actions.filter(
      (action) => action.attributes.state !== DashboardActionState.COMPLETED,
    ).length;

    // Finding the first active task type
    const firstActiveAction = actions.find(
      (action) => action.attributes.state === DashboardActionState.ACTIVE,
    );
    firstOpenTask = firstActiveAction ? firstActiveAction.type : 'openTask';

    // Calculating the completion value
    completedValue = (actionsDone / (actionsDone + actionsOpen)) * 100 || 0;

    // Finding the last item index
    lastItemIndex = actions.length - 1;

    // Calculating the total progress value
    progressTotal = actionsDone + actionsOpen;

    // Checking if all actions are completed
    isCompleted = actionsOpen === 0;

    // Checking if progress can be shown
    canShowProgress = actionsDone + actionsOpen > 0;

    // Storing the dashboard actions for rendering
    dashboardActions = actions;
  }

  // Returning calculated values and loading state
  return {
    isLoading,
    actionsDone,
    actionsOpen,
    firstOpenTask,
    completedValue,
    progressTotal,
    dashboardActions,
    canShowProgress,
    isCompleted,
    isLastItem,
  };
};

export const mapDashboardActionAttributes = (action: InquiryDashboardAction, index: number) => {
  const { state, stepsCompleted, stepsTotal, details = [] } = action.attributes;
  const id = action.type;
  const isFirst = index === 0;
  const isCompleted = state === DashboardActionState.COMPLETED;
  const completedValue = (stepsCompleted / stepsTotal) * 100;

  return {
    completedValue,
    isFirst,
    isCompleted,
    progressIncrement: stepsCompleted,
    progressTotal: stepsTotal,
    data: details,
    id,
    state,
  };
};
