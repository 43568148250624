import { ibanToBic } from 'iban-to-bic';
import { extractIBAN, isValidBIC, isValidIBAN } from 'ibantools';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import moment from 'moment';

import { PRODUCT_KIND__LOAN } from 'modules/Inquiry/Form/formFields';
import {
  containsLowerCaseRegex,
  containsNonAlphaNumericRegex,
  containsNumericCharRegex,
  containsUpperCaseRegex,
  isEmailRegex,
  isNumberRegex,
  isPhoneNumberRegex,
  isPostcodeRegex,
  isStrongPassword,
  isValidInstitutionNumberRegex,
  mobileNumberPrefixRegex,
  phoneNumberPrefixRegex,
} from 'utils/regexes';

import { isMobilePhoneNumberValid } from './phoneNumber';

export const buildValidator = (predicate, errorMessage) => (value, values) => {
  return predicate(value, values) ? undefined : errorMessage;
};

export const lowerBound = (threshold, errorMessage) =>
  buildValidator((value) => {
    const parsed = parseFloat(value, 10);
    return (parsed || parsed === 0) && parsed >= threshold.toString().replace(',', '.');
  }, errorMessage);

export const upperBound = (threshold, errorMessage) =>
  buildValidator((value) => !value || parseFloat(value) <= threshold, errorMessage);

export const required = (errorMessage) =>
  buildValidator(
    (value) => (Array.isArray(value) ? value.length : !_isNil(value) && value !== ''),
    errorMessage,
  );

export const requiredTrimmed = (errorMessage) =>
  buildValidator((value) => (value ? !!value.toString().trim() : false), errorMessage);

export const minLength = (threshold, errorMessage) =>
  buildValidator((value) => !value || value.length >= threshold, errorMessage);

export const maxLength = (threshold, errorMessage) =>
  buildValidator(
    (value) => (value ? value.toString().split('.')[0].length <= threshold : true),
    errorMessage,
  );

export const exactLength = (threshold, errorMessage) =>
  buildValidator((value) => !value || value.length === threshold, errorMessage);

export const isPostcode = (errorMessage) =>
  buildValidator((value) => isPostcodeRegex.test(value), errorMessage);

export const minYear = (year, errorMessage) =>
  buildValidator((value) => {
    const date = value instanceof Date ? value : new Date(value);
    const currentYear = date && date.getUTCFullYear();
    return currentYear && year <= parseInt(currentYear, 10);
  }, errorMessage);

export const maxYear = (year, errorMessage) =>
  buildValidator((value) => {
    if (!value) return true;
    const date = value instanceof Date ? value : new Date(value);
    const currentYear = date && date.getUTCFullYear();
    return currentYear && year >= parseInt(currentYear, 10);
  }, errorMessage);

export const isSameOrAfter = (secondDate, errorMessage) =>
  buildValidator((firstDate) => {
    return moment(firstDate).isSameOrAfter(secondDate);
  }, errorMessage);

export const under4Months = (data, errorMessage) =>
  buildValidator((value) => {
    if (data.product === PRODUCT_KIND__LOAN) {
      const date = new Date(data.foundingYear, value - 1);
      const minDate = moment().subtract(4, 'month');
      return moment(minDate).isSameOrAfter(date);
    }
    return true;
  }, errorMessage);
export const selectAtLeastOne = (validator, errorMessage) =>
  buildValidator((_value) => {
    return validator();
  }, errorMessage);
export const isSameOrBefore = (secondDate, errorMessage) =>
  buildValidator((firstDate) => {
    return moment(firstDate).isSameOrBefore(secondDate);
  }, errorMessage);

export const isFutureDate = (errorMessage) =>
  buildValidator((value) => {
    const today = moment().startOf('day');
    const selectedDate = moment(value).startOf('day');

    return selectedDate.isAfter(today);
  }, errorMessage);

export const isIn = (allowedValues, errorMessage) =>
  buildValidator((value) => allowedValues.includes(value), errorMessage);

export const nonEmptyArray = (errorMessage) =>
  buildValidator((value) => value && value.length > 0, errorMessage);

export const isEmail = (errorMessage) =>
  buildValidator((value) => isEmailRegex.test(value.toLowerCase()), errorMessage);

export const isValidInstitutionNumber = (errorMessage) =>
  buildValidator((value) => {
    if (value) {
      value = value.replace(/\s/g, '');
      return isValidInstitutionNumberRegex.test(value);
    }
    return true;
  }, errorMessage);

export const combineValidators =
  (...validators) =>
  (value, values) =>
    validators.reduce(
      (errorMessage, validator) => errorMessage || validator(value, values),
      undefined,
    );

export const isPhoneNumber = (errorMessage) =>
  buildValidator((value) => !value || isPhoneNumberRegex.test(value), errorMessage);

export const hasPhonePrefix = (errorMessage) =>
  buildValidator((value) => !value || phoneNumberPrefixRegex.test(value), errorMessage);

export const hasMobilePhonePrefix = (errorMessage) =>
  buildValidator((value) => !value || mobileNumberPrefixRegex.test(value), errorMessage);

export const isPercentage = (errorMessage, upperBoundValue = 100) =>
  combineValidators(lowerBound(0, errorMessage), upperBound(upperBoundValue, errorMessage));

export const isIban = (errorMessage) => buildValidator((value) => isValidIBAN(value), errorMessage);

export const isValidBicDeep = (field, errorMessage) =>
  buildValidator((value, values) => {
    const iban = _get(values, field);
    const { countryCode } = extractIBAN(iban);
    if (countryCode === 'DE') {
      if (ibanToBic(iban) === value) {
        return true;
      }
      return false;
    }
    if (isValidBIC(value)) {
      return true;
    }
    return false;
  }, errorMessage);

export const isGermanIban = (errorMessage) =>
  buildValidator((value) => extractIBAN(value).countryCode === 'DE', errorMessage);

export const matchesSpecificBankCodes = (bankCodes, errorMessage) =>
  buildValidator((value) => {
    const bankCode = value.substring(4, 12);
    return bankCodes.includes(bankCode);
  }, errorMessage);

export const equalTo = (field, errorMessage) =>
  buildValidator((value, values) => {
    return value === _get(values, field);
  }, errorMessage);

export const yearGreaterThan = (comparisonYear, errorMessage) =>
  buildValidator((value) => {
    if (!value) return true;
    const valueYear = value > 1000 && new Date(value).getUTCFullYear();
    return valueYear > comparisonYear;
  }, errorMessage);

export const upperBoundFrom = (field, errorMessage) =>
  buildValidator(
    (value, values) => values[field] && parseInt(value, 10) <= parseInt(values[field], 10),
    errorMessage,
  );

export const isPositive = (errorMessage) =>
  buildValidator((value) => !value || parseFloat(value) > 0, errorMessage);

export const notNegative = (errorMessage) =>
  buildValidator((value) => !value || parseFloat(value) >= 0, errorMessage);

export const mustBeTrue = (errorMessage) => buildValidator((value) => Boolean(value), errorMessage);

export const isInteger = (errorMessage) =>
  buildValidator((value) => !value || parseFloat(value) === parseInt(value, 10), errorMessage);

export const isNumber = (errorMessage) =>
  buildValidator((value) => !value || isNumberRegex.test(value), errorMessage);

export const isValidPassword = (errorMessage) =>
  buildValidator((value) => isStrongPassword.test(value), errorMessage);

export const containsUpperCase = (errorMessage) =>
  buildValidator((value) => containsUpperCaseRegex.test(value), errorMessage);
export const containsLowerCase = (errorMessage) =>
  buildValidator((value) => {
    return value && containsLowerCaseRegex.test(value);
  }, errorMessage);
export const containsNumericChar = (errorMessage) =>
  buildValidator((value) => containsNumericCharRegex.test(value), errorMessage);
export const containsNonAlphaNumeric = (errorMessage) =>
  buildValidator((value) => containsNonAlphaNumericRegex.test(value), errorMessage);

export const hasMinimalLength = (errorMessage, minCharacters) => {
  const regexp = new RegExp(`^(?=.{${minCharacters},}).+$`);
  return buildValidator((value) => regexp.test(value), errorMessage);
};

export const isMobilePhoneNumber = (errorMessage) =>
  buildValidator((value) => {
    if (!value) {
      return false;
    }
    return isMobilePhoneNumberValid(value);
  }, errorMessage);

/**
 * Creates a validator that combines required and optional validators and allows to specify the maximum number of optional errors allowed.
 * @param {Array<function>} validators - Array of required validators
 * @param {Array<function>} optionalValidators - Array of optional validators
 * @param {number} maxOptionalErrors - Maximum number of optional errors allowed
 */
export const combineWithOptionalValidators =
  (validators, optionalValidators = undefined, maxOptionalErrors = 0) =>
  (value) => {
    let errors = [];

    validators.forEach((validator) => {
      const validationResult = validator(value);

      if (validationResult) {
        errors.push(validationResult);
      }
    });

    if (optionalValidators) {
      let optionalErrors = [];

      optionalValidators.forEach((validator) => {
        const validationResult = validator(value);
        if (validationResult) {
          optionalErrors.push(validationResult);
        }
      });

      if (optionalErrors && optionalErrors.length > maxOptionalErrors) {
        errors.push(...optionalErrors);
      }
    }
    return errors.length > 0 ? errors : undefined;
  };
