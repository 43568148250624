import * as React from 'react';

import { Divider, VStack } from '@chakra-ui/react';

import { FONT_WEIGHT_BOLD } from 'constants/globalConstants';
import { ICommercialReport } from 'models/CompanyDetails.model';
import { translations } from 'new/form/common/types';
import { TextComponent } from 'theme/components/Text';
import { textStyle } from 'theme/themeConstants';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import { RiskCard, RiskContainer, RiskHeading } from '../RiskCard/RiskCard';

interface Props {
  heading: string;
  isRiskAnalysisPage?: boolean;
  report: ICommercialReport | undefined;
}

const Subheading: React.FC<React.PropsWithChildren> = ({ children }) => (
  <TextComponent color="text.secondary" {...textStyle.sizeS}>
    {children}
  </TextComponent>
);

export const Commercial: React.FC<Props> = ({ heading, report, isRiskAnalysisPage }: Props) => {
  const { adjustedEquity, annualResult } =
    translations.pages.companiesDetails.riskAnalysis.commercial;
  const t = useTranslations();

  return (
    <RiskCard
      timeOfMeasurement={report?.createdAt}
      isRiskAnalysisPage={isRiskAnalysisPage}
      isRating
    >
      <RiskHeading>{heading}</RiskHeading>
      <Divider opacity="1" mt="2" mb="6" borderColor="border.lightGrey" />
      <RiskContainer>
        <VStack width="100%" alignItems="flex-start" pb={4}>
          <Subheading>{t(adjustedEquity)}</Subheading>
          <TextComponent {...textStyle.sizeXxl} fontWeight={FONT_WEIGHT_BOLD}>
            {formatPrice(report?.adjustedEquity) ?? '–'}
          </TextComponent>
          <Subheading>{t(annualResult)}</Subheading>
          <TextComponent {...textStyle.sizeXxl} fontWeight={FONT_WEIGHT_BOLD}>
            {formatPrice(report?.annualResult) ?? '–'}
          </TextComponent>
        </VStack>
      </RiskContainer>
    </RiskCard>
  );
};
