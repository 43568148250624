import * as React from 'react';

import { Center, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DashboardActionState } from 'api/CompeonReverseApi/customer/queryHooks/inquiries';
import { Asset } from 'components/Asset';
import paths from 'constants/paths';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
  state: string;
}

export const CreateIndicativeConditionsStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
  state,
}: Props) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.createIndicativeConditions');
  const inquiryId = useSelector(getInquiryIdSelector) || '';
  const history = useHistory();

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12}>
        <GridItem>
          <HeadingComponent as="h4" color="brand.default" mb={2} variant="secondary">
            {t('title')}
          </HeadingComponent>
          <TextComponent mb={10}>{t('description')}</TextComponent>
          {isCompleted ? null : (
            <ButtonComponent
              onClick={() =>
                history.push(paths.operation.inquiryDetails.nonBindingOffer(inquiryId))
              }
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="primary"
              disabled={state === DashboardActionState.INACTIVE}
              testId="createIndicativeConditionsButton"
            >
              {t('action')}
            </ButtonComponent>
          )}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset
              htmlHeight="20"
              type="dashboard"
              value={ProgressSectionType.CREATE_INDICATIVE_CONDITIONS}
            />
          </Center>
        </GridItem>
      </Grid>
    </ProgressSection>
  );
};
