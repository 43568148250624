import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IMessageEvent } from 'websocket';

import { cbBankSteps } from 'cbbank/inquiry/steps';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { Channel, useWebSocket } from 'shared/useWebsocket';
import { setProcessLane } from 'store/inquiryProcess/actions';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';

import { LoadingPage } from './LoadingPage';
import { ContractLaneSection } from './sections/contractLane';
import { LeadLaneSection } from './sections/leadLane';
import { useRequestAssociatedPeople } from './useRequestAssociatedPeople';

type LocationState = {
  previousStep?: string;
};

export const CbBankOfferPage = () => {
  const dispatch = useDispatch();
  const inquiryId = useSelector(getCreatedInquiryId);
  const location = useLocation<LocationState>();
  const [locationState, setLocationState] = useState<string | undefined>(
    location?.state?.previousStep,
  );
  const startTime = useRef<number>(Date.now());
  const [hasTimedOut, setHasTimedOut] = useState(false);

  const isRedirectedFromCompanyPage = locationState === cbBankSteps.companyDataPage;

  const [connect, setConnect] = useState<boolean>(isRedirectedFromCompanyPage);
  const [kycDataAvailable, setKycDataAvailable] = useState(false);
  const [kycProcessFailed, setKycProcessFailed] = useState(false);
  const [laneSet, setLaneSet] = useState(false);

  const { isSuccess } = useRequestAssociatedPeople(kycDataAvailable);
  useWebSocket({
    channel: Channel.INQUIRY,
    connect,
    params: {
      inquiry_id: inquiryId,
    },
    onMessage: (m: IMessageEvent) => {
      const data = JSON.parse(m.data as string);
      if (data.message && data.message.data?.type === 'inquiries') {
        const completed =
          data.message.data.attributes.details['associated-people-import-completed'];
        const failed = data.message.data.attributes.details['associated-people-import-failed'];
        const lane = data.message.data.attributes['specialized_lane'];
        if (completed) {
          setKycDataAvailable(true);
        }
        if (failed) {
          setKycProcessFailed(true);
        }
        if (lane) {
          dispatch(setProcessLane(lane));
          setLaneSet(true);
        }
      }
      // Check if timeout is reached
      if ((Date.now() - startTime.current) / 1000 >= 55) {
        setHasTimedOut(true);
      }
    },
  });

  const checkCompleteted = (isSuccess || kycProcessFailed) && laneSet;
  const showContent = checkCompleteted || !isRedirectedFromCompanyPage || hasTimedOut;

  useEffect(() => {
    if (location?.state) {
      setLocationState(location?.state?.previousStep);
    }
  }, [location?.state]);

  useEffect(() => {
    if (showContent) {
      setConnect(false);
    }
  }, [showContent]);

  const processLane = useSelector(getProcessLane);

  const { progressBarTitle } = translations.inquiryType.cbBank.pages.offer;

  return !showContent ? (
    <Page translationStringForTitle={progressBarTitle} isNextButtonLoading>
      <LoadingPage />
    </Page>
  ) : (
    <Page translationStringForTitle={progressBarTitle} isNextButtonLoading={false}>
      {processLane === InquiryLane.contract ? (
        <ContractLaneSection locationState={locationState} />
      ) : (
        <LeadLaneSection />
      )}
    </Page>
  );
};
