import Joi, { ValidationErrorItem } from '@hapi/joi';

import { MAX_PHONE_NUMBER_LENGTH } from 'constants/globalConstants';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import { translations } from '../../../new/form/common/types';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const joiTranslations: { [key: string]: (t: Function, details: ValidationErrorItem) => string } = {
  'any.only': (t) => t('errors.required'),
  'any.required': (t) => t('errors.required'),
  'string.empty': (t) => t('errors.required'),
  'string.base': (t) => t('errors.required'),
  'string.length': (t) => t('errors.length'),
  'string.pattern.base': (t) => t('errors.onlyAlphabetical'),
  'number.min': (t, details) => t('errors.lowerBound', { number: details?.context?.limit }),
  'number.max': (t, details) => t('errors.upperBound', { number: details?.context?.limit }),
  'price.min': (t, details) => t('errors.minPrice', { amount: formatPrice(details?.context?.min) }),
  'price.max': (t, details) => t('errors.maxPrice', { amount: formatPrice(details?.context?.max) }),
  'string.max': (t, details) =>
    t('errors.maxLengthTextCharacters', { number: details?.context?.limit }),
  'date.min': (t) => t('errors.minYear'),
  'date.max': (t) => t('errors.maxYear'),
  'postal.valid': (t) => t('errors.zipCode'),
  'bank.iban.invalid': (t) => t('errors.iban'),
  'bank.iban.notGermanIban': (t) => t('errors.notGermanIban'),
  'bank.iban.noMatchingBankCode': (t) => t('error.noMatchingBankCode'),
  'bank.bic.invalid': (t) => t('errors.bic'),
  'number.base': (t) => t('errors.isNumber'),
  'number.unsafe': (t) => t('errors.tooBigNumber'),
  'number.integer': (t) => t('errors.isNumber'),
  'date.after2Years': (t) => t('errors.dateAfter2Years'),
  'date.afterXYears': (t, details) => t('errors.dateAfterXYears', { year: details?.context?.year }),
  'date.beforeYear': (t) => t('errors.beforeYear'),
  'number.positive': (t) => t('errors.isPositive'),
  'custom.higherThanLoanTerm': (t) => t('errors.upperBoundFrom'),
  'custom.coronaFoundingMonth': (t) => t('pages.coronaStep.fields.foundingYear.tooYoung'),
  'custom.coronaFoundingYear': (t) => t('pages.coronaStep.fields.foundingYear.tooYoung'),
  'custom.coronaViability': (t) => t('pages.coronaStep.fields.viability.mustBeTrue'),
  'custom.coronaViability2020': (t) => t('pages.coronaStep.fields.viability2020.mustBeTrue'),
  'custom.coronaEmployees': (t) => t('pages.coronaStep.fields.countOfEmployees.error.tooYoung'),
  'custom.financingOption': (t) => t('pages.coronaStep.fields.financingOption.error.toYoung'),
  'custom.isEmail': (t) => t('errors.isEmail'),
  'custom.isEmailAvailable': (t) => t('errors.isEmailAvailable'),
  'dateFormat.isDateFormat': (t) => t('errors.isDateFormat'),
  'dateFormat.isDateMin': (t, details) =>
    t('errors.isDateMin', { date: details?.context?.date.toLocaleString('ru-RU', dateOptions) }),
  'dateFormat.isDateMax': (t, details) =>
    t('errors.isDateMax', { date: details?.context?.date.toLocaleString('ru-RU', dateOptions) }),
  'yearFormat.isMinYear': (t, details) =>
    t(translations.errors.isMinYear, { year: details?.context?.year }),
  'yearFormat.isMaxYear': (t, details) =>
    t(translations.errors.isMaxYear, { year: details?.context?.year }),
  'phoneNumber.isPhoneNumber': (t) => t('errors.hasPhoneFormat'),
  'phoneNumber.isRealPhoneNumber': (t) => t('errors.isMobilePhoneNumber'),
  'phoneNumber.hasPhonePrefix': (t) => t('errors.isPhoneNumber'),
  'phoneNumber.minLength': (t, details) =>
    t('errors.minPhoneNumberLength', { number: details?.context?.minValue }),
  'phoneNumber.maxLength': (t) =>
    t('errors.maxPhoneNumberLength', { number: MAX_PHONE_NUMBER_LENGTH }),
  'stringBool.mustBeTrue': (t) => t('errors.mustBeTrue'),
  'only.numbers': (t) => t('errors.onlyNumbers'),
  'max.length': (t, details) => t('errors.maxLength', { length: details?.context?.length }),
  'min.length': (t, details) => t('errors.minLength', { length: details?.context?.length }),
  'custom.companyTaxNumber': (t) =>
    t('inquiryType.bfsService.steps.companyDetails.sections.fields.companyTaxNumber.error'),
  'custom.isNotEmpty': (t) => t('errors.isNotEmpty'),
  'custom.afterFewYears': (t, details) =>
    t('errors.yearAfterXYears', { year: details?.context?.year }),
  'custom.olderThan1900': (t) =>
    t('pages.financingNeed.sections.objectInformation.fields.buildingYear.errors.lowerBound'),
  'custom.afterCurrentYear': (t) =>
    t(
      'pages.financingNeed.sections.objectInformation.fields.modernizationYear.errors.afterCurrentYear',
    ),
  'custom.greaterThanX': (t, details) =>
    t('pages.financingNeed.sections.objectInformation.fields.numberOfUnits.errors.minLimit', {
      num: details?.context?.num,
    }),
  'custom.lessThanX': (t, details) =>
    t('pages.financingNeed.sections.objectInformation.fields.numberOfUnits.errors.maxLimit', {
      num: details?.context?.num,
    }),
  'custom.onlySpecific': (t, details) =>
    t(translations.errors.onlySpecific, { values: details?.context?.values.join(', ') }),
  'name.isValidName': (t) => t('errors.invalidName'),
};

const parseJoiError = (error: ValidationErrorItem, translator: Function) => {
  if (!error) {
    return null;
  }
  const joiTranslation = joiTranslations[error.type];
  if (!joiTranslation) {
    return error.type || ''; // fallback if there is some edge case
  }
  return joiTranslation(translator, error);
};

export const useErrorTranslator = () => {
  // some errors come as hapi/joi type format therefore we need to check if we should translate those
  const t = useTranslations();
  return (message: ValidationErrorItem | string) => {
    if (typeof message === 'object') {
      return parseJoiError(message, t);
    }
    return message;
  };
};

/**
 * Validates the given values against a Joi schema and translates any validation errors.
 *
 * @param {Record<string, any>} values - The values to validate.
 * @param {Joi.ObjectSchema} schema - The Joi schema to validate against.
 * @param errorTranslator - A function to translate error messages.
 * @returns An object representing the validation errors, with field paths as keys and translated error messages as values.
 */
export const formValidator = (
  values: Record<string, any>,
  schema: Joi.ObjectSchema<any>,
  errorTranslator: ReturnType<typeof useErrorTranslator>,
): Record<string, string> => {
  const { error } = schema.validate(values, { abortEarly: false });

  if (!error) return {};

  return error.details.reduce((errors, detail) => {
    const message = errorTranslator(detail); // Translate the error message
    // Accumulate errors, keying by the field path and assigning the translated message
    return {
      ...errors,
      [detail.path.join('.')]: message,
    };
  }, {});
};
