import React from 'react';

import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';

import StaticField from 'components/StaticField';
import { translations } from 'new/form/common/types';
import { withAssociatedPeopleEditMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/associatedPeopleMode/withAssociatedPeopleEditMode';
import { DzbPortalLegalRepresentative } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';
import { PotentiallyManipulatedWarning } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/PotentiallyManipulatedWarning';
import { translateSource } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/utils';
import { StyledOverviewFields, Subtitle } from 'pages/operationPortal/CompaniesDetails/styles';
import { AccordionComponent } from 'theme/components/Accordion';
import { formatDateDays } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';

type Props = {
  legalRepresentative: DzbPortalLegalRepresentative;
  setSelectedLegalRep: React.Dispatch<
    React.SetStateAction<DzbPortalLegalRepresentative | undefined>
  >;
  onEditModalOpen: () => void;
  onDeleteModalOpen: () => void;
  index: number;
};

export const DzbLegalRepresentativeAccordion = ({
  legalRepresentative,
  setSelectedLegalRep,
  onEditModalOpen,
  onDeleteModalOpen,
  index,
}: Props) => {
  const {
    legalRepresentatives: { fields },
    subheadings,
  } = translations.pages.companiesDetails.associatedPerson.new;
  const t = useTranslations();

  return (
    <AccordionComponent
      key={legalRepresentative.id}
      headingLevel={'h3'}
      mb={8}
      title={`${legalRepresentative.firstName} ${legalRepresentative.lastName}`}
      data-testId={`legalRepresentativeAccordion-${index}`}
      action={
        <EditModeActions
          legalRepresentative={legalRepresentative}
          setSelectedLegalRep={setSelectedLegalRep}
          onEditModalOpen={onEditModalOpen}
          onDeleteModalOpen={onDeleteModalOpen}
        />
      }
    >
      <StyledOverviewFields>
        <StaticField
          caption={t(fields.source.caption)}
          text={translateSource(legalRepresentative.source, t)}
        />
        <StaticField
          caption={t(fields.soleSignatureAuthorized.portalCaption)}
          text={
            legalRepresentative.soleSignatureAuthorized
              ? t(fields.soleSignatureAuthorized.values.soleRepresentative)
              : t(fields.soleSignatureAuthorized.values.notSoleRepresentative)
          }
        />
      </StyledOverviewFields>
      <Subtitle>{t(subheadings.contactInformation)}</Subtitle>
      <StyledOverviewFields>
        <StaticField caption={t(fields.email.caption)} text={legalRepresentative.email} />
        <StaticField
          caption={t(fields.phoneNumber.caption)}
          text={legalRepresentative.phoneNumber}
        />
      </StyledOverviewFields>
      <Subtitle>{t(subheadings.personalData)}</Subtitle>
      <StyledOverviewFields>
        <StaticField
          caption={t(fields.birthDate.caption)}
          text={formatDateDays(legalRepresentative.birthDate)}
        />
        <StaticField
          caption={t(fields.taxIdentificationNumber.caption)}
          text={legalRepresentative.taxIdentificationNumber}
        />
      </StyledOverviewFields>
    </AccordionComponent>
  );
};

type ActionProps = {
  legalRepresentative: DzbPortalLegalRepresentative;
  setSelectedLegalRep: React.Dispatch<
    React.SetStateAction<DzbPortalLegalRepresentative | undefined>
  >;
  onEditModalOpen: () => void;
  onDeleteModalOpen: () => void;
};

const Actions = ({
  legalRepresentative,
  setSelectedLegalRep,
  onEditModalOpen,
  onDeleteModalOpen,
}: ActionProps) => {
  return (
    <Flex gap={4}>
      <PotentiallyManipulatedWarning
        potentiallyManipulated={legalRepresentative.potentiallyManipulated}
      />

      <div data-testId={'editLrIcon'}>
        <EditIcon
          boxSize={5}
          display={'block'}
          cursor={'pointer'}
          color={'brand.default'}
          onClick={() => {
            setSelectedLegalRep(legalRepresentative);
            onEditModalOpen();
          }}
        />
      </div>

      <div data-testId={'deleteLrIcon'}>
        <DeleteIcon
          boxSize={5}
          display={'block'}
          color={'brand.default'}
          cursor={'pointer'}
          onClick={async () => {
            setSelectedLegalRep(legalRepresentative);
            onDeleteModalOpen();
          }}
        />
      </div>
    </Flex>
  );
};

const EditModeActions = withAssociatedPeopleEditMode(Actions);
