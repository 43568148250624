import React from 'react';

import { Asset } from 'components/Asset';
import { translations } from 'new/form/common/types';
import { FinalPageAction } from 'pages/inquiryFlow/FinalPage/actions/FinalPageAction';
import { InquiryAssetKeys } from 'store/partner/config/icons/inquiry';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const CbBankFinalDocumentsUploadAction = () => {
  const t = useTranslations();
  const { heading, description } = translations.inquiryType.cbBank.pages.final.documentsUpload;

  return (
    <FinalPageAction asset={<Asset type={'inquiry'} value={InquiryAssetKeys.DOCUMENT_UPLOAD} />}>
      <HeadingComponent as={'h3'} variant={'secondary'} mb={4}>
        {t(heading)}
      </HeadingComponent>
      <TextComponent mb={6}>{t(description)}</TextComponent>
    </FinalPageAction>
  );
};
