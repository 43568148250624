import React, { FC, useContext, useMemo } from 'react';

import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import Unit from 'components/Unit/Unit';
import { useFormConfig } from 'config/formConfig/hooks';
import { getFieldValidator } from 'modules/Inquiry/inquiryFieldValidation/validateField';
import { useFieldCaption } from 'modules/Inquiry/useFieldCaption';
import { useFieldPlaceholder } from 'modules/Inquiry/useFieldPlaceholder';
import { useFieldUnit } from 'modules/Inquiry/useFieldUnit';
import { ParentFieldContext } from 'modules/InquiryFormNew/ParentField.context';
import { useCanShowField } from 'new/form/hooks/useCanShowField';
import { InquiryConfigContext } from 'new/form/state/inquiryProcessConfig/context';
import { useCanShowField as useCanShowOldField } from 'pages/inquiryFlow/businessConditions/useCanShowField';
import { getProcessLane } from 'store/inquiryProcess/selectors';
import {
  getErrorMessageForField,
  getErrorMessageForStaticField,
} from 'utils/form/withForm.helpers';

interface IWithFormField {
  name: string;
  caption?: string;
  onChange?: Function;
  [key: string]: any;
}

type WithFormFieldOptions = {
  isStaticField?: boolean;
  showErrorsWhenPristine?: boolean;
};

const defaultOptions: WithFormFieldOptions = {
  isStaticField: false,
};

const withFormField =
  <T,>(Component: any, options: WithFormFieldOptions = defaultOptions): FC<IWithFormField> =>
  ({ name, caption = undefined, placeholder = undefined, onChange, initial, ...rest }) => {
    const { parentFieldName } = useContext(ParentFieldContext);
    const inquiryConfigContext = useContext(InquiryConfigContext);

    const processLane = useSelector(getProcessLane);
    const { selectedInquiryType } = useFormConfig();

    const validator = useMemo(
      () =>
        rest.validate && typeof rest.validate === 'function'
          ? rest.validate
          : getFieldValidator(name, selectedInquiryType, processLane),
      [rest.validate, name, selectedInquiryType, processLane],
    );
    const unitType = useFieldUnit(name);
    const defaultPlaceholder = placeholder || useFieldPlaceholder(name);
    const translatedCaption = caption || useFieldCaption(name);

    const fieldName = parentFieldName ? `${parentFieldName}.${name}` : name;

    const handleChange = (inputOnChange: Function, inputOnFocus: Function) => (value: any) => {
      if (rest.inputMaxLimit) value = value.substr(0, rest.inputMaxLimit);
      // save value in storage without spaces.
      inputOnChange(value); // save to final-form-state

      // Safari and Firefox on Mac doesn't apply focus state on clicked radio buttons/checkboxes
      // https://bugs.webkit.org/show_bug.cgi?id=19104
      inputOnFocus();
      if (onChange) {
        onChange(value);
      }
    };

    // check if we are in new inquiry flow
    let canShowNewField = true;
    if (inquiryConfigContext) {
      canShowNewField = useCanShowField(name);
    }

    const canShowOldField = useCanShowOldField(name);

    if (!canShowOldField || !canShowNewField) {
      return null;
    }

    return (
      <Field
        name={fieldName}
        validate={validator}
        validateFields={rest.validateFields}
        // @ts-ignore
        // FIXME this is incorrectly typed
        render={({ input, meta }) => {
          return (
            <Component
              input={input}
              onBlur={(e: any) => {
                // on blur remove white spaces
                if (rest.noWhiteSpaces) {
                  input.onChange(e.target?.value?.replace(/\s/g, ''));
                  if (onChange) {
                    onChange(e.target?.value?.replace(/\s/g, ''));
                  }
                }
                if (rest.trim) {
                  input.onChange(e.target?.value?.trim());
                  if (onChange) {
                    onChange(e.target?.value?.trim());
                  }
                }
                input.onBlur(e);
              }}
              meta={meta}
              placeholder={defaultPlaceholder}
              errorMessage={
                options.isStaticField
                  ? getErrorMessageForStaticField(meta)
                  : getErrorMessageForField(meta, options.showErrorsWhenPristine)
              }
              caption={rest.noCaption ? '' : translatedCaption}
              sideSymbol={unitType ? () => <Unit type={unitType} /> : undefined}
              onChange={handleChange(input.onChange, input.onFocus)}
              fieldName={fieldName}
              initial={initial}
              {...rest}
            />
          ) as unknown as T;
        }}
      />
    );
  };

export default withFormField;
