import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createMutation } from 'react-query-kit';
import { useSelector } from 'react-redux';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { translations } from 'new/form/common/types';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { Route } from 'utils/paths';

import { ASSOCIATED_PEOPLE_QUERY_KEY } from '../useFetchAssociatedPeople';

export function useDeleteLegalRepresentative(legalRepresentativeId: string) {
  const inquiryId = useSelector(getInquiryIdSelector);
  const toasts = useToasts();
  const t = useTranslations();
  const { successMessage, errorMessage } =
    translations.pages.companiesDetails.associatedPerson.new.legalRepresentatives.actions.delete;
  const queryClient = useQueryClient();
  const endpoint = useSelectedInquiryTypeSpecificValue<Route<
    string,
    Record<'id' | 'legalRepresentativeId', string>
  > | null>({
    [InquiryType.dzb]: endpoints.INQUIRIES.SPECIALIZED.DZB.LEGAL_REPRESENTATIVES.DELETE,
    [InquiryType.cbBank]: endpoints.INQUIRIES.SPECIALIZED.CB_BANK.LEGAL_REPRESENTATIVES.DELETE,
    [InquiryType.default]: null,
  });

  if (endpoint === null) {
    throw new Error(
      'No legal representative deletion endpoint defined for the selected inquiry type',
    );
  }

  const useDeleteLegalRepresentativeMutation = createMutation({
    mutationFn: async (variables: { inquiryId: string; legalRepresentativeId: string }) => {
      return EcoBankingAxiosClientAuthedInstance.delete(
        endpoint.compose({
          params: {
            id: variables.inquiryId,
            legalRepresentativeId: variables.legalRepresentativeId,
          },
        }),
      );
    },
  });

  const mutation = useDeleteLegalRepresentativeMutation({
    onSuccess: () => {
      toasts.success({ description: t(successMessage) });
      queryClient.invalidateQueries([ASSOCIATED_PEOPLE_QUERY_KEY]);
    },
    onError: () => {
      toasts.error({ description: t(errorMessage) });
    },
  });

  const mutateAsync = useCallback(() => {
    return mutation.mutateAsync({
      inquiryId,
      legalRepresentativeId,
    });
  }, [mutation, inquiryId, legalRepresentativeId]);

  return {
    ...mutation,
    mutateAsync,
  };
}
