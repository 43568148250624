import Joi from '@hapi/joi';

import {
  customValidations,
  fieldValidators,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import {
  DzbLegalRepresentative,
  LegalRepresentative,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/fields';

export const legalRepresentativeValidations: Record<
  keyof Omit<LegalRepresentative, 'id'>,
  Joi.AnySchema
> = {
  salutation: fieldValidators.string().required(),
  firstName: fieldValidators.name().isValidName().required(),
  lastName: fieldValidators.name().isValidName().optional(),
  birthDate: fieldValidators.date().max(Date.now()).optional(),
  email: fieldValidators.string().optional().custom(customValidations.isEmail),
  phoneNumber: fieldValidators
    .phoneNumber()
    .optional()
    .hasPhonePrefix()
    .isPhoneNumber()
    .maxPhoneNumberLength()
    .minPhoneNumberLength(),
  soleSignatureAuthorized: fieldValidators.boolean().optional(),
};

export const dzbLegalRepresentativesValidations: Record<
  keyof Omit<DzbLegalRepresentative, 'id'>,
  Joi.AnySchema
> = {
  ...legalRepresentativeValidations,
  lastName: legalRepresentativeValidations.lastName.required(),
  birthDate: legalRepresentativeValidations.birthDate.required(),
  email: legalRepresentativeValidations.email.required(),
  phoneNumber: fieldValidators.phoneNumber().isMobilePhoneNumber().required(),
  soleSignatureAuthorized: legalRepresentativeValidations.soleSignatureAuthorized.required(),
  taxIdentificationNumber: fieldValidators.string().optional().allow(null).allow(''),
};
