import React from 'react';

import { useField } from 'react-final-form';

import { cbBankFields } from 'cbbank/inquiry/fields';
import { InputWithField } from 'components/Input';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import { CardHeader, InputWrapper } from 'components/SMSVerification/styles';
import { ButtonComponent } from 'theme/components/Button';
import InfoIcon from 'theme/components/Icon/InfoIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

interface GetPhoneNumberProps {
  onSubmit: any;
  pending?: boolean;
}

export const GetPhoneNumber = ({ onSubmit, pending }: GetPhoneNumberProps) => {
  const t = useTranslations();
  const { phoneNumber } = cbBankFields.offerPage.contractLaneSection;
  const { meta } = useField(phoneNumber);

  return (
    <>
      <CardHeader data-testid="phone-number-verification">
        {t('components.smsVerification.header1CbBank')}
      </CardHeader>
      <TextComponent mt={100} display={'flex'} color={'brand.default'}>
        <InfoIcon boxSize={6} display="block" mr={4} />
        <MarkdownWrapper text={t('components.smsVerification.info4')} />
      </TextComponent>
      <InputWrapper>
        <InputWithField
          name={phoneNumber}
          placeholder={t('components.smsVerification.fields.mobileNumberPlaceholder')}
        >
          <ButtonComponent
            disabled={!meta.valid}
            flex="1 0 auto"
            isLoading={pending}
            onClick={onSubmit}
            testId="submitPhoneNumberButton"
          >
            {t('components.smsVerification.fields.submitPhoneNumber')}
          </ButtonComponent>
        </InputWithField>
      </InputWrapper>
    </>
  );
};
