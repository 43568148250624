import { useState, useEffect } from 'react';

import { SelectOption } from 'components/Selects/Select/StepsDropdown/types';
import { useFormConfig } from 'config/formConfig/hooks';
import { PurposeKind } from 'models/PurposeKind.model';
import { PURPOSE_KIND__REAL_ESTATE } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useTranslations } from 'utils/hooks/useTranslations';

export const usePurposeProducts = () => {
  const t = useTranslations();
  const [products, setProducts] = useState<Array<SelectOption>>([]);
  const [purposes, setPurposes] = useState<Array<SelectOption>>([]);
  const [selectedFormType, setSelectedFormType] = useState<InquiryType>();
  const [selectedPurpose, setSelectedPurpose] = useState<PurposeKind>();
  const { availableInquiryTypes, selectedInquiryType, formsConfig } = useFormConfig();

  // condition is needed to use it for rentenbank inquiry type only
  // otherwise we get MISSING_TRANSLATION console errors for our other inquiry types
  const formTypes =
    availableInquiryTypes.length > 1
      ? availableInquiryTypes.map((type) => ({
          value: type,
          label: t(`data.purposeKind.${type}`),
        }))
      : [];

  useEffect(() => {
    const selectedConfig = formsConfig[selectedFormType || selectedInquiryType];
    if (selectedConfig) {
      setPurposes(
        Object.keys(selectedConfig).map((item) => ({
          value: item,
          label: t(`data.purposeKind.${item}`),
        })),
      );
      if (selectedPurpose) {
        const selectedProducts =
          selectedPurpose === PURPOSE_KIND__REAL_ESTATE
            ? []
            : selectedConfig[selectedPurpose] || [];

        setProducts(
          selectedProducts.map((item: string) => ({
            value: item,
            label: t(`data.financingProduct.${item}`),
          })),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormType, selectedInquiryType, selectedPurpose, t]);

  return {
    formTypes,
    purposes,
    products,
    setSelectedPurpose,
    setSelectedFormType,
  };
};
