import React from 'react';

import { Image } from '@chakra-ui/react';

import { AssetType, useAssets } from 'shared/hooks/useAssets';
import { getIconValue } from 'store/partner/config/icons';

interface AssetProps {
  type: AssetType;
  value: string;
  htmlHeight?: string;
  alt?: string;
}

export const Asset: React.FC<AssetProps> = ({ type, value, htmlHeight, alt = '', ...rest }) => {
  const assets = useAssets();

  const asset = assets(type, value);

  if (typeof asset === 'string') {
    return <Image src={getIconValue(asset)} htmlHeight={htmlHeight} alt={alt} {...rest} />;
  } else {
    const SVGComponent = asset;
    return <SVGComponent {...rest} />;
  }
};
