import React from 'react';
import { ReactNode } from 'react';

import { useSelector } from 'react-redux';

import StaticField from 'components/StaticField';
import { ICompanyBaseData } from 'models/CompanyDetails.model';
import { getTranslationPath } from 'modules/Inquiry/Form/Steps/UserProfile/ProfileIndustry/service';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';
import { formatMonthDashYear, formatYear } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice } from 'utils/valueFormats';

import { getCompanyDetails } from '../store/selectors';

// Type definitions for field names, section types field declaration, etc.
type TranslationFunction = (id: string) => string;

enum CompanyOverviewFieldName {
  CompanyName = 'companyName',
  LegalForm = 'legalForm',
  FoundingDate = 'foundingDate',
  RegisterNumber = 'registerNumber',
  RegisterCourt = 'registerCourt',
  Street = 'street',
  ZipCode = 'zipCode',
  City = 'city',
  TaxNumber = 'taxNumber',
  RegisterCity = 'registerCity',
  TaxVatNumber = 'taxVatNumber',
  PhoneNumber = 'phoneNumber',
  FaxNumber = 'faxNumber',
  Email = 'email',
  Website = 'website',
  CompanyStaff = 'companyStaff',
  CompanyTurnover = 'companyTurnover',
  BalanceSheet = 'balanceSheet',
  Iban = 'iban',
  AlreadyCustomer = 'alreadyCustomer',
  DetailedAnnualTurnover = 'detailedAnnualTurnover',
  CountOfEmployees = 'countOfEmployees',
  DebtServiceAbility = 'debtServiceAbility',
  BusinessPurpose = 'businessPurpose',
  Industry = 'industry',
  LegalEntityIdentifier = 'LegalEntityIdentifier',
}

enum CompanyOverviewSectionType {
  Summary = 'summary',
  Contact = 'contact',
}

interface Base {
  name: CompanyOverviewFieldName;
  caption: string;
  section?: CompanyOverviewSectionType;
}

interface ICompanyOverviewFieldDeclaration extends Base {
  text: (companyDetails: ICompanyBaseData) => string | ((...args: any[]) => string);
  condition?: (companyDetails: ICompanyBaseData) => boolean;
}

export interface IHydratedCompanyOverviewFieldDeclaration extends Base {
  text: string;
}

type SummaryFields = Record<CompanyOverviewSectionType, ReactNode[]>;

const foundingDateCaption = chooseSelectedInquiryTypeSpecificValue({
  [InquiryType.hausbank]: 'pages.companiesDetails.overview.foundingYear',
  [InquiryType.default]: 'pages.companiesDetails.overview.foundingDate',
});

const INVALID_DATE = 'Invalid date';

/* Define all company overview field declarations which will be used later on to map the right content to the form fields being returned.
This way the form is modular and it is ensured that fields are structured in a unified way */
const companyOverviewFields: ICompanyOverviewFieldDeclaration[] = [
  {
    name: CompanyOverviewFieldName.CompanyName,
    caption: 'pages.companiesDetails.overview.companyName',
    text: ({ companyName }) => companyName,
  },
  {
    name: CompanyOverviewFieldName.LegalForm,
    caption: 'pages.companiesDetails.overview.legalForm',
    text: ({ legalForm }) => legalForm,
  },
  {
    name: CompanyOverviewFieldName.FoundingDate,
    caption: foundingDateCaption,
    text: ({ foundingDate }) => {
      const monthYear = formatMonthDashYear(foundingDate);
      const year = formatYear(foundingDate);

      if (monthYear === INVALID_DATE && year === INVALID_DATE) {
        return '-';
      }

      return monthYear === INVALID_DATE ? year : monthYear;
    },
  },
  {
    name: CompanyOverviewFieldName.RegisterNumber,
    caption: 'pages.companiesDetails.overview.registerNumber',
    text: ({ registerNumber }) => registerNumber,
  },
  {
    name: CompanyOverviewFieldName.RegisterCourt,
    caption: 'pages.companiesDetails.overview.registerCourt',
    text: ({ districtCourt, registerCity }) =>
      districtCourt && registerCity ? `${districtCourt}, ${registerCity}` : '',
  },
  {
    name: CompanyOverviewFieldName.Street,
    caption: 'pages.companiesDetails.overview.street',
    text: ({ street }) => street,
  },
  {
    name: CompanyOverviewFieldName.ZipCode,
    caption: 'pages.companiesDetails.overview.zipCode',
    text: ({ zipCode }) => zipCode,
  },
  {
    name: CompanyOverviewFieldName.City,
    caption: 'pages.companiesDetails.overview.city',
    text: ({ city }) => city,
  },
  {
    name: CompanyOverviewFieldName.TaxNumber,
    caption: 'pages.companiesDetails.overview.taxNumber',
    text: ({ taxNumber }) => taxNumber,
  },
  {
    name: CompanyOverviewFieldName.RegisterCity,
    caption: 'pages.companiesDetails.overview.registerCity',
    text: ({ registerCity }) => registerCity,
  },
  {
    name: CompanyOverviewFieldName.TaxVatNumber,
    caption: 'pages.companiesDetails.overview.taxVatNumber',
    text: ({ taxVatNumber }) => taxVatNumber,
  },
  {
    name: CompanyOverviewFieldName.PhoneNumber,
    caption: 'pages.companiesDetails.overview.phone',
    text: ({ phoneNumber }) => phoneNumber,
    section: CompanyOverviewSectionType.Contact,
  },
  {
    name: CompanyOverviewFieldName.FaxNumber,
    caption: 'pages.companiesDetails.overview.fax',
    text: ({ faxNumber }) => faxNumber,
    section: CompanyOverviewSectionType.Contact,
  },
  {
    name: CompanyOverviewFieldName.Email,
    caption: 'pages.companiesDetails.overview.email',
    text: ({ email }) => email,
    section: CompanyOverviewSectionType.Contact,
  },
  {
    name: CompanyOverviewFieldName.Website,
    caption: 'pages.companiesDetails.overview.website',
    text: ({ website }) => website,
    section: CompanyOverviewSectionType.Contact,
  },
  {
    name: CompanyOverviewFieldName.CompanyStaff,
    caption: 'pages.companiesDetails.overview.companyStaff',
    text: ({ companyStaff }) => companyStaff.toString(),
  },
  {
    name: CompanyOverviewFieldName.CompanyTurnover,
    caption: 'pages.companiesDetails.overview.companyTurnover',
    text: ({ companyTurnover }) => formatPrice(companyTurnover) as string,
  },
  {
    name: CompanyOverviewFieldName.BalanceSheet,
    caption: 'pages.companiesDetails.overview.balanceSheet',
    text: ({ balanceSheet }) => balanceSheet,
  },
  {
    name: CompanyOverviewFieldName.Iban,
    caption: 'pages.companiesDetails.overview.iban',
    text: ({ iban }) => iban,
    condition: ({ alreadyCustomer }) => Boolean(alreadyCustomer),
  },
  {
    name: CompanyOverviewFieldName.AlreadyCustomer,
    caption: 'pages.companiesDetails.overview.alreadyCustomer',
    text:
      ({ alreadyCustomer }) =>
      (t: TranslationFunction) =>
        t(`other.${alreadyCustomer}`),
  },
  {
    name: CompanyOverviewFieldName.DetailedAnnualTurnover,
    caption: 'pages.companiesDetails.overview.detailedAnnualTurnover',
    text: ({ detailedAnnualTurnover }) => formatPrice(detailedAnnualTurnover) as string,
  },
  {
    name: CompanyOverviewFieldName.CountOfEmployees,
    caption: 'pages.companiesDetails.overview.countOfEmployees',
    text: ({ countOfEmployees }) => countOfEmployees.toString(),
  },
  {
    name: CompanyOverviewFieldName.DebtServiceAbility,
    caption: 'pages.companiesDetails.overview.debtServiceAbility',
    text: ({ debtServiceAbility }) => formatPrice(debtServiceAbility) as string,
  },
  {
    name: CompanyOverviewFieldName.BusinessPurpose,
    caption: 'pages.companiesDetails.overview.businessPurpose',
    text: ({ businessPurpose }) => businessPurpose,
  },
  {
    name: CompanyOverviewFieldName.Industry,
    caption: 'pages.companiesDetails.overview.industry',
    text: ({ industry }) =>
      industry.length ? (t: TranslationFunction) => t(getTranslationPath(industry[0])) ?? '' : '',
  },
  {
    name: CompanyOverviewFieldName.LegalEntityIdentifier,
    caption: 'pages.companiesDetails.overview.legalEntityIdentifier',
    text: ({ legalEntityIdentifier }) => legalEntityIdentifier,
  },
];

// Define the fields that will be used as default for the company overview form
const defaultFields = [
  // Summary
  CompanyOverviewFieldName.FoundingDate,
  CompanyOverviewFieldName.RegisterNumber,
  CompanyOverviewFieldName.RegisterCourt,
  CompanyOverviewFieldName.LegalForm,
  CompanyOverviewFieldName.BusinessPurpose,
  CompanyOverviewFieldName.Industry,
  CompanyOverviewFieldName.LegalEntityIdentifier,
  CompanyOverviewFieldName.TaxNumber,
  CompanyOverviewFieldName.TaxVatNumber,
  CompanyOverviewFieldName.Iban,

  // Contact
  CompanyOverviewFieldName.PhoneNumber,
  CompanyOverviewFieldName.FaxNumber,
  CompanyOverviewFieldName.Email,
  CompanyOverviewFieldName.Website,
];

// Define which fields will be returned for which inquiry type (partner). Add fields to array in the display order.
const inquiryTypeFields: { [type in InquiryType]: CompanyOverviewFieldName[] } = {
  [InquiryType.default]: defaultFields,
  [InquiryType.onlinefactoring]: defaultFields,
  [InquiryType.leaseplan]: defaultFields,
  [InquiryType.bfs]: defaultFields,
  [InquiryType.profiRLL]: defaultFields,
  [InquiryType.profiMittweida]: defaultFields,
  [InquiryType.bfsService]: defaultFields,
  [InquiryType.mmv]: defaultFields,
  [InquiryType.hausbank]: [
    // Summary
    CompanyOverviewFieldName.FoundingDate,
    CompanyOverviewFieldName.LegalForm,
  ],
  [InquiryType.dzb]: [
    // Summary
    CompanyOverviewFieldName.FoundingDate,
    CompanyOverviewFieldName.LegalForm,
  ],
  [InquiryType.cbBank]: defaultFields,
};

// Define the empty field content for each inquiry type (partner)
// FIXME using ExtendedInquiryType here
const inquiryTypeEmptyFieldContent: Record<InquiryType, string> = {
  [InquiryType.default]: '-',
  [InquiryType.onlinefactoring]: '-',
  [InquiryType.leaseplan]: '-',
  [InquiryType.bfs]: '-',
  [InquiryType.profiRLL]: '-',
  [InquiryType.profiMittweida]: '-',
  [InquiryType.bfsService]: '-',
  [InquiryType.mmv]: '-',
  [InquiryType.dzb]: '-',
  [InquiryType.hausbank]: '-',
  [InquiryType.cbBank]: '-',
};

export const useCompanyOverviewFields = (): SummaryFields => {
  const inquiryType = useSelector(getInquiryFormTypeSelector) as InquiryType;
  const t = useTranslations();
  const companyDetails = useSelector(getCompanyDetails);
  const fields = inquiryTypeFields[inquiryType] ?? inquiryTypeFields.default;

  // Picks the correct field declaration for the provided field name and hydrates it
  const getFieldDeclaration = (
    fieldName: CompanyOverviewFieldName,
  ): IHydratedCompanyOverviewFieldDeclaration | undefined => {
    const fieldDeclaration = companyOverviewFields.find(
      (field) => field.name === fieldName,
    ) as ICompanyOverviewFieldDeclaration;

    if (fieldDeclaration) {
      // Checks if the condition (if available) to show this field is met
      if (fieldDeclaration.condition && !fieldDeclaration.condition(companyDetails)) {
        return undefined;
      }

      /* Takes the existing fieldDeclaration and replaces the text function with the return
      value of this function (translated key) if there was a function provided in the declaration */
      const text = fieldDeclaration.text(companyDetails);
      const hydratedFieldDeclaration = {
        ...fieldDeclaration,
        caption: t(fieldDeclaration.caption),
        text: typeof text === 'function' ? text(t) : text,
      };
      // Changes the value of the hydrated field declaration's text if the text was an empty string (not available)
      hydratedFieldDeclaration.text =
        hydratedFieldDeclaration.text === ''
          ? inquiryTypeEmptyFieldContent[inquiryType]
          : hydratedFieldDeclaration.text;
      return hydratedFieldDeclaration;
    }
    return undefined;
  };

  const defaultMapToField = (field: IHydratedCompanyOverviewFieldDeclaration): ReactNode => (
    <StaticField
      key={field.name}
      caption={field.caption}
      text={field.text}
      data-testid={field.name}
    />
  );

  const fieldDeclarations = fields
    .map(getFieldDeclaration)
    // Only keeps the field declarations which are defined
    .filter(Boolean) as IHydratedCompanyOverviewFieldDeclaration[];

  // Maps the field declarations to form fields for the respective end-component
  const summaryFields: SummaryFields = {
    [CompanyOverviewSectionType.Summary]: fieldDeclarations
      .filter(({ section }) => !section)
      .map(defaultMapToField),
    [CompanyOverviewSectionType.Contact]: fieldDeclarations
      .filter(({ section }) => section === CompanyOverviewSectionType.Contact)
      .map(defaultMapToField),
  };

  return summaryFields;
};
