import { cbBankFields } from 'cbbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateFactoringNeedSection: ValidateSectionFunction<InquiryType.cbBank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap<InquiryType.cbBank>({
    validationMap: factoringNeedSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { annualFactoringRelevantTurnover, paymentTerm } =
  cbBankFields.financingNeedPage.factoringNeedSection;

export const factoringNeedSectionValidationMap = {
  [annualFactoringRelevantTurnover]: fieldValidators.number().required(),
  [paymentTerm]: fieldValidators.string().required(),
};
