import React from 'react';

import { useIntl } from 'react-intl';

import { CbBankBeneficialOwner } from 'cbbank/inquiry/fields';
import { DayPickerWithField } from 'components/DatePicker';
import { INPUT_TYPE_NUMBER, InputWithField } from 'components/Input';
import { SelectWithField } from 'components/Selects/Select';
import { translations } from 'new/form/common/types';
import {
  FieldConfig,
  useGenerateField,
} from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/formFields/useGenerateField';
import { AssociatedPersonMode } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/types';
import { useGetCountryOptions } from 'shared/options/country';
import { useGetNationalityOptions } from 'shared/options/nationality';

const { fields } = translations.pages.companiesDetails.associatedPerson.new.beneficialOwners;

type CbBankBeneficialOwnerWithoutId = Omit<CbBankBeneficialOwner, 'id'>;

type BeneficialOwnerFields = Record<keyof CbBankBeneficialOwnerWithoutId, React.ReactNode>;

type PrecalculatedProps = {
  countryOptions: ReturnType<typeof useGetCountryOptions>;
  nationalityOptions: ReturnType<typeof useGetNationalityOptions>;
};

export const cbBankBeneficialOwnerFieldConfig: FieldConfig<
  CbBankBeneficialOwnerWithoutId,
  PrecalculatedProps
> = {
  firstName: {
    component: InputWithField,
    caption: fields.firstName.caption,
  },
  lastName: {
    component: InputWithField,
    caption: fields.lastName.caption,
  },
  nationality: {
    component: SelectWithField,
    caption: fields.nationality.caption,
    componentProps: (_, { nationalityOptions }) => ({
      options: nationalityOptions,
    }),
  },
  capitalOrVotingRightsPercentage: {
    componentProps: (translation) => ({
      type: INPUT_TYPE_NUMBER,
      sideSymbol: () => translation(translations.other.percent),
    }),
    component: InputWithField,
    caption: fields.capitalOrVotingRightsPercentage.caption,
  },
  placeOfResidence: {
    component: InputWithField,
    caption: fields.placeOfResidence.caption,
    optional: true,
  },
  countryOfResidence: {
    component: SelectWithField,
    caption: fields.countryOfResidence.caption,
    componentProps: (_, { countryOptions }) => ({
      options: countryOptions,
    }),
  },
  birthPlace: {
    component: InputWithField,
    caption: fields.birthPlace.caption,
    optional: true,
  },
  birthDate: {
    componentProps: () => ({
      maxDate: new Date(),
    }),
    component: DayPickerWithField,
    caption: fields.birthDate.caption,
    optional: true,
  },
};

export function useGenerateCbBankkBeneficialOwnerFields(
  mode: AssociatedPersonMode,
  prefix?: string,
): BeneficialOwnerFields {
  const { formatMessage } = useIntl();

  const countryOptions = useGetCountryOptions(formatMessage);
  const nationalityOptions = useGetNationalityOptions(formatMessage);

  const precalculatedProps = { countryOptions, nationalityOptions };
  const generateField = useGenerateField<CbBankBeneficialOwnerWithoutId, PrecalculatedProps>(
    cbBankBeneficialOwnerFieldConfig,
    mode,
    prefix,
    precalculatedProps,
  );

  return {
    firstName: generateField('firstName'),
    lastName: generateField('lastName'),
    nationality: generateField('nationality'),
    capitalOrVotingRightsPercentage: generateField('capitalOrVotingRightsPercentage'),
    placeOfResidence: generateField('placeOfResidence'),
    countryOfResidence: generateField('countryOfResidence'),
    birthPlace: generateField('birthPlace'),
    birthDate: generateField('birthDate'),
  };
}
