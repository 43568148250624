import { CbBankFieldTypes } from '../fields';

export const cbBankInitialFormValues: CbBankFieldTypes = {
  financingNeedPage: {
    factoringNeedSection: {
      annualFactoringRelevantTurnover: undefined,
      paymentTerm: undefined,
      factoringLine: undefined,
    },
    businessDetailsSection: {
      turnoverShareOfLargestClient: undefined,
      centralisedSettlement: undefined,
      tradeCreditInsurance: undefined,
      downPaymentOrInstallments: undefined,
      assignedElsewhere: undefined,
      customersAbroad: undefined,
    },
  },
  companyDataPage: {
    companySearchSection: {
      crefoId: undefined,
      selectedCompanyName: undefined,
    },
    companyDetailsCompletionSection: {
      name: undefined,
      street: undefined,
      zipCode: undefined,
      city: undefined,
      legalForm: undefined,
      industry: undefined,
      olderThan2Years: undefined,
      subjectToAccounting: undefined,
      positiveEquity: undefined,
      positiveAnnualResult: undefined,
    },
    bankAccountSection: {
      iban: undefined,
      bic: undefined,
      bankName: undefined,
    },
    complianceSection: {
      termsOfService: undefined,
      dataProtection: undefined,
      factoringTerms: undefined,
    },
  },
  offerPage: {
    contractLaneSection: {
      phoneNumber: undefined,
      factoringFee: undefined,
      factoringLine: undefined,
      payoutRatio: undefined,
      pinNumber: undefined,
    },
  },
  personalDataPage: {
    userDataSection: {
      salutation: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
    },
    legalRepresentativesSection: {
      legalRepresentatives: [
        {
          id: undefined,
          salutation: undefined,
          firstName: undefined,
          lastName: undefined,
          birthDate: undefined,
          email: undefined,
          phoneNumber: undefined,
          soleSignatureAuthorized: undefined,
        },
      ],
    },
    beneficialOwnersSection: {
      allInformationAvailable: undefined,
      beneficialOwners: [
        {
          id: undefined,
          firstName: undefined,
          lastName: undefined,
          birthDate: undefined,
          nationality: undefined,
          birthPlace: undefined,
          countryOfResidence: undefined,
          placeOfResidence: undefined,
          capitalOrVotingRightsPercentage: undefined,
        },
      ],
    },
  },
};
