import PinField from 'react-pin-field';
import styled from 'styled-components';

import InputContainer from 'components/Input/InputWrapper/InputContainer';
import LinkButton from 'components/LinkButton';
import StyledInput from 'components/TextInput/StyledInput';
import { FONT_WEIGHT_MEDIUM } from 'constants/globalConstants';
import { mqSmall, mqMedium, mqLarge, mqExtraLarge } from 'styles/breakpoints';
import { borderRadius, boxShadow } from 'theme/themeConstants';

export const Card = styled.div`
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
  box-shadow: ${boxShadow.default};

  ${mqMedium`
    padding: 2rem 3rem;
  `};

  ${mqLarge`
    padding: 3rem 4rem;
  `};
`;

export const CardHeader = styled.h3`
  margin-bottom: 1.5rem;
  font-size: 1.33rem;
  font-weight: ${FONT_WEIGHT_MEDIUM};
  color: ${(props) => props.theme.colors.text.primary};
`;

export const InputWrapper = styled.div`
  padding-top: 2rem;

  ${InputContainer} {
    flex-direction: column;
    align-items: stretch;

    ${mqMedium`
      flex-direction: row;
      align-items: center;
    `};
  }

  ${StyledInput} {
    margin-bottom: 1rem;
    padding: 1rem;

    ${mqSmall`
      padding: 1.25rem;
    `};

    ${mqMedium`
      margin-right: 2rem;
      margin-bottom: 0;
    `};
  }

  button {
    white-space: nowrap;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  margin-bottom: 2.5rem;
`;

export const PinEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqExtraLarge`
    flex-direction: row;
  `};
`;

export const PinEntryGroup = styled.div`
  display: flex;
  margin-bottom: 1rem;

  ${mqExtraLarge`
    flex: 1 1 0%;
    margin-right: 1.5rem;
    margin-bottom: 0;
  `};
`;

export const PinEntry = styled(PinField)`
  flex: 1 1 auto;
  width: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.border.lightGrey};
  border-radius: ${borderRadius.default};
  padding: 1rem 0;
  font-size: 1.75rem;
  text-align: center;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  ${mqSmall`
    &:not(:last-child) {
      margin-right: 1rem;
    }
  `};

  ${mqExtraLarge`
    padding: 1rem 0.5rem;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  `};
`;

export const TwoSectionPinEntry = styled(PinEntry)`
  &:nth-child(3) {
    margin-right: 1rem;
  }

  ${mqSmall`
    &:nth-child(3) {
      margin-right: 2rem;
    }
  `};

  ${mqExtraLarge`
    &:nth-child(3) {
      margin-right: 3.5rem;
    }
  `};
`;

export const ConditionsWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.5rem;
`;

export const ValueBox = styled.div`
  border-radius: ${borderRadius.large};
  padding: 2rem;
  text-align: right;
  color: ${(props) => props.theme.colors.text.white};
  background-color: ${(props) => props.theme.colors.background.blackGrey};
`;

export const Rate = styled.div`
  font-weight: ${FONT_WEIGHT_MEDIUM};

  .wrapper {
    flex-direction: column-reverse;
  }

  .caption {
    display: block;
    color: ${(props) => props.theme.colors.text.white};
  }

  .value {
    font-size: 3rem;
    color: ${(props) => props.theme.colors.text.white};
  }
`;

export const Unit = styled.span`
  margin-left: 0.25rem;
  font-size: 1.5rem;
`;

export const Description = styled.div`
  margin-top: 1.25rem;
`;
