import {
  CbBankFieldTypes,
  cbBankFields,
  cbBankFieldsWithSectionString,
  cbBankFieldsWithoutParents,
} from 'cbbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  customValidations,
  fieldValidators,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateArrayFields, ValidateSectionFunction } from 'new/form/useValidateFields';
import { parseStringBoolean } from 'utils/helpers';

export const validateLegalRepresentativesSection: ValidateSectionFunction<InquiryType.cbBank> = (
  form,
  values,
  store,
  conditions,
) => {
  const {
    salutation,
    firstName,
    lastName,
    birthDate,
    email,
    phoneNumber,
    soleSignatureAuthorized,
  } = cbBankFieldsWithoutParents.personalDataPage.legalRepresentativesSection.legalRepresentatives;

  const isSoleAuthorizationValid = isValidLegalRepresentatives(values);

  if (isSoleAuthorizationValid) {
    return validateArrayFields({
      arrayValues: values.personalDataPage.legalRepresentativesSection.legalRepresentatives || [],
      fieldsWithoutParent: [
        salutation,
        firstName,
        lastName,
        birthDate,
        email,
        phoneNumber,
        soleSignatureAuthorized,
      ],
      parentName:
        cbBankFieldsWithSectionString.personalDataPage.legalRepresentativesSection
          .legalRepresentatives.sectionString,
      form,
      values,
      conditions,
    });
  }

  return false;
};

const { salutation, firstName, lastName, birthDate, email, phoneNumber, soleSignatureAuthorized } =
  cbBankFields.personalDataPage.legalRepresentativesSection.legalRepresentatives;

export const legalRepresentativesSectionValidationMap = {
  [salutation]: fieldValidators.string().required(),
  [firstName]: fieldValidators.name().required().isValidName(),
  [lastName]: fieldValidators.name().required().isValidName(),
  [birthDate]: fieldValidators.date().required().max(Date.now()),
  [email]: fieldValidators.string().required().custom(customValidations.isEmail),
  [phoneNumber]: fieldValidators.phoneNumber().required().isMobilePhoneNumber(),
  [soleSignatureAuthorized]: fieldValidators.boolean().required(),
};

export const isValidLegalRepresentatives = (values: CbBankFieldTypes): boolean => {
  const legalRepresentatives =
    values.personalDataPage.legalRepresentativesSection.legalRepresentatives;

  // No legal representatives, assume it's invalid
  if (!legalRepresentatives || legalRepresentatives.length === 0) {
    return false;
  }

  // If there is only one legal representative he must be sole authorized
  if (legalRepresentatives.length === 1) {
    const [legalRepresentative] = legalRepresentatives; // Destructuring to get the first element

    if (
      legalRepresentative &&
      parseStringBoolean(legalRepresentative.soleSignatureAuthorized) === false
    ) {
      return false;
    }
  }

  return true;
};
