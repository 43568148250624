import React from 'react';

import PageHeading from 'components/PageHeading';
import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';
import { useTranslations } from 'utils/hooks/useTranslations';

import { BusinessDetailsSection } from './sections/businessDetails';
import { FactoringNeedSection } from './sections/factoringNeed';

export const CbBankFinancingNeedPage = () => {
  const t = useTranslations();

  const {
    progressBarTitle,
    heading: { header },
  } = translations.inquiryType.cbBank.pages.financingNeed;

  return (
    <Page translationStringForTitle={progressBarTitle}>
      <PageHeading heading={t(header)} />
      <FactoringNeedSection />
      <BusinessDetailsSection />
    </Page>
  );
};
