import { cbBankFields } from 'cbbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldValidators } from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateFieldValidationMap, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateBankAccountSection: ValidateSectionFunction<InquiryType.cbBank> = (
  form,
  values,
  store,
  conditions,
) => {
  return validateFieldValidationMap<InquiryType.cbBank>({
    validationMap: bankAccountSectionValidationMap,
    form,
    values,
    conditions,
  });
};

const { iban } = cbBankFields.companyDataPage.bankAccountSection;

export const bankAccountSectionValidationMap = {
  [iban]: fieldValidators.bank().isGermanIban().isValidIban().required(),
};
